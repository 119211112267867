import { EmergencyPage } from './emergency.component';
import { connect } from 'react-redux';
import { getEmergency, createEmergency, updateEmergency, deleteEmergency } from "./../../api/emergrncy/emergrncyAction";

const mapDispatchToProps = {
    getEmergency,
    createEmergency,
    updateEmergency,
    deleteEmergency
};

const mapStateToProps = state => ({
    error: state.emergencyPage.isError,
    isLoading: state.emergencyPage.isLoading,
    isSaved: state.emergencyPage.isSaved,
    emergencys: state.emergencyPage.emergencys,
    count: state.emergencyPage.count,
});

export const EmergencyContainer = connect(mapStateToProps, mapDispatchToProps)(EmergencyPage);