import { CaregiverPage } from './caregiver.component';
import { connect } from 'react-redux';
import { getCaregiver, createCaregiver, updateCaregiver, deleteCaregiver } from "./../../api/caregiver/caregiverAction";

const mapDispatchToProps = {
    getCaregiver,
    createCaregiver,
    updateCaregiver,
    deleteCaregiver
};

const mapStateToProps = state => ({
    error: state.caregiverPage.isError,
    isLoading: state.caregiverPage.isLoading,
    isSaved: state.caregiverPage.isSaved,
    caregivers: state.caregiverPage.caregivers,
    count: state.caregiverPage.count,
});

export const CaregiverContainer = connect(mapStateToProps, mapDispatchToProps)(CaregiverPage);