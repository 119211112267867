import React from 'react';
import { makeStyles } from '@mui/styles';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import Typography from '@mui/material/Typography';
import Slide from '@mui/material/Slide';
import Grid from '@mui/material/Grid';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import DialogContent from "@mui/material/DialogContent";
import Input from "@mui/material/Input";
import TextField from '@mui/material/TextField';
import Divider from "@mui/material/Divider";
import InputAdornment from "@mui/material/InputAdornment";
import ClearIcon from "@mui/icons-material/Clear";
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  paper: {
    marginTop: theme.spacing(3),
    padding: theme.spacing(3),
  },
  formControl: {
    width: '100%',
    spacing: theme.spacing(2),
  },
  inputLabelText: {
    fontSize: "14px !important",
    color: "rgba(0,0,0,.5) !important",
    fontWeight: "600 !important",
    textTransform: "uppercase",
    marginTop: "0px"
  },
  mdText1: {
    color: "red",
    fontSize: "12px",
    fontWeight: 400,
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

export default function VitalDialoge(props) {
  const { handleClose, open, errors, selected, setSelected, validate, custom, addCustom, deleteCustom, updateCustom } = props;
  const classes = useStyles();

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        // scroll="paper"
        TransitionComponent={Transition}
        keepMounted
        maxWidth="md"
        fullWidth={true}
      >
        <DialogContent>
          <ClearIcon
            onClick={handleClose}
            style={{ width: "25px", height: "25px", float: "right" }}
          />
          <div
            style={{
              marginTop: "10px",
              marginBottom: "20px",
            }}
          >
            <Typography
              style={{
                fontSize: "30px",
                fontWeight: 400,
                color: "rgba(0,0,0,.6)",
              }}
            >
              Vital
            </Typography>
          </div>
          <form
            noValidate
            onSubmit={(e) => {
              e.preventDefault();
              validate(selected);
            }}
            id="form">
            <Grid container spacing={2}>
              <Grid item xs={12} md={4} style={{ marginBottom: "15px" }}>
                <FormControl fullWidth variant="standard" required>
                  <InputLabel htmlFor="name" className={classes.inputLabelText}>
                    Name
                  </InputLabel>
                  <Input
                    id="name"
                    startAdornment={
                      <InputAdornment position="start"></InputAdornment>
                    }
                    margin="none"
                    name='name'
                    value={selected.name}
                    onChange={e => setSelected({ ...selected, name: e.target.value })}
                  />
                  {errors && errors.name && (
                    <p className={classes.mdText1}>{errors.name}</p>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={12} md={4} style={{ marginBottom: "15px" }}>
                <FormControl fullWidth variant="standard" required>
                  <InputLabel htmlFor="code" className={classes.inputLabelText}>
                    Code
                  </InputLabel>
                  <Input
                    id="code"
                    startAdornment={
                      <InputAdornment position="start"></InputAdornment>
                    }
                    margin="none"
                    name='code'
                    value={selected.code}
                    onChange={e => setSelected({ ...selected, code: e.target.value })}
                  />
                  {errors && errors.code && (
                    <p className={classes.mdText1}>{errors.code}</p>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={12} md={3} style={{ marginBottom: "15px" }}>
                <FormControl fullWidth variant="standard" required>
                  <InputLabel id="status">Status</InputLabel>
                  <Select
                    labelId="status"
                    id="status"
                    value={selected.status}
                    onChange={e => setSelected({ ...selected, status: e.target.value })}
                    label="status"
                  >
                    <MenuItem value={1}>Enable</MenuItem>
                    <MenuItem value={0}>Disable</MenuItem>
                  </Select>
                  {errors && errors.status && (
                    <p className={classes.mdText1}>{errors.status}</p>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={12} md={8} style={{ marginBottom: "15px" }}>
                <Typography variant='h6'>Vital Fields</Typography>
                {custom && Array.isArray(custom) && custom.map((item, idx) => (
                  <div key={idx} style={{ display: "flex", justifyContent: "space-between", marginBottom: "10px" }}>
                    <Input
                      startAdornment={
                        <InputAdornment position="start"></InputAdornment>
                      }
                      value={custom[idx].name}
                      onChange={(e) => updateCustom(idx, e.target.value, 'name')}
                      placeholder="Name"
                      margin="none"
                    // value={selected.code}
                    // onChange={e => setSelected({ ...selected, code: e.target.value })}
                    />
                    <Input
                      startAdornment={
                        <InputAdornment position="start"></InputAdornment>
                      }
                      placeholder="Unit"
                      value={custom[idx].unit}
                      onChange={(e) => updateCustom(idx, e.target.value, 'unit')}
                    // value={selected.code}
                    // onChange={e => setSelected({ ...selected, code: e.target.value })}
                    />
                    {idx === 0 ? (
                      <Button
                        onClick={() => addCustom()}
                        autoFocus
                        variant="contained"
                        color="primary"
                        style={{
                          textTransform: "capitalize",
                          backgroundColor: "#e2e4e6",
                          color: "#293038",
                          borderRadius: "20px",
                        }}
                      >
                        Add
                      </Button>
                    ) : (
                      <Button
                        onClick={() => deleteCustom(idx)}
                        autoFocus
                        variant="contained"
                        color="primary"
                        style={{
                          textTransform: "capitalize",
                          backgroundColor: "red",
                          color: "#fff",
                          borderRadius: "20px",
                        }}
                      >
                        delete
                      </Button>
                    )}
                  </div>
                ))}
              </Grid>
            </Grid>
            <Divider variant="middle" style={{ margin: "20px auto" }} />
            <Grid item xs={12} style={{ float: "right", margin: "10px auto" }}>
              <Button
                onClick={() => handleClose()}
                autoFocus
                variant="contained"
                color="primary"
                style={{
                  textTransform: "capitalize",
                  minWidth: "120px",
                  marginRight: "5px",
                  backgroundColor: "#e2e4e6",
                  color: "#293038",
                  borderRadius: "20px",
                }}
              >
                Cancel
              </Button>
              <Button
                autoFocus
                variant="contained"
                color="primary"
                form="form"
                type="submit"
                style={{
                  textTransform: "capitalize",
                  minWidth: "120px",
                  backgroundColor: "#FF8427",
                  color: "rgb(255, 255, 255)",
                  borderRadius: "20px",
                }}
              >
                {selected.id ? "Update" : "Add"}
              </Button>
            </Grid>
          </form>
        </DialogContent>
      </Dialog>
    </div>
  );
}