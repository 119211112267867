import React, { useState }  from 'react';
import clsx from 'clsx';
import { makeStyles } from '@mui/styles';
import Header from './../../layout/Header';
import { RoutePath } from './../../routes';
import { useLocation } from 'react-router-dom'; 

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
  wd100: {
    width: '100%',
  }
}));

export default function AppRoot(props) {
  const { isLoggedIn, loggedAdmin } = props;
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const location = useLocation();

  React.useEffect(() => {
    const loc = location.pathname.split("/");
    // console.warn("home loc length:- ",loc);
    // (loc[1] == '' ? setOpen(false) : (loc[1] == '' ? setOpen(false) : setOpen(true) )  )
    loc[1] == 'login' ? setOpen(false) : setOpen(true) ;
  }, [location]);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <div className={classes.root}>
      {open ? (
        <Header loggedAdmin={loggedAdmin} handleDrawerOpen={handleDrawerOpen} handleDrawerClose={handleDrawerClose} isOpen={open} />
        ) : null}
        
        <main className={classes.content}>
          <div className={classes.drawerHeader} />
            <RoutePath />
        </main>
    </div>
  );
}