export const GET_GATCHA_LIST = "GET_GATCHA_LIST";
export const GET_GATCHA_LIST_FAILURE = "GET_GATCHA_LIST_FAILURE";
export const GET_GATCHA_LIST_SUCCESS = "GET_GATCHA_LIST_SUCCESS";

export const CREATE_GATCHA = "CREATE_GATCHA";
export const CREATE_GATCHA_FAILURE = "CREATE_GATCHA_FAILURE";
export const CREATE_GATCHA_SUCCESS = "CREATE_GATCHA_SUCCESS";

export const UPDATE_GATCHA = "UPDATE_GATCHA";
export const UPDATE_GATCHA_FAILURE = "UPDATE_GATCHA_FAILURE";
export const UPDATE_GATCHA_SUCCESS = "UPDATE_GATCHA_SUCCESS";

export const DELETE_GATCHA = "DELETE_GATCHA";
export const DELETE_GATCHA_FAILURE = "DELETE_GATCHA_FAILURE";
export const DELETE_GATCHA_SUCCESS = "DELETE_GATCHA_SUCCESS";
