import React, { useState } from "react";
import { makeStyles } from "@mui/styles";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import Typography from "@mui/material/Typography";
import Slide from "@mui/material/Slide";
import Grid from "@mui/material/Grid";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import DialogContent from "@mui/material/DialogContent";
import Input from "@mui/material/Input";
import Divider from "@mui/material/Divider";
import InputAdornment from "@mui/material/InputAdornment";
import ClearIcon from "@mui/icons-material/Clear";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "relative",
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  paper: {
    marginTop: theme.spacing(3),
    padding: theme.spacing(3),
  },
  formControl: {
    width: "100%",
    spacing: theme.spacing(2),
  },
  inputLabelText: {
    fontSize: "14px !important",
    color: "rgba(0,0,0,.5) !important",
    fontWeight: "600 !important",
    textTransform: "uppercase",
    marginTop: "0px",
  },
  mdText1: {
    color: "red",
    fontSize: "12px",
    fontWeight: 400,
  },
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
  timeSection: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: "10px",

    height: "40px",
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

export default function PharmacyDialoge(props) {
  const {
    setNewImage,
    newImage,
    handleClose,
    open,
    errors,
    selected,
    setSelected,
    validate,
    custom,
    timeZone,
    fillAllDays,
    isChecked,
    handleCheckboxChange,
    addCustom,
    deleteCustom,
    updateCustom,
  } = props;
  const classes = useStyles();
  const [errorMessage, setErrorMessage] = useState("");
  const supportedFormats = ["image/jpeg", "image/jpg", "image/png"];
  const daysOfWeek = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
  ];

  const onImageChange = (event) => {
    if (
      event &&
      event.target &&
      event.target.files &&
      event.target.files.length > 0
    ) {
      const file = event.target.files[0];
      const fileType = file.type;
      console.warn("fileType:- ", fileType);
      if (supportedFormats.includes(fileType)) {
        setNewImage(true);
        setErrorMessage("");
        setSelected({
          ...selected,
          image: event.target.files ? event.target.files[0] : undefined,
        });
      } else {
        setNewImage(false);
        setErrorMessage("Only JPG or PNG images are allowed.");
      }
    }
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        // scroll="paper"
        TransitionComponent={Transition}
        keepMounted
        maxWidth="md"
        fullWidth={true}
      >
        <DialogContent>
          <ClearIcon
            onClick={handleClose}
            style={{ width: "25px", height: "25px", float: "right" }}
          />
          <div
            style={{
              marginTop: "10px",
              marginBottom: "20px",
            }}
          >
            <Typography
              style={{
                fontSize: "30px",
                fontWeight: 400,
                color: "rgba(0,0,0,.6)",
              }}
            >
              Pharmacy
            </Typography>
          </div>
          <form
            noValidate
            onSubmit={(e) => {
              e.preventDefault();
              validate(selected);
            }}
            id="form"
          >
            <div style={{ minWidth: "800px" }}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6} style={{ marginBottom: "15px" }}>
                  <FormControl fullWidth variant="standard" required>
                    <label
                      htmlFor="imagew"
                      style={{
                        cursor: "pointer",
                        padding: "7px 10px",
                        border: "1px solid #dadada",
                        borderRadius: "10px",
                        background: "#000",
                        color: "#fff",
                        textAlign: "center",
                      }}
                    >
                      Select Image
                    </label>
                    <Input
                      id="imagew"
                      startAdornment={
                        <InputAdornment position="start"></InputAdornment>
                      }
                      onChange={(e) => onImageChange(e)}
                      margin="none"
                      type="file"
                      name="image"
                      accept="image/png, image/jpg, image/jpeg"
                      style={{ display: "none" }}
                    />

                    {errorMessage && (
                      <p className={classes.mdText1}>{errorMessage}</p>
                    )}
                  </FormControl>
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={4}
                  style={{ marginBottom: "15px", display: "flex" }}
                >
                  {!selected.id && selected.image ? (
                    <img
                      src={URL.createObjectURL(selected.image)}
                      alt="image"
                      style={{ maxWidth: "100%", maxHeight: "150px" }}
                    />
                  ) : selected.image && !newImage ? (
                    <img
                      src={selected.image}
                      style={{ maxWidth: "100%", maxHeight: "150px" }}
                    />
                  ) : newImage && selected.id ? (
                    <img
                      src={URL.createObjectURL(selected.image)}
                      alt="image"
                      style={{ maxWidth: "100%", maxHeight: "150px" }}
                    />
                  ) : (
                    ""
                  )}
                </Grid>

                <Grid item xs={12} md={4} style={{ marginBottom: "15px" }}>
                  <FormControl fullWidth variant="standard" required>
                    <InputLabel
                      htmlFor="name"
                      className={classes.inputLabelText}
                    >
                      Name
                    </InputLabel>
                    <Input
                      id="name"
                      startAdornment={
                        <InputAdornment position="start"></InputAdornment>
                      }
                      margin="none"
                      name="name"
                      value={selected.name}
                      onChange={(e) =>
                        setSelected({ ...selected, name: e.target.value })
                      }
                    />
                    {errors && errors.name && (
                      <p className={classes.mdText1}>{errors.name}</p>
                    )}
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={4} style={{ marginBottom: "15px" }}>
                  <FormControl fullWidth variant="standard">
                    <InputLabel
                      htmlFor="specialization"
                      className={classes.inputLabelText}
                    >
                      Specialization
                    </InputLabel>
                    <Input
                      id="specialization"
                      startAdornment={
                        <InputAdornment position="start"></InputAdornment>
                      }
                      margin="none"
                      name="specialization"
                      value={selected.specialization}
                      onChange={(e) =>
                        setSelected({
                          ...selected,
                          specialization: e.target.value,
                        })
                      }
                    />
                    {errors && errors.specialization && (
                      <p className={classes.mdText1}>{errors.specialization}</p>
                    )}
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={4} style={{ marginBottom: "15px" }}>
                  <FormControl fullWidth variant="standard">
                    <InputLabel
                      htmlFor="contact"
                      className={classes.inputLabelText}
                    >
                      Contact 1
                    </InputLabel>
                    <Input
                      id="contact"
                      startAdornment={
                        <InputAdornment position="start"></InputAdornment>
                      }
                      margin="none"
                      name="contact"
                      value={selected.contact}
                      onChange={(e) =>
                        setSelected({ ...selected, contact: e.target.value })
                      }
                    />
                    {errors && errors.contact && (
                      <p className={classes.mdText1}>{errors.contact}</p>
                    )}
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={4} style={{ marginBottom: "15px" }}>
                  <FormControl fullWidth variant="standard">
                    <InputLabel
                      htmlFor="altContact"
                      className={classes.inputLabelText}
                    >
                      Contact 2
                    </InputLabel>
                    <Input
                      id="altContact"
                      startAdornment={
                        <InputAdornment position="start"></InputAdornment>
                      }
                      margin="none"
                      name="altContact"
                      value={
                        selected.altContact != "null" ? selected.altContact : ""
                      }
                      onChange={(e) =>
                        setSelected({ ...selected, altContact: e.target.value })
                      }
                    />
                    {errors && errors.altContact && (
                      <p className={classes.mdText1}>{errors.altContact}</p>
                    )}
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={4} style={{ marginBottom: "15px" }}>
                  <FormControl fullWidth variant="standard">
                    <InputLabel
                      htmlFor="email"
                      className={classes.inputLabelText}
                    >
                      Email
                    </InputLabel>
                    <Input
                      id="email"
                      startAdornment={
                        <InputAdornment position="start"></InputAdornment>
                      }
                      margin="none"
                      name="email"
                      value={selected.email}
                      onChange={(e) =>
                        setSelected({ ...selected, email: e.target.value })
                      }
                    />
                    {errors && errors.email && (
                      <p className={classes.mdText1}>{errors.email}</p>
                    )}
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={4} style={{ marginBottom: "15px" }}>
                  <FormControl fullWidth variant="standard">
                    <InputLabel
                      htmlFor="address"
                      className={classes.inputLabelText}
                    >
                      Address
                    </InputLabel>
                    <Input
                      id="address"
                      startAdornment={
                        <InputAdornment position="start"></InputAdornment>
                      }
                      margin="none"
                      name="address"
                      value={selected.address}
                      onChange={(e) =>
                        setSelected({ ...selected, address: e.target.value })
                      }
                    />
                    {errors && errors.address && (
                      <p className={classes.mdText1}>{errors.address}</p>
                    )}
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={4} style={{ marginBottom: "15px" }}>
                  <FormControl fullWidth variant="standard">
                    <InputLabel
                      htmlFor="city"
                      className={classes.inputLabelText}
                    >
                      City
                    </InputLabel>
                    <Input
                      id="city"
                      startAdornment={
                        <InputAdornment position="start"></InputAdornment>
                      }
                      margin="none"
                      name="city"
                      value={selected.City}
                      onChange={(e) =>
                        setSelected({ ...selected, City: e.target.value })
                      }
                    />
                    {errors && errors.City && (
                      <p className={classes.mdText1}>{errors.City}</p>
                    )}
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={4} style={{ marginBottom: "15px" }}>
                  <FormControl fullWidth variant="standard">
                    <InputLabel
                      htmlFor="district"
                      className={classes.inputLabelText}
                    >
                      District
                    </InputLabel>
                    <Input
                      id="district"
                      startAdornment={
                        <InputAdornment position="start"></InputAdornment>
                      }
                      margin="none"
                      name="district"
                      value={selected.District}
                      onChange={(e) =>
                        setSelected({ ...selected, District: e.target.value })
                      }
                    />
                    {errors && errors.District && (
                      <p className={classes.mdText1}>{errors.District}</p>
                    )}
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={4} style={{ marginBottom: "15px" }}>
                  <FormControl fullWidth variant="standard">
                    <InputLabel
                      htmlFor="state"
                      className={classes.inputLabelText}
                    >
                      State
                    </InputLabel>
                    <Input
                      id="state"
                      startAdornment={
                        <InputAdornment position="start"></InputAdornment>
                      }
                      margin="none"
                      name="state"
                      value={selected.State}
                      onChange={(e) =>
                        setSelected({ ...selected, State: e.target.value })
                      }
                    />
                    {errors && errors.State && (
                      <p className={classes.mdText1}>{errors.State}</p>
                    )}
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={4} style={{ marginBottom: "15px" }}>
                  <FormControl fullWidth variant="standard">
                    <InputLabel
                      htmlFor="zip"
                      className={classes.inputLabelText}
                    >
                      Zip
                    </InputLabel>
                    <Input
                      id="zip"
                      startAdornment={
                        <InputAdornment position="start"></InputAdornment>
                      }
                      margin="none"
                      name="zip"
                      value={selected.zip != "null" ? selected.zip : ""}
                      onChange={(e) =>
                        setSelected({ ...selected, zip: e.target.value })
                      }
                    />
                    {errors && errors.zip && (
                      <p className={classes.mdText1}>{errors.zip}</p>
                    )}
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={4} style={{ marginBottom: "15px" }}>
                  <FormControl fullWidth variant="standard" required>
                    <InputLabel
                      htmlFor="latitude"
                      className={classes.inputLabelText}
                    >
                      Latitude
                    </InputLabel>
                    <Input
                      id="latitude"
                      startAdornment={
                        <InputAdornment position="start"></InputAdornment>
                      }
                      margin="none"
                      name="latitude"
                      value={selected.latitude}
                      onChange={(e) =>
                        setSelected({ ...selected, latitude: e.target.value })
                      }
                    />
                    {errors && errors.latitude && (
                      <p className={classes.mdText1}>{errors.latitude}</p>
                    )}
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={4} style={{ marginBottom: "15px" }}>
                  <FormControl fullWidth variant="standard" required>
                    <InputLabel
                      htmlFor="longitude"
                      className={classes.inputLabelText}
                    >
                      Longitude
                    </InputLabel>
                    <Input
                      id="longitude"
                      startAdornment={
                        <InputAdornment position="start"></InputAdornment>
                      }
                      margin="none"
                      name="longitude"
                      value={selected.longitude}
                      onChange={(e) =>
                        setSelected({ ...selected, longitude: e.target.value })
                      }
                    />
                    {errors && errors.longitude && (
                      <p className={classes.mdText1}>{errors.longitude}</p>
                    )}
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={4} style={{ marginBottom: "15px" }}>
                  <FormControl fullWidth variant="standard">
                    <InputLabel id="status">Status</InputLabel>
                    <Select
                      labelId="status"
                      id="status"
                      value={selected.status}
                      onChange={(e) =>
                        setSelected({ ...selected, status: e.target.value })
                      }
                      label="status"
                    >
                      <MenuItem value={1}>Enable</MenuItem>
                      <MenuItem value={0}>Disable</MenuItem>
                    </Select>
                    {errors && errors.status && (
                      <p className={classes.mdText1}>{errors.status}</p>
                    )}
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={12} style={{ marginBottom: "15px" }}>
                  <Typography variant="h6">
                    Pharmacy working days{" "}
                    {Object.keys(timeZone).length > 0
                      ? "(" + timeZone.name + " GMT " + timeZone.offset + ")"
                      : ""}
                    <FormControlLabel
                      onClick={() => fillAllDays(0)}
                      style={{ float: "right" }}
                      control={
                        <Checkbox
                          checked={isChecked}
                          onChange={handleCheckboxChange}
                        />
                      }
                      label="Everyday"
                    />
                  </Typography>
                  {custom &&
                    Array.isArray(custom) &&
                    custom.map((item, idx) => (
                      <div
                        key={idx}
                        style={{ marginTop: "10px" }}
                        className={classes.timeSection}
                      >
                        <Input
                          startAdornment={
                            <InputAdornment position="start"></InputAdornment>
                          }
                          placeholder="Day Name"
                          margin="none"
                          value={daysOfWeek[idx]}
                          onChange={(e) =>
                            updateCustom(idx, e.target.value, "name")
                          }
                        />
                        <TextField
                          id="time"
                          label="Opening Time"
                          type="time"
                          variant="standard"
                          className={classes.textField}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          inputProps={{
                            step: 300,
                          }}
                          value={custom[idx].start}
                          onChange={(e) =>
                            updateCustom(idx, e.target.value, "start")
                          }
                        />
                        <TextField
                          id="time"
                          label="Closing Time"
                          type="time"
                          variant="standard"
                          className={classes.textField}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          inputProps={{
                            step: 300, // 5 min
                          }}
                          value={custom[idx].close}
                          onChange={(e) =>
                            updateCustom(idx, e.target.value, "close")
                          }
                        />
                        {/* {idx === 0 ? (
                      <Button
                        onClick={() => addCustom()}
                        autoFocus
                        variant="contained"
                        color="primary"
                        style={{
                          textTransform: "capitalize",
                          backgroundColor: "#e2e4e6",
                          color: "#293038",
                          borderRadius: "20px",
                        }}
                        className={classes.addButton}
                      >
                        Add
                      </Button>
                    ) : (
                      <Button
                        onClick={() => deleteCustom(idx)}
                        autoFocus
                        variant="contained"
                        color="primary"
                        style={{
                          textTransform: "capitalize",
                          backgroundColor: "red",
                          color: "#fff",
                          borderRadius: "20px",
                        }}
                        className={classes.addButton}
                      >
                        delete
                      </Button>
                    )} */}
                      </div>
                    ))}
                </Grid>
              </Grid>
              <Divider variant="middle" style={{ margin: "20px auto" }} />
              <Grid
                item
                xs={12}
                style={{ float: "right", margin: "10px auto" }}
              >
                <Button
                  onClick={() => handleClose()}
                  autoFocus
                  variant="contained"
                  color="primary"
                  style={{
                    textTransform: "capitalize",
                    minWidth: "120px",
                    marginRight: "5px",
                    backgroundColor: "#e2e4e6",
                    color: "#293038",
                    borderRadius: "20px",
                  }}
                >
                  Cancel
                </Button>
                <Button
                  autoFocus
                  variant="contained"
                  color="primary"
                  form="form"
                  type="submit"
                  style={{
                    textTransform: "capitalize",
                    minWidth: "120px",
                    backgroundColor: "#FF8427",
                    color: "rgb(255, 255, 255)",
                    borderRadius: "20px",
                  }}
                >
                  {selected.id ? "Update" : "Add"}
                </Button>
              </Grid>
            </div>
          </form>
        </DialogContent>
      </Dialog>
    </div>
  );
}
