import { connect } from 'react-redux';
import { DoctorPage } from './doctor.component';
import { getDoctor, createDoctor, updateDoctor, deleteDoctor, createImage } from './../../api/doctor/doctorAction';
import { getSpecialization } from './../../api/specialization/specializationAction';

const mapDispatchToProps = {
    getDoctor,
    createDoctor,
    updateDoctor,
    deleteDoctor,
    createImage,
    getSpecialization
}

const mapStateToProps = state => ({
    error: state.doctorPage.isError,
    isLoading: state.doctorPage.isLoading,
    doctors: state.doctorPage.doctors,
    isSaved: state.doctorPage.isSaved,
    count: state.doctorPage.count,
    imageData: state.doctorPage.imageData,
    specializations: state.specializationPage.specializations,
    isLoggedIn : state.adminPage.isLoggedIn
});

export const DoctorContainer = connect(mapStateToProps, mapDispatchToProps)(DoctorPage);
