import React, { useState, useRef, useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import StarIcon from '@mui/icons-material/Star';
import { useNavigate } from "react-router-dom";
import { Link } from 'react-router-dom';
import ApartmentIcon from "@mui/icons-material/Apartment";
import SentimentSatisfiedAltIcon from "@mui/icons-material/SentimentSatisfiedAlt";

import DomainAddIcon from "@mui/icons-material/DomainAdd";
import LocalPharmacyIcon from "@mui/icons-material/LocalPharmacy";

import BackHandIcon from "@mui/icons-material/BackHand";
import SensorOccupiedIcon from "@mui/icons-material/SensorOccupied";
import { requestConsultReducer } from '../../api/requestConsult/requestReducer';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  imgText: {
    width: '90px',
    height: '90px',
    borderRadius: '0.75rem',
    marginRight: '1.5rem',
  },
  notificationBox: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderRadius: '20px',
    padding: '10px 20px',
    flexWrap: "wrap",
    [theme.breakpoints.down('lg')]: {
      flexDirection: "column",
    },



  },
  headText: {
    fontSize: '20px',
    fontWeight: '500',
    color: '#000',
    textTransform: 'capitalize',
    padding: '15px',
  },
  paraHead: {
    fontWeight: '500',
    lineHeight: '1.2',
    color: '#3d4465',
    marginBottom: '0.5rem',
    fontSize: '1.125rem',
  },
  TextHead: {
    fontWeight: '500',
    lineHeight: '1.2',
    color: '#3d4465',
    marginBottom: '0.5rem',
    fontSize: '1.125rem',
    textTransform: 'capitalize',
  },
  paraText: {
    lineHeight: '1.5',
    color: '#711685',
    marginBottom: '0.5rem',
    fontSize: '14px',
  },
  spanText: {
    color: '#000',
    marginLeft: '7px',
    fontSize: "15px",
  },
  starIconText: {
    color: '#f90',
    fontSize: '20px',
  },
  iconBox: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    minWidth: "75px",
    minHeight: "75px",
    borderRadius: "50px",
    color: "#fff",
    backgroundColor: "hsla(0,0%,100%,.25)",
    marginBottom: "15px",
    marginRight: "15px"
  }
}));

export function DashboardPage(props) {
  const { users, getHospitals, hospitals, getDoctor, doctors, getShramans, shramans, getLabs, labs, getPharmacies, pharmacies, getCaregiver, caregivers,consultData,getReqConsult, isLoggedIn } = props;
 
  const classes = useStyles();
  const navigate = useNavigate();
  useEffect(() => {
    getReqConsult();
    getHospitals();
    getDoctor();
    getShramans();
    getLabs();
    getPharmacies();
    getCaregiver();
  }, [])
  if(!isLoggedIn) {
    navigate("/login");
  }
  
  return (
    <div className={classes.root}>
      <Grid container spacing={2} >
        {/* <Grid item xs={12} md={4}>
          <Box className={classes.notificationBox} sx={{ backgroundColor: '#711685', color: '#fff' }} onClick={() => navigate(`/users`)}>
            <div className={classes.iconBox}>
              <AccountCircleIcon />
            </div>
            <div>
              <Typography variant="h5">Users</Typography>
              <Typography variant="h6">0</Typography>
            </div>
          </Box>
        </Grid> */}
        <Grid item xs={12} md={4}>
          <Link to={"/hospital"} style={{textDecoration: 'none'}}>
            <Box className={classes.notificationBox} sx={{ backgroundColor: '#f72b50', color: '#fff' }}>
              <div className={classes.iconBox}>
                <AccountCircleIcon />
              </div>
              <div>
                <Typography variant="h5">Hospitals</Typography>
                <Typography variant="h6">{hospitals ? hospitals.count : 0}</Typography>
              </div>
            </Box>
          </Link>
        </Grid>
        <Grid item xs={12} md={4}>
          <Link to={"/doctor"} style={{textDecoration: 'none'}}>
            <Box className={classes.notificationBox} sx={{ backgroundColor: '#209f84', color: '#fff' }}>
              <div className={classes.iconBox}>
                <SensorOccupiedIcon />
              </div>
              <div>
                <Typography variant="h5">Doctors</Typography>
                <Typography variant="h6">{doctors ? doctors.count : 0}</Typography>
              </div>
            </Box>
          </Link>
        </Grid>
        <Grid item xs={12} md={4}>
          <Link to={"/shramans"} style={{textDecoration: 'none'}}>
            <Box className={classes.notificationBox} sx={{ backgroundColor: '#2781d5', color: '#fff' }}>
              <div className={classes.iconBox}>
                <BackHandIcon />
              </div>
              <div>
                <Typography variant="h5">Shramans</Typography>
                <Typography variant="h6">{shramans ? shramans.count : 0}</Typography>
              </div>
            </Box>
          </Link>
        </Grid>
        <Grid item xs={12} md={4}>
          <Link to={"/lab"} style={{textDecoration: 'none'}}>
            <Box className={classes.notificationBox} sx={{ backgroundColor: '#e65100', color: '#fff' }}>
              <div className={classes.iconBox}>
                <DomainAddIcon />
              </div>
              <div>
                <Typography variant="h5">Lab</Typography>
                <Typography variant="h6">{labs ? labs.count : 0}</Typography>
              </div>
            </Box>
          </Link>
        </Grid>
        <Grid item xs={12} md={4}>
          <Link to={"/pharmacy"} style={{textDecoration: 'none'}}>
            <Box className={classes.notificationBox} sx={{ backgroundColor: '#42a5f5', color: '#fff' }}>
              <div className={classes.iconBox}>
                <LocalPharmacyIcon />
              </div>
              <div>
                <Typography variant="h5">Pharmacy</Typography>
                <Typography variant="h6">{pharmacies ? pharmacies.count : 0}</Typography>
              </div>
            </Box>
          </Link>
        </Grid>
        <Grid item xs={12} md={4}>
          <Link to={"/caregiver"} style={{textDecoration: 'none'}}>
            <Box className={classes.notificationBox} sx={{ backgroundColor: '#42a5f5', color: '#fff' }} onClick={() => navigate(`/caregiver`)}>
              <div className={classes.iconBox}>
                <SensorOccupiedIcon />
              </div>
              <div>
                <Typography variant="h5">Caregiver</Typography>
                <Typography variant="h6">{caregivers ? caregivers.count : 0}</Typography>
              </div>
            </Box>
          </Link>
        </Grid>
        <Grid item xs={12} md={4}>
          <Link to={"/requstConsult"} style={{textDecoration: 'none'}}>
            <Box className={classes.notificationBox} sx={{ backgroundColor: '#42a5f5', color: '#fff' }} onClick={() => navigate(`/requstConsult`)}>
              <div className={classes.iconBox}>
                <SensorOccupiedIcon />
              </div>
              <div>
                <Typography variant="h5">Cases </Typography>
                <Typography variant="h6">{consultData ? consultData.count : 0}</Typography>
              </div>
            </Box>
          </Link>
        </Grid>
      </Grid>
      {/* <Grid container spacing={2} mt={2}>
        <Grid item xs={12} md={6}>
          <Paper elevation={2} >
            <div>
              <Typography className={classes.headText}>Top 5 Best Doctor</Typography>
              {doctors && doctors[0] ?
                <div style={{ display: "flex", padding: '30px 30px 20px', flexWrap: "wrap" }}>
                  <img src={doctors && doctors[0] ? doctors[0].image : process.env.PUBLIC_URL + '/img/user.png'} alt='doctorImg' className={classes.imgText} />

                  <div>
                    <Typography className={classes.paraHead}>{doctors && doctors[0] ? doctors[0].name : ""}</Typography>
                    <Typography className={classes.paraText}>{doctors && doctors[0] ? doctors[0].email : ""}</Typography>
                    <Typography className={classes.paraText}>{doctors && doctors[0] ? doctors[0].phone : ""}</Typography>
                    <div style={{ display: "flex" }}>
                      <StarIcon className={classes.starIconText} />
                      <StarIcon className={classes.starIconText} />
                      <StarIcon className={classes.starIconText} />
                      <StarIcon className={classes.starIconText} />
                      <StarIcon className={classes.starIconText} />
                      <span className={classes.spanText}>451 reviews</span>
                    </div>
                  </div>
                </div> : ''}

              {doctors && doctors[1] ?
                <div style={{ display: "flex", padding: '30px 30px 20px', flexWrap: "wrap" }}>
                  <img src={doctors && doctors[1] ? doctors[1].image : process.env.PUBLIC_URL + '/img/user.png'} alt='doctorImg' className={classes.imgText} />
                  <div>
                    <Typography className={classes.paraHead}>{doctors && doctors[1] ? doctors[1].name : ""}</Typography>
                    <Typography className={classes.paraText}>{doctors && doctors[1] ? doctors[1].email : ""}</Typography>
                    <Typography className={classes.paraText}>{doctors && doctors[1] ? doctors[1].phone : ""}</Typography>
                    <div style={{ display: "flex" }} >
                      <StarIcon className={classes.starIconText} />
                      <StarIcon className={classes.starIconText} />
                      <StarIcon className={classes.starIconText} />
                      <StarIcon className={classes.starIconText} />
                      <StarIcon className={classes.starIconText} />
                      <span className={classes.spanText}>451 reviews</span>
                    </div>
                  </div>
                </div>
                : ''}

              {doctors && doctors[2] ?
                <div style={{ display: "flex", padding: '30px 30px 20px', flexWrap: "wrap" }}>
                  <img src={doctors && doctors[2] ? doctors[2].image : process.env.PUBLIC_URL + '/img/user.png'} alt='doctorImg' className={classes.imgText} />
                  <div>
                  <Typography className={classes.paraHead}>{doctors && doctors[2] ? doctors[2].name : ""}</Typography>
                    <Typography className={classes.paraText}>{doctors && doctors[2] ? doctors[2].email : ""}</Typography>
                    <Typography className={classes.paraText}>{doctors && doctors[2] ? doctors[2].phone : ""}</Typography>
                    <div style={{ display: "flex" }}>
                      <StarIcon className={classes.starIconText} />
                      <StarIcon className={classes.starIconText} />
                      <StarIcon className={classes.starIconText} />
                      <StarIcon className={classes.starIconText} />
                      <StarIcon className={classes.starIconText} />
                      <span className={classes.spanText}>451 reviews</span>
                    </div>
                  </div>
                </div> : ''}

              {doctors && doctors[3] ?
                <div style={{ display: "flex", padding: '30px 30px 20px', flexWrap: "wrap" }}>
                  <img src={doctors && doctors[3] ? doctors[3].image : process.env.PUBLIC_URL + '/img/user.png'} alt='doctorImg' className={classes.imgText} />
                  <div>
                  <Typography className={classes.paraHead}>{doctors && doctors[3] ? doctors[3].name : ""}</Typography>
                    <Typography className={classes.paraText}>{doctors && doctors[3] ? doctors[3].email : ""}</Typography>
                    <Typography className={classes.paraText}>{doctors && doctors[3] ? doctors[3].phone : ""}</Typography>
                    <div style={{ display: "flex" }}>
                      <StarIcon className={classes.starIconText} />
                      <StarIcon className={classes.starIconText} />
                      <StarIcon className={classes.starIconText} />
                      <StarIcon className={classes.starIconText} />
                      <StarIcon className={classes.starIconText} />
                      <span className={classes.spanText}>451 reviews</span>
                    </div>
                  </div>
                </div> : ''}

              {doctors && doctors[4] ?
                <div style={{ display: "flex", padding: '30px 30px 20px', flexWrap: "wrap" }}>
                  <img src={doctors && doctors[4] ? doctors[4].image : process.env.PUBLIC_URL + '/img/user.png'} alt='doctorImg' className={classes.imgText} />
                  <div>
                  <Typography className={classes.paraHead}>{doctors && doctors[4] ? doctors[4].name : ""}</Typography>
                    <Typography className={classes.paraText}>{doctors && doctors[4] ? doctors[4].email : ""}</Typography>
                    <Typography className={classes.paraText}>{doctors && doctors[4] ? doctors[4].phone : ""}</Typography>
                    <div style={{ display: "flex" }}>
                      <StarIcon className={classes.starIconText} />
                      <StarIcon className={classes.starIconText} />
                      <StarIcon className={classes.starIconText} />
                      <StarIcon className={classes.starIconText} />
                      <StarIcon className={classes.starIconText} />
                      <span className={classes.spanText}>451 reviews</span>
                    </div>
                  </div>
                </div> : ''}

            </div>
          </Paper>
        </Grid>
        <Grid item xs={12} md={6}>
          <Paper elevation={2} >
            <div>
              <Typography className={classes.headText}>Top 5 Best Hospitals</Typography>
              {hospitals && hospitals[0] ?
                <div style={{ display: "flex", padding: '30px 30px 20px', alignItems: 'center', flexWrap: "wrap" }}>
                  <img src={process.env.PUBLIC_URL + '/img/hospital-icon.png'} alt='hospitalImg' className={classes.imgText} />
                  <div>
                    <Typography className={classes.TextHead}>{hospitals[0] ? hospitals[0].name : ""}</Typography>
                    <div style={{ display: "flex" }}>
                      <StarIcon className={classes.starIconText} />
                      <StarIcon className={classes.starIconText} />
                      <StarIcon className={classes.starIconText} />
                      <StarIcon className={classes.starIconText} />
                      <StarIcon className={classes.starIconText} />
                      <span className={classes.spanText}>451 reviews</span>
                    </div>
                  </div>
                </div> : ''}

              {hospitals && hospitals[1] ?
                <div style={{ display: "flex", padding: '30px 30px 20px', alignItems: 'center', flexWrap: "wrap" }}>
                  <img src={process.env.PUBLIC_URL + '/img/hospital-icon.png'} alt='hospitalImg' className={classes.imgText} />
                  <div>
                    <Typography className={classes.TextHead}>{hospitals[1] ? hospitals[1].name : ""}</Typography>
                    <div style={{ display: "flex" }} >
                      <StarIcon className={classes.starIconText} />
                      <StarIcon className={classes.starIconText} />
                      <StarIcon className={classes.starIconText} />
                      <StarIcon className={classes.starIconText} />
                      <StarIcon className={classes.starIconText} />
                      <span className={classes.spanText}>451 reviews</span>
                    </div>
                  </div>
                </div>
                : ''}
              {
                hospitals && hospitals[2] ?
                  <div style={{ display: "flex", padding: '30px 30px 20px', alignItems: 'center', flexWrap: "wrap" }}>
                    <img src={process.env.PUBLIC_URL + '/img/hospital-icon.png'} alt='hospitalImg' className={classes.imgText} />
                    <div>
                      <Typography className={classes.TextHead}>{hospitals[2] ? hospitals[2].name : ""}</Typography>
                      <div style={{ display: "flex" }}>
                        <StarIcon className={classes.starIconText} />
                        <StarIcon className={classes.starIconText} />
                        <StarIcon className={classes.starIconText} />
                        <StarIcon className={classes.starIconText} />
                        <StarIcon className={classes.starIconText} />
                        <span className={classes.spanText}>451 reviews</span>
                      </div>
                    </div>
                  </div>
                  : ''
              }
              {
                hospitals && hospitals[3] ? <div style={{ display: "flex", padding: '30px 30px 20px', alignItems: 'center', flexWrap: "wrap" }}>
                  <img src={process.env.PUBLIC_URL + '/img/hospital-icon.png'} alt='hospitalImg' className={classes.imgText} />
                  <div>
                    <Typography className={classes.TextHead}>{hospitals[3] ? hospitals[3].name : ""}</Typography>
                    <div style={{ display: "flex" }}>
                      <StarIcon className={classes.starIconText} />
                      <StarIcon className={classes.starIconText} />
                      <StarIcon className={classes.starIconText} />
                      <StarIcon className={classes.starIconText} />
                      <StarIcon className={classes.starIconText} />
                      <span className={classes.spanText}>451 reviews</span>
                    </div>
                  </div>
                </div> : ''
              }
              {
                hospitals && hospitals[4] ?
                  <div style={{ display: "flex", padding: '30px 30px 20px', alignItems: 'center', flexWrap: "wrap" }}>
                    <img src={process.env.PUBLIC_URL + '/img/hospital-icon.png'} alt='hospitalImg' className={classes.imgText} />
                    <div>
                      <Typography className={classes.TextHead}>{hospitals[4] ? hospitals[4].name : ""}</Typography>
                      <div style={{ display: "flex" }}>
                        <StarIcon className={classes.starIconText} />
                        <StarIcon className={classes.starIconText} />
                        <StarIcon className={classes.starIconText} />
                        <StarIcon className={classes.starIconText} />
                        <StarIcon className={classes.starIconText} />
                        <span className={classes.spanText}>451 reviews</span>
                      </div>
                    </div>
                  </div>
                  : ''
              }



            </div>
          </Paper>
        </Grid>
      </Grid> */}
    </div>
  );
}
