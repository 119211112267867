import React, { useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import CaseReportTable from './component/CaseReport-table.component';
import CaseReportDialoge from './component/CaseReport-dialoge';
import CaseReportDetailDialoge from './component/CaseReport-detail-dialoge';
import Loading from '../../layout/Loading';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import HomeIcon from '@mui/icons-material/Home';
import { Link } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import {Button , Box }from '@mui/material';
import AddCircleIcon from '@mui/icons-material/AddCircle';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    background: 'teal',
    color: '#fff',
    fontSize: '24px'
  },
  dblink: {
    textDecoration: 'none'
  },
  link: {
    display: 'flex',
    textDecoration: 'none',
    color: '#fff !important'
  },
  icon: {
    marginRight: theme.spacing(0.5),
    width: 20,
    height: 20,
  },
  breadcrum: {
    backgroundColor: theme.palette.primary.main,
    padding: '20px 40px',
    color: '#fff !important',
    marginBottom: '15px',
  },
  createBtn: {
    position: 'relative',
    left: 'calc(100% - 216px)',
    top: '-50px',
    background: "#000 !important",
  },
}));

export function CaseReportPage(props) {
  const {
    getCaregiver,
    createCaregiver,
    editCaregiver,
    updateCaregiver,
    deleteCaregiver,
    caregivers,
    isLoading,
    isSaving
  } = props;
  

  const emptyCaregiver = {
    fname: "",
    lname: "",
    age: "",
    dob: "",
    gender: "",
    weight: "",
    height: "",
    bmi: "",
    simpton_detail: "",
    current_location: "",
  }

  // const id = props.match.params.id;
  const classes = useStyles();

  const [open, setOpen] = useState(false);
  const [detail, setDetail] = useState(false);
  const [selected, setSelected] = useState(emptyCaregiver);
  const handleClickOpen = () => setOpen(true);
  const handleClickOpenDetail = () => setDetail(true);
  const [scroll, setScroll] = useState("paper");


  const handleClick = (scrollType) => () => {
    setOpen(true);
    setScroll(scrollType);
  };

  useEffect(()=>{
    if(isSaving && !isLoading){
      setSelected(emptyCaregiver);
    }
  })

  // useEffect(() => {
  //   getCaregiver();
  // }, []);

  return (
    <div className={classes.root}>
      <Box className={classes.breadcrum}>
      <Breadcrumbs aria-label="breadcrumb" >
        <Link to="/" className={classes.link}>
          <HomeIcon className={classes.icon} />
          Home
        </Link>
        <Typography color="textPrimary" className={classes.link}>
          Case Report
        </Typography>
      </Breadcrumbs>
      <Button variant="contained" color='primary' onClick={handleClick("body")} className={classes.createBtn}>
        <AddCircleIcon />&nbsp;
        Create Case Report
      </Button>
      </Box>
      <CaseReportTable
        rows={caregivers}
        handleClickOpen={handleClickOpen}
        handleClickOpenDetail={handleClickOpenDetail}
        editCaregiver={editCaregiver}
        deleteCaregiver={deleteCaregiver}
        setSelected={setSelected}
        selected={selected}
      />
      <CaseReportDialoge
        open={open}
        createCaregiver={createCaregiver}
        updateCaregiver={updateCaregiver}
        editCaregiver={editCaregiver}
        selected={selected}
        setSelected={setSelected}
        scroll={scroll}
        handleClose={() => {
          setSelected(emptyCaregiver);
          setOpen(false);
        }}
      />
      <CaseReportDetailDialoge
        detail={detail}
        selected={selected}
        setSelected={setSelected}
        scroll={scroll}
        handleClose={() => {
          setSelected(emptyCaregiver);
          setDetail(false);
        }}
      />
    </div>
  );
}