import * as actionType from "./bloodbankActionType";

const initialState = {
  isError: false,
  isLoading: false,
  isSaved: false,
  bloodbanks: [],
  timezone:{},
  count: 0
};

export const bloodbankReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionType.GET_BLOODBANKS_LIST:
            return {
                ...state,
                isError: false,
                isLoading: true
            };
        case actionType.GET_BLOODBANKS_LIST_FAILURE:
            return {
                ...state,
                isError: true,
                isLoading: false
            };
        case actionType.GET_BLOODBANKS_LIST_SUCCESS:
            return {
                ...state,
                isError: false,
                isLoading: false,
                bloodbanks: action.payload ? action.payload.data.rows : [],
                count: action.payload ? action.payload.data.count : 0
            };
        case actionType.CREATE_BLOODBANKS:
            return {
                ...state,
                isError: false,
                isLoading: true,
                isSaved: false
            };
        case actionType.CREATE_BLOODBANKS_FAILURE:
            return {
                ...state,
                isError: true,
                isLoading: false
            };
        case actionType.CREATE_BLOODBANKS_SUCCESS:
            return {
                ...state,
                isError: false,
                isLoading: false,
                isSaved: true
            };
        case actionType.UPDATE_BLOODBANKS:
            return {
                ...state,
                isError: false,
                isLoading: true,
                isSaved: false
            };
        case actionType.UPDATE_BLOODBANKS_FAILURE:
            return {
                ...state,
                isError: true,
                isLoading: false
            };
        case actionType.UPDATE_BLOODBANKS_SUCCESS:
            return {
                ...state,
                isError: false,
                isLoading: false,
                isSaved: true
            };
        case actionType.DELETE_BLOODBANKS:
            return {
                ...state,
                isError: false,
                isLoading: true,
                isSaved: false
            };
        case actionType.DELETE_BLOODBANKS_FAILURE:
            return {
                ...state,
                isError: true,
                isLoading: false
            };
        case actionType.DELETE_BLOODBANKS_SUCCESS:
            return {
                ...state,
                isError: false,
                isLoading: false,
                isSaved: true
            };
        case actionType.GET_TIMEZONE:
            return {
                ...state,
                isError: false,
                isLoading: true,
                isSaved: false
            };
        case actionType.GET_TIMEZONE_FAILURE:
            return {
                ...state,
                isError: true,
                isLoading: false
            };
        case actionType.GET_TIMEZONE_SUCCESS:
            return {
                ...state,
                isError: false,
                isLoading: false,
                timezone: action.payload ? action.payload.data : [],
            };
       default:
            return state
            
    }
}
