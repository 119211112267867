import React from 'react';
import { HashRouter } from 'react-router-dom';
import CssBaseline from '@mui/material/CssBaseline';
import { AppRootContainer } from './../shared/app-root/app-root.container';
import { ThemeProvider } from '@mui/styles';
import theme from './../theme/theme';
import store from './store';
import { Provider } from 'react-redux';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'react-confirm-alert/src/react-confirm-alert.css';
import 'react-18-image-lightbox/style.css';
toast.configure()

export default function App () {

  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <HashRouter>
          <AppRootContainer />
        </HashRouter>
      </ThemeProvider>
    </Provider>
  );
}