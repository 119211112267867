import * as actionType from "./gatchaActionType";

const initialState = {
  isError: false,
  isLoading: false,
  isSaved: false,
  result: [],
  count: 0
};

export const gatchaReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionType.GET_GATCHA_LIST:
            return {
                ...state,
                isError: false,
                isLoading: true
            };
        case actionType.GET_GATCHA_LIST_FAILURE:
            return {
                ...state,
                isError: true,
                isLoading: false
            };
        case actionType.GET_GATCHA_LIST_SUCCESS:
            console.warn("GET_GATCHA_LIST_SUCCESS::")
            return {
                ...state,
                isError: false,
                isLoading: false,
                result: action.payload ? action.payload.data.result : [],
                count: action.payload ? action.payload.data.count : 0
            };
        case actionType.CREATE_GATCHA:
            return {
                ...state,
                isError: false,
                isLoading: true,
                isSaved: false
            };
        case actionType.CREATE_GATCHA_FAILURE:
            return {
                ...state,
                isError: true,
                isLoading: false
            };
        case actionType.CREATE_GATCHA_SUCCESS:
            return {
                ...state,
                isError: false,
                isLoading: false,
                isSaved: true
            };
        case actionType.UPDATE_GATCHA:
            return {
                ...state,
                isError: false,
                isLoading: true,
                isSaved: false
            };
        case actionType.UPDATE_GATCHA_FAILURE:
            return {
                ...state,
                isError: true,
                isLoading: false
            };
        case actionType.UPDATE_GATCHA_SUCCESS:
            return {
                ...state,
                isError: false,
                isLoading: false,
                isSaved: true
            };
        case actionType.DELETE_GATCHA:
            return {
                ...state,
                isError: false,
                isLoading: true,
                isSaved: false
            };
        case actionType.DELETE_GATCHA_FAILURE:
            return {
                ...state,
                isError: true,
                isLoading: false
            };
        case actionType.DELETE_GATCHA_SUCCESS:
            return {
                ...state,
                isError: false,
                isLoading: false,
                isSaved: true
            };
       default:
            return state
            
    }
}
