import React from 'react';
import { makeStyles } from '@mui/styles';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import HomeIcon from '@mui/icons-material/Home';
import { Link } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import AddCircleIcon from '@mui/icons-material/AddCircle';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    background: 'teal',
    color: '#fff',
    fontSize: '24px'
  },
  dblink: {
    textDecoration: 'none'
  },
  link: {
    display: 'flex',
    textDecoration: 'none',
    color: '#fff !important'
  },
  icon: {
    marginRight: theme.spacing(0.5),
    width: 20,
    height: 20,
  },
  breadcrum: {
    backgroundColor: theme.palette.primary.main,
    padding: '20px 40px',
    color: '#fff !important',
    marginBottom: '15px',
  },
  createBtn: {
    position: 'relative',
    left: 'calc(100% - 200px)',
    top: '-50px',
    position: "relative !important",
    background: "#000 !important"
  },
}));

export function PageNotFound() {
    const classes = useStyles();
  return (
    <div className={classes.root}>
      <Breadcrumbs aria-label="breadcrumb" className={classes.breadcrum}>
        <Link to="/" className={classes.link}>
          <HomeIcon className={classes.icon} />
          Home
        </Link>
        <Typography color="textPrimary" className={classes.link}>
          404
        </Typography>
      </Breadcrumbs>
      <Button
        variant="contained"
        color='primary'
        className={classes.createBtn}
      >
        <AddCircleIcon />&nbsp;
        404
      </Button>
      <div style={{ textAlign: 'center', marginTop: '50px' }}>
        <img src="https://st.depositphotos.com/1203257/3921/i/600/depositphotos_39215359-stock-photo-error-404-construction.jpg" alt="Page not found"/>
      </div>
    </div>
  );
}