import React from 'react';
import { makeStyles } from '@mui/styles';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import Typography from '@mui/material/Typography';
import Slide from '@mui/material/Slide';
import Grid from '@mui/material/Grid';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import DialogContent from "@mui/material/DialogContent";
import Input from "@mui/material/Input";
import TextField from '@mui/material/TextField';
import Divider from "@mui/material/Divider";
import InputAdornment from "@mui/material/InputAdornment";
import ClearIcon from "@mui/icons-material/Clear";

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  paper: {
    marginTop: theme.spacing(3),
    padding: theme.spacing(3),
  },
  formControl: {
    width: '100%',
    spacing: theme.spacing(2),
  },
  inputLabelText: {
    fontSize: "14px !important",
    color: "rgba(0,0,0,.5) !important",
    fontWeight: "600 !important",
    textTransform: "uppercase",
    marginTop: "0px"
  },
  mdText1: {
    color: "red",
    fontSize: "12px",
    fontWeight: 400,
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

export default function CaseReportDialoge(props) {
  const { handleClose, scroll, open, createCaregiver, selected, setSelected, updateCaregiver } = props;
  const classes = useStyles();

  const [value, setValue] = React.useState('2014-08-18T21:11:54');

  const handleChangeD = (newValue) => {
    setValue(newValue);
  };
  const [values, setValues] = React.useState({
    shramanname: "",
    location: "",
    latitude: "",
    lonsitude: "",
    info: "",
    isemergency: "",
    images: "",
    status: "",
  });

  const [errors, setErrors] = React.useState({});

  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    });
    setSelected({
      ...values,
      [event.target.name]: event.target.value,
    });
  };

  // const submitHandle = (event) => {
  //   event.preventDefault();
  //   setErrors(validate(values));
  // };
  const validate = (values) => {
    let errorss = {};
    let isError = false;
    const regex = /\S+@\S+\.\S+/;

    if (!values.shramanname && !values.location && !values.latitude && !values.lonsitude && !values.info && !values.isemergency && !values.images && !values.status) {
      isError = true;
      errorss.shramanname = "First name is required";
      errorss.location = "Last name is required";
      errorss.latitude = "latitude is required";
      errorss.lonsitude = "lonsitude is required";
      errorss.info = "info is required";
      errorss.isemergency = "isemergency is required";
      errorss.images = "images is required";
      errorss.status = "status is required";
      errorss.simpton_detail = "Symptom_detail is required";
      errorss.current_location = "Current_location is required";
    } else if (!values.shramanname) {
      isError = true;
      errorss.shramanname = "First name is required";
    } else if (!values.location) {
      isError = true;
      errorss.location = "Last name is required";
    } else if (!values.latitude) {
      isError = true;
      errorss.latitude = "latitude is required";
    } else if (!values.lonsitude) {
      isError = true;
      errorss.lonsitude = "lonsitude is required";
    } else if (!values.info) {
      isError = true;
      errorss.info = "info is required";
    } else if (!values.isemergency) {
      isError = true;
      errorss.isemergency = "isemergency is required";
    } else if (!values.images) {
      isError = true;
      errorss.images = "images is required";
    } else if (!values.status) {
      isError = true;
      errorss.status = "status is required";
    }
    if (!isError) {
      setErrors(values);
      // createCaregiver(values);
    }
    return errorss;
  };


  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        scroll={scroll}
        TransitionComponent={Transition}
        keepMounted
        aria-labelledby="scroll-dialog-title"
        aria-describedby="alert-dialog-slide-description"
        maxWidth="md"
        fullWidth={true}
      >
        <DialogContent>
          <ClearIcon
            onClick={handleClose}
            style={{ width: "25px", height: "25px", float: "right" }}
          />
          <div
            style={{
              marginTop: "10px",
              marginBottom: "20px",
            }}
          >
            <Typography
              style={{
                fontSize: "30px",
                fontWeight: 400,
                color: "rgba(0,0,0,.6)",
              }}
            >
              Case Report
            </Typography>
          </div>
          <form
            noValidate
            onSubmit={(e) => {
              e.preventDefault();
              setErrors(validate(values));
              createCaregiver(values);
              if (!validate(selected)) {
                {
                  selected.id
                    ? updateCaregiver(selected)
                    : createCaregiver(selected);
                }
              }

            }}
            id="form">
            <Grid container spacing={2}>
              <Grid item xs={12} md={6} style={{ marginBottom: "15px" }}>
                <FormControl fullWidth variant="standard" required>
                  <InputLabel htmlFor="standard-adornment-amount" className={classes.inputLabelText}>
                    Shraman name
                  </InputLabel>
                  <Input
                    id="standard-adornment-amount"
                    startAdornment={
                      <InputAdornment position="start"></InputAdornment>
                    }
                    margin='normal'
                    name='shramanname'
                    value={values.shramanname}
                    onChange={handleChange}
                  />
                  {errors.shramanname && (
                    <p className={classes.mdText1}>{errors.shramanname}</p>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6} style={{ marginBottom: "15px" }}>
                <FormControl fullWidth variant="standard" required>
                  <InputLabel htmlFor="standard-adornment-amount" className={classes.inputLabelText}>
                    Location
                  </InputLabel>
                  <Input
                    id="standard-adornment-amount"
                    startAdornment={
                      <InputAdornment position="start"></InputAdornment>
                    }
                    margin='normal'
                    name='location'
                    value={values.location}
                    onChange={handleChange}
                  />
                  {errors.location && (
                    <p className={classes.mdText1}>{errors.location}</p>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6} style={{ marginBottom: "15px" }}>
                <FormControl fullWidth variant="standard" required>
                  <InputLabel htmlFor="latitude" className={classes.inputLabelText}>
                    latitude
                  </InputLabel>
                  <Input
                    id="latitude"
                    startAdornment={
                      <InputAdornment position="start"></InputAdornment>
                    }
                    margin='normal'
                    name='latitude'
                    value={values.latitude}
                    onChange={handleChange}
                  />
                  {errors.latitude && (
                    <p className={classes.mdText1}>{errors.latitude}</p>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6} style={{ marginBottom: "15px" }}>
                <FormControl fullWidth variant="standard" required>
                  <InputLabel htmlFor="lonsitude" className={classes.inputLabelText}>
                    Lonsitude
                  </InputLabel>
                  <Input
                    id="lonsitude"
                    startAdornment={
                      <InputAdornment position="start"></InputAdornment>
                    }
                    margin='normal'
                    name='lonsitude'
                    value={values.lonsitude}
                    onChange={handleChange}
                  />
                  {errors.lonsitude && (
                    <p className={classes.mdText1}>{errors.lonsitude}</p>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6} style={{ marginBottom: "15px" }}>
                <FormControl fullWidth variant="standard" required>
                  <InputLabel htmlFor="images" className={classes.inputLabelText}>
                    Image
                  </InputLabel>
                  <Input
                    id="images"
                    startAdornment={
                      <InputAdornment position="start"></InputAdornment>
                    }
                    margin='normal'
                    type='file'
                    name='images'
                    accept="image/png, image/jpg, image/jpeg"
                    value={values.images}
                    onChange={handleChange}
                    multipal
                  />
                  {errors.images && (
                    <p className={classes.mdText1}>{errors.images}</p>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6} style={{ marginBottom: "15px" }}>
                <FormControl fullWidth variant="standard" required>
                  <InputLabel htmlFor="info" className={classes.inputLabelText}>
                    info
                  </InputLabel>
                  <Input
                    id="info"
                    startAdornment={
                      <InputAdornment position="start"></InputAdornment>
                    }
                    margin='normal'
                    type='text'
                    name='info'
                    value={values.info}
                    onChange={handleChange}
                  />
                  {errors.info && (
                    <p className={classes.mdText1}>{errors.info}</p>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6} style={{ marginBottom: "15px" }}>
                <FormControl fullWidth variant="standard" required>
                  <InputLabel htmlFor="isemergency" className={classes.inputLabelText}>
                    Is Emergency
                  </InputLabel>
                  <Input
                    id="isemergency"
                    startAdornment={
                      <InputAdornment position="start"></InputAdornment>
                    }
                    margin='normal'
                    type='text'
                    name='isemergency'
                    value={values.isemergency}
                    onChange={handleChange}
                  />
                  {errors.isemergency && (
                    <p className={classes.mdText1}>{errors.isemergency}</p>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6} style={{ marginBottom: "15px" }}>
                <FormControl fullWidth variant="standard" required>
                  <InputLabel htmlFor="status" className={classes.inputLabelText}>
                    status
                  </InputLabel>
                  <Input
                    id="status"
                    startAdornment={
                      <InputAdornment position="start"></InputAdornment>
                    }
                    margin='normal'
                    type='number'
                    name='status'
                    value={values.status}
                    onChange={handleChange}
                  />
                  {errors.status && (
                    <p className={classes.mdText1}>{errors.status}</p>
                  )}
                </FormControl>
              </Grid>
            </Grid>
            <Divider variant="middle" style={{ margin: "20px auto" }} />
            <Grid item xs={12} style={{ float: "right", margin: "10px auto" }}>
              <Button
                onClick={handleClose}
                autoFocus
                variant="contained"
                color="primary"
                style={{
                  textTransform: "capitalize",
                  minWidth: "120px",
                  marginRight: "5px",
                  backgroundColor: "#e2e4e6",
                  color: "#293038",
                  borderRadius: "20px",
                }}
              >
                Cancel
              </Button>
              <Button
                // onClick={submitHandle}
                autoFocus
                variant="contained"
                color="primary"
                form="form"
                type="submit"
                style={{
                  textTransform: "capitalize",
                  minWidth: "120px",
                  backgroundColor: "#FF8427",
                  color: "rgb(255, 255, 255)",
                  borderRadius: "20px",
                }}
              >
                Add
              </Button>
            </Grid>
          </form>
        </DialogContent>
      </Dialog>
    </div>
  );
}