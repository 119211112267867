import React, { useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import EmergencyTable from './component/emergency-table.component';
import EmergencyDialoge from './component/emergency-dialoge';
import Loading from '../../layout/Loading';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import HomeIcon from '@mui/icons-material/Home';
import { Link } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import {Button , Box} from '@mui/material';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { confirmAlert } from "react-confirm-alert";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    background: 'teal',
    color: '#fff',
    fontSize: '24px'
  },
  dblink: {
    textDecoration: 'none'
  },
  link: {
    display: 'flex',
    textDecoration: 'none',
    color: '#fff !important'
  },
  icon: {
    marginRight: theme.spacing(0.5),
    width: 20,
    height: 20,
  },
  breadcrum: {
    backgroundColor: theme.palette.primary.main,
    padding: "20px 40px",
    color: "#fff !important",
    marginBottom: "15px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
      alignItems: "flex-start",
      padding: "13px 30px",
    },
  },
  createBtn: {
    background: "#000 !important",

    "&:hover": {
      backgroundColor: "transparent !important",
    },

    [theme.breakpoints.down("md")]: {
      marginTop: "15px",
      paddingLeft: "0px !important",
      paddingBottom: "0px !important",
    },
  },
}));

export function EmergencyPage(props) {
  const {
    getEmergency,
    createEmergency,
    updateEmergency,
    deleteEmergency,
    emergencys,
    count,
    isSaved,
    isLoading
  } = props;
  

  const emptyEmergency = {
    name: "",
    code: "",
    phone: "",
    status: 1,
    description: "",
  }

  // const id = props.match.params.id;
  const classes = useStyles();

  const [open, setOpen] = useState(false);
  const [selected, setSelected] = useState(emptyEmergency);
  const [errors, setErrors] = React.useState({});
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('name');
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [filtes, setFiltes] = React.useState("");

  useEffect(()=>{
    getEmergency({
      offset: page * rowsPerPage,
      limit: rowsPerPage,
      orderBy: orderBy,
      orderType: order,
      search: filtes
    })
  },[])

  useEffect(()=>{
    if(isSaved){
      setOpen(false);
      setSelected(emptyEmergency);
      getEmergency({
        offset: page * rowsPerPage,
        limit: rowsPerPage,
        orderBy: orderBy,
        orderType: order,
        search: filtes
      })
    }
  },[isSaved])

  useEffect(() => {
    if (filtes.length > 1) {
      getEmergency({
        offset: page * rowsPerPage,
        limit: rowsPerPage,
        orderBy: orderBy,
        orderType: order,
        search: filtes
      })
    } else {
      getEmergency({
        offset: page * rowsPerPage,
        limit: rowsPerPage,
        orderBy: orderBy,
        orderType: order
      })
    }
  }, [filtes])

  const validate = (values) => {
    let errorss = {};
    let isError = false;
    const regex = /\S+@\S+\.\S+/;

    if (!values.name && !values.code && !values.phone ) {
      isError = true;
      errorss.name = "Name is required";
      errorss.status = "Status is required";
      errorss.code = "Code is required";
      errorss.phone = "Phone is required";
      errorss.description = "Descriptiom is required";
    } else if (!values.name) {
      isError = true;
      errorss.name = "Name is required";
    } else if (!values.code) {
      isError = true;
      errorss.code = "Code is required";
    } else if (!values.phone) {
      isError = true;
      errorss.phone = "Phone is required";
    } 
    if (!isError) {
      setErrors(null);
      values.id ? updateEmergency(values) : createEmergency(values);
    }
    setErrors(errorss);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'ASC';
    setOrder(isAsc ? 'DESC' : 'ASC');
    setOrderBy(property);
    getEmergency({
      offset: page * rowsPerPage,
      limit: rowsPerPage,
      orderBy: property,
      orderType: isAsc ? 'DESC' : 'ASC',
      search: filtes
    })
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    getEmergency({
      offset: newPage * rowsPerPage,
      limit: rowsPerPage,
      orderBy: orderBy,
      orderType: order,
      search: filtes
    })
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    getEmergency({
      offset: 0,
      limit: parseInt(event.target.value, 10),
      orderBy: orderBy,
      orderType: order,
      search: filtes
    })
  };

  const confirmDelete = (id) => {
    confirmAlert({
      title: "Confirm to delete",
      message: "Are you sure to do this.",
      buttons: [
        {
          label: "Yes",
          onClick: () => deleteEmergency(id),
        },
        {
          label: "No",
        },
      ],
    });
  };


  return (
    <div className={classes.root}>
      <Loading loading={isLoading}/>
      <Box className={classes.breadcrum}>
      <Breadcrumbs aria-label="breadcrumb" >
        <Link to="/" className={classes.link}>
          <HomeIcon className={classes.icon} />
          Home
        </Link>
        <Typography color="textPrimary" className={classes.link}>
          Emergency
        </Typography>
      </Breadcrumbs>
      <Button variant="contained" color='primary' onClick={()=> setOpen(true)} className={classes.createBtn}>
        <AddCircleIcon />&nbsp;
        Create Emergency
      </Button>
      </Box>
      <EmergencyTable
        rows={emergencys}
        handleClickOpen={() => {
          setOpen(true);
        }}
        count={count}
        setSelected={setSelected}
        selected={selected}
        order={order}
        orderBy={orderBy}
        rowsPerPage={rowsPerPage}
        page={page}
        setFiltes={setFiltes}
        confirmDelete={confirmDelete}
        handleRequestSort={handleRequestSort}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
      />
      <EmergencyDialoge
        open={open}
        selected={selected}
        setSelected={setSelected}
        validate={validate}
        errors={errors}
        handleClose={() => {
          setSelected(emptyEmergency);
          setErrors(null);
          setOpen(false);
        }}
      />
    </div>
  );
}