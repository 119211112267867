export const GET_GURU_LIST = "GET_GURU_LIST";
export const GET_GURU_LIST_FAILURE = "GET_GURU_LIST_FAILURE";
export const GET_GURU_LIST_SUCCESS = "GET_GURU_LIST_SUCCESS";

export const CREATE_GURU = "CREATE_GURU";
export const CREATE_GURU_FAILURE = "CREATE_GURU_FAILURE";
export const CREATE_GURU_SUCCESS = "CREATE_GURU_SUCCESS";

export const UPDATE_GURU = "UPDATE_GURU";
export const UPDATE_GURU_FAILURE = "UPDATE_GURU_FAILURE";
export const UPDATE_GURU_SUCCESS = "UPDATE_GURU_SUCCESS";

export const DELETE_GURU = "DELETE_GURU";
export const DELETE_GURU_FAILURE = "DELETE_GURU_FAILURE";
export const DELETE_GURU_SUCCESS = "DELETE_GURU_SUCCESS";

export const SAMUDHAY_GURU = "SAMUDHAY_GURU";
export const SAMUDHAY_GURU_FAILURE = "SAMUDHAY_GURU_FAILURE";
export const SAMUDHAY_GURU_SUCCESS = "SAMUDHAY_GURU_SUCCESS";
