import React, { useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import MedicalTable from './component/specialization-table.component';
import MedicalDialoge from './component/specialization-dialoge';
import Loading from '../../layout/Loading';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import HomeIcon from '@mui/icons-material/Home';
import { Link } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import {Button , Box} from '@mui/material';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { confirmAlert } from "react-confirm-alert";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    background: 'teal',
    color: '#fff',
    fontSize: '24px'
  },
  dblink: {
    textDecoration: 'none'
  },
  link: {
    display: 'flex',
    textDecoration: 'none',
    color: '#fff !important'
  },
  icon: {
    marginRight: theme.spacing(0.5),
    width: 20,
    height: 20,
  },
  // breadcrum: {
  //   backgroundColor: theme.palette.primary.main,
  //   padding: '20px 40px',
  //   color: '#fff !important',
  //   marginBottom: '15px',
  // },
  // createBtn: {
  //   position: 'relative !important',
  //   left: 'calc(100% - 200px)',
  //   top: '-50px !important',
  //   backgroundColor: "#000 !important"
  // },
  breadcrum: {
    backgroundColor: theme.palette.primary.main,
    padding: "20px 40px",
    color: "#fff !important",
    marginBottom: "15px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
      alignItems: "flex-start",
      padding: "13px 30px",
    },
  },
  createBtn: {
    background: "#000 !important",

    "&:hover": {
      backgroundColor: "transparent !important",
    },

    [theme.breakpoints.down("md")]: {
      marginTop: "15px",
      paddingLeft: "0px !important",
      paddingBottom: "0px !important",
    },
  },
}));

export function SpecializationPage(props) {
  const {
    getSpecialization,
    createSpecialization,
    updateSpecialization,
    deleteSpecialization,
    specializations,
    count,
    isLoading,
    isSaved
    
  } = props;

  console.warn('props:- ',props);

  const emptySpecialization = {
    name: "",
    status: 1
  }

  // const id = props.match.params.id;
  const classes = useStyles();

  const [open, setOpen] = useState(false);
  const [selected, setSelected] = useState(emptySpecialization);
  const [errors, setErrors] = React.useState({});
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('name');
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [filtes, setFiltes] = React.useState("");

  useEffect(() => {
    getSpecialization({
      offset: page * rowsPerPage,
      limit: rowsPerPage,
      orderBy: orderBy,
      orderType: order
    });
    
  }, []);

  useEffect(() => {
    if(isSaved) {
      setOpen(false);
      setSelected(emptySpecialization);
      getSpecialization({
        offset: page * rowsPerPage,
        limit: rowsPerPage,
        orderBy: orderBy,
        orderType: order
      });
    }
  }, [isSaved]);

  useEffect(() => {
    if (filtes.length > 1) {
      getSpecialization({
        offset: page * rowsPerPage,
        limit: rowsPerPage,
        orderBy: orderBy,
        orderType: order,
        search: filtes
      })
    } else {
      getSpecialization({
        offset: page * rowsPerPage,
        limit: rowsPerPage,
        orderBy: orderBy,
        orderType: order
      })
    }
  }, [filtes])

  const validate = (values) => {
    let errorss = {};
    let isError = false;
    const regex = /\S+@\S+\.\S+/;

    if (!values.name) {
      isError = true;
      errorss.name = "Name is required";
    }
    if (!isError) {
      setErrors(null);
      values.id ? updateSpecialization(values) : createSpecialization(values);
    }
    setErrors(errorss);
  };

  const confirmDelete = (id) => {
    confirmAlert({
      title: "Confirm to delete",
      message: "Are you sure to do this.",
      buttons: [
        {
          label: "Yes",
          onClick: () => deleteSpecialization(id),
        },
        {
          label: "No",
        },
      ],
    });
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
    getSpecialization({
      offset: page * rowsPerPage,
      limit: rowsPerPage,
      orderBy: property,
      orderType: isAsc ? 'desc' : 'asc'
    });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    getSpecialization({
      offset: newPage * rowsPerPage,
      limit: rowsPerPage,
      orderBy: orderBy,
      orderType: order
    });
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    getSpecialization({
      offset: 0,
      limit: parseInt(event.target.value, 10),
      orderBy: orderBy,
      orderType: order
    });
  };


  return (
    <div className={classes.root}>
      <Loading loading={isLoading} />
      <Box className={classes.breadcrum}>
      <Breadcrumbs aria-label="breadcrumb" >
        <Link to="/" className={classes.link}>
          <HomeIcon className={classes.icon} />
          Home
        </Link>
        <Typography color="textPrimary" className={classes.link}>
          Specialization
        </Typography>
      </Breadcrumbs>
      <Button variant="contained" color='primary' onClick={()=> setOpen(true)} className={classes.createBtn}>
        <AddCircleIcon />&nbsp;
        Create Specialization
      </Button>
      </Box>
      
      <MedicalTable
        rows={specializations}
        handleClickOpen={() => {
          setOpen(true);
        }}
        count={count}
        setSelected={setSelected}
        selected={selected}
        order={order}
        orderBy={orderBy}
        rowsPerPage={rowsPerPage}
        setFiltes={setFiltes}
        page={page}
        confirmDelete={confirmDelete}
        handleRequestSort={handleRequestSort}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
      />
      <MedicalDialoge
        open={open}
        selected={selected}
        setSelected={setSelected}
        validate={validate}
        errors={errors}
        handleClose={() => {
          setSelected(emptySpecialization);
          setErrors(null);
          setOpen(false);
        }}
      />
    </div>
  );
}