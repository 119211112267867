import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { makeStyles } from "@mui/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import EditIcon from "@mui/icons-material/Edit";
import SearchIcon from "@mui/icons-material/Search";
import DeleteIcon from "@mui/icons-material/Delete";
import { confirmAlert } from "react-confirm-alert";
import TextField from "@mui/material/TextField";
import Avatar from "@mui/material/Avatar";
import { useNavigate } from "react-router-dom";

function descendingComparator (a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator (order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort (array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  { id: "id", key: '0', numeric: true, disablePadding: false, label: "S.No." },
  { id: "id", key: '1', numeric: false, disablePadding: false, label: "Image" },
  {
    id: "id",
    key: '2',
    numeric: false,
    disablePadding: false,
    label: "Name",
  },
  { id: "id", key: '3', numeric: false, disablePadding: false, label: "Email" },
  { id: "id", key: '4', numeric: false, disablePadding: false, label: "Phone" },
  {
    id: "dob",
    key: '5',
    numeric: false,
    disablePadding: false,
    label: "Dob",
  },
  { id: "id", key: '6', numeric: false, disablePadding: false, label: "Gender" },
  { id: "id", key: '7', numeric: false, disablePadding: false, label: "Address" },
  { id: "id", key: '8', numeric: false, disablePadding: false, label: "Remarks" },


];

function EnhancedTableHead (props) {
  const { classes, order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            style={{
              textTransform: "uppercase",
              fontSize: "10px",
              fontWeight: 600,
              fontFamily: "Metropolis-Regular,Sans-Serif",
              color: "rgba(37,45,71,.62)",
            }}
            key={headCell.key}
            align="left"
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
        <TableCell
          style={{
            textTransform: "uppercase",
            fontSize: "10px",
            fontWeight: 600,
            fontFamily: "Metropolis-Regular,Sans-Serif",
            color: "rgba(37,45,71,.62)",
          }}
        >
          Actions
        </TableCell>
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
};

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.type === "light"
      ? {
        color: theme.palette.secondary.main,
        backgroundColor: "#9e040e",
      }
      : {
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.secondary.dark,
      },
  title: {
    flex: "1 1 100%",
  },
}));

const EnhancedTableToolbar = (props) => {
  const classes = useToolbarStyles();
  const { data, headers, rows } = props;
  const csvReport = {
    data: data,
    headers: headers,
    filename: "user.csv",
  };

  return (
    <Toolbar className={clsx(classes.root)}>
      <Typography
        className={classes.title}
        variant="h6"
        id="tableTitle"
        component="div"
      >
        Doctor
      </Typography>
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {};

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  menuText: {
    fontWeight: "400",
    fontSize: "14px",
    color: "#534F6B",
    minWidth: "135px",
  },
  editIcon: {
    fontSize: "30px",
    position: "relative",
    lineHeight: 1,
    backgroundColor: "rgba(0, 255, 10, 0.2)",
    borderColor: "transparent",
    color: "#4caf50",
    padding: "8px",
    height: "100%",
    borderRadius: "5px",
  },
  deleteIcon: {
    fontSize: "30px",
    position: "relative",
    lineHeight: 1,
    backgroundColor: "rgba(255, 68, 0, 0.2)",
    borderColor: "transparent",
    color: "#fa6c39",
    padding: "8px",
    height: "100%",
    borderRadius: "5px",
  },
}));

export default function ShramanTable (props) {
  const navigate = useNavigate();
  const {
    setNewImage,
    newImage,
    shramans,
    handleClickOpen,
    setSelected,
    count,
    order,
    orderBy,
    rowsPerPage,
    page,
    handleRequestSort,
    handleChangePage,
    handleChangeRowsPerPage,
    setFiltes,
    confirmDelete,
    isLoggedIn
  } = props;
  const classes = useStyles();
  if (!isLoggedIn) {
    navigate("/login");
  }
  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        {/* <EnhancedTableToolbar
          handleClickOpen={handleClickOpen}
          headers={headers}
        /> */}
        <Toolbar className={classes.root} style={{ justifyContent: "space-between" }}>
          <Typography
            className={classes.title}
            variant="h6"
            id="tableTitle"
            component="div"
          >
            Shramans
          </Typography>
          <TextField
            helperText=" "
            id="demo-helper-text-aligned-no-helper"
            label="Search"
            // value={search}
            type="search"
            // state
            placeholder="Name, Phone, City, State, District"
            onChange={(e) => setFiltes(e.target.value)}
          />
        </Toolbar>
        <TableContainer>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            aria-label="enhanced table"
          >
            <EnhancedTableHead
              classes={classes}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
            />
            <TableBody>
              {shramans.rows && Array.isArray(shramans.rows) && shramans.rows.map((row, index) => {
                const labelId = `user-${index}`;

                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                    <TableCell component="th" id={labelId} scope="row">
                      {rowsPerPage * page + index + 1}
                    </TableCell>
                    <TableCell>{row.image ? <Avatar src={row.image} alt={row.name} /> : <Avatar src="" alt={row.name} />}</TableCell>
                    <TableCell>{row.name ? (row.prefix != 'null' && row.prefix != null ? row.prefix : '') + ' ' + row.name : "N/A"}</TableCell>
                    <TableCell>{row.email && row.email != 'null' && row.email != null ? row.email : "N/A"}</TableCell>
                    <TableCell>{row.phone && row.phone != 'null' && row.phone != null ? row.phone : "N/A"}</TableCell>
                    <TableCell>{row.dob && row.dob != 'null' && row.dob != null ? row.dob : "N/A"}</TableCell>
                    <TableCell>{row.gender && row.gender != 'null' && row.gender != null ? row.gender : "N/A"}</TableCell>
                    <TableCell>{row.address && row.address != 'null' && row.address != null ? row.address : "N/A"}</TableCell>
                    <TableCell>{row.remarks && row.remarks != 'null' && row.remarks != null ? row.remarks : "N/A"}</TableCell>
                    <TableCell>
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <EditIcon
                          className={classes.editIcon}
                          onClick={() => {
                            setNewImage(false);
                            setSelected(row);
                            handleClickOpen();
                          }}
                          style={{ cursor: "pointer", fontSize: '30px' }}
                        />
                        <DeleteIcon
                          className={classes.deleteIcon}
                          onClick={() => {
                            confirmDelete(row.id);
                          }}
                          style={{
                            cursor: "pointer",
                            marginLeft: "15px",
                            marginRight: "15px",
                            fontSize: '30px',
                          }}
                        />
                      </div>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={count}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </div>
  );
}
