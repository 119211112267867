import * as actionTypes from "./doctorActionType";
import axios from 'axios';
import { checkError } from './../error';

const baseUrl = process.env.REACT_APP_API_URL;

export const getDoctor = (data) => {
    
    const config = { headers: { Authorization: `Bearer ${localStorage.getItem("token")}` }, params: data };
      return (dispatch) => {
        dispatch(getDoctorInit());
        axios.get(`${baseUrl}/doctor/admin`,config)
        .then((response)=>{
            dispatch(getDoctorSuccess(response.data.data));
        })
        .catch(function (error) {
            checkError(error);
            dispatch(getDoctorFailure());
        })
    };
};

export const getDoctorInit = () => ({
    type: actionTypes.GET_DOCTOR_LIST
});

export const getDoctorFailure = data => ({
    type: actionTypes.GET_DOCTOR_LIST_FAILURE
});

export const getDoctorSuccess = data => ({
    type: actionTypes.GET_DOCTOR_LIST_SUCCESS,
    payload: { data }
});

export const createDoctor = (data) => {
    return (dispatch) => {
        dispatch(createDoctorInit());
        axios.post(`${baseUrl}/doctor/store`, data)
        .then((response)=>{
            dispatch(createDoctorSuccess(response.data.data));
        })
        .catch(function (error) {
            checkError(error);
            dispatch(createDoctorFailure());
        })
    };
};


export const createDoctorInit = () => ({
    type: actionTypes.CREATE_DOCTOR
});

export const createDoctorFailure = data => ({
    type: actionTypes.CREATE_DOCTOR_FAILURE
});

export const createDoctorSuccess = data => ({
    type: actionTypes.CREATE_DOCTOR_SUCCESS,
    payload: { data }
});

export const createImage = (data) => {
    const config = { headers: { Authorization: `Bearer ${localStorage.getItem("token")}` } };
    const params = new FormData();
    params.append("image", data);
      return (dispatch) => {
        dispatch(createImageInit());
        axios.post(`${baseUrl}/doctor/admin-image-upload`, params, config)
        .then((response)=>{
            dispatch(createImageSuccess(response.data.data));
            
        })
        .catch(function (error) {
            checkError(error);
            dispatch(createImageFailure());
        })
    };
};

export const createImageInit = () => ({
    type: actionTypes.CREATE_IMAGE
});

export const createImageFailure = data => ({
    type: actionTypes.CREATE_IMAGE_FAILURE
});

export const createImageSuccess = data => ({
    type: actionTypes.CREATE_IMAGE_SUCCESS,
    payload: { data }
    
});

export const updateDoctor = (data) => {
    console.warn('updateDoctor calling....',data);
    const config = { headers: { Authorization: `Bearer ${localStorage.getItem("token")}` } };
      return (dispatch) => {
        dispatch(updateDoctorInit());
        axios.put(`${baseUrl}/doctor/${data.id}`, data, config)
        .then((response)=>{
            dispatch(updateDoctorSuccess(response.data.data));
        })
        .catch(function (error) {
            checkError(error);
            dispatch(updateDoctorFailure());
        })
    };
};

export const updateDoctorInit = () => ({
    type: actionTypes.UPDATE_DOCTOR
});

export const updateDoctorFailure = data => ({
    type: actionTypes.UPDATE_DOCTOR_FAILURE
});

export const updateDoctorSuccess = data => ({
    type: actionTypes.UPDATE_DOCTOR_SUCCESS,
    payload: { data }
});

export const deleteDoctor = (data) => {
    const config = { headers: { Authorization: `Bearer ${localStorage.getItem("token")}` } };
      return (dispatch) => {
        dispatch(deleteDoctorInit());
        axios.delete(`${baseUrl}/doctor/${data}`, config)
        .then((response)=>{
            dispatch(deleteDoctorSuccess(response.data.data));
        })
        .catch(function (error) {
            checkError(error);
            dispatch(deleteDoctorFailure());
        })
    };
};

export const deleteDoctorInit = () => ({
    type: actionTypes.DELETE_DOCTOR
});

export const deleteDoctorFailure = data => ({
    type: actionTypes.DELETE_DOCTOR_FAILURE
});

export const deleteDoctorSuccess = data => ({
    type: actionTypes.DELETE_DOCTOR_SUCCESS,
    payload: { data }
});
