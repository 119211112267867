import { SpecializationPage } from './specialization.component';
import { connect } from 'react-redux';
import { getSpecialization, createSpecialization, updateSpecialization, deleteSpecialization } from "../../api/specialization/specializationAction";
const mapDispatchToProps = {
    getSpecialization,
    createSpecialization,
    updateSpecialization,
    deleteSpecialization
};

const mapStateToProps = state => ({
    error: state.specializationPage.isError,
    isLoading: state.specializationPage.isLoading,
    isSaved: state.specializationPage.isSaved,
    specializations: state.specializationPage.specializations,
    count: state.specializationPage.count
});

export const SpecializationContainer = connect(mapStateToProps, mapDispatchToProps)(SpecializationPage);