export const GET_HOSPITAL_LIST = "GET_HOSPITAL_LIST";
export const GET_HOSPITAL_LIST_FAILURE = "GET_HOSPITAL_LIST_FAILURE";
export const GET_HOSPITAL_LIST_SUCCESS = "GET_HOSPITAL_LIST_SUCCESS";

export const CREATE_HOSPITAL = "CREATE_HOSPITAL";
export const CREATE_HOSPITAL_FAILURE = "CREATE_HOSPITAL_FAILURE";
export const CREATE_HOSPITAL_SUCCESS = "CREATE_HOSPITAL_SUCCESS";

export const UPDATE_HOSPITAL = "UPDATE_HOSPITAL";
export const UPDATE_HOSPITAL_FAILURE = "UPDATE_HOSPITAL_FAILURE";
export const UPDATE_HOSPITAL_SUCCESS = "UPDATE_HOSPITAL_SUCCESS";

export const DELETE_HOSPITAL = "DELETE_HOSPITAL";
export const DELETE_HOSPITAL_FAILURE = "DELETE_HOSPITAL_FAILURE";
export const DELETE_HOSPITAL_SUCCESS = "DELETE_HOSPITAL_SUCCESS";

export const GET_TIMEZONE = "GET_TIMEZONE";
export const GET_TIMEZONE_FAILURE = "GET_TIMEZONE_FAILURE";
export const GET_TIMEZONE_SUCCESS = "GET_TIMEZONE_SUCCESS";
