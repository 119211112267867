import React, { useEffect, useState, useRef } from "react";
import { makeStyles } from "@mui/styles";
import Dialog from "@mui/material/Dialog";
import { Typography, TextField, Box, Avatar } from "@mui/material";
import Slide from "@mui/material/Slide";
import Grid from "@mui/material/Grid";
import PropTypes from "prop-types";
import DialogContent from "@mui/material/DialogContent";
import { Button } from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import FormLabel from "@mui/material/FormLabel";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import moment from "moment";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import SendIcon from "@mui/icons-material/Send";


const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTableCell-head": {
      color: "black",
      backgroundColor: "#FF8427 !important",
    },
  },

  appBar: {
    position: "relative",
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  paper: {
    marginTop: theme.spacing(3),
    padding: theme.spacing(3),
  },
  textBold: {
    fontWeight: "600",
  },
  list: {
    marginTop: "10px !important",
    marginBottom: "10px !important",
  },
}));

const Transition = React.forwardRef(function Transition (props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const headCells = [
  {
    id: "comment",
    key: "0",
    numeric: false,
    disablePadding: false,
    label: "Comment",
  },
  {
    id: "commentBy",
    key: "1",
    numeric: false,
    disablePadding: false,
    label: "Comment By",
  },
  {
    id: "date",
    key: "2",
    numeric: false,
    disablePadding: false,
    label: "Date",
  },
];

function EnhancedTableHead (props) {
  const { classes, order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell>{headCell.label}</TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["ASC", "DESC"]).isRequired,
  orderBy: PropTypes.string.isRequired,
};

export default function DetailRequest (props) {

  const {
    saveComment,
    setSaveComment,
    imageArray,
    setImageArray,
    handleClose,
    openDetail,
    selected,
    orderBy,
    order,
    handleRequestSort,
    isSavedComment,
    handleDialogue,
    handleComment,
    img
  } = props;

  const classes = useStyles();
  const vitalJson = selected?.vitals;
  let vitalArr;
  let jsonDatas;

  if (Array.isArray(vitalJson) === true) {
    vitalArr = vitalJson.name || vitalJson.custom;
  } else {
    jsonDatas = vitalJson;
  }

  const dataVital = jsonDatas ? JSON.parse(jsonDatas) : "";
  const date =
    selected.date_of_visit && selected.date_of_visit.split("T")
      ? selected.date_of_visit.split("T") &&
      selected.date_of_visit.split("T")[0]
      : "N/A";

  let attechlength = selected.id && selected.pres_attachment.length > 0
      ? JSON.parse(selected.pres_attachment)
      : "0";

  const otherReq = selected ? selected.RequestOtherConsultant : "N/A";

  let otherattechlength =
    selected.RequestOtherConsultant &&
      selected.RequestOtherConsultant.pres_attachment.length > 0
      ? JSON.parse(selected.RequestOtherConsultant.pres_attachment)
      : "0";
  //table  functions
  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));

  return (
    <div>
      <Dialog
        open={openDetail}
        onClose={handleClose}
        TransitionComponent={Transition}
        keepMounted
        aria-labelledby="scroll-dialog-title"
        aria-describedby="alert-dialog-slide-description"
        maxWidth="md"
        fullWidth={true}
      >
        <DialogContent>
          <ClearIcon
            onClick={handleClose}
            style={{ width: "25px", height: "25px", float: "right" }}
          />
          <div
            style={{
              marginTop: "10px",
              marginBottom: "20px",
            }}
          >
            <Typography
              style={{
                fontSize: "30px",
                fontWeight: 500,
                color: "rgba(0,0,0,.6)",
                marginBottom: "2rem",
              }}
            >
              Detail Page
            </Typography>

            <Grid container>
              <Grid item md={4} xs={6} className={classes.list}>
                <span className={classes.textBold}>Shraman :</span>
                {selected && selected.Sharman
                  ? selected.Sharman.name
                  : "N/A"}{" "}
              </Grid>
              <Grid item md={4} xs={6} className={classes.list}>
                <span className={classes.textBold}>Doctor :</span>{" "}
                {selected && selected.Doctor ? selected.Doctor.name : "N/A"}{" "}
              </Grid>
              <Grid item md={4} xs={6} className={classes.list}>
                <span className={classes.textBold}>CareGiver :</span>{" "}
                {selected && selected.CareGiver
                  ? selected.CareGiver.name
                  : "N/A"}{" "}
              </Grid>
              <Grid item md={4} xs={6} className={classes.list}>
                <span className={classes.textBold}>Date Of Visit :</span> {date}{" "}
              </Grid>
              <Grid item md={4} xs={6} className={classes.list}>
                <span className={classes.textBold}>Medical Case :</span>{" "}
                {selected ? selected.medical_case : "N/A"}{" "}
              </Grid>
              <Grid item md={4} xs={6} className={classes.list}>
                <span className={classes.textBold}>Type Of Visit :</span>{" "}
                {selected && selected.type_of_visit
                  ? selected.type_of_visit
                  : "N/A"}{" "}
              </Grid>
              <Grid item md={4} xs={6} className={classes.list}>
                <span className={classes.textBold}>Remark :</span>{" "}
                {selected && selected.remark ? selected.remark : "N/A"}{" "}
              </Grid>
              <Grid
                item
                md={4}
                xs={6}
                className={classes.list}
                sx={{ display: "flex", alignItems: "center" }}
              >

                <span className={classes.textBold}>Press Attachment : </span>{" "}
                {img && img.length > 0 ? (
                  <Avatar
                    onClick={() => {
                      handleDialogue();
                    }}
                    src={img[0].image}

                    sx={{ marginLeft: "4px", height: "30px", cursor: "pointer", width: "30px" }}
                  />


                ) : (
                  "N/A"
                )}{" "}
              </Grid>
              <Grid
                item
                md={4}
                xs={6}
                className={classes.list}
                sx={{ display: "flex", alignItems: "center" }}
              >
                <span className={classes.textBold}>Assign: </span>{" "}
                {selected.assignBy && selected.assignBy
                  ? selected.assignBy +
                  "(" +
                  moment(selected.assignTime).format("DD-MM-YYYY h:mm:ss") +
                  ")"
                  : "N/A"}{" "}
              </Grid>

              <Grid item md={12} xs={12} className={classes.list}>
                <TableContainer component={Paper}>
                  <Table sx={{ minWidth: 700 }} aria-label="customized table">
                    <TableHead>
                      <TableRow className={classes.root}>
                        <StyledTableCell>Name</StyledTableCell>
                        <StyledTableCell align="center">
                          Name(Custom)
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          Value(Custom)
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          Unit(Custom)
                        </StyledTableCell>
                        <StyledTableCell align="center">Time</StyledTableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {Array.isArray(dataVital) && dataVital.length > 0 ? (

                        dataVital.map((row, ind) => (

                          <StyledTableRow key={ind}>
                            <StyledTableCell component="th" scope="row">
                              {row.name && row.name ? row.name : "N/A"}
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              {row.custom
                                ? row.custom.map(item => item.name).join(', ')
                                : "N/A"}
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              {Array.isArray(row.custom) &&
                                row.custom.length > 0 ? row.custom.map(item => item.value).join(', ')
                                : "N/A"}
                            </StyledTableCell>

                            <StyledTableCell align="center">
                              {Array.isArray(row.custom) &&
                                row.custom.length > 0 &&
                                row.custom[0].unit
                                ? row.custom[0].unit
                                : "N/A"}
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              {row && row.time ? row.time : "N/A"}
                            </StyledTableCell>
                          </StyledTableRow>
                        ))
                      ) : (
                        <p>
                          {" "}
                          <span
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            No Vitals Add
                          </span>{" "}
                        </p>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            </Grid>
            {otherReq ? (
              <Typography
                style={{
                  fontSize: "30px",
                  fontWeight: 500,
                  color: "rgba(0,0,0,.6)",
                  marginBottom: "2rem",
                }}
              >
                Request Other Consult
              </Typography>
            ) : (
              ""
            )}

            {otherReq && otherReq !== null ? (
              <Grid container>
                <Grid item md={4} xs={6} className={classes.list}>
                  <span className={classes.textBold}> Shraman : </span>
                  {otherReq && otherReq.Sharman
                    ? otherReq.Sharman.name
                    : "N/A"}{" "}
                </Grid>
                <Grid item md={4} xs={6} className={classes.list}>
                  <span className={classes.textBold}> Doctor : </span>
                  {otherReq && otherReq.Doctor
                    ? otherReq.Doctor.name
                    : "N/A"}{" "}
                </Grid>

                <Grid item md={4} xs={6} className={classes.list}>
                  <span className={classes.textBold}> CareGiver : </span>
                  {otherReq && otherReq.CareGiver
                    ? otherReq.CareGiver.name
                    : "N/A"}{" "}
                </Grid>
                <Grid item md={4} xs={6} className={classes.list}>
                  <span className={classes.textBold}> Date Of Visit : </span>
                  {otherReq && otherReq.date_of_visit
                    ? otherReq.date_of_visit
                    : "N/A"}{" "}
                </Grid>
                <Grid item md={4} xs={6} className={classes.list}>
                  <span className={classes.textBold}> Medical Case : </span>
                  {otherReq && otherReq.medical_case
                    ? otherReq.medical_case
                    : "N/A"}{" "}
                </Grid>
                <Grid item md={4} xs={6} className={classes.list}>
                  <span className={classes.textBold}> Type Of Visit : </span>
                  {otherReq && otherReq.type_of_visit
                    ? otherReq.type_of_visit
                    : "N/A"}{" "}
                </Grid>
                <Grid item md={4} xs={6} className={classes.list}>
                  <span className={classes.textBold}> Remark : </span>
                  {otherReq && otherReq.remark ? otherReq.remark : "N/A"}{" "}
                </Grid>
                <Grid item md={4} xs={6} className={classes.list}>
                  <span className={classes.textBold}> Medical Case : </span>
                  {otherReq && otherReq.medical_case
                    ? otherReq.medical_case
                    : "N/A"}{" "}
                </Grid>

                <Grid
                  item
                  md={4}
                  xs={6}
                  className={classes.list}
                  sx={{ display: "flex", alignItems: "center" }}
                >
                  <span className={classes.textBold}>Press Attachment :</span>
                  {otherattechlength &&
                    otherattechlength.pres_attachment &&
                    otherattechlength.length > 0 ? (
                    <Avatar
                      src={otherattechlength.pres_attachment[0].image}
                      alt="not found"
                      sx={{ marginLeft: "4px", height: "30px", width: "30px" }}
                    />
                  ) : (
                    "N/A"
                  )}{" "}
                </Grid>

                <Grid item md={12} xs={12} className={classes.list}>
                  <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 700 }} aria-label="customized table">
                      <TableHead>
                        <TableRow className={classes.root}>
                          <StyledTableCell>Name</StyledTableCell>
                          <StyledTableCell align="center">
                            Name(Custom)
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            Value(Custom)
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            Unit(Custom)
                          </StyledTableCell>
                          <StyledTableCell align="center">Time</StyledTableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {Array.isArray(dataVital) && dataVital.length > 0 ? (
                          dataVital.map((row, ind) => (
                            <StyledTableRow key={ind}>
                              <StyledTableCell component="th" scope="row">
                                {row.name && row.name ? row.name : "N/A"}
                              </StyledTableCell>
                              <StyledTableCell align="center">
                                {row.custom &&
                                  row.custom[0] &&
                                  row.custom[0].name
                                  ? row.custom[0].name
                                  : "N/A"}
                              </StyledTableCell>
                              <StyledTableCell align="center">
                                {Array.isArray(row.custom) &&
                                  row.custom.length > 0 &&
                                  row.custom[0].value
                                  ? row.custom[0].value
                                  : "N/A"}
                              </StyledTableCell>

                              <StyledTableCell align="center">
                                {Array.isArray(row.custom) &&
                                  row.custom.length > 0 &&
                                  row.custom[0].unit
                                  ? row.custom[0].unit
                                  : "N/A"}
                              </StyledTableCell>
                              <StyledTableCell align="center">
                                {row && row.time ? row.time : "N/A"}
                              </StyledTableCell>
                            </StyledTableRow>
                          ))
                        ) : (
                          <p>
                            {" "}
                            <span
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                              No Vitals Add
                            </span>{" "}
                          </p>
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
              </Grid>
            ) : (
              <Typography variant="p" sx={{ textAlign: "center" }}>
                {" "}
                No Consult Other Doctor
              </Typography>
            )}
          </div>
          <div
            style={{
              marginTop: "10px",
              marginBottom: "20px",
            }}
          >
            <Grid container>
              <Grid item md={12} xs={12} className={classes.list}>
                <div
                  style={{ marginTop: "10px" }}
                  className={classes.timeSection}
                >
                  <TableContainer>
                    <Table
                      className={classes.table}
                      aria-labelledby="tableTitle"
                      aria-label="enhanced table"
                    >
                      <EnhancedTableHead
                        classes={classes}
                        order={order}
                        orderBy={orderBy}
                        onRequestSort={handleRequestSort}
                      />
                      <TableBody>

                        {

                          (selected.AddComments) && (Array.isArray(selected.AddComments)) && selected.AddComments.map((row, index) => {
                            return (
                              <TableRow>
                                <TableCell>{row.Comment}</TableCell>

                                {row.CommentBy === "Doctor" && row.Doctor ? (
                                  <TableCell>
                                    {row.Doctor.name}({row.CommentBy})
                                  </TableCell>
                                ) : row.CommentBy == "Admin" ? (
                                  <TableCell>Admin</TableCell>
                                ) : (
                                  <TableCell>
                                    {selected.CareGiver.name}({row.CommentBy})
                                  </TableCell>
                                )}
                                <TableCell>
                                  {moment(row.createdAt).format(
                                    "YYYY-MM-DD HH:mm:ss"
                                  )}
                                </TableCell>
                              </TableRow>
                            );
                          })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </Grid>
            </Grid>
          </div>
          <form
          // noValidate
          // onSubmit={(e) => {
          //   console.warn("submit calling.....", selected);
          //   e.preventDefault();
          // }}
          // id="form"
          >
            <Grid
              item
              md={12}
              xs={12}
              className={classes.list}
              sx={{ display: "flex", alignItems: "center" }}
            >
              {/*
              <span className={classes.textBold}>Comment : </span>
            */}
              <TextareaAutosize
                value={saveComment}
                rows={10}
                cols={29}
                placeholder="Enter your text here"
                onChange={(e) => setSaveComment(e.target.value)}
                style={{
                  marginLeft: "8px",
                  height: "34px",
                  padding: "4px",
                  fontSize: "18px",
                }}
              />
              <Button variant="outlined" sx={{ ml: 1 }} onClick={() => handleComment()}>
                <SendIcon />
              </Button>
            </Grid>
          </form>
        </DialogContent>
      </Dialog>
    </div>
  );
}
