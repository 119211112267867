
export const CREATE_REQ_CONSULT_INIT = "CREATE_REQ_CONSULT_INIT";
export const CREATE_REQ_CONSULT_FAILURE = "CREATE_REQ_CONSULT_FAILURE";
export const CREATE_REQ_CONSULT_SUCCESS = "CREATE_REQ_CONSULT_SUCCESS";

export const GET_REQ_CONSULT = "GET_REQ_CONSULT";
export const GET_REQ_CONSULT_FAILURE = "GET_REQ_CONSULT_FAILURE";
export const GET_REQ_CONSULT_SUCCESS = "GET_REQ_CONSULT_SUCCESS";


export const CREATE_IMAGE = "CREATE_IMAGE";
export const CREATE_IMAGE_FAILURE = "CREATE_IMAGE_FAILURE";
export const CREATE_IMAGE_SUCCESS = "CREATE_IMAGE_SUCCESS";

export const UPDATE_REQ_CONSULT = "UPDATE_REQ_CONSULT";
export const UPDATE_REQ_CONSULT_FAILURE = "UPDATE_REQ_CONSULT_FAILURE";
export const UPDATE_REQ_CONSULT_SUCCESS = "UPDATE_REQ_CONSULT_SUCCESS";

export const DELETE_REQ_CONSULT = "DELETE_REQ_CONSULT";
export const DELETE_REQ_CONSULT_FAILURE = "DELETE_REQ_CONSULT_FAILURE";
export const DELETE_REQ_CONSULT_SUCCESS = "DELETE_REQ_CONSULT_SUCCESS";

// export const DELETE_REQ_CONSULT = "DELETE_REQ_CONSULT";
// export const DELETE_REQ_CONSULT_FAILURE = "DELETE_REQ_CONSULT_FAILURE";
// export const DELETE_REQ_CONSULT_SUCCESS = "DELETE_REQ_CONSULT_SUCCESS";



export const CREATE_ADD_COMMENTCONSULT_INIT = "CREATE_ADD_COMMENTCONSULT_INIT";
export const CREATE_ADD_COMMENTCONSULT_FAILURE = "CREATE_ADD_COMMENTCONSULT_FAILURE";
export const CREATE_ADD_COMMENTCONSULT_SUCCESS = "CREATE_ADD_COMMENTCONSULT_SUCCESS";