import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@mui/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Drawer from '@mui/material/Drawer';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { Link } from 'react-router-dom';
import ListItem from '@mui/material/ListItem';
import AccountCircle from '@mui/icons-material/AccountCircle';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import DashboardIcon from '@mui/icons-material/Dashboard';
import StorefrontIcon from '@mui/icons-material/Storefront';
import DriveEtaIcon from '@mui/icons-material/DriveEta';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import Badge from '@mui/material/Badge';
import Menu from '@mui/material/Menu';
import NotificationsIcon from "@mui/icons-material/Notifications";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import FaceIcon from "@mui/icons-material/Face";
import EmailIcon from "@mui/icons-material/Email";
import PeopleIcon from "@mui/icons-material/People";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import SettingsIcon from "@mui/icons-material/Settings";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import MenuItem from "@mui/material/MenuItem";
import VaccinesIcon from "@mui/icons-material/Vaccines";
import SimCardAlertIcon from "@mui/icons-material/SimCardAlert";
import MedicalServicesIcon from "@mui/icons-material/MedicalServices";
import BackHandIcon from "@mui/icons-material/BackHand";
import DomainAddIcon from "@mui/icons-material/DomainAdd";
import LocalPharmacyIcon from "@mui/icons-material/LocalPharmacy";
import SentimentSatisfiedAltIcon from "@mui/icons-material/SentimentSatisfiedAlt";
import ReportIcon from "@mui/icons-material/Report";
import SensorOccupiedIcon from "@mui/icons-material/SensorOccupied";
import MonitorHeartIcon from "@mui/icons-material/MonitorHeart";
import { Apartment } from '@mui/icons-material';
import ApartmentIcon from "@mui/icons-material/Apartment";
import InterpreterModeIcon from '@mui/icons-material/InterpreterMode';

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  appBar: {
    backgroundColor: '#FF8427 !important',
    width: `calc(100% - ${drawerWidth}px) !important`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),

  },
  // appBarShift: {
  //   width: "97% !important",
  //   transition: theme.transitions.create(['width', 'margin'], {
  //     easing: theme.transitions.easing.sharp,
  //     duration: theme.transitions.duration.enteringScreen,
  //   }),
  // },
  // hide: {
  //   display: 'block',
  // },
  appBarShift: {
    width: "97% !important",
    [theme.breakpoints.down("sm")]: {
      width: "90% !important",
    },
    [theme.breakpoints.down("md")]: {
      width: "95% !important",
    },

    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },

  hide: {
    display: "block",
    marginLeft: "0px",
    [theme.breakpoints.down("sm")]: {
      marginLeft: "14px !important",
    },
    [theme.breakpoints.down("lg")]: {
      marginLeft: "8px !important",
    },
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerClose: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerOpen: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(7),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9) + 1,
    },
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    ...theme.mixins.toolbar,
  },
  itemText: {
    textDecoration: 'none',
    color: '#5a5a5a',
  },
  downBtn: {
    float: "right"
  },
  rightBtn: {
    float: "right"
  }
}));

export default function Header() {
  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const [drop, setDrop] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorElN, setAnchorElN] = React.useState(null);
  const [screenSize, setscreenSize] = React.useState(undefined);
  const openC = Boolean(anchorEl);
  const openN = Boolean(anchorElN);
  const handleLogout = () => {
    localStorage.removeItem('loggedAdmin');
    localStorage.removeItem('token');
    window.location.reload();
  }
  const anchor = "left";

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClick = (event) => {
    setAnchorElN(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleCloseN = () => {
    setAnchorElN(null);
  };

  const handleDrawer = () => {
    setOpen(!open);
  };

  useEffect(() => {
    const handleResize = () => setscreenSize(window.innerWidth);
    window.addEventListener("resize", handleResize);

    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    if (screenSize <= 600) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [screenSize]);

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawer}
            edge="start"
            className={clsx(classes.menuButton, {
              [classes.hide]: open,
            })}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" style={{ flexGrow: 1 }}>
            Shaata
          </Typography>
          <div>
            {/* <IconButton
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleClick}
              variant="contained"
              size="small"
              disableRipple
              color="inherit"
            >
              <Badge badgeContent={4} color="error" size="xs" circular={true.toString()} >
                <NotificationsIcon />
              </Badge>
            </IconButton> */}
            {/* <Menu sx={{ width: "280px" }}
              id="menu-appbar"
              anchorEl={anchorElN}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={openN}
              onClose={handleCloseN}
            >
              <Typography style={{ textAlign: "center", color: "#6c757d", fontSize: ".875rem", padding: "0.5rem 1rem" }}>15 Notifications</Typography>
              <Divider />
              <MenuItem><EmailIcon />&nbsp;&nbsp;&nbsp;&nbsp;4 new messages&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</MenuItem>
              <Divider />
              <MenuItem><PeopleIcon />&nbsp;&nbsp;&nbsp;&nbsp;8 friend requests&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</MenuItem>
              <Divider />
              <MenuItem><InsertDriveFileIcon />&nbsp;&nbsp;&nbsp;&nbsp;3 new reports&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</MenuItem>
              <Divider />
              <MenuItem style={{ justifyContent: "center" }}>See All Notifications</MenuItem>
            </Menu> */}
            <IconButton
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleMenu}
              color="inherit"
            >
              <AccountCircle style={{ fontSize: '35px' }} /><Typography></Typography><ExpandMoreIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={openC}
              onClose={handleClose}
            >
              <MenuItem><Link to={"/profile"} style={{ textDecoration: "none", color: "black" }}> <FaceIcon />&nbsp;&nbsp;Profile  </Link></MenuItem>
              <MenuItem><Link to={"/change-password"} style={{ textDecoration: "none", color: "black" }}> <SettingsIcon />&nbsp;&nbsp;Change Password </Link></MenuItem>
              <MenuItem onClick={() => handleLogout()}><ExitToAppIcon />&nbsp;&nbsp;Logout</MenuItem>
            </Menu>
          </div>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          }),
        }}
      >
        <div className={classes.toolbar}>
          <img src={`${process.env.PUBLIC_URL}/img/hospital-logo.png`} alt='Hospital' style={{ maxWidth: "70px" }} />
          <Typography style={{ fontSize: "25px", fontWeight: "600", marginLeft: "2px" }}>Shaata</Typography>
        </div>
        <Divider />
        <List>
          <Link to={"/dashboard"} key='dashboard' className={classes.itemText}>
            <ListItem button className={classes.itemText}>
              <ListItemIcon><DashboardIcon className={classes.iconColorWhite} /></ListItemIcon>
              <ListItemText primary='Dashboard' />
            </ListItem>
          </Link>
          <ListItem onClick={() => setDrop((current) => !current)} button className={classes.itemText}>
            <ListItemIcon><AccountCircle className={classes.iconColorWhite} /></ListItemIcon>
            <ListItemText>Masters{drop ? <KeyboardArrowDownIcon className={classes.downBtn} /> : <KeyboardArrowRightIcon className={classes.rightBtn} />}</ListItemText>
          </ListItem>
          {drop ?
            <div style={{ paddingLeft: "15px" }}>
              <Link to={"/blood-bank"} key='Blood Bank' className={classes.itemText}>
                <ListItem button className={classes.itemText}>
                  <ListItemIcon><SimCardAlertIcon /></ListItemIcon>
                  <ListItemText primary='Blood Bank' />
                </ListItem>
              </Link>
              <Link to={"/emergency"} key='emergencynumber' className={classes.itemText}>
                <ListItem button className={classes.itemText}>
                  <ListItemIcon><SimCardAlertIcon /></ListItemIcon>
                  <ListItemText primary='Emergency Number' />
                </ListItem>
              </Link>
              <Link to={"/vital"} key='vital' className={classes.itemText}>
                <ListItem button className={classes.itemText}>
                  <ListItemIcon><MonitorHeartIcon /></ListItemIcon>
                  <ListItemText primary='Vital' />
                </ListItem>
              </Link>
              <Link to={"/specialization"} key='specialization' className={classes.itemText}>
                <ListItem button className={classes.itemText}>
                  <ListItemIcon><VaccinesIcon /></ListItemIcon>
                  <ListItemText primary='Specialization' />
                </ListItem>
              </Link>
              <Link to={"/medical"} key='medicalcases' className={classes.itemText}>
                <ListItem button className={classes.itemText}>
                  <ListItemIcon><MedicalServicesIcon /></ListItemIcon>
                  <ListItemText primary='Medical Cases' />
                </ListItem>
              </Link>
              <Link to={"/medicine"} key='medicine' className={classes.itemText}>
                <ListItem button className={classes.itemText}>
                  <ListItemIcon><VaccinesIcon /></ListItemIcon>
                  <ListItemText primary='Medicine' />
                </ListItem>
              </Link>
              <Link to={"/gatcha"} key='gatcha' className={classes.itemText}>
                <ListItem button className={classes.itemText}>
                  <ListItemIcon><VaccinesIcon /></ListItemIcon>
                  <ListItemText primary='Gatcha' />
                </ListItem>
              </Link>
              <Link to={"/samudhay"} key='samudhay' className={classes.itemText}>
                <ListItem button className={classes.itemText}>
                  <ListItemIcon><VaccinesIcon /></ListItemIcon>
                  <ListItemText primary='Samudhay' />
                </ListItem>
              </Link>
              <Link to={"/guru"} key='guru' className={classes.itemText}>
                <ListItem button className={classes.itemText}>
                  <ListItemIcon><VaccinesIcon /></ListItemIcon>
                  <ListItemText primary='Guru' />
                </ListItem>
              </Link>
            </div>
            : null}
          <Link to={"/hospital"} key='hospital' className={classes.itemText}>
            <ListItem button className={classes.itemText}>
              <ListItemIcon><ApartmentIcon className={classes.iconColorWhite} /></ListItemIcon>
              <ListItemText primary='Hospitals' />
            </ListItem>
          </Link>
          <Link to={"/doctor"} key='doctor' className={classes.itemText}>
            <ListItem button className={classes.itemText}>
              <ListItemIcon><SensorOccupiedIcon className={classes.iconColorWhite} /></ListItemIcon>
              <ListItemText primary='Doctors' />
            </ListItem>
          </Link>
          <Link to={"/shramans"} key='shramans' className={classes.itemText}>
            <ListItem button className={classes.itemText}>
              <ListItemIcon><BackHandIcon className={classes.iconColorWhite} /></ListItemIcon>
              <ListItemText primary='Shramans' />
            </ListItem>
          </Link>
          <Link to={"/lab"} key='labs' className={classes.itemText}>
            <ListItem button className={classes.itemText}>
              <ListItemIcon><DomainAddIcon className={classes.iconColorWhite} /></ListItemIcon>
              <ListItemText primary='Lab' />
            </ListItem>
          </Link>
          <Link to={"/pharmacy"} key='pharmacys' className={classes.itemText}>
            <ListItem button className={classes.itemText}>
              <ListItemIcon><LocalPharmacyIcon className={classes.iconColorWhite} /></ListItemIcon>
              <ListItemText primary='Pharmacy' />
            </ListItem>
          </Link>
          <Link to={"/caregiver"} key='caregiver' className={classes.itemText}>
            <ListItem button className={classes.itemText}>
              <ListItemIcon>< SentimentSatisfiedAltIcon className={classes.iconColorWhite} /></ListItemIcon>
              <ListItemText primary='Care Giver' />
            </ListItem>
          </Link>
          <Link to={"/report-medical-case"} key='report-medical-case' className={classes.itemText}>
            <ListItem button className={classes.itemText}>
              <ListItemIcon>< SentimentSatisfiedAltIcon className={classes.iconColorWhite} /></ListItemIcon>
              <ListItemText primary='Medical Case Report' />
            </ListItem>
          </Link>
          <Link
            to={"/requstConsult"}
            key="requst"
            className={classes.itemText}
          >
            <ListItem button className={classes.itemText}>
              <ListItemIcon>
                <InterpreterModeIcon className={classes.iconColorWhite} />
              </ListItemIcon>
              <ListItemText primary="Request Consultation" />
            </ListItem>
          </Link>
          {/* <Link to={"/case-report"} key='case-report' className={classes.itemText}>
            <ListItem button className={classes.itemText}>
              <ListItemIcon><ReportIcon className={classes.iconColorWhite} /></ListItemIcon>
              <ListItemText primary='Case report' />
            </ListItem>
          </Link> */}
        </List>
      </Drawer>
    </div>
  );
}