import React, { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import RequestConsultTable from "./component/request-table.component";
import ConsultDialoge from "./component/request-dialoge";
import DetailRequest from "./component/detailRequest";
import RequestAnother from "./component/requestAnother";
import Loading from "../../layout/Loading";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import HomeIcon from "@mui/icons-material/Home";
import { Link } from "react-router-dom";
import Typography from "@mui/material/Typography";
import { Button, Box } from "@mui/material";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { confirmAlert } from "react-confirm-alert";
import RequestAttatchmentDialogue from "./component/request-attatchment-dialoge";
import Lightbox from 'react-18-image-lightbox';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    background: "teal",
    color: "#fff",
    fontSize: "24px",
  },
  dblink: {
    textDecoration: "none",
  },
  link: {
    display: "flex",
    textDecoration: "none",
    color: "#fff !important",
  },
  icon: {
    marginRight: theme.spacing(0.5),
    width: 20,
    height: 20,
  },
  breadcrum: {
    backgroundColor: theme.palette.primary.main,
    padding: "20px 40px",
    color: "#fff !important",
    marginBottom: "15px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
      alignItems: "flex-start",
      padding: "13px 30px",
    },
  },
  createBtn: {
    background: "#000 !important",

    "&:hover": {
      backgroundColor: "transparent !important",
    },

    [theme.breakpoints.down("md")]: {
      marginTop: "15px",
      paddingLeft: "0px !important",
      paddingBottom: "0px !important",
    },
  },
}));

export function RequstConsult (props) {
  const {
    count,
    isSaved,
    isLoading,
    isLoggedIn,
    getReqConsult,
    consultData,
    createReqConsult,
    updateReqConsult,
    caregivers,
    getCaregiver,
    shramans,
    getShramans,
    medicals,
    getMedical,
    getDoctor,
    doctors,
    deleteReqConsult,
    isSavedComment,
    createCommentConsult,
    getVitals,
    vitals,
    createImage,
    imageData

  } = props;

  // const id = props.match.params.id;
  const classes = useStyles();

  useEffect(() => {
    getCaregiver();
    getShramans();
    getMedical();
    getDoctor();
    getVitals();
    getReqConsult();
  }, []);
  const emptyVital = {
    name: "",
    custom: [],
    time: new Date(),
  };
  const emptyReqConsult = {
    shraman_id: "",
    SharmanId: "",
    CareGiverId: "",
    medical_case: "",
    type_of_visit: "Doctor Consultation",
    DoctorId: "",
    date_of_visit: "",
    remark: "",
    invoice_attachment: [],
    assignBy: "",
    assignTime: "",
    caseUpdate: "",
    status: "",
    secondOpinionStatus : ""
  };

  const handleVitalAdd = () => {
    setVital((prevVitals) => [...prevVitals, emptyVital]);
  };

  const updateVitalBySelect = (id, index) => {
    const vital = vitals.filter(x => x.id == id);
    if (vital.length > 0) {
      setVital((prevVital) =>
        prevVital.map((item, i) =>
          index === i
            ? {
              time: new Date(),
              name: vital[0].name,
              custom: vital[0]?.VitalCustoms,
            }
            : item
        )
      );

      // setVital(
      //   vital.map((item, i) => (index == i ? {
      //     time: new Date(), name: vital[i].name, custom: vital[i]?.VitalCustoms
      //     // vital.map((item, i) => (index == i ? { id: vital[0].id, time: new Date(), name: vital[0].name, custom: vital[0]?.VitalCustoms
      //   } : item))
      // );
      console.warn('vital- ', vital);
    }
  }

  const handlevitalDelete = (index) => {
    setVital((vitalArray) => vitalArray.filter((x, i) => i !== index));
  };

  const imageSave = {
    image: "",
  };
  const [imageArray, setImageArray] = useState([imageSave]);
  const [open, setOpen] = useState(false);
  const [openDetail, setOpenDetail] = useState(false);
  const [attatchment, setAttatchment] = useState(false);
  const [img, setImg] = useState([]);
  const [openRequestAnother, setRequestAnother] = useState(false);
  const [imgIndex, setImgIndex] = useState(0);
  const [selected, setSelected] = useState(emptyReqConsult);
  const [errors, setErrors] = useState({});
  const [order, setOrder] = useState("DESC");
  const [orderBy, setOrderBy] = useState("id");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [filtes, setFiltes] = useState("");
  const [vital, setVital] = useState([emptyVital]);
  const [saveComment, setSaveComment] = useState("");


  const deleteImageArray = (index) => {
    setImageArray((imageArray) => imageArray.filter((x, i) => i !== index));
  }

  const addImageArray = () => {
    setImageArray((prevImages) => [...prevImages, imageSave]);
  };
  useEffect(() => {
    if (imageData) {
      setImageArray(prevData => {
        return prevData.map((x, i) => {
          if (i == imageData.index) {
            return { image: imageData.image[0]?.media };
          } else {
            return x;
          }
        });
      });
    }
  }, [imageData]);


  useEffect(() => {
    getReqConsult({
      offset: page * rowsPerPage,
      limit: rowsPerPage,
      orderBy: orderBy,
      orderType: order,
      search: filtes,
      // specialization: searchAge
    });
  }, []);
  
useEffect(() => {
    if (isSaved) {
      setOpen(false);
      setOpenDetail(false);
      setRequestAnother(false);
      setSelected(emptyReqConsult);
      getReqConsult({
        offset: page * rowsPerPage,
        limit: rowsPerPage,
        orderBy: orderBy,
        orderType: order,
        search: filtes,
        // specialization: searchAge
      });
    }
  }, [isSaved]);

  useEffect(() => {
    if (filtes) {
      getReqConsult({
        offset: page * rowsPerPage,
        limit: rowsPerPage,
        orderBy: orderBy,
        orderType: order,
        search: filtes,
        // specialization: searchAge
      });
    }
  }, [filtes]);

  const validate = (values) => {
    let errorss = {};
    let isError = false;

    if (!values.medical_case || values.medical_case =='' && !values.shraman_id || values.shraman_id == "" && !values.doctor_id || values.doctor_id == "" && !values.caregiver_id || values.caregiver_id == "") {
      isError = true;
      errorss.medical_case = "Medical case is required";
      errorss.shraman_id = "Sharman is required";
      errorss.doctor_id = "Doctor is required";
      errorss.caregiver_id = "Caregiver is required";

      setErrors(errorss);
    } else {

      setErrors(null);
      if (imageArray[0]?.image !== '' || vital[0].custom.length > 0) {
        values.id ? updateReqConsult({ ...values, vitals: vital, pres_attachment: imageArray }) : createReqConsult({ ...values, vitals: vital, pres_attachment: imageArray });
      } else {
        values.id ? updateReqConsult({ ...values, vitals: [], pres_attachment: [] }) : createReqConsult({ ...values, vitals: [], pres_attachment: [] });
      }
    }

  };

  const confirmDelete = (id) => {
    confirmAlert({
      title: "Confirm to delete",
      message: "Are you sure to do this.",
      buttons: [
        {
          label: "Yes",
          onClick: () => deleteReqConsult(id),
        },
        {
          label: "No",
        },
      ],
    });
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
    getReqConsult({
      offset: page * rowsPerPage,
      limit: rowsPerPage,
      orderBy: property,
      orderType: isAsc ? "desc" : "asc",
    });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    getReqConsult({
      offset: newPage * rowsPerPage,
      limit: rowsPerPage,
      orderBy: orderBy,
      orderType: order,
      search: filtes,
      // specialization: searchAge
    });
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    getReqConsult({
      offset: 0,
      limit: parseInt(event.target.value, 10),
      orderBy: orderBy,
      orderType: order,
      search: filtes,
    });
  };

  const handleDialogue = () => {
    setOpenDetail(false);
    setAttatchment(true);
  }

  const handleAttatchmentClose = () => {
    setAttatchment(false);
  }

  const updateCustomValue = (value, idx, l) => {
    setVital(prevData => {
      return prevData.map((x, i) => {
        if (i == idx) {
          const a = x.custom.map((z, j) => {
            if (j == l) {
              return { ...z, value: value };
            } else {
              return z;
            }
          });
          return { ...x, custom: a };
        } else {
          return x;
        }
      });
    });
  }

  useEffect(() => {
    if (isSavedComment) {
      setSaveComment("");

    }
  }, [isSavedComment])

  const handleComment = () => {
    const RequestId = selected && selected.id;
    createCommentConsult({
      RequestConsultantId: RequestId,
      CareGiverId: selected.CareGiverId,
      DoctorId: selected.DoctorId,
      Comment: saveComment,
      CommentBy: "Admin",
    });
  };
  console.warn("consultData :: ",consultData);
  return (
    <div className={classes.root}>
      <Loading />
      <Box className={classes.breadcrum}>
        <Breadcrumbs aria-label="breadcrumb">
          <Link to="/" className={classes.link}>
            <HomeIcon className={classes.icon} />
            Home
          </Link>
          <Typography color="textPrimary" className={classes.link}>
            Request Consultation
          </Typography>
        </Breadcrumbs>


        <Button
          variant="contained"
          color="primary"
          onClick={() => {

            setOpen(true);
          }}
          className={classes.createBtn}
        >
          <AddCircleIcon sx={{ marginRight: "5px" }} />
          Create Request Consultant
        </Button>

      </Box>
      <RequestConsultTable
        consultData={consultData}
        imageArray={imageArray}
        setImageArray={setImageArray}
        handleClickOpen={() => {
          setOpen(true);
        }}
        handleClickDetail={() => {
          setOpenDetail(true);
        }}
        handleRequestAnother={() => {
          setRequestAnother(true);
        }}
        isLoggedIn={isLoggedIn}
        count={count}
        setSelected={setSelected}
        selected={selected}
        order={order}
        orderBy={orderBy}
        rowsPerPage={rowsPerPage}
        page={page}
        setFiltes={setFiltes}
        confirmDelete={confirmDelete}
        handleRequestSort={handleRequestSort}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        setImg={setImg}
      />
      <ConsultDialoge
        open={open}
        selected={selected}
        setSelected={setSelected}
        validate={validate}
        createImage={createImage}
        imageData={imageData}
        CareGiver={caregivers}
        Sharman={shramans}
        medicals={medicals}
        Doctor={doctors}
        errors={errors}
        handleClose={() => {
          setSelected(emptyReqConsult);
          setErrors(null);
          setOpen(false);
          setImageArray([imageSave]);
          setVital([emptyVital]);
          setImg([]);
        }}
        vitals={vitals}
        vital={vital}
        setVital={setVital}
        handleVitalAdd={handleVitalAdd}
        updateVitalBySelect={updateVitalBySelect}
        handlevitalDelete={handlevitalDelete}
        updateCustomValue={updateCustomValue}
        imageArray={imageArray}
        setImageArray={setImageArray}
        deleteImageArray={deleteImageArray}
        addImageArray={addImageArray}
      />
      <DetailRequest
        saveComment={saveComment}
        setSaveComment={setSaveComment}
        handleComment={handleComment}
        imageArray={imageArray}
        setImageArray={setImageArray}
        handleDialogue={handleDialogue}
        setOpenDetail={setOpenDetail}
        openDetail={openDetail}
        selected={selected}
        setSelected={setSelected}
        validate={validate}
        isSavedComment={isSavedComment}
        handleClose={() => {
          setOpenDetail(false);
        }}
        img={img}
      />
      <RequestAnother
        openRequestAnother={openRequestAnother}
        selected={selected}
        setSelected={setSelected}
        validate={validate}
        doctors={doctors}
        caregivers={caregivers}
        handleClose={() => {
          setSelected(emptyReqConsult);
          setErrors(null);
          setRequestAnother(false);
        }}
      />
      <RequestAttatchmentDialogue
        attatchment={attatchment}
        selected={selected}
        img={img}
        handleAttatchmentClose={handleAttatchmentClose}
      />
      {attatchment && (
        <Lightbox
          imageTitle={img[imgIndex]?.amount || img[imgIndex]?.comment}
          imageCaption={null}
          mainSrc={img[imgIndex]?.image}
          nextSrc={img[(imgIndex + 1) % img.length]?.image}
          prevSrc={img[(imgIndex + img.length - 1) % img.length]?.image}
          onCloseRequest={() => setOpen(false)}
          onMovePrevRequest={() =>
            setImgIndex((imgIndex + img.length - 1) % img.length)
          }
          onMoveNextRequest={() => setImgIndex((imgIndex + 1) % img.length)}
        />
      )}
    </div>
  );
}
