export const ADMIN_LOGIN = "ADMIN_LOGIN";
export const ADMIN_LOGIN_FAILURE = "ADMIN_LOGIN_FAILURE";
export const ADMIN_LOGIN_SUCCESS = "ADMIN_LOGIN_SUCCESS";


export const ADMIN_PASSWORD = "ADMIN_PASSWORD";
export const ADMIN_PASSWORD_FAILURE = "ADMIN_PASSWORD_FAILURE";
export const ADMIN_PASSWORD_SUCCESS = "ADMIN_PASSWORD_SUCCESS";


export const ADMIN_PROFILE  = "ADMIN_PROFILE";
export const ADMIN_PROFILE_FAILURE  = "ADMIN_PROFILE_FAILURE";
export const ADMIN_PROFILE_SUCCESS  = "ADMIN_PROFILE_SUCCESS";