import React, { useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import BloodBankTable from './component/bloodBank-table.component';
import BloodBankDialoge from './component/bloodBank-dialoge';
import Loading from '../../layout/Loading';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import HomeIcon from '@mui/icons-material/Home';
import { Link } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import { Button, Box } from '@mui/material';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { confirmAlert } from "react-confirm-alert";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    background: 'teal',
    color: '#fff',
    fontSize: '24px'
  },
  dblink: {
    textDecoration: 'none'
  },
  link: {
    display: 'flex',
    textDecoration: 'none',
    color: '#fff !important'
  },
  icon: {
    marginRight: theme.spacing(0.5),
    width: 20,
    height: 20,
  },
  breadcrum: {
    backgroundColor: theme.palette.primary.main,
    padding: "20px 40px",
    color: "#fff !important",
    marginBottom: "15px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
      alignItems: "flex-start",
      padding: "13px 30px",
    },
  },
  createBtn: {
    background: "#000 !important",

    "&:hover": {
      backgroundColor: "transparent !important",
    },

    [theme.breakpoints.down("md")]: {
      marginTop: "15px",
      paddingLeft: "0px !important",
      paddingBottom: "0px !important",
    },
  },
}));

export function BloodBankPage (props) {
  const {
    getBloodBanks,
    bloodbanks,
    createBloodBank,
    updateBloodBank,
    deleteBloodBank,
    count,
    isLoading,
    isSaved,
    isLoggedIn,
    getTimezone,
    timezone,

  } = props;

  const emptyBloodBank = {
    name: "",
    specialization: "",
    email: "",
    latitude: "",
    longitude: "",
    address: "",
    contact: "",
    altContact: "",
    status: 1
  }
  const emptyCustom = {
    name: "",
    start: "",
    close: ""
  };
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [selected, setSelected] = useState(emptyBloodBank);
  const [errors, setErrors] = React.useState({});
  const [order, setOrder] = React.useState('DESC');
  const [orderBy, setOrderBy] = React.useState('id');
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [filtes, setFiltes] = React.useState("");
  const [isChecked, setIsChecked] = React.useState(false);
  const [custom, setCustom] = useState(Array.from({ length: 7 }, () => emptyCustom));
  const [timeZone, setTimeZone] = React.useState({});
  const daysOfWeek = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];

  /* Day Time */
  const addCustom = () => {
    setCustom(custom.concat([emptyCustom]));
  };
  const deleteCustom = (index) => {
    setCustom((custom) =>
      custom.filter((x, i) => i !== index)
    );
  };
  const updateCustom = (i, v, t) => {
    setCustom(custom.map((item, index) => i == index ? { ...item, [t]: v, name: 'Monday' } : item))
  }

  async function convertIntoAvail (y) {
    return await Promise.all(y.map(x => {
      return {
        name: x.name,
        time: `${x.start}-${x.close}`
      }
    }))
  }

  async function convertIntoCustom (z) {
    const s = await Promise.all(z.map(a => {
      let d = a.time.split('-');
      return {
        name: a.name,
        start: d[0],
        close: d[1]
      }
    }));
    setCustom(s);
  }

  useEffect(() => {
    getBloodBanks({
      offset: page * rowsPerPage,
      limit: rowsPerPage,
      orderBy: orderBy,
      orderType: order,
      search: filtes
    });
  }, []);

  useEffect(() => {

    if (isSaved) {
      setOpen(false);
      setSelected(emptyBloodBank);
      setCustom(custom);
      getBloodBanks({
        offset: page * rowsPerPage,
        limit: rowsPerPage,
        orderBy: orderBy,
        orderType: order,
        search: filtes
      });
    }
  }, [isSaved]);

  useEffect(() => {
    if (filtes.length > 1) {
      getBloodBanks({
        offset: page * rowsPerPage,
        limit: rowsPerPage,
        orderBy: orderBy,
        orderType: order,
        search: filtes
      })
    } else {
      getBloodBanks({
        offset: page * rowsPerPage,
        limit: rowsPerPage,
        orderBy: orderBy,
        orderType: order
      })
    }
  }, [filtes])

  const validate = async (values) => {
    const available = await convertIntoAvail(custom);
    let errorss = {};
    let isError = false;
    const regex = /\S+@\S+\.\S+/;
    if (!values.name && !values.latitude && !values.longitude) {
      isError = true;
      errorss.name = "Name is required";
      errorss.latitude = "Latitude is required";
      errorss.longitude = "Longitude is required";
    }
    else if (!values.name) {
      isError = true;
      errorss.name = "Name is required";
    } else if (!values.latitude) {
      isError = true;
      errorss.latitude = "Latitude is required";
    } else if (!values.longitude) {
      isError = true;
      errorss.longitude = "Longitude is required";
    } if (!isError) {
      setErrors(null);
      // values.latitude = values.latitude ? values.latitude.toString() : null;
      // values.longitude = values.longitude ? values.longitude.toString() : null ;

      console.warn('available:- ', JSON.stringify(available));
      values.id ? updateBloodBank({ ...values, available: JSON.stringify(available) }) : createBloodBank({ ...values, available: JSON.stringify(available) });
    }
    setErrors(errorss);
  };

  const confirmDelete = (id) => {
    confirmAlert({
      title: "Confirm to delete",
      message: "Are you sure to do this.",
      buttons: [
        {
          label: "Yes",
          onClick: () => deleteBloodBank(id),
        },
        {
          label: "No",
        },
      ],
    });
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'ASC';
    setOrder(isAsc ? 'DESC' : 'ASC');
    setOrderBy(property);
    getBloodBanks({
      offset: page * rowsPerPage,
      limit: rowsPerPage,
      orderBy: property,
      orderType: isAsc ? 'DESC' : 'ASC',
      search: filtes
    });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    getBloodBanks({
      offset: newPage * rowsPerPage,
      limit: rowsPerPage,
      orderBy: orderBy,
      orderType: order,
      search: filtes
    });
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    getBloodBanks({
      offset: 0,
      limit: parseInt(event.target.value, 10),
      orderBy: orderBy,
      orderType: order,
      search: filtes
    });
  };
  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
    console.warn('isChecked:- ', isChecked)
  };

  const fillAllDays = (sourceRowIndex) => {
    const sourceValue = custom[sourceRowIndex];
    console.warn('custom:- ', custom);
    const updatedRowData = custom.map((value, index) =>
      index !== sourceRowIndex ? { ...sourceValue, name: daysOfWeek[index] } : value
    );

    if (!isChecked) {
      setCustom(updatedRowData)
    } else {
      const clearedData = updatedRowData.map((item, key) => ({
        name: key > 0 ? '' : item.name,
        start: key > 0 ? '' : item.start,
        close: key > 0 ? '' : item.close,
      }));
      setCustom(clearedData)
    }
  }
  useEffect(() => {
    if (custom) {
      getTimezone();
      setTimeZone(timezone)
    }
  }, [custom])

  return (
    <div className={classes.root}>
      <Loading loading={isLoading} />
      <Box className={classes.breadcrum}>
        <Breadcrumbs aria-label="breadcrumb">
          <Link to="/" className={classes.link}>
            <HomeIcon className={classes.icon} />
            Home
          </Link>
          <Typography color="textPrimary" className={classes.link}>
            BloodBank
          </Typography>
        </Breadcrumbs>
        <Button
          variant="contained"
          color='primary'
          onClick={() => setOpen(true)}
          className={classes.createBtn}
        >
          <AddCircleIcon />&nbsp;
          Create BloodBank
        </Button>
      </Box>
      <BloodBankTable
        rows={bloodbanks}
        handleClickOpen={() => {
          setOpen(true);
        }}
        count={count}
        setSelected={setSelected}
        selected={selected}
        order={order}
        orderBy={orderBy}
        rowsPerPage={rowsPerPage}
        page={page}
        isLoggedIn={isLoggedIn}
        setFiltes={setFiltes}
        confirmDelete={confirmDelete}
        handleRequestSort={handleRequestSort}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        convertIntoCustom={convertIntoCustom}
      />
      <BloodBankDialoge
        open={open}
        selected={selected}
        isChecked={isChecked}
        timeZone={timeZone}
        fillAllDays={fillAllDays}
        handleCheckboxChange={handleCheckboxChange}
        setSelected={setSelected}
        validate={validate}
        errors={errors}
        handleClose={() => {
          setSelected(emptyBloodBank);
          setCustom([emptyCustom]);
          setErrors(null);
          setOpen(false);
        }}
        custom={custom}
        addCustom={addCustom}
        deleteCustom={deleteCustom}
        updateCustom={updateCustom}
      />
    </div>
  );
}