import React, { useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import GuruTable from './component/guru-table.component';
import GuruDialoge from './component/guru-dialoge';
import Loading from '../../layout/Loading';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import HomeIcon from '@mui/icons-material/Home';
import { Link } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import {Button , Box} from '@mui/material';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { confirmAlert } from "react-confirm-alert";


const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    background: 'teal',
    color: '#fff',
    fontSize: '24px'
  },
  dblink: {
    textDecoration: 'none'
  },
  link: {
    display: 'flex',
    textDecoration: 'none',
    color: '#fff !important'
  },
  icon: {
    marginRight: theme.spacing(0.5),
    width: 20,
    height: 20,
  },

  breadcrum: {
    backgroundColor: theme.palette.primary.main,
    padding: "20px 40px",
    color: "#fff !important",
    marginBottom: "15px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
      alignItems: "flex-start",
      padding: "13px 30px",
    },
  },
  createBtn: {
    background: "#000 !important",

    "&:hover": {
      backgroundColor: "transparent !important",
    },

    [theme.breakpoints.down("md")]: {
      marginTop: "15px",
      paddingLeft: "0px !important",
      paddingBottom: "0px !important",
    },
  },
}));

export function GuruPage(props) {
  const {
    getGuru,
    getGatcha,
    getSamudhay,
    createGuru,
    updateGuru,
    deleteGuru,
    result,
    gatcha,
    samudhay,
    count,
    isLoading,
    isSaved
  } = props;

  

  const emptyGuru = {
    name: "",
    GatchaId : "",
    SamudhayId : "",
    status : 1
  }
  
  // const id = props.match.params.id;
  const classes = useStyles();

  const [open, setOpen] = useState(false);
  const [selected, setSelected] = useState(emptyGuru);
  const [errors, setErrors] = React.useState({});
  const [order, setOrder] = React.useState('desc');
  const [orderBy, setOrderBy] = React.useState('id');
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [filtes, setFiltes] = React.useState("");

  useEffect(() => {
    if(open) {
      getSamudhay({
        offset: page * rowsPerPage,
        limit: rowsPerPage,
        orderBy: orderBy,
        orderType: order
      });
      getGatcha({
        offset: page * rowsPerPage,
        limit: rowsPerPage,
        orderBy: orderBy,
        orderType: order
      });
    }
    getGuru({
      offset: page * rowsPerPage,
      limit: rowsPerPage,
      orderBy: orderBy,
      orderType: order
    });
    
    
  }, [open]);


  

  useEffect(() => {
    if(isSaved) {
      setOpen(false);
      setSelected(emptyGuru);
      getSamudhay({
        offset: page * rowsPerPage,
        limit: rowsPerPage,
        orderBy: orderBy,
        orderType: order
      });
    }
  }, [isSaved]);

  useEffect(() => {
    if (filtes.length > 1) {
      getSamudhay({
        offset: page * rowsPerPage,
        limit: rowsPerPage,
        orderBy: orderBy,
        orderType: order,
        search: filtes
      })
    } else {
      getSamudhay({
        offset: page * rowsPerPage,
        limit: rowsPerPage,
        orderBy: orderBy,
        orderType: order
      })
    }
  }, [filtes])

  const validate = (values) => {
    let errorss = {};
    let isError = false;
    const regex = /\S+@\S+\.\S+/;
console.warn("validate:_ ",values);
    if (!values.GatchaId && values.GatchaId == " " ) {
      isError = true;
      errorss.GatchaId = "Gatcha name is required";
    } else if (!values.SamudhayId && values.SamudhayId == " ") {
      isError = true;
      errorss.SamudhayId = "Samudhay is required";
    } 
    if (!isError) {
      setErrors(null);
      values.id ? updateGuru(values) : createGuru(values);
    }
    setErrors(errorss);
  };

  const confirmDelete = (id) => {
    confirmAlert({
      title: "Confirm to delete",
      message: "Are you sure to do this.",
      buttons: [
        {
          label: "Yes",
          onClick: () => deleteGuru(id),
        },
        {
          label: "No",
        },
      ],
    });
    getGuru({
      offset: page * rowsPerPage,
      limit: rowsPerPage,
      orderBy: orderBy,
      orderType: order
    });
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
    getSamudhay({
      offset: page * rowsPerPage,
      limit: rowsPerPage,
      orderBy: property,
      orderType: isAsc ? 'desc' : 'asc'
    });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    getSamudhay({
      offset: newPage * rowsPerPage,
      limit: rowsPerPage,
      orderBy: orderBy,
      orderType: order
    });
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    getSamudhay({
      offset: 0,
      limit: parseInt(event.target.value, 10),
      orderBy: orderBy,
      orderType: order
    });
  };


  return (
    <div className={classes.root}>
      <Loading loading={isLoading} />
      <Box  className={classes.breadcrum}>
      <Breadcrumbs aria-label="breadcrumb">
        <Link to="/" className={classes.link}>
          <HomeIcon className={classes.icon} />
          Home
        </Link>
        <Typography color="textPrimary" className={classes.link}>
        Guru
        </Typography>
      </Breadcrumbs>
      <Button variant="contained" color='primary' onClick={()=> setOpen(true)} className={classes.createBtn}>
        <AddCircleIcon />&nbsp;
        Create Guru
      </Button>
      </Box>
      <GuruTable
        rows={result}
        handleClickOpen={() => {
          setOpen(true);
        }}
        count={count}
        selected={selected}
        setSelected={setSelected}
        order={order}
        orderBy={orderBy}
        rowsPerPage={rowsPerPage}
        page={page}
        setFiltes={setFiltes}
        confirmDelete={confirmDelete}
        handleRequestSort={handleRequestSort}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
      />
      <GuruDialoge
        open={open}
        gatcha={gatcha}
        samudhay={samudhay}
        selected={selected}
        setSelected={setSelected}
        validate={validate}
        errors={errors}
        handleClose={() => {
          setSelected(emptyGuru);
          setErrors(null);
          setOpen(false);
        }}
      />
    </div>
  );
}