import { combineReducers } from 'redux';
import { adminReducer as adminPage } from './../api/admin/adminReducer';
import { vitalReducer as vitalPage } from './../api/vital/vitalReducer';
import { emergencyReducer as emergencyPage } from './../api/emergrncy/emergrncyReducer';
import { medicalReducer as medicalPage } from './../api/medical/medicalReducer';
import { caregiverReducer as caregiverPage } from './../api/caregiver/caregiverReducer';
import { doctorReducer as doctorPage } from './../api/doctor/doctorReducer';
import { shramansReducer as shramansPage } from './../api/shramans/shramansReducer';
import { medicineReducer as medicinePage } from './../api/medicine/medicineReducer';
import { pharmacyReducer as pharmacyPage } from '../api/pharmacy/pharmacyReducer';
import { labReducer as labPage } from '../api/lab/labReducer';
import { hospitalReducer as hospitalPage } from '../api/hospital/hospitalReducer';
import { specializationReducer as specializationPage } from '../api/specialization/specializationReducer';
import { bloodbankReducer as bloodbankPage } from '../api/bloodbank/bloodbankReducer';
import { requestConsultReducer as requestConsultPage } from '../api/requestConsult/requestReducer';
import { medicalReportReducer as medicalReportPage } from '../api/medicalReport/medicalReportReducer';
import { gatchaReducer as gatchaPage } from '../api/gatcha/gatchaReducer';
import { samudhayReducer as samudhayPage } from '../api/samudhay/samudhayReducer';
import { guruReducer as guruPage } from '../api/guru/guruReducer';



const appReducer = combineReducers({
    adminPage,
    vitalPage,
    emergencyPage,
    medicalPage,
    caregiverPage,
    doctorPage,
    shramansPage,
    medicinePage,
    pharmacyPage,
    labPage,
    hospitalPage,
    specializationPage,
    bloodbankPage,
    requestConsultPage,
    medicalReportPage,
    gatchaPage,
    samudhayPage,
    guruPage
});

export default appReducer;