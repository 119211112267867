import { connect } from "react-redux";
import { RequstConsult } from "./requstConsult.component";
import { getReqConsult,createReqConsult,deleteReqConsult ,updateReqConsult, createImage} from "../../api/requestConsult/requestAction";
import { getShramans } from "../../api/shramans/shramansAction";
import { getCaregiver } from "../../api/caregiver/caregiverAction";
import { getMedical } from "../../api/medical/medicalAction";
import { getDoctor } from "../../api/doctor/doctorAction";
import { createCommentConsult } from "../../api/requestConsult/requestAction";
import { getVitals } from "../../api/vital/vitalAction";

const mapDispatchToProps = {
  getReqConsult,
  createReqConsult,
  updateReqConsult,
  getShramans,
  getCaregiver,
  getMedical,
  getDoctor,
  deleteReqConsult,
  createCommentConsult,
  getVitals,
  createImage
};

const mapStateToProps = (state) => ({
  isError: state.requestConsultPage.isError,
  isLoading: state.requestConsultPage.isLoading,
  consultData: state.requestConsultPage.consultData,
  isSaved: state.requestConsultPage.isSaved,
  imageData: state.requestConsultPage.imageData,
  isSavedComment :state.requestConsultPage.isSavedComment,
  isLoggedIn: state.adminPage.isLoggedIn,
  count : state.requestConsultPage.count,
  caregivers: state.caregiverPage.caregivers,
  shramans: state.shramansPage.shramans,
  medicals: state.medicalPage.medicals,
  doctors: state.doctorPage.doctors,
  vitals: state.vitalPage.vitals
});

export const ConsultContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(RequstConsult);
