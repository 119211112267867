import { VitalPage } from './vital.component';
import { connect } from 'react-redux';
import { getVitals, createVital, updateVital, deleteVital } from './../../api/vital/vitalAction';

const mapDispatchToProps = {
    getVitals,
    createVital,
    updateVital,
    deleteVital
};

const mapStateToProps = state => ({
    error: state.vitalPage.isError,
    isLoading: state.vitalPage.isLoading,
    isSaved: state.vitalPage.isSaved,
    vitals: state.vitalPage.vitals,
    count: state.vitalPage.count,
});

export const VitalContainer = connect(mapStateToProps, mapDispatchToProps)(VitalPage);