export const GET_MEDICINE_LIST = "GET_MEDICINE_LIST";
export const GET_MEDICINE_LIST_FAILURE = "GET_MEDICINE_LIST_FAILURE";
export const GET_MEDICINE_LIST_SUCCESS = "GET_MEDICINE_LIST_SUCCESS";

export const CREATE_MEDICINE = "CREATE_MEDICINE";
export const CREATE_MEDICINE_FAILURE = "CREATE_MEDICINE_FAILURE";
export const CREATE_MEDICINE_SUCCESS = "CREATE_MEDICINE_SUCCESS";

export const UPDATE_MEDICINE = "UPDATE_MEDICINE";
export const UPDATE_MEDICINE_FAILURE = "UPDATE_MEDICINE_FAILURE";
export const UPDATE_MEDICINE_SUCCESS = "UPDATE_MEDICINE_SUCCESS";

export const DELETE_MEDICINE = "DELETE_MEDICINE";
export const DELETE_MEDICINE_FAILURE = "DELETE_MEDICINE_FAILURE";
export const DELETE_MEDICINE_SUCCESS = "DELETE_MEDICINE_SUCCESS";
