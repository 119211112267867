import React, { useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import Typography from '@mui/material/Typography';
import Slide from '@mui/material/Slide';
import Grid from '@mui/material/Grid';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import DialogContent from "@mui/material/DialogContent";
import Input from "@mui/material/Input";
import TextField from '@mui/material/TextField';
import Divider from "@mui/material/Divider";
import InputAdornment from "@mui/material/InputAdornment";
import ClearIcon from "@mui/icons-material/Clear";
import Avatar from '@mui/material/Avatar';

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  paper: {
    marginTop: theme.spacing(3),
    padding: theme.spacing(3),
  },
  formControl: {
    width: '100%',
    spacing: theme.spacing(2),
  },
  inputLabelText: {
    fontSize: "14px !important",
    color: "rgba(0,0,0,.5) !important",
    fontWeight: "600 !important",
    textTransform: "uppercase",
    marginTop: "0px",
    display: 'flex !important',
    alignItems: 'center',
  },
  mdText1: {
    color: "red",
    fontSize: "12px",
    fontWeight: 400,
  },
  data: {
    fontSize: "13px",
    color: "#000",
  },
  red: {
    display: "flex",
    height: "10px",
    width: "10px",
    background: "red",
    borderRadius: "10px",
  },
  green: {
    display: "flex",
    height: "10px",
    width: "10px",
    background: "green",
    borderRadius: "10px",
  },
  enableDisable: {
    display: 'flex',
    alignItems: 'center',
  }
}));

const Transition = React.forwardRef(function Transition (props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

export default function CaregiverDetailDialoge (props) {
  const { handleClose, show, selected, verify, setSelected, setShow } = props;
  const classes = useStyles();

  return (
    <div>
      <Dialog
        // open={true}
        open={show}
        onClose={handleClose}
        TransitionComponent={Transition}
        keepMounted
        aria-labelledby="scroll-dialog-title"
        aria-describedby="alert-dialog-slide-description"
        maxWidth="md"
        fullWidth={true}
      >
        <DialogContent>
          <ClearIcon
            onClick={() => {
              setShow(false);
            }}
            style={{ width: "25px", height: "25px", float: "right" }}
          />
          <div
            style={{
              marginTop: "10px",
              marginBottom: "20px",
            }}
          >
            {selected.image ? <span><Avatar alt={selected.name} src={selected.image} /> </span> : <Avatar alt={selected.name} src="/broken-image.jpg" />}&nbsp;Caregiver
          </div>


          <Grid container spacing={2}>
            <Grid item xs={12} md={6} style={{ marginBottom: "15px" }}>
              <FormControl fullWidth variant="standard">
                <InputLabel htmlFor="standard-adornment-amount" className={classes.inputLabelText}>
                  Name:- {selected.name ? <span className={classes.data}> {selected.name} </span> : 'N/A'}
                </InputLabel>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6} style={{ marginBottom: "15px" }}>
              <FormControl fullWidth variant="standard">
                <InputLabel htmlFor="img" className={classes.inputLabelText}>
                  Email:- {selected.email ? <span className={classes.data}> {selected.email} </span> : 'N/A'}
                </InputLabel>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6} style={{ marginBottom: "15px" }}>
              <FormControl fullWidth variant="standard">
                <InputLabel htmlFor="age" className={classes.inputLabelText}>
                  Phone:- {selected.phone ? <span className={classes.data}> {selected.phone} </span> : 'N/A'}
                </InputLabel>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6} style={{ marginBottom: "15px" }}>
              <FormControl fullWidth variant="standard">
                <InputLabel htmlFor="emailVerified" className={classes.inputLabelText}>
                  Email verified:-&nbsp;{selected.emailVerified ? <span className={classes.enableDisable}><span className={classes.green}></span>&nbsp;Enable</span> : <span className={classes.enableDisable}><span className={classes.red}></span>&nbsp;Disable</span>}
                </InputLabel>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6} style={{ marginBottom: "15px" }}>
              <FormControl fullWidth variant="standard">
                <InputLabel htmlFor="phoneVerified" className={classes.inputLabelText}>
                  Phone verified:-&nbsp;{selected.phoneVerified ? <span className={classes.enableDisable}><span className={classes.green}></span>&nbsp;Enable</span> : <span className={classes.enableDisable}><span className={classes.red}></span>&nbsp;Disable</span>}
                </InputLabel>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6} style={{ marginBottom: "15px" }}>
              <FormControl fullWidth variant="standard">
                <InputLabel htmlFor="verified" className={classes.inputLabelText}>
                  verified:-&nbsp;{selected.verified ? <span className={classes.enableDisable}><span className={classes.green}></span>&nbsp;Enable</span> : <span className={classes.enableDisable}><span className={classes.red}></span>&nbsp;Disable</span>}
                </InputLabel>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6} style={{ marginBottom: "15px" }}>
              <FormControl fullWidth variant="standard">
                <InputLabel htmlFor="verifiedBy" className={classes.inputLabelText}>
                  Verified by:- {selected.verifiedBy ? <span className={classes.data}> {selected.verifiedBy} </span> : 'N/A'}
                </InputLabel>
              </FormControl>
            </Grid>
          </Grid>
          <Divider variant="middle" style={{ margin: "20px auto" }} />
          <Grid item xs={12} style={{ float: "right", margin: "10px auto" }}>
            <Button
              autoFocus
              variant="contained"
              color="primary"
              onClick={() => verify({
                ...selected,
                emailVerified: 1,
                phoneVerified: 1,
                verified: 1,
                verifiedBy: 'admin'
              })}
              style={{
                textTransform: "capitalize",
                minWidth: "120px",
                backgroundColor: "#FF8427",
                color: "rgb(255, 255, 255)",
                borderRadius: "20px",
              }}
            >
              Verify now
            </Button>
          </Grid>

        </DialogContent>
      </Dialog>
    </div>
  );
}