import * as actionTypes from "./medicalReportActionType";
import axios from 'axios';
import { checkError } from './../error';

const baseUrl = process.env.REACT_APP_API_URL;

export const getMedicalReport = (data) => {
    
    const config = { params: data };
      return (dispatch) => {
        dispatch(getMedicalReportInit());
        axios.get(`${baseUrl}/report-medical-case`,config)
        .then((response)=>{
            dispatch(getMedicalReportSuccess(response.data.data));
        })
        .catch(function (error) {
            checkError(error);
            dispatch(getMedicalReportFailure());
        })
    };
};

export const getMedicalReportInit = () => ({
    type: actionTypes.GET_MEDICAL_REPORT_LIST
});

export const getMedicalReportFailure = data => ({
    type: actionTypes.GET_MEDICAL_REPORT_LIST_FAILURE
});

export const getMedicalReportSuccess = data => ({
    type: actionTypes.GET_MEDICAL_REPORT_LIST_SUCCESS,
    payload: { data }
});

export const createMedicalReport = (data) => {
    return (dispatch) => {
        dispatch(createMedicalReportInit());
        axios.post(`${baseUrl}/report-medical-case/store`, data)
        .then((response)=>{
            dispatch(createMedicalReportSuccess(response.data.data));
        })
        .catch(function (error) {
            checkError(error);
            dispatch(createMedicalReportFailure());
        })
    };
};


export const createMedicalReportInit = () => ({
    type: actionTypes.CREATE_MEDICAL_REPORT
});

export const createMedicalReportFailure = data => ({
    type: actionTypes.CREATE_MEDICAL_REPORT_FAILURE
});

export const createMedicalReportSuccess = data => ({
    type: actionTypes.CREATE_MEDICAL_REPORT_SUCCESS,
    payload: { data }
});

export const createImage = (data) => {
    const config = { headers: { Authorization: `Bearer ${localStorage.getItem("token")}` } };
    const params = new FormData();
    params.append("image", data);
      return (dispatch) => {
        dispatch(createImageInit());
        axios.post(`${baseUrl}/request-consultant-image/store`, params, config)
        .then((response)=>{
            dispatch(createImageSuccess(response.data.data));
            
        })
        .catch(function (error) {
            checkError(error);
            dispatch(createImageFailure());
        })
    };
};

export const createImageInit = () => ({
    type: actionTypes.CREATE_IMAGE
});

export const createImageFailure = data => ({
    type: actionTypes.CREATE_IMAGE_FAILURE
});

export const createImageSuccess = data => ({
    type: actionTypes.CREATE_IMAGE_SUCCESS,
    payload: { data }
    
});

export const updateMedicalReport = (data) => {
    console.warn('updateMedicalReport calling....',data);
    const config = { headers: { Authorization: `Bearer ${localStorage.getItem("token")}` } };
   
      return (dispatch) => {
        dispatch(updateMedicalReportInit());
        axios.put(`${baseUrl}/report-medical-case/${data.id}`, data, config)
        .then((response)=>{
            dispatch(updateMedicalReportSuccess(response.data.data));
        })
        .catch(function (error) {
            checkError(error);
            dispatch(updateMedicalReportFailure());
        })
    };
};

export const updateMedicalReportInit = () => ({
    type: actionTypes.UPDATE_MEDICAL_REPORT
});

export const updateMedicalReportFailure = data => ({
    type: actionTypes.UPDATE_MEDICAL_REPORT_FAILURE
});

export const updateMedicalReportSuccess = data => ({
    type: actionTypes.UPDATE_MEDICAL_REPORT_SUCCESS,
    payload: { data }
});

export const deleteMedicalReport = (data) => {
    const config = { headers: { Authorization: `Bearer ${localStorage.getItem("token")}` } };
      return (dispatch) => {
        dispatch(deleteMedicalReportInit());
        axios.delete(`${baseUrl}/report-medical-case/${data}`, config)
        .then((response)=>{
            dispatch(deleteMedicalReportSuccess(response.data.data));
        })
        .catch(function (error) {
            checkError(error);
            dispatch(deleteMedicalReportFailure());
        })
    };
};

export const deleteMedicalReportInit = () => ({
    type: actionTypes.DELETE_MEDICAL_REPORT
});

export const deleteMedicalReportFailure = data => ({
    type: actionTypes.DELETE_MEDICAL_REPORT_FAILURE
});

export const deleteMedicalReportSuccess = data => ({
    type: actionTypes.DELETE_MEDICAL_REPORT_SUCCESS,
    payload: { data }
});
