import React, { useState } from 'react';
import { makeStyles } from '@mui/styles';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import Typography from '@mui/material/Typography';
import Slide from '@mui/material/Slide';
import Grid from '@mui/material/Grid';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import DialogContent from "@mui/material/DialogContent";
import Input from "@mui/material/Input";
import TextField from '@mui/material/TextField';
import Divider from "@mui/material/Divider";
import InputAdornment from "@mui/material/InputAdornment";
import ClearIcon from "@mui/icons-material/Clear";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  paper: {
    marginTop: theme.spacing(3),
    padding: theme.spacing(3),
  },
  formControl: {
    width: '100%',
    spacing: theme.spacing(2),
  },
  inputLabelText: {
    fontSize: "14px !important",
    color: "rgba(0,0,0,.5) !important",
    fontWeight: "600 !important",
    textTransform: "uppercase",
    marginTop: "0px"
  },
  mdText1: {
    color: "red",
    fontSize: "12px",
    fontWeight: 400,
  },
}));

const Transition = React.forwardRef(function Transition (props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});



export default function CaregiverDialoge (props) {
  const { setNewImage, newImage, handleClose, open, errors, selected, setSelected, validate } = props;
  const classes = useStyles();
  const [inputType, setInputType] = useState('password');
  const [errorMessage, setErrorMessage] = useState('');
  const supportedFormats = ['image/jpeg', 'image/jpg', 'image/png'];

  const togglePasswordVisibility = () => {
    setInputType(inputType === 'password' ? 'text' : 'password');
  }

  const onImageChange = (event) => {
    if (
      event &&
      event.target &&
      event.target.files &&
      event.target.files.length > 0
    ) {
      const file = event.target.files[0];
      const fileType = file.type;
      console.warn('fileType:- ', fileType)
      if (supportedFormats.includes(fileType)) {
        setNewImage(true);
        setErrorMessage('');
        setSelected({
          ...selected,
          image: event.target.files ? event.target.files[0] : undefined,
        });
      } else {
        setNewImage(false);
        setErrorMessage('Only JPG or PNG images are allowed.');
      }
    }

  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
        keepMounted
        aria-labelledby="scroll-dialog-title"
        aria-describedby="alert-dialog-slide-description"
        maxWidth="md"
        fullWidth={true}
      >
        <DialogContent>
          <ClearIcon
            onClick={handleClose}
            style={{ width: "25px", height: "25px", float: "right" }}
          />
          <form
            noValidate
            onSubmit={(e) => {
              e.preventDefault();
              validate(selected);
            }}
            id="form">
            <Grid container spacing={2}>
              <Grid item xs={12} md={6} style={{ marginBottom: "15px" }}>
                <FormControl fullWidth variant="standard" required>
                  <label htmlFor="imagew" style={{ cursor: "pointer", padding: "7px 10px", border: "1px solid #dadada", borderRadius: "10px", background: "#000", color: "#fff", textAlign: "center" }}>
                    Select Image
                  </label>
                  <Input
                    id="imagew"
                    startAdornment={
                      <InputAdornment position="start"></InputAdornment>
                    }
                    onChange={e => onImageChange(e)}
                    margin="none"
                    type='file'
                    name='image'
                    accept="image/png, image/jpg, image/jpeg"
                    style={{ display: "none" }}
                  />

                  {errorMessage && (
                    <p className={classes.mdText1}>{errorMessage}</p>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={12} md={4} style={{ marginBottom: "15px", display: "flex" }}>
                {!selected.id && selected.image ? (
                  <img
                    src={URL.createObjectURL(selected.image)}
                    alt="image"
                    style={{ maxWidth: "100%", maxHeight: "150px" }}
                  />
                ) : selected.image && !newImage ? (
                  <img
                    src={selected.image}
                    style={{ maxWidth: "100%", maxHeight: "150px" }}
                  />
                ) : newImage && selected.id ?
                  (
                    <img
                      src={URL.createObjectURL(selected.image)}
                      alt="image"
                      style={{ maxWidth: "100%", maxHeight: "150px" }}
                    />
                  ) :
                  (
                    ""
                  )}
              </Grid>
              <Grid item xs={12} md={6} style={{ marginBottom: "15px" }}>
                <FormControl fullWidth variant="standard">
                  <InputLabel htmlFor="prefix" className={classes.inputLabelText}>
                    Prefix
                  </InputLabel>
                  <Input
                    id="prefix"
                    startAdornment={
                      <InputAdornment position="start"></InputAdornment>
                    }
                    margin="none"
                    name='prefix'
                    value={selected.prefix}
                    onChange={e => setSelected({ ...selected, prefix: e.target.value })}
                  />
                  {errors && errors.prefix && (
                    <p className={classes.mdText1}>{errors.prefix}</p>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6} style={{ marginBottom: "15px" }}>
                <FormControl fullWidth variant="standard" required>
                  <InputLabel htmlFor="name" className={classes.inputLabelText}>
                    Name
                  </InputLabel>
                  <Input
                    id="name"
                    startAdornment={
                      <InputAdornment position="start"></InputAdornment>
                    }
                    margin="none"
                    name='name'
                    value={selected.name}
                    onChange={e => setSelected({ ...selected, name: e.target.value })}
                  />
                  {errors && errors.name && (
                    <p className={classes.mdText1}>{errors.name}</p>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6} style={{ marginBottom: "15px" }}>
                <FormControl fullWidth variant="standard" required>
                  <InputLabel htmlFor="email" className={classes.inputLabelText}>
                    Email
                  </InputLabel>
                  <Input
                    id="email"
                    startAdornment={
                      <InputAdornment position="start"></InputAdornment>
                    }
                    margin="none"
                    name='email'
                    value={selected.email}
                    onChange={e => setSelected({ ...selected, email: e.target.value })}
                  />
                  {errors && errors.email && (
                    <p className={classes.mdText1}>{errors.email}</p>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6} style={{ marginBottom: "15px" }}>
                <FormControl fullWidth variant="standard" required>
                  <InputLabel htmlFor="password" className={classes.inputLabelText}> Password
                    <RemoveRedEyeIcon style={{ paddingTop: "2px", float: "right", marginTop: "-7px", marginLeft: "10px" }} onClick={() => { togglePasswordVisibility() }} />
                  </InputLabel>

                  <Input
                    id="password"
                    startAdornment={
                      <InputAdornment position="start"></InputAdornment>
                    }
                    margin="none"
                    type={inputType}
                    name='password'
                    value={selected.password}
                    onChange={e => setSelected({ ...selected, password: e.target.value })}
                  />
                  {errors && errors.password && (
                    <p className={classes.mdText1}>{errors.password}</p>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6} style={{ marginBottom: "15px" }}>
                <FormControl fullWidth variant="standard" required>
                  <InputLabel htmlFor="phone" className={classes.inputLabelText}>
                    Phone
                  </InputLabel>
                  <Input
                    id="phone"
                    startAdornment={
                      <InputAdornment position="start"></InputAdornment>
                    }
                    margin="none"
                    type='number'
                    name='phone'
                    value={selected.phone}
                    onChange={e => setSelected({ ...selected, phone: e.target.value })}
                  />
                  {errors && errors.phone && (
                    <p className={classes.mdText1}>{errors.phone}</p>
                  )}
                </FormControl>
              </Grid>

              <Grid item xs={12} md={6} style={{ marginBottom: "15px" }}>
                <FormControl fullWidth variant="standard">
                  <InputLabel htmlFor="city" className={classes.inputLabelText}>
                    City
                  </InputLabel>
                  <Input
                    id="city"
                    startAdornment={
                      <InputAdornment position="start"></InputAdornment>
                    }
                    margin="none"
                    name='city'
                    value={selected.City != null ? selected.City : ''}
                    onChange={e => setSelected({ ...selected, City: e.target.value })}
                  />
                  {errors && errors.City && (
                    <p className={classes.mdText1}>{errors.City}</p>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6} style={{ marginBottom: "15px" }}>
                <FormControl fullWidth variant="standard">
                  <InputLabel htmlFor="district" className={classes.inputLabelText}>
                    District
                  </InputLabel>
                  <Input
                    id="district"
                    startAdornment={
                      <InputAdornment position="start"></InputAdornment>
                    }
                    margin="none"
                    name='district'
                    value={selected.District != null ? selected.District : ''}
                    onChange={e => setSelected({ ...selected, District: e.target.value })}
                  />
                  {errors && errors.District && (
                    <p className={classes.mdText1}>{errors.District}</p>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6} style={{ marginBottom: "15px" }}>
                <FormControl fullWidth variant="standard">
                  <InputLabel htmlFor="state" className={classes.inputLabelText}>
                    State
                  </InputLabel>
                  <Input
                    id="state"
                    startAdornment={
                      <InputAdornment position="start"></InputAdornment>
                    }
                    margin="none"
                    name='state'
                    value={selected.State != null ? selected.State : ''}
                    onChange={e => setSelected({ ...selected, State: e.target.value })}
                  />
                  {errors && errors.State && (
                    <p className={classes.mdText1}>{errors.State}</p>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6} style={{ marginBottom: "15px" }}>
                <FormControl fullWidth variant="standard">
                  <InputLabel htmlFor="zip" className={classes.inputLabelText}>
                    Zip
                  </InputLabel>
                  <Input
                    id="zip"
                    startAdornment={
                      <InputAdornment position="start"></InputAdornment>
                    }
                    margin="none"
                    name='zip'
                    value={selected.zip != null ? selected.zip : ''}
                    onChange={e => setSelected({ ...selected, zip: e.target.value })}
                  />
                  {errors && errors.zip && (
                    <p className={classes.mdText1}>{errors.zip}</p>
                  )}
                </FormControl>
              </Grid>



            </Grid>
            <Divider variant="middle" style={{ margin: "20px auto" }} />
            <Grid item xs={12} style={{ float: "right", margin: "10px auto" }}>
              <Button
                onClick={() => handleClose()}
                autoFocus
                variant="contained"
                color="primary"
                style={{
                  textTransform: "capitalize",
                  minWidth: "120px",
                  marginRight: "5px",
                  backgroundColor: "#e2e4e6",
                  color: "#293038",
                  borderRadius: "20px",
                }}
              >
                Cancel
              </Button>
              <Button
                autoFocus
                variant="contained"
                color="primary"
                form="form"
                type="submit"
                style={{
                  textTransform: "capitalize",
                  minWidth: "120px",
                  backgroundColor: "#FF8427",
                  color: "rgb(255, 255, 255)",
                  borderRadius: "20px",
                }}
              >
                Add
              </Button>
            </Grid>
          </form>
        </DialogContent>
      </Dialog>
    </div>
  );
}