import React from 'react';
import { makeStyles } from '@mui/styles';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import Typography from '@mui/material/Typography';
import Slide from '@mui/material/Slide';
import Grid from '@mui/material/Grid';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import DialogContent from "@mui/material/DialogContent";
import Input from "@mui/material/Input";
import TextField from '@mui/material/TextField';
import Divider from "@mui/material/Divider";
import InputAdornment from "@mui/material/InputAdornment";
import ClearIcon from "@mui/icons-material/Clear";
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  paper: {
    marginTop: theme.spacing(3),
    padding: theme.spacing(3),
  },
  formControl: {
    width: '100%',
    spacing: theme.spacing(2),
  },
  inputLabelText: {
    fontSize: "14px !important",
    color: "rgba(0,0,0,.5) !important",
    fontWeight: "600 !important",
    textTransform: "uppercase",
    marginTop: "0px"
  },
  mdText1: {
    color: "red",
    fontSize: "12px",
    fontWeight: 400,
  },
}));

const Transition = React.forwardRef(function Transition (props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

export default function GuruDialoge (props) {
  const { handleClose, open, errors, selected, setSelected, validate, gatcha, samudhay, samudhayGuru,samudhaygururesult } = props;
  const classes = useStyles();
  console.warn("GuruDialoge samudhay :: ", samudhay)
  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
        keepMounted
        aria-labelledby="scroll-dialog-title"
        aria-describedby="alert-dialog-slide-description"
        maxWidth="md"
        fullWidth={true}
      >
        <DialogContent>
          <ClearIcon
            onClick={handleClose}
            style={{ width: "25px", height: "25px", float: "right" }}
          />
          <div
            style={{
              marginTop: "10px",
              marginBottom: "20px",
            }}
          >
            <Typography
              style={{
                fontSize: "30px",
                fontWeight: 400,
                color: "rgba(0,0,0,.6)",
              }}
            >
              Guru
            </Typography>
          </div>
          <form
            noValidate
            onSubmit={(e) => {
              e.preventDefault();
              validate(selected);
            }}
            id="form">
            <Grid container spacing={2}>
              <Grid item xs={12} md={6} style={{ marginBottom: "15px" }}>
                <FormControl fullWidth variant="standard" required>
                  <InputLabel id="gatcha">Gatcha</InputLabel>
                  <Select
                    labelId="gatcha"
                    id="GatchaId"
                    value={selected.gatchaId}
                    onChange={e => setSelected({ ...selected, GatchaId: e.target.value })}
                    label="gatcha"
                  >
                    {
                      gatcha && gatcha.map((value) => {
                        return (
                          <MenuItem
                            key={value.id}
                            value={value.id}
                            onChange={e => setSelected({ ...selected, GatchaId: e.target.value })}
                            className={selected.GatchaId === value.id ? 'selected' : ''}
                          >{value.name}</MenuItem>
                        );
                      })
                    }


                  </Select>
                  {errors && errors.GatchaId && (
                    <p className={classes.mdText1}>{errors.GatchaId}</p>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6} style={{ marginBottom: "15px" }}>
                <FormControl fullWidth variant="standard" required>
                  <InputLabel id="SamudhayId">Samudhay</InputLabel>
                  <Select
                    labelId="Samudhay"
                    id="SamudhayId"
                    value={selected.SamudhayId}
                    onChange={e => setSelected({ ...selected, SamudhayId: e.target.value })}
                    label="Samudhay"
                  >
                    {
                      samudhay && samudhay.map((value) => {
                        return (
                          <MenuItem selected={selected.SamudhayId === value.id} value={value.id}>{value.name}</MenuItem>
                        );
                      })
                    }


                  </Select>
                  {errors && errors.status && (
                    <p className={classes.mdText1}>{errors.status}</p>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6} style={{ marginBottom: "15px" }}>
                <FormControl fullWidth variant="standard" required>
                  <InputLabel htmlFor="name" className={classes.inputLabelText}>
                    Name
                  </InputLabel>
                  <Input
                    id="name"
                    startAdornment={
                      <InputAdornment position="start"></InputAdornment>
                    }
                    margin="none"
                    name='name'
                    value={selected.name}
                    onChange={e => setSelected({ ...selected, name: e.target.value })}
                  />
                  {errors && errors.name && (
                    <p className={classes.mdText1}>{errors.name}</p>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6} style={{ marginBottom: "15px" }}>
                <FormControl fullWidth variant="standard" required>
                  <InputLabel id="status">Status</InputLabel>
                  <Select
                    labelId="status"
                    id="status"
                    value={selected.status}
                    onChange={e => setSelected({ ...selected, status: e.target.value })}
                    label="status"
                  >
                    <MenuItem value={1}>Enable</MenuItem>
                    <MenuItem value={0}>Disable</MenuItem>
                  </Select>
                  {errors && errors.status && (
                    <p className={classes.mdText1}>{errors.status}</p>
                  )}
                </FormControl>
              </Grid>
            </Grid>
            <Divider variant="middle" style={{ margin: "20px auto" }} />
            <Grid item xs={12} style={{ float: "right", margin: "10px auto" }}>
              <Button
                onClick={() => handleClose()}
                autoFocus
                variant="contained"
                color="primary"
                style={{
                  textTransform: "capitalize",
                  minWidth: "120px",
                  marginRight: "5px",
                  backgroundColor: "#e2e4e6",
                  color: "#293038",
                  borderRadius: "20px",
                }}
              >
                Cancel
              </Button>
              <Button
                // onClick={submitHandle}
                autoFocus
                variant="contained"
                color="primary"
                form="form"
                type="submit"
                style={{
                  textTransform: "capitalize",
                  minWidth: "120px",
                  backgroundColor: "#FF8427",
                  color: "rgb(255, 255, 255)",
                  borderRadius: "20px",
                }}
              >
                Add
              </Button>
            </Grid>
          </form>
        </DialogContent>
      </Dialog>
    </div>
  );
}