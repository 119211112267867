import React, { useEffect, useState, useRef } from "react";
import { makeStyles } from "@mui/styles";
import Dialog from "@mui/material/Dialog";
import { Typography, Avatar } from "@mui/material";
import Slide from "@mui/material/Slide";
import Grid from "@mui/material/Grid";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import DialogContent from "@mui/material/DialogContent";
import ClearIcon from "@mui/icons-material/Clear";
import RadioGroup from "@mui/material/RadioGroup";
import FormLabel from "@mui/material/FormLabel";


const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "relative",
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  paper: {
    marginTop: theme.spacing(3),
    padding: theme.spacing(3),
  },
  formControl: {
    width: "100%",
    spacing: theme.spacing(2),
  },
  inputLabelText: {
    fontSize: "14px !important",
    color: "rgba(0,0,0,.5) !important",
    fontWeight: "600 !important",
    textTransform: "uppercase",
    marginTop: "0px",
  },
  mdText1: {
    color: "red",
    fontSize: "12px",
    fontWeight: 400,
  },
  imgSelect: {
    width: "100px",
    marginLeft: "10px",
    // height:"120px"
  },
  imgSection: {
    display: "flex",
    // justifyContent:"space-between",
    alignItems: "center",
    marginBottom: "10px",
  },
  textAreaSection: {
    width: "100%",
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "space-between",
  },
  deleteBtn: {
    backgroundColor: "red !important",
    color: "white !important",
    marginLeft: "10px !important",
    // margin: "10px auto 10px 10px !important",
  },
  addBtn: {
    backgroundColor: "green !important",
    color: "white !important",
    marginLeft: "10px !important",
    // margin: "10px auto 10px 10px !important",
  },
  vitalCustom: {
    marginBottom: "10px !important",
    width: "95%",
    background: "transparent !important",

    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
  },
  customTime: {
    marginBottom: "10px !important",
    width: "95%",
    border: "none !important",
    padding: "0px",

    "& fieldset": { borderColor: "gray !important" },

    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
  },

  vitalInput: {
    width: "97.5%",

    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
  },
  headVital: {
    position: "absolute",
    top: "11%",
    left: "50%",
  },
  vitalsSection: {
    marginTop: "10px",
    marginBottom: "10px",
    display: "flex",
    justifyContent: "space-between",
  },

}));

const Transition = React.forwardRef(function Transition (props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

export default function RequestAttatchmentDialogue (props) {
  const {
    setAttatchment,
    attatchment,
    selected,
    img,
    handleAttatchmentClose
  } = props;

  return (
    <div>
      <Dialog
        open={attatchment}
        onClick={handleAttatchmentClose}
        keepMounted
        aria-labelledby="scroll-dialog-title"
        aria-describedby="alert-dialog-slide-description"
        maxWidth="md"

        fullWidth={true}
      >
        <DialogContent>
          <ClearIcon
            style={{ width: "25px", height: "25px", float: "right" }}
          />
          <div
            style={{
              marginTop: "10px",
              marginBottom: "20px",
            }}
          >
            <Typography
              style={{
                fontSize: "30px",
                fontWeight: 400,
                color: "rgba(0,0,0,.6)",
              }}
            >
              Request Attatchment
            </Typography>
          </div>
          <form
            noValidate
            id="form"
            onSubmit={(event) => {
              event.preventDefault();
            }}
          >
            <Grid container spacing={2}>
              {
                img ?
                  img.map((row) => (
                    <Grid key={row.id} item xs={12} md={2} style={{ marginBottom: "15px" }} >
                      <Avatar
                        src={row.image}
                        sx={{ height: "100px", width: "100px" }}
                      />
                    </Grid>
                  )
                  )
                  : ('N/A')

              }





            </Grid>


          </form>
        </DialogContent>
      </Dialog>
    </div >
  );
}
