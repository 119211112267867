import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@mui/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import TextField from "@mui/material/TextField";
import { useNavigate } from "react-router-dom";

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  { id: 'name', key:'0', numeric: false, disablePadding: false, label: 'Name' },
  { id: 'status',key:'1', numeric: false, disablePadding: false, label: 'Status' },
];

function EnhancedTableHead(props) {
  const { classes, order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell></TableCell>
        {headCells.map((headCell) => (
          <TableCell style={{
            textTransform: "uppercase",
            fontSize: "10px",
            fontWeight: 600,
            fontFamily: "Metropolis-Regular,Sans-Serif",
            color: "rgba(37,45,71,.62)"
          }}
            key={headCell.key}
            align='left'
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
        <TableCell style={{
          textTransform: "uppercase",
          fontSize: "10px",
          fontWeight: 600,
          fontFamily: "Metropolis-Regular,Sans-Serif",
          color: "rgba(37,45,71,.62)"
        }}>
          Actions
        </TableCell>
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
};

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.type === 'light'
      ? {
        color: theme.palette.secondary.main,
        backgroundColor: "#9e040e",
      }
      : {
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.secondary.dark,
      },
  title: {
    flex: '1 1 100%',
  },
}));

const EnhancedTableToolbar = (props) => {
  const classes = useToolbarStyles();

  return (
    <Toolbar
      className={clsx(classes.root)}
    >
      <Typography className={classes.title} variant="h6" id="tableTitle" component="div">
        Medical case
      </Typography>
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  menuText: {
    fontWeight: "400",
    fontSize: "14px",
    color: "#534F6B",
    minWidth: "135px"
  },
  editIcon: {
    fontSize: '30px',
    position: 'relative',
    lineHeight: 1,
    backgroundColor: 'rgba(0, 255, 10, 0.2)',
    borderColor: 'transparent',
    color: '#4caf50',
    padding: '8px',
    height: '100%',
    borderRadius: '5px',
  },
  detailIcon: {
    fontSize: '30px',
    position: 'relative',
    lineHeight: 1,
    backgroundColor: 'rgba(0, 255, 10, 0.2)',
    borderColor: 'transparent',
    color: '#4caf50',
    padding: '8px',
    height: '100%',
    borderRadius: '5px',
  },
  deleteIcon: {
    fontSize: '30px',
    position: 'relative',
    lineHeight: 1,
    backgroundColor: 'rgba(255, 68, 0, 0.2)',
    borderColor: 'transparent',
    color: '#fa6c39',
    padding: '8px',
    height: '100%',
    borderRadius: '5px',
  },
  enable: {
    background: "green",
    padding: "5px",
    color: "white",
    borderRadius: "10px"
  },
  disable: {
    background: "red",
    padding: "5px",
    color: "white",
    borderRadius: "10px"
  },
}));

export default function MedicineTable(props) {
  const navigate = useNavigate();
  const {
    handleClickOpen,
    rows,
    setSelected,
    count,
    order,
    orderBy,
    rowsPerPage,
    page,
    handleRequestSort,
    handleChangePage,
    handleChangeRowsPerPage,
    confirmDelete,
    setFiltes
  } = props;

  
  const classes = useStyles();

  // console.warn("count:--", count)

  // const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);
  

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <Toolbar className={classes.root} style={{ justifyContent: "space-between" }}>
          <Typography
            className={classes.title}
            variant="h6"
            id="tableTitle"
            component="div"
          >
            Medicine
          </Typography>
          <TextField
            helperText=" "
            id="demo-helper-text-aligned-no-helper"
            label="Search"
            type="search"
            onChange={(e) => setFiltes(e.target.value)}
          />
        </Toolbar>
        <TableContainer>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            aria-label="enhanced table"
          >
            <EnhancedTableHead
              classes={classes}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
            />
            <TableBody>
              {rows && Array.isArray(rows) && rows.map((row, index) => {
                const labelId = `medical-${index}`;

                return (
                  <TableRow
                    hover
                    role="checkbox"
                    tabIndex={-1}
                    key={row.id}
                  >
                    <TableCell component="th" id={labelId} scope="row">
                      {(rowsPerPage * page) + index + 1}
                    </TableCell>
                    <TableCell>{row.name ? <span> {row.name} </span> : 'N/A'}</TableCell>
                    <TableCell> {row.status == 1 ? <span className={classes.enable}> Enable </span> : <span className={classes.disable}> Disable </span>}</TableCell>
                    <TableCell>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <EditIcon className={classes.editIcon}
                          onClick={() => {
                            setSelected(row);
                            handleClickOpen();
                          }}
                          style={{ cursor: 'pointer',fontSize:'30px' }}
                        />
                        <DeleteIcon className={classes.deleteIcon}
                          onClick={() => {
                            confirmDelete(row.id);
                          }}
                          style={{ cursor: 'pointer', marginLeft: '15px',fontSize:'30px', marginRight: '15px' }}
                        />
                      </div>
                    </TableCell>
                  </TableRow>
                );
              })}
              {count === 0 && (
                <TableRow style={{ height: 53 * 5 }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={count}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </div>
  );
}