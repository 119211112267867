import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Paper, TextField } from "@mui/material";
import { makeStyles } from "@mui/styles";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "table",
    height: "100%",
    position: "absolute",
    width: "100%",
    overflow: "hidden",
    backgroundColor: "#fff",
    margin: "0",
  },
  content: {
    margin: "0 auto",
    maxWidth: "400px",
  },
  form: {
    padding: 24,
  },
  moveForgetPasswordToRight: {
    float: "right",
    display: "flex",
    paddingBottom: 20,
  },
  shrinkLogo: {
    minHeight: "130px",
    minWidth: "130px",
    margin: "0px auto",
    display: "block",
    position: "relative",
    top: "-70px",
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    background: "#1394ff",
    color: "#fff",
    fontSize: "24px",
    minWidth: 280,
    margin: theme.spacing(2),
    cursor: "pointer",
  },
}));

export function LoginPage(props) {
  const classes = useStyles();
  const navigate = useNavigate();
  const { isLoading, isLoggedIn, adminLogin } = props;
  const [login, setLogin] = useState({
    username: "",
    password: "",
  });

  if (isLoggedIn) {
    navigate("/dashboard");
  }
  return (
    <div>
      <Grid container alignItems="center" justifyContent="center">
        <Grid key="loginform" item md={12}>
          <Paper className={classes.content} elevation={5}>
            <div className={classes.form}>
              <img
                src={process.env.PUBLIC_URL + "/img/hospital-logo.png"}
                alt="Logo"
                className={classes.shrinkLogo}
              />
              <Typography
                align="center"
                variant="h6"
                style={{ marginTop: "-50px" }}
              >
                Login
              </Typography>
              <form
                noValidate
                onSubmit={(event) => {
                  event.preventDefault();
                  adminLogin(login);
                }}
              >
                <TextField
                  id="Username"
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  value={login.username}
                  onChange={(event) =>
                    setLogin({
                      ...login,
                      username: event.target.value,
                    })
                  }
                  label="Username"
                  autoFocus
                />

                <TextField
                  id="Password"
                  type="password"
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  value={login.password}
                  onChange={(event) =>
                    setLogin({
                      ...login,
                      password: event.target.value,
                    })
                  }
                  label="Password"
                  autoFocus
                />

                <Button
                  fullWidth
                  disabled={isLoading}
                  variant="contained"
                  size="large"
                  type="submit"
                  style={{ background: "#FF8427", color: "#fff" }}
                >
                  {isLoading ? "Loggin in..." : "Login"}
                </Button>
                <Typography
                  align="right"
                  variant="subtitle2"
                  style={{ marginTop: "10px", marginBottom: "10px" }}
                >
                  Forgot Password?
                </Typography>
              </form>
            </div>
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
}
