import * as actionTypes from "./vitalActionType";
import axios from 'axios';
import { toast } from 'react-toastify';
import { checkError } from './../error';

const baseUrl = process.env.REACT_APP_API_URL;

export const getVitals = (data) => {
    const config = { headers: { Authorization: `Bearer ${localStorage.getItem("token")}` }, params: data };
      return (dispatch) => {
        dispatch(getVitalsInit());
        axios.get(`${baseUrl}/vital/admin`, config)
        .then((response)=>{
            dispatch(getVitalsSuccess(response.data.data));
        })
        .catch(function (error) {
            checkError(error);
            dispatch(getVitalsFailure());
        })
    };
};

export const getVitalsInit = () => ({
    type: actionTypes.GET_VITAL_LIST
});

export const getVitalsFailure = data => ({
    type: actionTypes.GET_VITAL_LIST_FAILURE
});

export const getVitalsSuccess = data => ({
    type: actionTypes.GET_VITAL_LIST_SUCCESS,
    payload: { data }
});

export const createVital = (data) => {
    const config = { headers: { Authorization: `Bearer ${localStorage.getItem("token")}` } };
      return (dispatch) => {
        dispatch(createVitalInit());
        axios.post(`${baseUrl}/vital/store`, data, config)
        .then((response)=>{
            dispatch(createVitalSuccess(response.data.data));
        })
        .catch(function (error) {
            checkError(error);
            dispatch(createVitalFailure());
        })
    };
};

export const createVitalInit = () => ({
    type: actionTypes.CREATE_VITAL
});

export const createVitalFailure = data => ({
    type: actionTypes.CREATE_VITAL_FAILURE
});

export const createVitalSuccess = data => ({
    type: actionTypes.CREATE_VITAL_SUCCESS,
    payload: { data }
});

export const updateVital = (data) => {
    const config = { headers: { Authorization: `Bearer ${localStorage.getItem("token")}` } };
      return (dispatch) => {
        dispatch(updateVitalInit());
        axios.put(`${baseUrl}/vital/${data.id}`, data, config)
        .then((response)=>{
            dispatch(updateVitalSuccess(response.data.data));
        })
        .catch(function (error) {
            checkError(error);
            dispatch(updateVitalFailure());
        })
    };
};

export const updateVitalInit = () => ({
    type: actionTypes.UPDATE_VITAL
});

export const updateVitalFailure = data => ({
    type: actionTypes.UPDATE_VITAL_FAILURE
});

export const updateVitalSuccess = data => ({
    type: actionTypes.UPDATE_VITAL_SUCCESS,
    payload: { data }
});

export const deleteVital = (data) => {
    const config = { headers: { Authorization: `Bearer ${localStorage.getItem("token")}` } };
      return (dispatch) => {
        dispatch(deleteVitalInit());
        axios.delete(`${baseUrl}/vital/${data}`, config)
        .then((response)=>{
            dispatch(deleteVitalSuccess(response.data.data));
        })
        .catch(function (error) {
            checkError(error);
            dispatch(deleteVitalFailure());
        })
    };
};

export const deleteVitalInit = () => ({
    type: actionTypes.DELETE_VITAL
});

export const deleteVitalFailure = data => ({
    type: actionTypes.DELETE_VITAL_FAILURE
});

export const deleteVitalSuccess = data => ({
    type: actionTypes.DELETE_VITAL_SUCCESS,
    payload: { data }
});
