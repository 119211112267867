import * as actionType from "./emergrncyActionType";

const initialState = {
  isError: false,
  isLoading: false,
  isSaved: false,
  emergencys: [],
  count: 0
};

export const emergencyReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionType.GET_EMERGENCY_LIST:
            return {
                ...state,
                isError: false,
                isLoading: true
            };
        case actionType.GET_EMERGENCY_LIST_FAILURE:
            return {
                ...state,
                isError: true,
                isLoading: false
            };
        case actionType.GET_EMERGENCY_LIST_SUCCESS:
            return {
                ...state,
                isError: false,
                isLoading: false,
                emergencys: action.payload ? action.payload.data.rows : [],
                count: action.payload ? action.payload.data.count : 0
            };
        case actionType.CREATE_EMERGENCY:
            return {
                ...state,
                isError: false,
                isLoading: true,
                isSaved: false
            };
        case actionType.CREATE_EMERGENCY_FAILURE:
            return {
                ...state,
                isError: true,
                isLoading: false
            };
        case actionType.CREATE_EMERGENCY_SUCCESS:
            return {
                ...state,
                isError: false,
                isLoading: false,
                isSaved: true
            };
        case actionType.UPDATE_EMERGENCY:
            return {
                ...state,
                isError: false,
                isLoading: true,
                isSaved: false
            };
        case actionType.UPDATE_EMERGENCY_FAILURE:
            return {
                ...state,
                isError: true,
                isLoading: false
            };
        case actionType.UPDATE_EMERGENCY_SUCCESS:
            return {
                ...state,
                isError: false,
                isLoading: false,
                isSaved: true
            };
        case actionType.DELETE_EMERGENCY:
            return {
                ...state,
                isError: false,
                isLoading: true,
                isSaved: false
            };
        case actionType.DELETE_EMERGENCY_FAILURE:
            return {
                ...state,
                isError: true,
                isLoading: false
            };
        case actionType.DELETE_EMERGENCY_SUCCESS:
            return {
                ...state,
                isError: false,
                isLoading: false,
                isSaved: true
            };
       default:
            return state
            
    }
}
