import * as actionType from "./requestActionType";

const initialState = {
  isError: false,
  isLoading: false,
  isSaved: false,
  imageData: "",
  count : 0,
  consultData: [],
  isSavedComment:false,
  };

export const requestConsultReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionType.GET_REQ_CONSULT:
      return {
        ...state,
        isError: false,
        isLoading: true,
      };
    case actionType.GET_REQ_CONSULT_FAILURE:
      return {
        ...state,
        isError: true,
        isLoading: false,
      };
    case actionType.GET_REQ_CONSULT_SUCCESS:
      return {
        ...state,
        isError: false,
        isLoading: false,
        consultData: action.payload ? action.payload.data : [],
        count: action.payload ? action.payload.data.count : 0
      };
    case actionType.CREATE_REQ_CONSULT_INIT:
      return {
        ...state,
        isError: false,
        isLoading: true,
        isSaved: false,
      };
    case actionType.CREATE_REQ_CONSULT_FAILURE:
      return {
        ...state,
        isError: true,
        isLoading: false,
      };
    case actionType.CREATE_REQ_CONSULT_SUCCESS:
      return {
        ...state,
        isError: false,
        isLoading: false,
        isSaved: true,
        
      };
    case actionType.UPDATE_REQ_CONSULT:
      return {
        ...state,
        isError: false,
        isLoading: true,
        isSaved: false,
      };
    case actionType.UPDATE_REQ_CONSULT_FAILURE:
      return {
        ...state,
        isError: true,
        isLoading: false,
      };
    case actionType.UPDATE_REQ_CONSULT_SUCCESS:
      return {
        ...state,
        isError: false,
        isLoading: false,
        isSaved: true,
      };
    case actionType.DELETE_REQ_CONSULT:
      return {
        ...state,
        isError: false,
        isLoading: true,
        isSaved: false,
      };
    case actionType.DELETE_REQ_CONSULT_FAILURE:
      return {
        ...state,
        isError: true,
        isLoading: false,
        isSaved: true,
      };
    case actionType.DELETE_REQ_CONSULT_SUCCESS:
      return {
        ...state,
        isError: false,
        isLoading: false,
        isSaved: true,
      };

    case actionType.CREATE_ADD_COMMENTCONSULT_INIT:
      return {
        ...state,
        isError: false,
        isLoading: true,
        isSavedComment: false,
      };
    case actionType.CREATE_ADD_COMMENTCONSULT_FAILURE:
      return {
        ...state,
        isError: true,
        isLoading: false,
      };
    case actionType.CREATE_ADD_COMMENTCONSULT_SUCCESS:
      const consultDataIndex = state.consultData.findIndex(
        (data) => data.id === action.payload.data.RequestConsultantId
      );
      const updatedConsultData = [...state.consultData];
      if (consultDataIndex !== -1) {
        updatedConsultData[consultDataIndex].AddComments.push(action.payload.data);
      }
      return {
        ...state,
        isError: false,
        isLoading: false,
        isSavedComment: true,
        consultData: updatedConsultData
      };
      case actionType.CREATE_IMAGE:
        return {
            ...state,
            isError: false,
            isLoading: true,
            isSaved: false
        };
    case actionType.CREATE_IMAGE_FAILURE:
        return {
            ...state,
            isError: true,
            isLoading: false
        };
    case actionType.CREATE_IMAGE_SUCCESS:
        return {
            ...state,
            isError: false,
            isLoading: false,
            imageData: action.payload ? {image: action.payload.data, index: action.payload.i} : ""
        };
    default:
      return state;
  }
};
