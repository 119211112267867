export const GET_DOCTOR_LIST = "GET_DOCTOR_LIST";
export const GET_DOCTOR_LIST_FAILURE = "GET_DOCTOR_LIST_FAILURE";
export const GET_DOCTOR_LIST_SUCCESS = "GET_DOCTOR_LIST_SUCCESS";

export const CREATE_DOCTOR = "CREATE_DOCTOR";
export const CREATE_DOCTOR_FAILURE = "CREATE_DOCTOR_FAILURE";
export const CREATE_DOCTOR_SUCCESS = "CREATE_DOCTOR_SUCCESS";

export const CREATE_IMAGE = "CREATE_IMAGE";
export const CREATE_IMAGE_FAILURE = "CREATE_IMAGE_FAILURE";
export const CREATE_IMAGE_SUCCESS = "CREATE_IMAGE_SUCCESS";

export const UPDATE_DOCTOR = "UPDATE_DOCTOR";
export const UPDATE_DOCTOR_FAILURE = "UPDATE_DOCTOR_FAILURE";
export const UPDATE_DOCTOR_SUCCESS = "UPDATE_DOCTOR_SUCCESS";

export const DELETE_DOCTOR = "DELETE_DOCTOR";
export const DELETE_DOCTOR_FAILURE = "DELETE_DOCTOR_FAILURE";
export const DELETE_DOCTOR_SUCCESS = "DELETE_DOCTOR_SUCCESS";
