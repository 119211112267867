import * as actionTypes from "./caregiverActionType";
import axios from 'axios';
import { checkError } from './../error';

const baseUrl = process.env.REACT_APP_API_URL;

export const getCaregiver = (data) => {
    const config = { headers: { Authorization: `Bearer ${localStorage.getItem("token")}` }, params: data };
    return (dispatch) => {
        dispatch(getCaregiverInit());
        axios.get(`${baseUrl}/care-giver/admin`, config)
            .then((response) => {
                dispatch(getCaregiverSuccess(response.data.data));
            })
            .catch(function (error) {
                checkError(error);
                dispatch(getCaregiverFailure());
            })
    };
};

export const getCaregiverInit = () => ({
    type: actionTypes.GET_CAREGIVER_LIST
});

export const getCaregiverFailure = data => ({
    type: actionTypes.GET_CAREGIVER_LIST_FAILURE
});

export const getCaregiverSuccess = data => ({
    type: actionTypes.GET_CAREGIVER_LIST_SUCCESS,
    payload: { data }
});

export const createCaregiver = (data) => {
    console.warn('data:- ',data);
    
    const config = { headers: { Authorization: `Bearer ${localStorage.getItem("token")}` } };
    const params = new FormData();
    if(data.image) params.append("image", data.image);
    if(data.prefix) params.append("prefix", data.prefix);
    if(data.name) params.append("name", data.name);
    if(data.email) params.append("email", data.email);
    if(data.phone) params.append("phone", data.phone);
    if(data.password) params.append("password", data.password);
    if(data.City) params.append("City", data.City);
    if(data.District) params.append("District", data.District);
    if(data.State) params.append("State", data.State);
    if(data.zip) params.append("zip", data.zip);
    params.append("verifiedBy", data.verifiedBy);
    params.append("emailVerified", data.emailVerified);
    params.append("phoneVerified", data.phoneVerified);
    params.append("verified", data.verified);
    return (dispatch) => {
        dispatch(createCaregiverInit());
        axios.post(`${baseUrl}/care-giver/store`, params, config )
            .then((response) => {
                dispatch(createCaregiverSuccess(response.data.data));
            })
            .catch(function (error) {
                checkError(error);
                dispatch(createCaregiverFailure());
            })
    };
};

export const createCaregiverInit = () => ({
    type: actionTypes.CREATE_CAREGIVER
});

export const createCaregiverFailure = data => ({
    type: actionTypes.CREATE_CAREGIVER_FAILURE
});

export const createCaregiverSuccess = data => ({
    type: actionTypes.CREATE_CAREGIVER_SUCCESS,
    payload: { data }
});

export const updateCaregiver = (data) => {
    const config = { headers: { Authorization: `Bearer ${localStorage.getItem("token")}` } };
    const params = new FormData();
    
    if(data.image) params.append("image", data.image);
    if(data.prefix) params.append("prefix", data.prefix);
    if(data.name) params.append("name", data.name);
    if(data.email) params.append("email", data.email);
    if(data.phone) params.append("phone", data.phone);
    if(data.password) params.append("password", data.password);
    if(data.City) params.append("City", data.City);
    if(data.District) params.append("District", data.District);
    if(data.State) params.append("State", data.State);
    if(data.zip) params.append("zip", data.zip);
    params.append("verifiedBy", data.verifiedBy);
    params.append("emailVerified", data.emailVerified);
    params.append("phoneVerified", data.phoneVerified);
    params.append("verified", data.verified);



      return (dispatch) => {
        dispatch(updateCaregiverInit());
        axios.put(`${baseUrl}/care-giver/admin/${data.id}`, params, config)
        .then((response)=>{
            dispatch(updateCaregiverSuccess(response.data.data));
        })
        .catch(function (error) {
            checkError(error);
            dispatch(updateCaregiverFailure());
        })
    };
};

export const updateCaregiverInit = () => ({
    type: actionTypes.UPDATE_CAREGIVER
});

export const updateCaregiverFailure = data => ({
    type: actionTypes.UPDATE_CAREGIVER_FAILURE
});

export const updateCaregiverSuccess = data => ({
    type: actionTypes.UPDATE_CAREGIVER_SUCCESS,
    payload: { data }
});

export const deleteCaregiver = (data) => {
    const config = { headers: { Authorization: `Bearer ${localStorage.getItem("token")}` } };
      return (dispatch) => {
        dispatch(deleteCaregiverInit());
        axios.delete(`${baseUrl}/care-giver/${data}`, config)
        .then((response)=>{
            dispatch(deleteCaregiverSuccess(response.data.data));
        })
        .catch(function (error) {
            checkError(error);
            dispatch(deleteCaregiverFailure());
        })
    };
};

export const deleteCaregiverInit = () => ({
    type: actionTypes.DELETE_CAREGIVER
});

export const deleteCaregiverFailure = data => ({
    type: actionTypes.DELETE_CAREGIVER_FAILURE
});

export const deleteCaregiverSuccess = data => ({
    type: actionTypes.DELETE_CAREGIVER_SUCCESS,
    payload: { data }
});
