import { connect } from "react-redux";
import { Password } from "./Password.component";
import { PasswordUpdate } from "../../api/admin/adminAction";

const mapDispatchToProps = {
  PasswordUpdate,
};

const mapStateToProps = () => ({});

export const PasswordContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(Password);
