import { connect } from "react-redux";
import  {Profile} from "./Profile.component"
import { ProfileUpdate } from "../../api/admin/adminAction";
const mapDispatchToProps = {
    ProfileUpdate,
};

const mapStateToProps = (state) => ({
  
});

export const ProfileContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(Profile);
