import * as actionTypes from "./medicineActionType";
import axios from 'axios';
import { checkError } from './../error';

const baseUrl = process.env.REACT_APP_API_URL;

export const getMedicine = (data) => {
    const config = { headers: { Authorization: `Bearer ${localStorage.getItem("token")}` }, params: data };
      return (dispatch) => {
        dispatch(getMedicineInit());
        axios.get(`${baseUrl}/medicine/admin`, config)
        .then((response)=>{
            dispatch(getMedicineSuccess(response.data.data));
        })
        .catch(function (error) {
            checkError(error);
            dispatch(getMedicineFailure());
        })
    };
};

export const getMedicineInit = () => ({
    type: actionTypes.GET_MEDICINE_LIST
});

export const getMedicineFailure = data => ({
    type: actionTypes.GET_MEDICINE_LIST_FAILURE
});

export const getMedicineSuccess = data => ({
    type: actionTypes.GET_MEDICINE_LIST_SUCCESS,
    payload: { data }
});

export const createMedicine = (data) => {
    const config = { headers: { Authorization: `Bearer ${localStorage.getItem("token")}` } };
      return (dispatch) => {
        dispatch(createMedicineInit());
        axios.post(`${baseUrl}/medicine/store`, data, config)
        .then((response)=>{
            dispatch(createMedicineSuccess(response.data.data));
        })
        .catch(function (error) {
            checkError(error);
            dispatch(createMedicineFailure());
        })
    };
};

export const createMedicineInit = () => ({
    type: actionTypes.CREATE_MEDICINE
});

export const createMedicineFailure = data => ({
    type: actionTypes.CREATE_MEDICINE_FAILURE
});

export const createMedicineSuccess = data => ({
    type: actionTypes.CREATE_MEDICINE_SUCCESS,
    payload: { data }
});

export const updateMedicine = (data) => {
    const config = { headers: { Authorization: `Bearer ${localStorage.getItem("token")}` } };
      return (dispatch) => {
        dispatch(updateMedicineInit());
        axios.put(`${baseUrl}/medicine/${data.id}`, data, config)
        .then((response)=>{
            dispatch(updateMedicineSuccess(response.data.data));
        })
        .catch(function (error) {
            checkError(error);
            dispatch(updateMedicineFailure());
        })
    };
};

export const updateMedicineInit = () => ({
    type: actionTypes.UPDATE_MEDICINE
});

export const updateMedicineFailure = data => ({
    type: actionTypes.UPDATE_MEDICINE_FAILURE
});

export const updateMedicineSuccess = data => ({
    type: actionTypes.UPDATE_MEDICINE_SUCCESS,
    payload: { data }
});

export const deleteMedicine = (data) => {
    const config = { headers: { Authorization: `Bearer ${localStorage.getItem("token")}` } };
      return (dispatch) => {
        dispatch(deleteMedicineInit());
        axios.delete(`${baseUrl}/medicine/${data}`, config)
        .then((response)=>{
            dispatch(deleteMedicineSuccess(response.data.data));
        })
        .catch(function (error) {
            checkError(error);
            dispatch(deleteMedicineFailure());
        })
    };
};

export const deleteMedicineInit = () => ({
    type: actionTypes.DELETE_MEDICINE
});

export const deleteMedicineFailure = data => ({
    type: actionTypes.DELETE_MEDICINE_FAILURE
});

export const deleteMedicineSuccess = data => ({
    type: actionTypes.DELETE_MEDICINE_SUCCESS,
    payload: { data }
});
