import { MedicinePage } from './medicine.component';
import { connect } from 'react-redux';
import { getMedicine, createMedicine, updateMedicine, deleteMedicine } from "../../api/medicine/medicineAction";

const mapDispatchToProps = {
    getMedicine,
    createMedicine,
    updateMedicine,
    deleteMedicine
};

const mapStateToProps = state => ({
    error: state.medicinePage.isError,
    isLoading: state.medicinePage.isLoading,
    isSaved: state.medicinePage.isSaved,
    medicines: state.medicinePage.medicines,
    count: state.medicinePage.count,
    isLoggedIn : state.adminPage.isLoggedIn
});

export const MedicineContainer = connect(mapStateToProps, mapDispatchToProps)(MedicinePage);