import * as actionType from "./vitalActionType";

const initialState = {
  isError: false,
  isLoading: false,
  isSaved: false,
  vitals: [],
  count: 0
};

export const vitalReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionType.GET_VITAL_LIST:
            return {
                ...state,
                isError: false,
                isLoading: true
            };
        case actionType.GET_VITAL_LIST_FAILURE:
            return {
                ...state,
                isError: true,
                isLoading: false
            };
        case actionType.GET_VITAL_LIST_SUCCESS:
            return {
                ...state,
                isError: false,
                isLoading: false,
                vitals: action.payload ? action.payload.data.rows : [],
                count: action.payload ? action.payload.data.count : 0
            };
        case actionType.CREATE_VITAL:
            return {
                ...state,
                isError: false,
                isLoading: true,
                isSaved: false
            };
        case actionType.CREATE_VITAL_FAILURE:
            return {
                ...state,
                isError: true,
                isLoading: false
            };
        case actionType.CREATE_VITAL_SUCCESS:
            return {
                ...state,
                isError: false,
                isLoading: false,
                isSaved: true
            };
        case actionType.UPDATE_VITAL:
            return {
                ...state,
                isError: false,
                isLoading: true,
                isSaved: false
            };
        case actionType.UPDATE_VITAL_FAILURE:
            return {
                ...state,
                isError: true,
                isLoading: false
            };
        case actionType.UPDATE_VITAL_SUCCESS:
            return {
                ...state,
                isError: false,
                isLoading: false,
                isSaved: true
            };
        case actionType.DELETE_VITAL:
            return {
                ...state,
                isError: false,
                isLoading: true,
                isSaved: false
            };
        case actionType.DELETE_VITAL_FAILURE:
            return {
                ...state,
                isError: true,
                isLoading: false
            };
        case actionType.DELETE_VITAL_SUCCESS:
            return {
                ...state,
                isError: false,
                isLoading: false,
                isSaved: true
            };
       default:
            return state
            
    }
}
