import { React, useState } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { makeStyles } from "@mui/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import EditIcon from "@mui/icons-material/Edit";
import SearchIcon from "@mui/icons-material/Search";
import DeleteIcon from "@mui/icons-material/Delete";
import { confirmAlert } from "react-confirm-alert";
import TextField from "@mui/material/TextField";
import Avatar from "@mui/material/Avatar";
import { useNavigate } from "react-router-dom";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import AssignmentInd from "@mui/icons-material/AssignmentInd";

import Box from "@mui/material/Box";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import { Tooltip, IconButton, Button } from "@mui/material";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import MailIcon from "@mui/icons-material/Mail";
import { useEffect } from "react";


function descendingComparator (a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator (order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort (array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  { id: "id", key: '0', numeric: true, disablePadding: false, label: "S.No." },
  { id: "id", key: '1', numeric: false, disablePadding: false, label: "Type" },
  {
    id: "id",
    key: '2',
    numeric: false,
    disablePadding: false,
    label: "Doctor",
  },
  {
    id: "id",
    key: '3',
    numeric: false,
    disablePadding: false,
    label: "Medical Case",
  },

  {
    id: "id",
    key: '4',
    numeric: false,
    disablePadding: false,
    label: "Sharman",
  },
  {
    id: "id",
    key: '5',
    numeric: false,
    disablePadding: false,
    label: "Type Visit",
  },
  { id: "id", key: '6', numeric: false, disablePadding: false, label: "Date" },
  { id: "id", key: '7', numeric: false, disablePadding: false, label: "AcceptTime" },
  {
    id: "id",
    key: '8',
    numeric: false,
    disablePadding: false,
    label: "Care Giver",
  },
  { id: "id", key: '9', numeric: false, disablePadding: false, label: "Status" },
];

function EnhancedTableHead (props) {
  const { classes, order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            className={classes.tableHeader}
            key={headCell.key}
            align="left"
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
        <TableCell className={classes.tableHeader}>Actions</TableCell>
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
};

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.type === "light"
      ? {
        color: theme.palette.secondary.main,
        backgroundColor: "#9e040e",
      }
      : {
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.secondary.dark,
      },
  title: {
    flex: "1 1 100%",
  },
}));

const EnhancedTableToolbar = (props) => {
  const classes = useToolbarStyles();
  const { data, headers, rows } = props;
  const csvReport = {
    data: data,
    headers: headers,
    filename: "user.csv",
  };

  return (
    <Toolbar className={clsx(classes.root)}>
      <Typography
        className={classes.title}
        variant="h6"
        id="tableTitle"
        component="div"
      >
        Doctor
      </Typography>
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {};

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  menuText: {
    fontWeight: "400",
    fontSize: "14px",
    color: "#534F6B",
    minWidth: "135px",
  },
  editIcon: {
    fontSize: "30px",
    position: "relative",
    lineHeight: 1,
    backgroundColor: "rgba(0, 255, 10, 0.2) !important",
    borderColor: "transparent",
    color: "#4caf50",
    padding: "8px",
    height: "100%",
    borderRadius: "5px",


    "&:hover": {
      backgroundColor: "rgba(0, 255, 10, 0.2)  !important",
      cursor: "default",
    },
  },
  deleteIcon: {
    fontSize: "30px",
    position: "relative",
    lineHeight: 1,
    backgroundColor: "rgba(255, 68, 0, 0.2)",
    borderColor: "transparent",
    color: "#fa6c39",
    padding: "8px",
    height: "100%",
    borderRadius: "5px",
  },

  tableHeader: {
    textTransform: "uppercase !important",
    fontSize: "14px !important",
    fontWeight: ` ${600} !important`,
    fontFamily: "Metropolis-Regular,Sans-Serif  !important",
    color: "rgba(37,45,71,.62) !important",
    backgroundColor: "#dcdde1",
  },
  tableTextSearch: {
    display: "flex !important",
    alignItems: "center !important",
    justifyContent: "space-between !important",
    marginBottom: "1rem",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column !important",
      alignItems: "flex-start !important",
    },
  },
  tableSearch: {
    // height:"34px",
    textAlign: "center",
  },
  btn_icon: {
    backgroundColor: "transparent",

    "&:hover": {
      backgroundColor: "transparent !important",
      cursor: "default",
    },
  },
}));

export default function RequestConsultTable (props) {
  const navigate = useNavigate();
  const {
    consultData,
    handleClickOpen,
    handleClickDetail,
    handleRequestAnother,
    selected,
    setSelected,
    count,
    order,
    orderBy,
    rowsPerPage,
    page,
    handleRequestSort,
    handleChangePage,
    handleChangeRowsPerPage,
    confirmDelete,
    setFiltes,
    isLoggedIn,
    setImageArray,
    setImg
  } = props;
  const classes = useStyles();
  console.warn("rows count :: ",count);
  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, consultData.rows - page * rowsPerPage);
  if (!isLoggedIn) {
    navigate("/login");
  }

  ///  side dialog functions for user detail
  const [state, setState] = useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });
  const [detail, setDetail] = useState();
  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, right: open });
  };

  const list = (anchor) => (
    <Box
      sx={{ width: anchor === "top" || anchor === "bottom" ? "auto" : 400 }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <List>
        <Box sx={{ padding: "2rem" }}>
          <Box className="border_dialog">
            <Box
              sx={{
                display: "flex",
                width: "90%",
                margin: "1rem auto",
                fontSize: "20px",
                fontWeight: "550",
              }}
            >
              <p className="project_head">
                Project <span>Sata</span>
              </p>
            </Box>

            <Box
              sx={{
                display: "flex",
                width: "90%",
                margin: "1rem auto",
                fontSize: "20px",
                fontWeight: "550",
              }}
            >
              <Avatar />{" "}
              <span
                className="profile_name"
                sx={{
                  display: "flex",
                  alignItems: "center",
                  marginLeft: "16px",
                }}
              >
                {" "}
                Ashok kumar
              </span>
            </Box>

            <Box
              sx={{
                display: "flex",
                width: "90%",
                margin: "1rem auto",
                fontSize: "20px",
                fontWeight: "550",
              }}
              className="dialog_text"
            >
              <p>
                Doctor :{" "}
                <span>
                  {detail && detail.Doctor ? detail.Doctor.name : "N/A"}
                </span>
              </p>
            </Box>
            <Box
              sx={{
                display: "flex",
                width: "90%",
                margin: "1rem auto",
                fontSize: "20px",
                fontWeight: "550",
              }}
              className="dialog_text"
            >
              <p>
                Shraman :{" "}
                <span className="text_green">
                  {detail && detail.Sharman ? detail.Sharman.name : "N/A"}
                </span>
              </p>
            </Box>
            <Box
              sx={{
                display: "flex",
                width: "90%",
                margin: "1rem auto",
                fontSize: "20px",
                fontWeight: "550",
              }}
              className="dialog_text"
            >
              <p>
                Cavgiver :{" "}
                <span className="text_red">
                  {" "}
                  {detail && detail.CareGiver ? detail.CareGiver.name : "N/A"}
                </span>
              </p>
            </Box>
            <Box
              sx={{
                display: "flex",
                width: "90%",
                margin: "1rem auto",
                fontSize: "20px",
                fontWeight: "550",
              }}
              className="dialog_text"
            >
              <p>
                Date Of Visit:{" "}
                <span>
                  {detail && detail.date_of_visit
                    ? detail.date_of_visit
                    : "N/A"}
                </span>
              </p>
            </Box>
            <Box
              sx={{
                display: "flex",
                width: "90%",
                margin: "1rem auto",
                fontSize: "20px",
                fontWeight: "550",
              }}
              className="dialog_text"
            >
              <p>
                Request Consultation :{" "}
                <span>
                  {detail && detail.RequestOtherConsultant
                    ? detail.RequestOtherConsultant
                    : "N/A"}
                </span>
              </p>
            </Box>
          </Box>
        </Box>
      </List>
    </Box>
  );

  function setAllImages(x) {
    let pres = x.id && x.pres_attachment.length > 0
      ? JSON.parse(x.pres_attachment)
      : [];
    let invoice = x.id && x.invoice_attachment.length > 0
    ? JSON.parse(x.invoice_attachment)
    : [];
    let otherPres =
    x.RequestOtherConsultant &&
      x.RequestOtherConsultant.pres_attachment.length > 0
      ? JSON.parse(x.RequestOtherConsultant.pres_attachment)
      : [];
      let otherInvoice =
      x.RequestOtherConsultant &&
        x.RequestOtherConsultant.invoice_attachment.length > 0
        ? JSON.parse(x.RequestOtherConsultant.invoice_attachment)
        : [];

    setImg([...pres, ...invoice, ...otherPres, ...otherInvoice]);
  }

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <Toolbar className={classes.tableTextSearch}>
          <Typography
            className={classes.title}
            variant="h6"
            id="tableTitle"
            component="div"
          >
            Request Consultation
          </Typography>
          <TextField
            // helperText=" "
            className={classes.tableSearch}
            id="demo-helper-text-aligned-no-helper"
            // label="Search"
            // value={search}
            type="search"
            placeholder="Search..."
            onChange={(e) => setFiltes(e.target.value)}
          />
        </Toolbar>
        <TableContainer>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            aria-label="enhanced table"
          >
            <EnhancedTableHead
              classes={classes}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
            />
            <TableBody>
              {consultData.rows &&
                Array.isArray(consultData.rows) &&
                consultData.rows.map((row, index) => {
                  const labelId = `user-${index}`;
                   
                  return (
                    <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                      <TableCell component="th" id={labelId} scope="row">
                        {index + 1 }
                      </TableCell>
                      <TableCell>{row.type ? row.type : "N/A"}</TableCell>
                      <TableCell>
                        {row.Doctor ? row.Doctor.name : "N/A"}
                      </TableCell>
                      <TableCell>
                        {row.medical_case ? row.medical_case : "N/A"}
                      </TableCell>

                      <TableCell>
                        {row.Sharman ? row.Sharman.name : "N/A"}
                      </TableCell>
                      <TableCell>
                        {row.type_of_visit ? row.type_of_visit : "N/A"}
                      </TableCell>
                      <TableCell>
                        {row.date_of_visit ? row.date_of_visit : "N/A"}
                      </TableCell>
                      <TableCell>
                        {row.acceptTime ? row.acceptTime : "N/A"}
                      </TableCell>
                      <TableCell>
                        {row.CareGiver ? row.CareGiver.name : "N/A"}
                      </TableCell>
                      <TableCell>{row.status == '1' ? "Accepted" : row.status == '2' ? "Closed" : row.status == '3' ? "Reject" : "Pending"}</TableCell>
                      <TableCell>
                        <div style={{ display: "flex" }}>
                          <Tooltip title="Assign to Another">
                            <AssignmentInd className={classes.editIcon}
                              onClick={() => {
                                setSelected(row);
                                handleRequestAnother();
                              }} style={{ cursor: "pointer", fontSize: "35px" }} />
                          </Tooltip>

                          &nbsp;&nbsp;
                          <Tooltip title="Detail">
                            <RemoveRedEyeIcon className={classes.editIcon} onClick={() => {
                              setSelected(row);
                              setAllImages(row);
                              handleClickDetail();
                            }} style={{ cursor: "pointer", fontSize: "35px" }} />
                          </Tooltip>
                          <Tooltip title="Delete">
                            <DeleteIcon
                              className={classes.deleteIcon}
                              onClick={() => {
                                confirmDelete(row.id);
                              }}
                              style={{
                                fontSize: "35px",
                                cursor: "pointer",
                                marginLeft: "15px",
                                marginRight: "15px",
                              }}
                            />
                          </Tooltip>


                        </div>
                      </TableCell>
                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow style={{ emptyRows }}>
                  <TableCell colSpan={11} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={count}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </div>
  );
}
