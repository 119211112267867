import { LabPage } from './lab.component';
import { connect } from 'react-redux';
import { getLabs, createLab, updateLab, deleteLab, getTimezone } from './../../api/lab/labAction';

const mapDispatchToProps = {
    getLabs,
    createLab,
    updateLab,
    deleteLab,
    getTimezone
};

const mapStateToProps = state => ({
    error: state.labPage.isError,
    isLoading: state.labPage.isLoading,
    isSaved: state.labPage.isSaved,
    labs: state.labPage.labs,
    count: state.labPage.count,
    timezone:state.labPage.timezone,
    isLoggedIn : state.adminPage.isLoggedIn
});

export const LabContainer = connect(mapStateToProps, mapDispatchToProps)(LabPage);