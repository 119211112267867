import React, { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import { Button, Box } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import Typography from "@mui/material/Typography";
import Slide from "@mui/material/Slide";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import DialogContent from "@mui/material/DialogContent";
import Input from "@mui/material/Input";
import Divider from "@mui/material/Divider";
import InputAdornment from "@mui/material/InputAdornment";
import ClearIcon from "@mui/icons-material/Clear";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Moment from "moment";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import { DemoItem } from "@mui/x-date-pickers/internals/demo";
import OutlinedInput from "@mui/material/OutlinedInput";
import ListItemText from "@mui/material/ListItemText";
import Checkbox from "@mui/material/Checkbox";
import { Autocomplete } from '@mui/material';
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";


const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "relative",
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  paper: {
    marginTop: theme.spacing(3),
    padding: theme.spacing(3),
  },
  formControl: {
    width: "100%",
    spacing: theme.spacing(2),
  },
  inputLabelText: {
    fontSize: "12px !important",
    color: "rgba(0,0,0,.5) !important",
    fontWeight: "600 !important",
    textTransform: "uppercase",
    marginTop: "0px",
  },
  mdText1: {
    color: "red",
    fontSize: "12px",
    fontWeight: 400,
  },
  locTime: {
    marginTop: "15px",
  },
  textTime: {
    width: "fit-content",
    display: "flex",
  },
}));

//select checkboxes

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const names = [
  "Sunday",
  "Monday",
  "Tuesday",
  "wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];

//   selected doctor checkbox name

const Transition = React.forwardRef(function Transition (props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

export default function DoctorDialoge (props) {
  const {
    handleClose,
    open,
    errors,
    selected,
    setSelected,
    validate,
    createImage,
    specializations,
    imageData,
    locations,
    addLocation,
    deleteLocation,
    updateLocation,
  } = props;

  const [inputType, setInputType] = useState('password');
  const [errorMessage, setErrorMessage] = useState('');
  const supportedFormats = ['image/jpeg', 'image/jpg', 'image/png'];
  const [newImage, setNewImage] = useState(false);

  const classes = useStyles();

  const onImageChange = (event) => {
    if (
      event &&
      event.target &&
      event.target.files &&
      event.target.files.length > 0
    ) {
      const file = event.target.files[0];
      const fileType = file.type;
      if (supportedFormats.includes(fileType)) {
        setNewImage(true);
        console.warn('new:- ', newImage)
        setErrorMessage('');
        setSelected({
          ...selected,
          image: event.target.files ? URL.createObjectURL(event.target.files[0]) : undefined,
        });
      } else {
        setNewImage(false);
        setErrorMessage('Only JPG or PNG images are allowed.');
      }
    }

  };

  const handleChange = (event, idx) => {
    const value = event.target.value;
    updateLocation(
      idx,
      typeof value === "string" ? value.split(",") : value,
      "available_days"
    );
  };

  const handleDateChange = (e) => {
    const today = Moment();
    const birthDate = Moment(e.target.value);
    const yearsDiff = today.diff(birthDate, 'years');
    setSelected({
      ...selected,
      dob: e.target.value,
      age: yearsDiff
    })
  }
  const togglePasswordVisibility = () => {
    setInputType(inputType === 'password' ? 'text' : 'password');
  }

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        // scroll={scroll}
        TransitionComponent={Transition}
        keepMounted
        aria-labelledby="scroll-dialog-title"
        aria-describedby="alert-dialog-slide-description"
        maxWidth="md"
        fullWidth={true}
      >
        <DialogContent>
          <ClearIcon
            onClick={handleClose}
            style={{ width: "25px", height: "25px", float: "right" }}
          />
          <div
            style={{
              marginTop: "10px",
              marginBottom: "20px",
            }}
          >
            <Typography
              style={{
                fontSize: "30px",
                fontWeight: 400,
                color: "rgba(0,0,0,.6)",
              }}
            >
              Doctor
            </Typography>
          </div>
          <form
            noValidate
            id="form"
            onSubmit={(event) => {
              event.preventDefault();
              validate(selected);
            }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12} md={6} style={{ marginBottom: "15px" }}>
                <FormControl fullWidth variant="standard" required>
                  <label
                    htmlFor="imagew"
                    style={{
                      cursor: "pointer",
                      padding: "7px 10px",
                      border: "1px solid #dadada",
                      borderRadius: "10px",
                      background: "#000",
                      color: "#fff",
                      textAlign: "center",
                    }}
                  >
                    Select Image
                  </label>
                  <Input
                    id="imagew"
                    startAdornment={
                      <InputAdornment position="start"></InputAdornment>
                    }
                    onChange={(e) => onImageChange(e)}
                    margin="none"
                    type="file"
                    name="image"
                    accept="image/png, image/jpg, image/jpeg"
                    style={{ display: "none" }}
                  />

                  {errorMessage && (
                    <p className={classes.mdText1}>{errorMessage}</p>
                  )}
                </FormControl>
              </Grid>
              <Grid
                item
                xs={12}
                md={4}
                style={{ marginBottom: "15px", display: "flex" }}
              >
                {!selected.id && selected.image ? (
                  <img
                    src={selected.image}
                    alt="image"
                    style={{ maxWidth: "100%", maxHeight: "150px" }}
                  />
                ) : selected.image && !newImage ? (
                  <img
                    src={selected.image}
                    style={{ maxWidth: "100%", maxHeight: "150px" }}
                  />
                ) : newImage ?
                  (
                    <img
                      src={selected.image}
                      style={{ maxWidth: "100%", maxHeight: "150px" }}
                    />
                  ) :
                  (
                    ""
                  )}
              </Grid>
              {/* <Grid item xs={12} md={4} style={{ marginBottom: "15px" }}>
                <FormControl fullWidth variant="standard" required>
                  <InputLabel htmlFor="img" className={classes.inputLabelText}>
                    Image
                  </InputLabel>
                  <Input
                    id="img"
                    startAdornment={
                      <InputAdornment position="start"></InputAdornment>
                    }
                    margin='normal'
                    type='file'
                    name='img'
                    accept="image/png, image/jpg, image/jpeg"
                    value={selected.img}
                    onChange={e => { setSelected({ ...selected, img: e.target.value }); setImage(URL.createObjectURL(e.target.files[0])) }}
                  />
                  {errors && errors.img && (
                    <p className={classes.mdText1}>{errors.img}</p>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={12} md={4} style={{ marginBottom: "15px", display: "flex" }}>
                <img src={image} alt="image" style={{ maxWidth: "100%", maxHeight: "150px" }} />
                <button onClick={() => { setSelected({ ...selected, img: "" }); setImage("") }}>X</button>
              </Grid> */}
              <Grid item xs={12} md={4} style={{ marginBottom: "15px", width: "15px" }}>
                <FormControl fullWidth variant="standard">
                  <InputLabel htmlFor="prefix" className={classes.inputLabelText}>
                    Prefix
                  </InputLabel>
                  <Input
                    id="prefix"
                    startAdornment={
                      <InputAdornment position="start"></InputAdornment>
                    }
                    margin="none"
                    name="prefix"
                    value={selected.prefix != null ? selected.prefix : ''}
                    onChange={(e) =>
                      setSelected({ ...selected, prefix: e.target.value })
                    }
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} md={4} style={{ marginBottom: "15px" }}>
                <FormControl fullWidth variant="standard" required>
                  <InputLabel htmlFor="name" className={classes.inputLabelText}>
                    Name
                  </InputLabel>
                  <Input
                    id="name"
                    startAdornment={
                      <InputAdornment position="start"></InputAdornment>
                    }
                    margin="none"
                    name="name"
                    value={selected.name != null ? selected.name : ''}
                    onChange={(e) =>
                      setSelected({ ...selected, name: e.target.value })
                    }
                  />
                  {errors && errors.name && (
                    <p className={classes.mdText1}>{errors.name}</p>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={12} md={4} style={{ marginBottom: "15px" }}>
                <FormControl fullWidth variant="standard" required>
                  <InputLabel
                    htmlFor="email"
                    className={classes.inputLabelText}
                  >
                    Email
                  </InputLabel>
                  <Input
                    id="email"
                    startAdornment={
                      <InputAdornment position="start"></InputAdornment>
                    }
                    margin="none"
                    type="email"
                    name="email"
                    value={selected.email}
                    onChange={(event) =>
                      setSelected({
                        ...selected,
                        email: event.target.value,
                      })
                    }
                  />
                  {errors && errors.email && (
                    <p className={classes.mdText1}>{errors.email}</p>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={12} md={4} style={{ marginBottom: "15px" }}>
                <FormControl fullWidth variant="standard" >
                  <InputLabel
                    htmlFor="password"
                    className={classes.inputLabelText}
                  >
                    Password
                    <RemoveRedEyeIcon style={{ paddingTop: "2px", float: "right", marginTop: "-7px", marginLeft: "10px" }} onClick={() => { togglePasswordVisibility() }} />
                  </InputLabel>
                  <Input
                    id="password"
                    startAdornment={
                      <InputAdornment position="start"></InputAdornment>
                    }
                    margin="none"
                    type={inputType}
                    name="password"
                    value={selected.password}
                    onChange={(event) =>
                      setSelected({
                        ...selected,
                        password: event.target.value,
                      })
                    }
                  />
                  {errors && errors.password && (
                    <p className={classes.mdText1}>{errors.password}</p>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={12} md={4} style={{ marginBottom: "15px" }}>
                <FormControl fullWidth variant="standard" required>
                  <InputLabel
                    htmlFor="phone"
                    className={classes.inputLabelText}
                  >
                    Phone
                  </InputLabel>
                  <Input
                    id="phone"
                    startAdornment={
                      <InputAdornment position="start"></InputAdornment>
                    }
                    margin="none"
                    name="phone"
                    value={selected.phone != null ? selected.phone : ''}
                    onChange={(event) =>
                      setSelected({
                        ...selected,
                        phone: event.target.value,
                      })
                    }
                  />
                  {errors && errors.phone && (
                    <p className={classes.mdText1}>{errors.phone}</p>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={12} md={4} style={{ marginBottom: "15px" }}>
                <FormControl fullWidth variant="standard">
                  <InputLabel htmlFor="dob" className={classes.inputLabelText}>
                    Date of birth
                  </InputLabel>
                  <Input
                    id="dob"
                    startAdornment={
                      <InputAdornment position="start"></InputAdornment>
                    }
                    margin="none"
                    name="dob"
                    type="date"
                    value={selected.dob != null ? Moment(selected.dob).format("YYYY-MM-DD") : ''}
                    onChange={(event) =>
                      handleDateChange(event)
                    }
                  />
                  {errors && errors.dob && (
                    <p className={classes.mdText1}>{errors.dob}</p>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={12} md={4}>
                <InputLabel htmlFor="phone" style={{ fontSize: "10px" }}>
                  Gender
                </InputLabel>
                <FormControl>
                  <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    value={selected.gender}
                    name="gender"
                    style={{ display: "inline" }}
                  >
                    <FormControlLabel
                      value="Male"
                      control={<Radio />}
                      onChange={(event) =>
                        setSelected({
                          ...selected,
                          gender: event.target.value,
                        })
                      }
                      label="Male"
                      style={{ fontSize: "13px" }}
                    />
                    <FormControlLabel
                      value="Female"
                      control={<Radio />}
                      onChange={(event) =>
                        setSelected({
                          ...selected,
                          gender: event.target.value,
                        })
                      }
                      label="Female"
                      style={{ fontSize: "13px" }}
                    />
                  </RadioGroup>

                  {errors && errors.gender && (
                    <p className={classes.mdText1}>{errors.gender}</p>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={12} md={4} style={{ marginBottom: "15px" }}>
                <FormControl fullWidth variant="standard">
                  <InputLabel htmlFor="age" className={classes.inputLabelText}>
                    Age
                  </InputLabel>
                  <Input
                    id="age"
                    startAdornment={
                      <InputAdornment position="start"></InputAdornment>
                    }
                    margin="none"
                    type="number"
                    name="age"
                    readonly
                    value={selected.age != null ? selected.age : ''}
                  />
                  {errors && errors.age && (
                    <p className={classes.mdText1}>{errors.age}</p>
                  )}
                </FormControl>
              </Grid>

              <Grid item xs={12} md={4} style={{ marginBottom: "15px" }}>
                <FormControl fullWidth variant="standard" required>
                  <InputLabel htmlFor="visit" className={classes.inputLabelText}>
                    Type Of Practice
                  </InputLabel>
                  <Select
                    labelId="type_of_practise"
                    id="type_of_practise"
                    value={selected.type_of_practise != null ? selected.type_of_practise : ''}
                    onChange={e => setSelected({ ...selected, type_of_practise: e.target.value })}
                    label="type_of_practise"
                  >

                    <MenuItem value="Allopathic">Allopathic</MenuItem>
                    <MenuItem value="Ayurvedic">Ayurvedic</MenuItem>
                    <MenuItem value="Homeopathic">Homeopathic</MenuItem>

                  </Select>
                  {errors && errors.typeOfPractise && (
                    <p className={classes.mdText1}>{errors.typeOfPractise}</p>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={12} md={4} style={{ marginBottom: "15px" }}>
                <FormControl fullWidth variant="standard" required>
                  <InputLabel
                    htmlFor="address"
                    className={classes.inputLabelText}
                  >
                    Address
                  </InputLabel>
                  <Input
                    id="address"
                    startAdornment={
                      <InputAdornment position="start"></InputAdornment>
                    }
                    margin="none"
                    name="address"
                    value={selected.address != null ? selected.address : ''}
                    onChange={(event) =>
                      setSelected({
                        ...selected,
                        address: event.target.value,
                      })
                    }
                  />
                  {errors && errors.address && (
                    <p className={classes.mdText1}>{errors.address}</p>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={12} md={4} style={{ marginBottom: "15px" }}>
                <FormControl fullWidth variant="standard">
                  <InputLabel htmlFor="city" className={classes.inputLabelText}>
                    City
                  </InputLabel>
                  <Input
                    id="city"
                    startAdornment={
                      <InputAdornment position="start"></InputAdornment>
                    }
                    margin="none"
                    name="city"
                    value={selected.City != null ? selected.City : ''}
                    onChange={(e) =>
                      setSelected({ ...selected, City: e.target.value })
                    }
                  />
                  {errors && errors.City && (
                    <p className={classes.mdText1}>{errors.City}</p>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={12} md={4} style={{ marginBottom: "15px" }}>
                <FormControl fullWidth variant="standard">
                  <InputLabel
                    htmlFor="district"
                    className={classes.inputLabelText}
                  >
                    District
                  </InputLabel>
                  <Input
                    id="district"
                    startAdornment={
                      <InputAdornment position="start"></InputAdornment>
                    }
                    margin="none"
                    name="district"
                    value={selected.District != null ? selected.District : ''}
                    onChange={(e) =>
                      setSelected({ ...selected, District: e.target.value })
                    }
                  />
                  {errors && errors.District && (
                    <p className={classes.mdText1}>{errors.District}</p>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={12} md={4} style={{ marginBottom: "15px" }}>
                <FormControl fullWidth variant="standard">
                  <InputLabel
                    htmlFor="state"
                    className={classes.inputLabelText}
                  >
                    State
                  </InputLabel>
                  <Input
                    id="state"
                    startAdornment={
                      <InputAdornment position="start"></InputAdornment>
                    }
                    margin="none"
                    name="state"
                    value={selected.State != null ? selected.State : ''}
                    onChange={(e) =>
                      setSelected({ ...selected, State: e.target.value })
                    }
                  />
                  {errors && errors.State && (
                    <p className={classes.mdText1}>{errors.State}</p>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={12} md={4} style={{ marginBottom: "15px" }}>
                <FormControl fullWidth variant="standard">
                  <InputLabel htmlFor="zip" className={classes.inputLabelText}>
                    Zip
                  </InputLabel>
                  <Input
                    id="zip"
                    startAdornment={
                      <InputAdornment position="start"></InputAdornment>
                    }
                    margin="none"
                    name="zip"
                    value={selected.zip != null ? selected.zip : ''}
                    onChange={(e) =>
                      setSelected({ ...selected, zip: e.target.value })
                    }
                  />
                  {errors && errors.zip && (
                    <p className={classes.mdText1}>{errors.zip}</p>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={12} md={4} style={{ marginBottom: "15px" }}>
                <FormControl fullWidth variant="standard" required>
                  <InputLabel
                    htmlFor="latitude"
                    className={classes.inputLabelText}
                  >
                    Latitude
                  </InputLabel>
                  <Input
                    id="latitude"
                    startAdornment={
                      <InputAdornment position="start"></InputAdornment>
                    }
                    margin="none"
                    name="latitude"
                    value={selected.latitude != null ? selected.latitude : ''}
                    onChange={(event) =>
                      setSelected({
                        ...selected,
                        latitude: event.target.value,
                      })
                    }
                  />
                  {errors && errors.latitude && (
                    <p className={classes.mdText1}>{errors.latitude}</p>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={12} md={4} style={{ marginBottom: "15px" }}>
                <FormControl fullWidth variant="standard" required>
                  <InputLabel
                    htmlFor="longitude"
                    className={classes.inputLabelText}
                  >
                    Longitude
                  </InputLabel>
                  <Input
                    id="longitude"
                    startAdornment={
                      <InputAdornment position="start"></InputAdornment>
                    }
                    margin="none"
                    type="number"
                    name="mobile"
                    value={selected.longitude != null ? selected.longitude : ''}
                    onChange={(event) =>
                      setSelected({
                        ...selected,
                        longitude: event.target.value,
                      })
                    }
                  />
                  {errors && errors.longitude && (
                    <p className={classes.mdText1}>{errors.longitude}</p>
                  )}
                </FormControl>
              </Grid>

              <Grid item xs={4} md={4} style={{ marginBottom: "15px" }}>

                <Autocomplete
                  multiple
                  options={specializations}
                  getOptionLabel={(option) => option.name}
                  value={selected.specialization != null ? selected.specialization : ''}
                  onChange={(event, value) =>
                    setSelected({
                      ...selected,
                      specialization: value,
                    })
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Specialization"
                      variant="standard"
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={12} style={{ marginBottom: "15px" }}>
                <Typography variant="h6">Location </Typography>
                {locations &&
                  Array.isArray(locations) &&
                  locations.map((item, idx) => (
                    <div
                      className="pharmaWork"
                      key={idx}
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        marginBottom: "10px",
                      }}
                    >
                      <Grid container>
                        <Grid item lg={4} md={4}>
                          <InputLabel htmlFor="address">Address</InputLabel>
                          <Input
                            id="address"
                            value={locations[idx].address != null ? locations[idx].address : ''}
                            onChange={(e) =>
                              updateLocation(idx, e.target.value, "address")
                            }
                            placeholder="Address"
                            margin="none"
                          />
                        </Grid>
                        <Grid item lg={4} md={4}>
                          <InputLabel htmlFor="latitude">Latitude</InputLabel>
                          <Input
                            id="latitude"
                            value={locations[idx].latitude != null ? locations[idx].latitude : ''}
                            onChange={(e) =>
                              updateLocation(idx, e.target.value, "latitude")
                            }
                            placeholder="latitude"
                            margin="none"
                          />
                        </Grid>
                        <Grid item lg={4} md={4}>
                          <InputLabel htmlFor="longitude">Longitude</InputLabel>
                          <Input
                            id="longitude"
                            value={locations[idx].longitude != null ? locations[idx].longitude : ''}
                            onChange={(e) =>
                              updateLocation(idx, e.target.value, "longitude")
                            }
                            placeholder="longitude"
                            margin="none"
                          />
                        </Grid>
                        <Grid item
                          lg={3}
                          md={3}
                          className={classes.locTime}
                          style={{ display: "flex" }}
                        >
                          <FormControl sx={{ m: 1, width: 300 }} variant="standard">
                            <InputLabel id="demo-multiple-checkbox-label">
                              Available
                            </InputLabel>
                            <Select
                              variant="standard"
                              labelId="demo-multiple-checkbox-label"
                              id="demo-multiple-checkbox"
                              multiple
                              value={locations[idx].available_days != null ? locations[idx].available_days : ''}
                              onChange={(e) => handleChange(e, idx)}
                              input={<OutlinedInput label="Tag" />}
                              renderValue={(selected) => selected.join(", ")}
                              MenuProps={MenuProps}
                            >
                              {names.map((name, i) => (
                                <MenuItem key={i} value={name}>
                                  <Checkbox
                                    checked={
                                      locations[idx].available_days.indexOf(
                                        name
                                      ) > -1
                                    }
                                  />
                                  <ListItemText primary={name} />
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Grid>

                        <Grid item lg={3} md={3} className={classes.locTime}>
                          <Box sx={{ marginLeft: "4rem" }}>
                            <DemoItem label={"Available From"}>
                              <TextField
                                id="time_in"
                                // label="Avalable from"
                                type="time"
                                value={locations[idx].time_in != null ? locations[idx].time_in : ''}
                                onChange={(e) =>
                                  updateLocation(idx, e.target.value, "time_in")
                                }
                                className={classes.textTime}
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                inputProps={{
                                  step: 300, // 5 min
                                }}
                                variant="standard"
                              />
                            </DemoItem>
                          </Box>
                        </Grid>
                        <Grid item lg={3} md={3} className={classes.locTime}>
                          <Box sx={{ marginLeft: "2rem" }}>
                            <DemoItem
                              label={"Available Till"}
                              style={{ marginTop: "30px" }}
                            >
                              <TextField
                                id="time_in"
                                label=""
                                type="time"
                                value={locations[idx].time_out != null ? locations[idx].time_out : ''}
                                onChange={(e) =>
                                  updateLocation(
                                    idx,
                                    e.target.value,
                                    "time_out"
                                  )
                                }
                                className={classes.textTime}
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                inputProps={{
                                  step: 300, // 5 min
                                }}
                                variant="standard"
                              />
                            </DemoItem>
                          </Box>
                        </Grid>
                        <Grid
                          item
                          lg={3}
                          md={3}
                          className={classes.locTime}
                          style={{ display: "flex", alignItems: "center" }}
                        >
                          {idx === 0 ? (
                            <Button
                              onClick={() => addLocation()}
                              autoFocus
                              variant="contained"
                              color="primary"
                              style={{
                                textTransform: "capitalize",
                                backgroundColor: "#e2e4e6",
                                color: "#293038",
                                borderRadius: "20px",
                              }}
                            >
                              Add
                            </Button>
                          ) : (
                            <Button
                              onClick={() => deleteLocation(idx)}
                              autoFocus
                              variant="contained"
                              color="primary"
                              style={{
                                textTransform: "capitalize",
                                backgroundColor: "red",
                                color: "#fff",
                                borderRadius: "20px",
                              }}
                            >
                              delete
                            </Button>
                          )}
                        </Grid>
                        <hr style={{ color: "black", width: "100%" }} />
                      </Grid>
                    </div>
                  ))}
              </Grid>
            </Grid>
            <Divider variant="middle" style={{ margin: "20px auto" }} />
            <Grid item xs={12} style={{ float: "right", margin: "10px auto" }}>
              <Button
                onClick={handleClose}
                autoFocus
                variant="contained"
                color="primary"
                style={{
                  textTransform: "capitalize",
                  minWidth: "120px",
                  marginRight: "5px",
                  backgroundColor: "#e2e4e6",
                  color: "#293038",
                  borderRadius: "20px",
                }}
              >
                Cancel
              </Button>
              <Button
                autoFocus
                variant="contained"
                color="primary"
                form="form"
                type="submit"
                style={{
                  textTransform: "capitalize",
                  minWidth: "120px",
                  backgroundColor: "#FF8427",
                  color: "rgb(255, 255, 255)",
                  borderRadius: "20px",
                }}
              >
                {selected.id ? "Update" : "Add"}
              </Button>
            </Grid>
          </form>
        </DialogContent>
      </Dialog>
    </div>
  );
}
