import * as actionTypes from "./guruActionType";
import axios from 'axios';
import { checkError } from '../error';

const baseUrl = process.env.REACT_APP_API_URL;

export const getGuru = (data) => {
    const config = { headers: { Authorization: `Bearer ${localStorage.getItem("token")}` }, params: data };
      return (dispatch) => {
        dispatch(getGuruInit());
        axios.get(`${baseUrl}/guru/admin`, config)
        .then((response)=>{
            dispatch(getGuruSuccess(response.data.data));
        })
        .catch(function (error) {
            checkError(error);
            dispatch(getGuruFailure());
        })
    };
};

export const getGuruInit = () => ({
    type: actionTypes.GET_GURU_LIST
});

export const getGuruFailure = data => ({
    type: actionTypes.GET_GURU_LIST_FAILURE
});

export const getGuruSuccess = data => ({
    type: actionTypes.GET_GURU_LIST_SUCCESS,
    payload: { data }
});

export const createGuru = (data) => {
    const config = { headers: { Authorization: `Bearer ${localStorage.getItem("token")}` } };
      return (dispatch) => {
        dispatch(createGuruInit());
        axios.post(`${baseUrl}/guru/store`, data, config)
        .then((response)=>{
            dispatch(createGuruSuccess(response.data.data));
        })
        .catch(function (error) {
            checkError(error);
            dispatch(createGuruFailure());
        })
    };
};

export const createGuruInit = () => ({
    type: actionTypes.CREATE_GURU
});

export const createGuruFailure = data => ({
    type: actionTypes.CREATE_GURU_FAILURE
});

export const createGuruSuccess = data => ({
    type: actionTypes.CREATE_GURU_SUCCESS,
    payload: { data }
});

export const updateGuru = (data) => {
    const config = { headers: { Authorization: `Bearer ${localStorage.getItem("token")}` } };
      return (dispatch) => {
        dispatch(updateGuruInit());
        axios.put(`${baseUrl}/guru/${data.id}`, data, config)
        .then((response)=>{
            dispatch(updateGuruSuccess(response.data.data));
        })
        .catch(function (error) {
            checkError(error);
            dispatch(updateGuruFailure());
        })
    };
};

export const updateGuruInit = () => ({
    type: actionTypes.UPDATE_GURU
});

export const updateGuruFailure = data => ({
    type: actionTypes.UPDATE_GURU_FAILURE
});

export const updateGuruSuccess = data => ({
    type: actionTypes.UPDATE_GURU_SUCCESS,
    payload: { data }
});

export const deleteGuru = (data) => {
    const config = { headers: { Authorization: `Bearer ${localStorage.getItem("token")}` } };
      return (dispatch) => {
        dispatch(deleteGuruInit());
        axios.delete(`${baseUrl}/guru/${data}`, config)
        .then((response)=>{
            dispatch(deleteGuruSuccess(response.data.data));
        })
        .catch(function (error) {
            checkError(error);
            dispatch(deleteGuruFailure());
        })
    };
};

export const deleteGuruInit = () => ({
    type: actionTypes.DELETE_GURU
});

export const deleteGuruFailure = data => ({
    type: actionTypes.DELETE_GURU_FAILURE
});

export const deleteGuruSuccess = data => ({
    type: actionTypes.DELETE_GURU_SUCCESS,
    payload: { data }
});


export const samudhayGuru = (GatchaId,SamudhayId) => {
    const config = { headers: { Authorization: `Bearer ${localStorage.getItem("token")}` } };
    return (dispatch) => {
      dispatch(SamudhayGuruInit());
      axios.get(`${baseUrl}/guru/gatch-samudhay/${GatchaId}/${SamudhayId}`, config)
      .then((response)=>{
          dispatch(SamudhayGuruSuccess(response.data.data));
      })
      .catch(function (error) {
          checkError(error);
          dispatch(SamudhayGuruFailure());
      })
  };
}

export const SamudhayGuruInit = () => ({
    type: actionTypes.SAMUDHAY_GURU
});

export const SamudhayGuruFailure = data => ({
    type: actionTypes.SAMUDHAY_GURU_FAILURE
});

export const SamudhayGuruSuccess = data => ({
    type: actionTypes.SAMUDHAY_GURU_SUCCESS,
    payload: { data }
});
