import React, { useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import { Button, Box } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import Typography from '@mui/material/Typography';
import Slide from '@mui/material/Slide';
import Grid from '@mui/material/Grid';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import DialogContent from "@mui/material/DialogContent";
import Input from "@mui/material/Input";
import Divider from "@mui/material/Divider";
import InputAdornment from "@mui/material/InputAdornment";
import ClearIcon from "@mui/icons-material/Clear";
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import AddIcon from '@mui/icons-material/Add';
import Moment from "moment";


import { styled } from '@mui/material/styles';



const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  paper: {
    marginTop: theme.spacing(3),
    padding: theme.spacing(3),
  },
  formControl: {
    width: '100%',
    spacing: theme.spacing(2),
  },
  inputLabelText: {
    fontSize: "14px !important",
    color: "rgba(0,0,0,.5) !important",
    fontWeight: "600 !important",
    textTransform: "uppercase",
    marginTop: "0px"
  },
  mdText1: {
    color: "red",
    fontSize: "12px",
    fontWeight: 400,
  },
  inputLabelText: {
    marginBottom: "0px !important"
  }
  , inputDob: {

    paddingTop: "10px",
    paddingBottom: "0px",
  },

}));

const Transition = React.forwardRef(function Transition (props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));
export default function ShramanDialoge (props) {
  const {
    setNewImage,
    gatcha,
    samudhay,
    guru,
    newImage,
    handleClose,
    open,
    errors,
    selected,
    setSelected,
    validate,
    caregivers,
    getGatchSamudhay,
    gatchasamudhay,
    samudhayGuru,
    samudhaygururesult,
    gatchaValidate,
    selectedGatch,
    setSelectedGatcha,
    openGatchaDialogue,
    setOpenGatchaDialogue,
    openSamudhayDialogue,
    setOpenSamudhayDialogue,
    setOpenGuruDialogue,
    openGuruDialogue,
    samudhayValidate,
    selectedSamudhay,
    setSelectedSamudhay,
    guruValidate,
    selectedGuru,
    setSelectedGuru
  } = props;


  const classes = useStyles();
  const [errorMessage, setErrorMessage] = useState('');
  const supportedFormats = ['image/jpeg', 'image/jpg', 'image/png'];

  const handleDateChange = (e) => {

    const today = Moment();
    const birthDate = Moment(e.target.value);
    const yearsDiff = today.diff(birthDate, 'years');
    setSelected({
      ...selected,
      dob: e.target.value,
      age: yearsDiff
    })
  };

  const onImageChange = (event) => {
    if (
      event &&
      event.target &&
      event.target.files &&
      event.target.files.length > 0
    ) {
      const file = event.target.files[0];
      const fileType = file.type;

      if (supportedFormats.includes(fileType)) {
        setNewImage(true);
        setErrorMessage('');
        setSelected({
          ...selected,
          image: event.target.files ? event.target.files[0] : undefined,
        });
      } else {
        setErrorMessage('Only JPG or PNG images are allowed.');
      }
    }

  };

  const getSamudhay = (id) => {
    setSelected({
      ...selected,
      GatchaId: id,
    })
    getGatchSamudhay(id);
  }
  const getSamudhayGuru = (id) => {
    setSelected({
      ...selected,
      GatchaId: selected.GatchaId,
      SamudhayId: id,
    })
    samudhayGuru(selected.GatchaId, id);
  }
  const handleClickOpen = () => {
    setSelectedGatcha({ name: "", status: "" });
    setOpenGatchaDialogue(true);
  };
  const handleCloseDialogue = () => {
    setSelectedGatcha({ name: "", status: "" });
    setOpenGatchaDialogue(false);
  }

  const handleClickOpenSamudhay = () => {
    setSelectedSamudhay({ name: "", GatchaId: "", status: 1 });
    setOpenSamudhayDialogue(true);
  }
  const handleCloseSamudhayDialogue = () => {
    setSelectedSamudhay({ name: "", GatchaId: "", status: 1 });
    setOpenSamudhayDialogue(false);
  }

  const handleClickOpenGuru = () => {
    setSelectedGuru({ name: "", GatchaId: "", SamudhayId: "", status: 1 });
    setOpenGuruDialogue(true);
  }
  const handleCloseGuruDialogue = () => {
    setSelectedGuru({ name: "", GatchaId: "", SamudhayId: "", status: 1 });
    setOpenGuruDialogue(false);
  }
  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        // scroll={scroll}
        TransitionComponent={Transition}
        keepMounted
        aria-labelledby="scroll-dialog-title"
        aria-describedby="alert-dialog-slide-description"
        maxWidth="md"
        fullWidth={true}
      >
        <DialogContent>
          <ClearIcon
            onClick={handleClose}
            style={{ width: "25px", height: "25px", float: "right" }}
          />
          <div
            style={{
              marginTop: "10px",
              marginBottom: "20px",
            }}
          >
            <Typography
              style={{
                fontSize: "30px",
                fontWeight: 400,
                color: "rgba(0,0,0,.6)",
              }}
            >
              Shramans
            </Typography>
          </div>
          <form
            noValidate
            id="form"
            onSubmit={(event) => {
              event.preventDefault();
              validate(selected)
            }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12} md={6} style={{ marginBottom: "15px" }}>
                <FormControl fullWidth variant="standard" required>
                  <label htmlFor="imagew" style={{ cursor: "pointer", padding: "7px 10px", border: "1px solid #dadada", borderRadius: "10px", background: "#000", color: "#fff", textAlign: "center" }}>
                    Select Image
                  </label>
                  <Input
                    id="imagew"
                    startAdornment={
                      <InputAdornment position="start"></InputAdornment>
                    }
                    onChange={e => onImageChange(e)}
                    margin="none"
                    type='file'
                    name='image'
                    accept="image/png, image/jpg, image/jpeg"
                    style={{ display: "none" }}
                  />

                  {errorMessage && (
                    <p className={classes.mdText1}>{errorMessage}</p>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={12} md={4} style={{ marginBottom: "15px", display: "flex" }}>
                {!selected.id && selected.image ? (
                  <img
                    src={URL.createObjectURL(selected.image)}
                    alt="image"
                    style={{ maxWidth: "100%", maxHeight: "150px" }}
                  />
                ) : selected.image && !newImage ? (
                  <img
                    src={selected.image}
                    style={{ maxWidth: "100%", maxHeight: "150px" }}
                  />
                ) : newImage && selected.id ?
                  (
                    <img
                      src={URL.createObjectURL(selected.image)}
                      alt="image"
                      style={{ maxWidth: "100%", maxHeight: "150px" }}
                    />
                  ) :
                  (
                    ""
                  )}
              </Grid>

              <Grid item xs={12} md={4} style={{ marginBottom: "15px", width: "15px" }}>
                <FormControl fullWidth variant="standard">
                  <InputLabel htmlFor="prefix" className={classes.inputLabelText}>
                    Prefix
                  </InputLabel>
                  <Input
                    id="prefix"
                    startAdornment={
                      <InputAdornment position="start"></InputAdornment>
                    }
                    margin="none"
                    name="prefix"
                    value={selected.prefix != 'null' && selected.prefix != null ? selected.prefix : ''}
                    onChange={(e) =>
                      setSelected({ ...selected, prefix: e.target.value })
                    }
                  />
                  {errors && errors.prefix && (
                    <p className={classes.mdText1}>{errors.prefix}</p>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={12} md={4} style={{ marginBottom: "15px" }}>
                <FormControl fullWidth variant="standard" required>
                  <InputLabel htmlFor="name" className={classes.inputLabelText}>
                    Name
                  </InputLabel>
                  <Input
                    id="name"
                    startAdornment={
                      <InputAdornment position="start"></InputAdornment>
                    }
                    margin="none"
                    name='name'
                    value={selected.name}
                    onChange={e => setSelected({ ...selected, name: e.target.value })}
                  />
                  {errors && errors.name && (
                    <p className={classes.mdText1}>{errors.name}</p>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={12} md={4} style={{ marginBottom: "15px" }}>
                <FormControl fullWidth variant="standard">
                  <InputLabel htmlFor="email" className={classes.inputLabelText}>
                    Email
                  </InputLabel>
                  <Input
                    id="email"
                    startAdornment={
                      <InputAdornment position="start"></InputAdornment>
                    }
                    margin="none"
                    type='email'
                    name='email'
                    value={selected.email != 'null' && selected.email != null ? selected.email : ''}
                    onChange={(event) =>
                      setSelected({
                        ...selected,
                        email: event.target.value,
                      })
                    }
                  />
                  {errors && errors.email && (
                    <p className={classes.mdText1}>{errors.email}</p>
                  )}
                </FormControl>
              </Grid>

              <Grid item xs={12} md={4} style={{ marginBottom: "15px" }}>
                <FormControl fullWidth variant="standard">
                  <InputLabel htmlFor="phone" className={classes.inputLabelText}>
                    Phone
                  </InputLabel>
                  <Input
                    id="phone"
                    startAdornment={
                      <InputAdornment position="start"></InputAdornment>
                    }
                    type="number"
                    margin="none"
                    name='phone'
                    value={selected.phone}
                    onChange={(event) =>
                      setSelected({
                        ...selected,
                        phone: event.target.value,
                      })
                    }
                  />
                  {errors && errors.phone && (
                    <p className={classes.mdText1}>{errors.phone}</p>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={12} md={4} style={{ marginBottom: "15px" }}>
                <FormControl fullWidth variant="standard" required>
                  <TextField
                    id="date"
                    label="Date of Birth"
                    type="date"
                    value={selected.dob}
                    className={classes.inputDob}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onChange={(event) =>
                      handleDateChange(event)
                    }
                  />
                  {errors && errors.dob && (
                    <p className={classes.mdText1}>{errors.dob}</p>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={12} md={4}>
                <InputLabel htmlFor="phone" className={classes.inputLabelText} style={{ fontSize: "12px" }}>
                  Gender
                </InputLabel>
                <FormControl>
                  <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    value={selected.gender}
                    name="gender"
                    style={{ display: "inline" }}
                  >
                    <FormControlLabel value="Male" control={<Radio />} onChange={(event) =>
                      setSelected({
                        ...selected,
                        gender: event.target.value,
                      })
                    } label="Male" style={{ fontSize: "13px" }} />
                    <FormControlLabel value="Female" control={<Radio />} onChange={(event) =>
                      setSelected({
                        ...selected,
                        gender: event.target.value,
                      })
                    } label="Female" style={{ fontSize: "13px" }} />
                  </RadioGroup>

                  {errors && errors.gender && (
                    <p className={classes.mdText1}>{errors.gender}</p>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={12} md={4} style={{ marginBottom: "15px" }}>
                <FormControl fullWidth variant="standard" required>
                  <InputLabel htmlFor="age" className={classes.inputLabelText}>
                    Age
                  </InputLabel>
                  <Input
                    id="age"
                    startAdornment={
                      <InputAdornment position="start"></InputAdornment>
                    }
                    margin="none"
                    name='age'
                    value={selected.age}
                    readonly={true}

                  />
                  {errors && errors.age && (
                    <p className={classes.mdText1}>{errors.age}</p>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={12} md={4} style={{ marginBottom: "15px" }}>
                <FormControl fullWidth variant="standard" required>
                  <InputLabel htmlFor="CareGiver" className={classes.inputLabelText}>
                    CareGiver
                  </InputLabel>
                  <Select
                    id="CareGiverId"
                    startAdornment={
                      <InputAdornment position="start"></InputAdornment>
                    }
                    margin="none"
                    name='CareGiverId'
                    value={selected.CareGiverId}
                    onChange={(event) =>
                      setSelected({
                        ...selected,
                        CareGiverId: event.target.value,
                      })
                    }
                  >
                    {caregivers && Array.isArray(caregivers) && caregivers.map((y, i) => {
                      return (
                        <MenuItem key={caregivers[i].id} value={caregivers[i].id}>{caregivers[i].name}</MenuItem>
                      )
                    })}
                  </Select>
                  {errors && errors.CareGiverId && (
                    <p className={classes.mdText1}>{errors.CareGiverId}</p>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={12} md={4} style={{ marginBottom: "15px" }}>
                <FormControl fullWidth variant="standard" required>
                  <InputLabel htmlFor="CareGiver" className={classes.inputLabelText}>
                    Gatch 
                  </InputLabel>
                  <span><Button style={{ float: "right", fontSize: "10px", marginTop: "-10px" }} className="btn btn-info btn-xs action-button" onClick={() => handleClickOpen()}><AddIcon style={{ fontSize:'15px'}} /></Button></span>
                  <Select
                    id="GatchaId"
                    startAdornment={
                      <InputAdornment position="start"></InputAdornment>
                    }
                    margin="none"
                    name='GatchaId'
                    value={selected.GatchaId}
                    onChange={(event) =>
                      getSamudhay(event.target.value)
                    }
                  >
                    {gatcha && Array.isArray(gatcha) && gatcha.map((y, i) => {
                      return (
                        <MenuItem key={gatcha[i].id} value={gatcha[i].id}>{gatcha[i].name}</MenuItem>
                      )
                    })}
                  </Select>
                  {errors && errors.GatchaId && (
                    <p className={classes.mdText1}>{errors.GatchaId}</p>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={12} md={4} style={{ marginBottom: "15px" }}>
                <FormControl fullWidth variant="standard" required>
                  <InputLabel htmlFor="CareGiver" className={classes.inputLabelText}>
                    Samudhay
                  </InputLabel>
                  <span><Button style={{ float: "right", fontSize: "10px", marginTop: "-10px" }} className="btn btn-info btn-xs action-button" onClick={() => handleClickOpenSamudhay()}><AddIcon style={{ fontSize:'15px'}} /></Button></span>
                  <Select
                    id="SamudhayId"
                    startAdornment={
                      <InputAdornment position="start"></InputAdornment>
                    }
                    margin="none"
                    name='SamudhayId'
                    value={selected.SamudhayId}
                    onChange={(event) =>
                      getSamudhayGuru(event.target.value)
                    }
                  >

                    {samudhay && samudhay.map((val, index) => {
                      return (
                        val
                          ? <MenuItem key={val.id} value={val.id}>{val.name}</MenuItem>
                          : <MenuItem key={index} value="">Select Samudhay</MenuItem>
                      );
                    })}

                  </Select>
                  {errors && errors.SamudhayId && (
                    <p className={classes.mdText1}>{errors.SamudhayId}</p>
                  )}
                </FormControl>
              </Grid>

              <Grid item xs={12} md={4} style={{ marginBottom: "15px" }}>
                <FormControl fullWidth variant="standard" required>
                  <InputLabel htmlFor="CareGiver" className={classes.inputLabelText}>
                    Guru 
                  </InputLabel>
                  <span><Button style={{ float: "right", fontSize: "10px", marginTop: "-10px" }} className="btn btn-info btn-xs action-button" onClick={() => handleClickOpenGuru()}><AddIcon style={{ fontSize:'15px'}} /></Button></span>
                  <Select
                    id="GuruId"
                    startAdornment={
                      <InputAdornment position="start"></InputAdornment>
                    }
                    margin="none"
                    name='GuruId'
                    value={selected.GuruId}
                    onChange={(event) =>
                      setSelected({
                        ...selected,
                        GuruId: event.target.value,
                      })
                    }
                  >
                    {guru && guru.map((val, index) => {
                      return (
                        val
                          ? <MenuItem key={val.id} value={val.id}>{val.name}</MenuItem>
                          : <MenuItem key={index} value="">Select Guru</MenuItem>
                      );
                    })}
                  </Select>
                  {errors && errors.GuruId && (
                    <p className={classes.mdText1}>{errors.GuruId}</p>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={12} md={4} style={{ marginBottom: "15px" }}>
                <FormControl fullWidth variant="standard">
                  <InputLabel htmlFor="address" className={classes.inputLabelText}>
                    Address
                  </InputLabel>
                  <Input
                    id="address"
                    startAdornment={
                      <InputAdornment position="start"></InputAdornment>
                    }
                    margin="none"
                    name='address'
                    value={selected.address != 'null' && selected.address != null ? selected.address : ''}
                    onChange={(event) =>
                      setSelected({
                        ...selected,
                        address: event.target.value,
                      })
                    }
                  />
                  {errors && errors.address && (
                    <p className={classes.mdText1}>{errors.address}</p>
                  )}
                </FormControl>
              </Grid>

              <Grid item xs={12} md={4} style={{ marginBottom: "15px" }}>
                <FormControl fullWidth variant="standard">
                  <InputLabel htmlFor="city" className={classes.inputLabelText}>
                    City
                  </InputLabel>
                  <Input
                    id="city"
                    startAdornment={
                      <InputAdornment position="start"></InputAdornment>
                    }
                    margin="none"
                    name='city'
                    value={selected.City != 'null' && selected.City != null ? selected.City : ''}
                    onChange={e => setSelected({ ...selected, City: e.target.value })}
                  />
                  {errors && errors.City && (
                    <p className={classes.mdText1}>{errors.City}</p>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={12} md={4} style={{ marginBottom: "15px" }}>
                <FormControl fullWidth variant="standard">
                  <InputLabel htmlFor="district" className={classes.inputLabelText}>
                    District
                  </InputLabel>
                  <Input
                    id="district"
                    startAdornment={
                      <InputAdornment position="start"></InputAdornment>
                    }
                    margin="none"
                    name='district'
                    value={selected.District != 'null' && selected.District != null ? selected.District : ''}
                    onChange={e => setSelected({ ...selected, District: e.target.value })}
                  />
                  {errors && errors.District && (
                    <p className={classes.mdText1}>{errors.District}</p>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={12} md={4} style={{ marginBottom: "15px" }}>
                <FormControl fullWidth variant="standard">
                  <InputLabel htmlFor="state" className={classes.inputLabelText}>
                    State
                  </InputLabel>
                  <Input
                    id="state"
                    startAdornment={
                      <InputAdornment position="start"></InputAdornment>
                    }
                    margin="none"
                    name='state'
                    value={selected.State != 'null' && selected.State != null ? selected.State : ''}
                    onChange={e => setSelected({ ...selected, State: e.target.value })}
                  />
                  {errors && errors.State && (
                    <p className={classes.mdText1}>{errors.State}</p>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={12} md={4} style={{ marginBottom: "15px" }}>
                <FormControl fullWidth variant="standard">
                  <InputLabel htmlFor="zip" className={classes.inputLabelText}>
                    Zip
                  </InputLabel>
                  <Input
                    id="zip"
                    startAdornment={
                      <InputAdornment position="start"></InputAdornment>
                    }
                    margin="none"
                    name='zip'
                    value={selected.zip != 'null' && selected.zip != null ? selected.zip : ''}
                    onChange={e => setSelected({ ...selected, zip: e.target.value })}
                  />
                  {errors && errors.zip && (
                    <p className={classes.mdText1}>{errors.zip}</p>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={12} md={4} style={{ marginBottom: "15px" }}>
                <FormControl fullWidth variant="standard" required>
                  <InputLabel htmlFor="latitude" className={classes.inputLabelText}>
                    Latitude
                  </InputLabel>
                  <Input
                    id="latitude"
                    startAdornment={
                      <InputAdornment position="start"></InputAdornment>
                    }
                    margin="none"
                    name='latitude'
                    value={selected.latitude}
                    onChange={(event) =>
                      setSelected({
                        ...selected,
                        latitude: event.target.value,
                      })
                    }
                  />
                  {errors && errors.latitude && (
                    <p className={classes.mdText1}>{errors.latitude}</p>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={12} md={4} style={{ marginBottom: "15px" }}>
                <FormControl fullWidth variant="standard" required>
                  <InputLabel htmlFor="longitude" className={classes.inputLabelText}>
                    Longitude
                  </InputLabel>
                  <Input
                    id="longitude"
                    startAdornment={
                      <InputAdornment position="start"></InputAdornment>
                    }
                    margin="none"
                    name='mobile'
                    value={selected.longitude}
                    onChange={(event) =>
                      setSelected({
                        ...selected,
                        longitude: event.target.value,
                      })
                    }
                  />
                  {errors && errors.longitude && (
                    <p className={classes.mdText1}>{errors.longitude}</p>
                  )}
                </FormControl>
              </Grid>
            </Grid>
            <Divider variant="middle" style={{ margin: "20px auto" }} />
            <Grid item xs={12} style={{ float: "right", margin: "10px auto" }}>
              <Button
                onClick={handleClose}
                autoFocus
                variant="contained"
                color="primary"
                style={{
                  textTransform: "capitalize",
                  minWidth: "120px",
                  marginRight: "5px",
                  backgroundColor: "#e2e4e6",
                  color: "#293038",
                  borderRadius: "20px",
                }}
              >
                Cancel
              </Button>
              <Button
                autoFocus
                variant="contained"
                color="primary"
                form="form"
                type="submit"
                style={{
                  textTransform: "capitalize",
                  minWidth: "120px",
                  backgroundColor: "#FF8427",
                  color: "rgb(255, 255, 255)",
                  borderRadius: "20px",
                }}
              >
                {selected.id ? "Update" : "Add"}
              </Button>
            </Grid>
          </form>
        </DialogContent>
      </Dialog>

      <Dialog
        open={openGatchaDialogue}
        onClose={() => handleCloseDialogue()}
        TransitionComponent={Transition}
        keepMounted
        aria-labelledby="scroll-dialog-title"
        aria-describedby="alert-dialog-slide-description"
        maxWidth="md"
        fullWidth={true}
      >
        <DialogContent>
          <ClearIcon
            onClick={handleCloseDialogue}
            style={{ width: "25px", height: "25px", float: "right" }}
          />
          <div
            style={{
              marginTop: "10px",
              marginBottom: "20px",
            }}
          >
            <Typography
              style={{
                fontSize: "30px",
                fontWeight: 400,
                color: "rgba(0,0,0,.6)",
              }}
            >
              Gatcha
            </Typography>
          </div>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              console.warn("onSubmit :: ", selectedGatch);
              gatchaValidate(selectedGatch)
              // Add your form submission logic here
            }}
            id="form1"
          >
            <Grid container spacing={2}>

              <Grid item xs={12} md={6} style={{ marginBottom: "15px" }}>
                <FormControl fullWidth variant="standard">
                  <InputLabel htmlFor="name" className={classes.inputLabelText}>
                    Name
                  </InputLabel>
                  <Input
                    id="name"
                    startAdornment={
                      <InputAdornment position="start"></InputAdornment>
                    }
                    margin="none"
                    name='name'
                    value={selectedGatch.name}
                    onChange={e => setSelectedGatcha({ ...selectedGatch, name: e.target.value })}
                  />
                  {errors && errors.name && (
                    <p className={classes.mdText1}>{errors.name}</p>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6} style={{ marginBottom: "15px" }}>
                <FormControl fullWidth variant="standard" required>
                  <InputLabel id="status">Status</InputLabel>
                  <Select
                    labelId="status"
                    id="status"
                    value={selectedGatch.status}
                    onChange={e => setSelectedGatcha({ ...selectedGatch, status: e.target.value })}
                    label="status"
                  >
                    <MenuItem value={1}>Enable</MenuItem>
                    <MenuItem value={0}>Disable</MenuItem>
                  </Select>
                  {errors && errors.status && (
                    <p className={classes.mdText1}>{errors.status}</p>
                  )}
                </FormControl>
              </Grid>
            </Grid>
            <Divider variant="middle" style={{ margin: "20px auto" }} />
            <Grid item xs={12} style={{ float: "right", margin: "10px auto" }}>
              <Button
                onClick={() => handleCloseDialogue()}
                autoFocus
                variant="contained"
                color="primary"
                style={{
                  textTransform: "capitalize",
                  minWidth: "120px",
                  marginRight: "5px",
                  backgroundColor: "#e2e4e6",
                  color: "#293038",
                  borderRadius: "20px",
                }}
              >
                Cancel
              </Button>
              <Button
                autoFocus
                variant="contained"
                color="primary"
                form="form1"  // Changed this to match the form ID
                type="submit"
                style={{
                  textTransform: "capitalize",
                  minWidth: "120px",
                  backgroundColor: "#FF8427",
                  color: "rgb(255, 255, 255)",
                  borderRadius: "20px",
                }}
              >
                Add
              </Button>
            </Grid>
          </form>

        </DialogContent>
      </Dialog>

      <Dialog
        open={openSamudhayDialogue}
        onClose={() => handleCloseSamudhayDialogue()}
        TransitionComponent={Transition}
        keepMounted
        aria-labelledby="scroll-dialog-title"
        aria-describedby="alert-dialog-slide-description"
        maxWidth="md"
        fullWidth={true}
      >
        <DialogContent>
          <ClearIcon
            onClick={handleCloseSamudhayDialogue}
            style={{ width: "25px", height: "25px", float: "right" }}
          />
          <div
            style={{
              marginTop: "10px",
              marginBottom: "20px",
            }}
          >
            <Typography
              style={{
                fontSize: "30px",
                fontWeight: 400,
                color: "rgba(0,0,0,.6)",
              }}
            >
              Gatcha
            </Typography>
          </div>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              console.warn("onSubmitSamudhay :: ", selectedSamudhay);
              samudhayValidate(selectedSamudhay);
            }}
            id="form11"
          >
            <Grid container spacing={2}>
              <Grid item xs={12} md={6} style={{ marginBottom: "15px" }}>
                <FormControl fullWidth variant="standard" required>
                  <InputLabel id="gatcha">Gatcha</InputLabel>
                  <Select
                    labelId="gatcha"
                    id="GatchaId"
                    value={selectedSamudhay.GatchaId}
                    onChange={e => setSelectedSamudhay({ ...selectedSamudhay, GatchaId: e.target.value })}
                    label="gatcha"
                  >
                    {
                      gatcha && gatcha.map((value) => (
                        <MenuItem key={value.id} value={value.id}>{value.name}</MenuItem>
                      ))
                    }
                  </Select>
                  {errors && errors.status && (
                    <p className={classes.mdText1}>{errors.status}</p>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6} style={{ marginBottom: "15px" }}>
                <FormControl fullWidth variant="standard" required>
                  <InputLabel htmlFor="name" className={classes.inputLabelText}>
                    Name
                  </InputLabel>
                  <Input
                    id="name"
                    startAdornment={
                      <InputAdornment position="start"></InputAdornment>
                    }
                    margin="none"
                    name='name'
                    value={selectedSamudhay.name}
                    onChange={e => setSelectedSamudhay({ ...selectedSamudhay, name: e.target.value })}
                  />
                  {errors && errors.name && (
                    <p className={classes.mdText1}>{errors.name}</p>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6} style={{ marginBottom: "15px" }}>
                <FormControl fullWidth variant="standard" required>
                  <InputLabel id="status">Status</InputLabel>
                  <Select
                    labelId="status"
                    id="status"
                    value={selectedSamudhay.status}
                    onChange={e => setSelectedSamudhay({ ...selectedSamudhay, status: e.target.value })}
                    label="status"
                  >
                    <MenuItem value={1}>Enable</MenuItem>
                    <MenuItem value={0}>Disable</MenuItem>
                  </Select>
                  {errors && errors.status && (
                    <p className={classes.mdText1}>{errors.status}</p>
                  )}
                </FormControl>
              </Grid>
            </Grid>
            <Divider variant="middle" style={{ margin: "20px auto" }} />
            <Grid item xs={12} style={{ float: "right", margin: "10px auto" }}>
              <Button
                onClick={() => handleCloseSamudhayDialogue()}
                autoFocus
                variant="contained"
                color="primary"
                style={{
                  textTransform: "capitalize",
                  minWidth: "120px",
                  marginRight: "5px",
                  backgroundColor: "#e2e4e6",
                  color: "#293038",
                  borderRadius: "20px",
                }}
              >
                Cancel
              </Button>
              <Button
                autoFocus
                variant="contained"
                color="primary"
                form="form11"
                type="submit"
                style={{
                  textTransform: "capitalize",
                  minWidth: "120px",
                  backgroundColor: "#FF8427",
                  color: "rgb(255, 255, 255)",
                  borderRadius: "20px",
                }}
              >
                Add
              </Button>
            </Grid>
          </form>

        </DialogContent>
      </Dialog>


      <Dialog
        open={openGuruDialogue}
        onClose={() => handleCloseGuruDialogue()}
        TransitionComponent={Transition}
        keepMounted
        aria-labelledby="scroll-dialog-title"
        aria-describedby="alert-dialog-slide-description"
        maxWidth="md"
        fullWidth={true}
      >
        <DialogContent>
          <ClearIcon
            onClick={handleCloseGuruDialogue}
            style={{ width: "25px", height: "25px", float: "right" }}
          />
          <div
            style={{
              marginTop: "10px",
              marginBottom: "20px",
            }}
          >
            <Typography
              style={{
                fontSize: "30px",
                fontWeight: 400,
                color: "rgba(0,0,0,.6)",
              }}
            >
              Gatcha
            </Typography>
          </div>
          <form
            noValidate
            onSubmit={(e) => {
              e.preventDefault();
              guruValidate(selectedGuru);
            }}
            id="formGuru">
            <Grid container spacing={2}>
              <Grid item xs={12} md={6} style={{ marginBottom: "15px" }}>
                <FormControl fullWidth variant="standard" required>
                  <InputLabel id="gatcha">Gatcha</InputLabel>
                  <Select
                    labelId="gatcha"
                    id="GatchaId"
                    value={selectedGuru.gatchaId}
                    onChange={e => setSelectedGuru({ ...selectedGuru, GatchaId: e.target.value })}
                    label="gatcha"
                  >
                    {
                      gatcha && gatcha.map((value) => {
                        return (
                          <MenuItem
                            key={value.id}
                            value={value.id}
                            onChange={e => setSelectedGuru({ ...selectedGuru, GatchaId: e.target.value })}
                            className={selected.GatchaId === value.id ? 'selected' : ''}
                          >{value.name}</MenuItem>
                        );
                      })
                    }


                  </Select>
                  {errors && errors.GatchaId && (
                    <p className={classes.mdText1}>{errors.GatchaId}</p>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6} style={{ marginBottom: "15px" }}>
                <FormControl fullWidth variant="standard" required>
                  <InputLabel id="SamudhayId">Samudhay</InputLabel>
                  <Select
                    labelId="Samudhay"
                    id="SamudhayId"
                    value={selectedGuru.SamudhayId}
                    onChange={e => setSelectedGuru({ ...selectedGuru, SamudhayId: e.target.value })}
                    label="Samudhay"
                  >
                    {
                      samudhay && samudhay.map((value) => {
                        return (
                          <MenuItem selected={selectedGuru.SamudhayId === value.id} value={value.id}>{value.name}</MenuItem>
                        );
                      })
                    }


                  </Select>
                  {errors && errors.SamudhayId && (
                    <p className={classes.mdText1}>{errors.SamudhayId}</p>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6} style={{ marginBottom: "15px" }}>
                <FormControl fullWidth variant="standard" required>
                  <InputLabel htmlFor="name" className={classes.inputLabelText}>
                    Name
                  </InputLabel>
                  <Input
                    id="name"
                    startAdornment={
                      <InputAdornment position="start"></InputAdornment>
                    }
                    margin="none"
                    name='name'
                    value={selectedGuru.name}
                    onChange={e => setSelectedGuru({ ...selectedGuru, name: e.target.value })}
                  />
                  {errors && errors.name && (
                    <p className={classes.mdText1}>{errors.name}</p>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6} style={{ marginBottom: "15px" }}>
                <FormControl fullWidth variant="standard" required>
                  <InputLabel id="status">Status</InputLabel>
                  <Select
                    labelId="status"
                    id="status"
                    value={selectedGuru.status}
                    onChange={e => setSelectedGuru({ ...selectedGuru, status: e.target.value })}
                    label="status"
                  >
                    <MenuItem value={1}>Enable</MenuItem>
                    <MenuItem value={0}>Disable</MenuItem>
                  </Select>
                  {errors && errors.status && (
                    <p className={classes.mdText1}>{errors.status}</p>
                  )}
                </FormControl>
              </Grid>
            </Grid>
            <Divider variant="middle" style={{ margin: "20px auto" }} />
            <Grid item xs={12} style={{ float: "right", margin: "10px auto" }}>
              <Button
                onClick={() => handleCloseGuruDialogue()}
                autoFocus
                variant="contained"
                color="primary"
                style={{
                  textTransform: "capitalize",
                  minWidth: "120px",
                  marginRight: "5px",
                  backgroundColor: "#e2e4e6",
                  color: "#293038",
                  borderRadius: "20px",
                }}
              >
                Cancel
              </Button>
              <Button
                // onClick={submitHandle}
                autoFocus
                variant="contained"
                color="primary"
                form="formGuru"
                type="submit"
                style={{
                  textTransform: "capitalize",
                  minWidth: "120px",
                  backgroundColor: "#FF8427",
                  color: "rgb(255, 255, 255)",
                  borderRadius: "20px",
                }}
              >
                Add
              </Button>
            </Grid>
          </form>

        </DialogContent>
      </Dialog>


    </div>


  );
}