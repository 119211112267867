import * as actionTypes from "./pharmacyActionType";
import axios from 'axios';
import { checkError } from './../error';

const baseUrl = process.env.REACT_APP_API_URL;

export const getPharmacies = (data) => {
    const config = { headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },  params: data};
      return (dispatch) => {
        dispatch(getPharmaciesInit());
        axios.get(`${baseUrl}/pharmacy/admin`,config)
        .then((response)=>{
            dispatch(getPharmaciesSuccess(response.data.data));
        })
        .catch(function (error) {
            checkError(error);
            dispatch(getPharmaciesFailure());
        })
    };
};

export const getPharmaciesInit = () => ({
    type: actionTypes.GET_PHARMACY_LIST
});

export const getPharmaciesFailure = data => ({
    type: actionTypes.GET_PHARMACY_LIST_FAILURE
});

export const getPharmaciesSuccess = data => ({
    type: actionTypes.GET_PHARMACY_LIST_SUCCESS,
    payload: { data }
});

export const createPharmacy = (data) => {
    const params = new FormData();
    params.image = data.image ? params.append("image", data.image) : params.append("image", null);
    params.name = data.name ? params.append("name", data.name) : params.append("name", null);
    params.specialization = data.specialization ? params.append("specialization", data.specialization) : params.append("specialization", null);
    params.email = data.email ? params.append("email", data.email) : params.append("email", null);
    params.latitude = data.latitude ? params.append("latitude", data.latitude) : params.append("latitude", null);
    params.longitude = data.longitude ? params.append("longitude", data.longitude) : params.append("longitude", null);
    params.address = data.address ? params.append("address", data.address) : params.append("address", null);
    params.contact = data.contact ? params.append("contact", data.contact) : params.append("contact", null);
    params.altContact = data.altContact ? params.append("altContact", data.altContact) : params.append("altContact", null);
    params.City = data.City ? params.append("City", data.City) : params.append("City", null);
    params.District = data.District ? params.append("District", data.District) : params.append("District", null);
    params.State = data.State ? params.append("State", data.State) : params.append("State", null);
    params.zip = data.zip ? params.append("zip", data.zip) : params.append("zip", null);
    params.available = data.available ? params.append("available", data.available) : params.append("available", null);
    params.append("status", data.status);
    console.warn('params:_ ',params);
    const config = { headers: { Authorization: `Bearer ${localStorage.getItem("token")}` } };
      return (dispatch) => {
        dispatch(createPharmacyInit());
        axios.post(`${baseUrl}/pharmacy/store`, params, config)
        .then((response)=>{
            dispatch(createPharmacySuccess(response.data.data));
        })
        .catch(function (error) {
            checkError(error);
            dispatch(createPharmacyFailure());
        })
    };
};

export const createPharmacyInit = () => ({
    type: actionTypes.CREATE_PHARMACY
});

export const createPharmacyFailure = data => ({
    type: actionTypes.CREATE_PHARMACY_FAILURE
});

export const createPharmacySuccess = data => ({
    type: actionTypes.CREATE_PHARMACY_SUCCESS,
    payload: { data }
});

export const updatePharmacy = (data) => {
    const config = { headers: { Authorization: `Bearer ${localStorage.getItem("token")}` } };
    const params = new FormData();
    params.image = data.image ? params.append("image", data.image) : params.append("image", null);
    params.name = data.name ? params.append("name", data.name) : params.append("name", null);
    params.specialization = data.specialization ? params.append("specialization", data.specialization) : params.append("specialization", null);
    params.email = data.email ? params.append("email", data.email) : params.append("email", null);
    params.latitude = data.latitude ? params.append("latitude", data.latitude) : params.append("latitude", null);
    params.longitude = data.longitude ? params.append("longitude", data.longitude) : params.append("longitude", null);
    params.address = data.address ? params.append("address", data.address) : params.append("address", null);
    params.contact = data.contact ? params.append("contact", data.contact) : params.append("contact", null);
    params.altContact = data.altContact ? params.append("altContact", data.altContact) : params.append("altContact", null);
    params.City = data.City ? params.append("City", data.City) : params.append("City", null);
    params.District = data.District ? params.append("District", data.District) : params.append("District", null);
    params.State = data.State ? params.append("State", data.State) : params.append("State", null);
    params.zip = data.zip ? params.append("zip", data.zip) : params.append("zip", null);
    params.available = data.available ? params.append("available", data.available) : params.append("available", null);
    params.append("status", data.status);
    console.warn('params:_ ',params);
      return (dispatch) => {
        dispatch(updatePharmacyInit());
        axios.put(`${baseUrl}/pharmacy/${data.id}`, params, config)
        .then((response)=>{
            dispatch(updatePharmacySuccess(response.data.data));
        })
        .catch(function (error) {
            checkError(error);
            dispatch(updatePharmacyFailure());
        })
    };
};

export const updatePharmacyInit = () => ({
    type: actionTypes.UPDATE_PHARMACY
});

export const updatePharmacyFailure = data => ({
    type: actionTypes.UPDATE_PHARMACY_FAILURE
});

export const updatePharmacySuccess = data => ({
    type: actionTypes.UPDATE_PHARMACY_SUCCESS,
    payload: { data }
});

export const deletePharmacy = (data) => {
    const config = { headers: { Authorization: `Bearer ${localStorage.getItem("token")}` } };
      return (dispatch) => {
        dispatch(deletePharmacyInit());
        axios.delete(`${baseUrl}/pharmacy/${data}`, config)
        .then((response)=>{
            dispatch(deletePharmacySuccess(response.data.data));
        })
        .catch(function (error) {
            checkError(error);
            dispatch(deletePharmacyFailure());
        })
    };
};

export const deletePharmacyInit = () => ({
    type: actionTypes.DELETE_PHARMACY
});

export const deletePharmacyFailure = data => ({
    type: actionTypes.DELETE_PHARMACY_FAILURE
});

export const deletePharmacySuccess = data => ({
    type: actionTypes.DELETE_PHARMACY_SUCCESS,
    payload: { data }
});

export const getTimezone = () => {
    
    return (dispatch) => {
        dispatch(getTimezoneInit());
        axios.get(`https://api.ipgeolocation.io/ipgeo?apiKey=0a85ab484e4a4f2caf453d75b2e13408`)
            .then((response) => {
                dispatch(getTimezoneSuccess(response.data.time_zone));
            })
            .catch(function (error) {
                checkError(error);
                dispatch(getTimezoneFailure());
            })
    };
};

export const getTimezoneInit = () => ({
    type: actionTypes.GET_TIMEZONE
});

export const getTimezoneFailure = data => ({
    type: actionTypes.GET_TIMEZONE_FAILURE
});

export const getTimezoneSuccess = data => ({
    type: actionTypes.GET_TIMEZONE_SUCCESS,
    payload: { data }
});
