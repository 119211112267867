import { BloodBankPage } from './bloodBank.component';
import { connect } from 'react-redux';
import { getBloodBanks, createBloodBank, updateBloodBank, deleteBloodBank, getTimezone } from "../../api/bloodbank/bloodbankAction";

const mapDispatchToProps = {
    getBloodBanks,
    createBloodBank,
    updateBloodBank,
    deleteBloodBank,
    getTimezone
};

const mapStateToProps = state => ({
    error: state.bloodbankPage.isError,
    isLoading: state.bloodbankPage.isLoading,
    isSaved: state.bloodbankPage.isSaved,
    bloodbanks: state.bloodbankPage.bloodbanks,
    timezone:state.bloodbankPage.timezone,
    count: state.bloodbankPage.count,
});

export const BloodBankContainer = connect(mapStateToProps, mapDispatchToProps)(BloodBankPage);