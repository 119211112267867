import * as actionTypes from "./gatchaActionType";
import axios from 'axios';
import { checkError } from '../error';

const baseUrl = process.env.REACT_APP_API_URL;

export const getGatcha = (data) => {
    const config = { headers: { Authorization: `Bearer ${localStorage.getItem("token")}` }, params: data };
      return (dispatch) => {
        dispatch(getGatchaInit());
        axios.get(`${baseUrl}/gatcha/admin`, config)
        .then((response)=>{
            dispatch(getGatchaSuccess(response.data.data));
        })
        .catch(function (error) {
            checkError(error);
            dispatch(getGatchaFailure());
        })
    };
};

export const getGatchaInit = () => ({
    type: actionTypes.GET_GATCHA_LIST
});

export const getGatchaFailure = data => ({
    type: actionTypes.GET_GATCHA_LIST_FAILURE
});

export const getGatchaSuccess = data => ({
    type: actionTypes.GET_GATCHA_LIST_SUCCESS,
    payload: { data }
});

export const createGatcha = (data) => {
    const config = { headers: { Authorization: `Bearer ${localStorage.getItem("token")}` } };
      return (dispatch) => {
        dispatch(createGatchaInit());
        axios.post(`${baseUrl}/gatcha/store`, data, config)
        .then((response)=>{
            dispatch(createGatchaSuccess(response.data.data));
        })
        .catch(function (error) {
            checkError(error);
            dispatch(createGatchaFailure());
        })
    };
};

export const createGatchaInit = () => ({
    type: actionTypes.CREATE_GATCHA
});

export const createGatchaFailure = data => ({
    type: actionTypes.CREATE_GATCHA_FAILURE
});

export const createGatchaSuccess = data => ({
    type: actionTypes.CREATE_GATCHA_SUCCESS,
    payload: { data }
});

export const updateGatcha = (data) => {
    const config = { headers: { Authorization: `Bearer ${localStorage.getItem("token")}` } };
      return (dispatch) => {
        dispatch(updateGatchaInit());
        axios.put(`${baseUrl}/gatcha/${data.id}`, data, config)
        .then((response)=>{
            dispatch(updateGatchaSuccess(response.data.data));
        })
        .catch(function (error) {
            checkError(error);
            dispatch(updateGatchaFailure());
        })
    };
};

export const updateGatchaInit = () => ({
    type: actionTypes.UPDATE_GATCHA
});

export const updateGatchaFailure = data => ({
    type: actionTypes.UPDATE_GATCHA_FAILURE
});

export const updateGatchaSuccess = data => ({
    type: actionTypes.UPDATE_GATCHA_SUCCESS,
    payload: { data }
});

export const deleteGatcha = (data) => {
    const config = { headers: { Authorization: `Bearer ${localStorage.getItem("token")}` } };
      return (dispatch) => {
        dispatch(deleteGatchaInit());
        axios.delete(`${baseUrl}/gatcha/${data}`, config)
        .then((response)=>{
            dispatch(deleteGatchaSuccess(response.data.data));
        })
        .catch(function (error) {
            checkError(error);
            dispatch(deleteGatchaFailure());
        })
    };
};

export const deleteGatchaInit = () => ({
    type: actionTypes.DELETE_GATCHA
});

export const deleteGatchaFailure = data => ({
    type: actionTypes.DELETE_GATCHA_FAILURE
});

export const deleteGatchaSuccess = data => ({
    type: actionTypes.DELETE_GATCHA_SUCCESS,
    payload: { data }
});
