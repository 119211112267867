import { GatchaPage } from './gatcha.component';
import { connect } from 'react-redux';
import { getGatcha, createGatcha, updateGatcha, deleteGatcha } from "../../api/gatcha/gatchaAction";

const mapDispatchToProps = {
    getGatcha,
    createGatcha,
    updateGatcha,
    deleteGatcha
};

const mapStateToProps = state => ({
    error: state.gatchaPage.isError,
    isLoading: state.gatchaPage.isLoading,
    isSaved: state.gatchaPage.isSaved,
    result: state.gatchaPage.result,
    count: state.gatchaPage.count,
    isLoggedIn : state.adminPage.isLoggedIn
});

export const GatchaContainer = connect(mapStateToProps, mapDispatchToProps)(GatchaPage);