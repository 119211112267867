import * as actionType from "./medicalReportActionType";

const initialState = {
    isError: false,
    isLoading: false,
    isSaved: false,
    reports: [],
    count: 0,
    imageData: ""
};

export const medicalReportReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionType.GET_MEDICAL_REPORT_LIST:
            return {
                ...state,
                isError: false,
                isLoading: true
            };
        case actionType.GET_MEDICAL_REPORT_LIST_FAILURE:
            return {
                ...state,
                isError: true,
                isLoading: false
            };
        case actionType.GET_MEDICAL_REPORT_LIST_SUCCESS:
            return {
                ...state,
                isError: false,
                isLoading: false,
                reports: action.payload ? action.payload.data.rows : [],
                count: action.payload ? action.payload.data.count : 0
            };
        case actionType.CREATE_MEDICAL_REPORT:
            return {
                ...state,
                isError: false,
                isLoading: true,
                isSaved: false
            };
        case actionType.CREATE_MEDICAL_REPORT_FAILURE:
            return {
                ...state,
                isError: true,
                isLoading: false
            };
        case actionType.CREATE_MEDICAL_REPORT_SUCCESS:
            return {
                ...state,
                isError: false,
                isLoading: false,
                isSaved: true
            };

        case actionType.CREATE_IMAGE:
            return {
                ...state,
                isError: false,
                isLoading: true,
                isSaved: false
            };
        case actionType.CREATE_IMAGE_FAILURE:
            return {
                ...state,
                isError: true,
                isLoading: false
            };
        case actionType.CREATE_IMAGE_SUCCESS:
            
            
            return {
                ...state,
                isError: false,
                isLoading: false,
                isSaved: true,
                imageData: action.payload ? action.payload.data : ""
            };
        case actionType.UPDATE_MEDICAL_REPORT:
            return {
                ...state,
                isError: false,
                isLoading: true,
                isSaved: false
            };
        case actionType.UPDATE_MEDICAL_REPORT_FAILURE:
            return {
                ...state,
                isError: true,
                isLoading: false
            };
        case actionType.UPDATE_MEDICAL_REPORT_SUCCESS:
            return {
                ...state,
                isError: false,
                isLoading: false,
                isSaved: true
            };
        case actionType.DELETE_MEDICAL_REPORT:
            return {
                ...state,
                isError: false,
                isLoading: true,
                isSaved: false
            };
        case actionType.DELETE_MEDICAL_REPORT_FAILURE:
            return {
                ...state,
                isError: true,
                isLoading: false
            };
        case actionType.DELETE_MEDICAL_REPORT_SUCCESS:
            return {
                ...state,
                isError: false,
                isLoading: false,
                isSaved: true
            };
        default:
            return state

    }
}
