export const GET_EMERGENCY_LIST = "GET_EMERGENCY_LIST";
export const GET_EMERGENCY_LIST_FAILURE = "GET_EMERGENCY_LIST_FAILURE";
export const GET_EMERGENCY_LIST_SUCCESS = "GET_EMERGENCY_LIST_SUCCESS";

export const CREATE_EMERGENCY = "CREATE_EMERGENCY";
export const CREATE_EMERGENCY_FAILURE = "CREATE_EMERGENCY_FAILURE";
export const CREATE_EMERGENCY_SUCCESS = "CREATE_EMERGENCY_SUCCESS";

export const UPDATE_EMERGENCY = "UPDATE_EMERGENCY";
export const UPDATE_EMERGENCY_FAILURE = "UPDATE_EMERGENCY_FAILURE";
export const UPDATE_EMERGENCY_SUCCESS = "UPDATE_EMERGENCY_SUCCESS";

export const DELETE_EMERGENCY = "DELETE_EMERGENCY";
export const DELETE_EMERGENCY_FAILURE = "DELETE_EMERGENCY_FAILURE";
export const DELETE_EMERGENCY_SUCCESS = "DELETE_EMERGENCY_SUCCESS";
