import { MedicalPage } from './medical.component';
import { connect } from 'react-redux';
import { getMedical, createMedical, updateMedical, deleteMedical } from "./../../api/medical/medicalAction";
import { getShramans } from './../../api/shramans/shramansAction';
const mapDispatchToProps = {
    getMedical,
    createMedical,
    updateMedical,
    deleteMedical,
    getShramans
};

const mapStateToProps = state => ({
    error: state.medicalPage.isError,
    isLoading: state.medicalPage.isLoading,
    isSaved: state.medicalPage.isSaved,
    medicals: state.medicalPage.medicals,
    count: state.medicalPage.count,
    sharmans : state.shramansPage.shramans
});

export const MedicalContainer = connect(mapStateToProps, mapDispatchToProps)(MedicalPage);