import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@mui/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { confirmAlert } from "react-confirm-alert";
import TextField from "@mui/material/TextField";


function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  { id: 'shramanname', numeric: false, disablePadding: false, label: 'ShramanName' },
  { id: 'location', numeric: false, disablePadding: false, label: 'Location' },
  { id: 'latitude', numeric: false, disablePadding: false, label: 'Latitude' },
  { id: 'lonsitude', numeric: false, disablePadding: false, label: 'Lonsitude' },
  { id: 'info', numeric: false, disablePadding: false, label: 'Info' },
  { id: 'isemergency', numeric: false, disablePadding: false, label: 'IsEmergency' },
  { id: 'images', numeric: false, disablePadding: false, label: 'Images' },
  { id: 'status', numeric: false, disablePadding: false, label: 'Status' },
];

function EnhancedTableHead(props) {
  const { classes, order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell style={{
            textTransform: "uppercase",
            fontSize: "10px",
            fontWeight: 600,
            fontFamily: "Metropolis-Regular,Sans-Serif",
            color: "rgba(37,45,71,.62)"
          }}
            key={headCell.id}
            align='left'
            padding={headCell.disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
        <TableCell style={{
          textTransform: "uppercase",
          fontSize: "10px",
          fontWeight: 600,
          fontFamily: "Metropolis-Regular,Sans-Serif",
          color: "rgba(37,45,71,.62)"
        }}>
          Actions
        </TableCell>
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
};

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.type === 'light'
      ? {
        color: theme.palette.secondary.main,
        backgroundColor: "#9e040e",
      }
      : {
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.secondary.dark,
      },
  title: {
    flex: '1 1 100%',
  },
}));

const EnhancedTableToolbar = (props) => {
  const classes = useToolbarStyles();

  return (
    <Toolbar
      className={clsx(classes.root)}
    >
      <Typography className={classes.title} variant="h6" id="tableTitle" component="div">
        Caregiver
      </Typography>
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  menuText: {
    fontWeight: "400",
    fontSize: "14px",
    color: "#534F6B",
    minWidth: "135px"
  },
  editIcon: {
    fontSize: '30px',
    position: 'relative',
    lineHeight: 1,
    backgroundColor: 'rgba(0, 255, 10, 0.2)',
    borderColor: 'transparent',
    color: '#4caf50',
    padding: '8px',
    height: '100%',
    borderRadius: '5px',
  },
  detailIcon: {
    fontSize: '30px',
    position: 'relative',
    lineHeight: 1,
    backgroundColor: 'rgba(0, 255, 10, 0.2)',
    borderColor: 'transparent',
    color: '#4caf50',
    padding: '8px',
    height: '100%',
    borderRadius: '5px',
  },
  deleteIcon: {
    fontSize: '30px',
    position: 'relative',
    lineHeight: 1,
    backgroundColor: 'rgba(255, 68, 0, 0.2)',
    borderColor: 'transparent',
    color: '#fa6c39',
    padding: '8px',
    height: '100%',
    borderRadius: '5px',
  },
}));

export default function CaseReportTable(props) {
  const { handleClickOpen, handleClickOpenDetail, rows, setSelected, deleteCaregiver } = props;

  const classes = useStyles();
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('calories');
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const headers = [
    { label: "Fname", key: "fname" },
    { label: "Dob", key: "dob" },
    { label: "Age", key: "age" },
    { label: "Gender", key: "gender" },
    { label: "Weight", key: "weight" },
  ];

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const confirmDelete = (id) => {
    confirmAlert({
      title: "Confirm to delete",
      message: "Are you sure to do this.",
      buttons: [
        {
          label: "Yes",
          onClick: () => deleteCaregiver(id),
        },
        {
          label: "No",
        },
      ],
    });
  };

  // const emptyRows = rowsPerPage - Math.min(rowsPerPage, users.length - page * rowsPerPage);

  // const [search, setSearch] = React.useState("");

  // const handleSearchChange = (e) => {
  //   setSearch(e.target.value);
  // };

  // const filtered = !search
  //   ? rows
  //   : rows.filter((person) =>
  //       person.name && person.email && person.phoneNo
  //         ? person.name && person.email && person.phoneNo.toLowerCase().includes(search.toLowerCase())
  //         : ""
  //     );

  const [searchText, setSearchText] = React.useState("");
  const [data, setData] = React.useState(rows);

  const excludeColumns = ["age"];

  const handleChange = value => {
    setSearchText(value);
    filterData(value);
  };

  const filterData = (value) => {
    const lowercasedValue = value.toLowerCase().trim();
    if (lowercasedValue === "") setData(rows);
    else {
      const filteredData = rows.filter(item => {
        return Object.keys(item).some(key =>
          excludeColumns.includes(key) ? false : item[key]?.toString().toLowerCase().includes(lowercasedValue)
        );
      });
      setData(filteredData);
    }
  }
  const viewDetail = () => {
    handleClickOpenDetail();
  }
  const [datas, setDatas] = React.useState(true)
  return (
    <div className={classes.root}>
      {!datas == true ? <p>No data available</p> :
        <Paper className={classes.paper}>
          <Toolbar className={classes.root} style={{ justifyContent: "space-between" }}>
            <Typography
              className={classes.title}
              variant="h6"
              id="tableTitle"
              component="div"
            >
              Case Report
            </Typography>
            <TextField
              helperText=" "
              id="demo-helper-text-aligned-no-helper"
              label="Search"
              type="search"
              value={searchText}
              onChange={e => handleChange(e.target.value)}
            />
          </Toolbar>
          <TableContainer>
            <Table
              className={classes.table}
              aria-labelledby="tableTitle"
              aria-label="enhanced table"
            >
              <EnhancedTableHead
                classes={classes}
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
              />
              <TableBody>
              <TableCell>Record not found</TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
                {/* {data && Array.isArray(data) && data.map((row, index) => {
                  const labelId = `user-${index}`;

                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={row.id}
                    >
                      <TableCell component="th" id={labelId} scope="row">
                      {(rowsPerPage * page) + index + 1}
                    </TableCell>
                      <TableCell>{row.shramanname ? <span> {row.shramanname} </span> : 'N/A'}</TableCell>
                      <TableCell>{row.location ? <span> {row.location} </span> : 'N/A'}</TableCell>
                      <TableCell>{row.latitude ? <span> {row.latitude} </span> : 'N/A'}</TableCell>
                      <TableCell>{row.lonsitude ? <span> {row.lonsitude} </span> : 'N/A'}</TableCell>
                      <TableCell>{row.info ? <span> {row.info} </span> : 'N/A'}</TableCell>
                      <TableCell>{row.isemergency ? <span> {row.isemergency} </span> : 'N/A'}</TableCell>
                      <TableCell>{row.images ? <span> {row.images} </span> : 'N/A'}</TableCell>
                      <TableCell>{row.status ? <span> {row.status} </span> : 'N/A'}</TableCell>
                      <TableCell>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <EditIcon className={classes.editIcon}
                            onClick={() => {
                              setSelected(row.id);
                              handleClickOpen();
                            }}
                            style={{ cursor: 'pointer' }}
                          />
                          <DeleteIcon className={classes.deleteIcon}
                            onClick={() => {
                              confirmDelete(row.id);
                            }}
                            style={{ cursor: 'pointer', marginLeft: '15px', marginRight: '15px' }}
                          />
                          <VisibilityIcon className={classes.detailIcon}
                            onClick={viewDetail}
                            style={{ cursor: 'pointer' }}
                          />
                        </div>
                      </TableCell>
                    </TableRow>
                  );
                })} */}
                {/* {emptyRows > 0 && (
              <TableRow style={{ height: 53 * emptyRows }}>
                <TableCell colSpan={8} />
              </TableRow>
            )} */}
              </TableBody>
            </Table>
          </TableContainer>
          {/* <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={users.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      /> */}
        </Paper>
      }
      {/* {data.length === 0 && <span style={{display: "flex", justifyContent: "center"}}>No records found to display!</span>} */}
    </div>
  );
}