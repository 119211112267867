import { LoginPage } from './login.component';
import { adminLogin } from './../../api/admin/adminAction';
import { connect } from 'react-redux';

const mapDispatchToProps = {
    adminLogin
};

const mapStateToProps = state => ({
    error: state.adminPage.isError,
    isLoading: state.adminPage.isLoading,
    isLoggedIn: state.adminPage.isLoggedIn,
});

export const LoginContainer = connect(mapStateToProps, mapDispatchToProps)(LoginPage);