export const GET_CAREGIVER_LIST = "GET_CAREGIVER_LIST";
export const GET_CAREGIVER_LIST_FAILURE = "GET_CAREGIVER_LIST_FAILURE";
export const GET_CAREGIVER_LIST_SUCCESS = "GET_CAREGIVER_LIST_SUCCESS";

export const CREATE_CAREGIVER = "CREATE_CAREGIVER";
export const CREATE_CAREGIVER_FAILURE = "CREATE_CAREGIVER_FAILURE";
export const CREATE_CAREGIVER_SUCCESS = "CREATE_CAREGIVER_SUCCESS";

export const UPDATE_CAREGIVER = "UPDATE_CAREGIVER";
export const UPDATE_CAREGIVER_FAILURE = "UPDATE_CAREGIVER_FAILURE";
export const UPDATE_CAREGIVER_SUCCESS = "UPDATE_CAREGIVER_SUCCESS";

export const DELETE_CAREGIVER = "DELETE_CAREGIVER";
export const DELETE_CAREGIVER_FAILURE = "DELETE_CAREGIVER_FAILURE";
export const DELETE_CAREGIVER_SUCCESS = "DELETE_CAREGIVER_SUCCESS";
