import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { LoginContainer } from './views/login/login.container';
import { ProtectedRoute } from './shared/protected.route';
import { DashboardContainer } from './views/dashboard/dashboard.container';
import { CaregiverContainer } from "./views/caregiver/caregiver.container";
import { EmergencyContainer } from "./views/emergency/emergency.container";
import { VitalContainer } from "./views/vital/vital.container";
import { MedicalContainer } from "./views/medical/medical.container";
import { CaseReportContainer } from "./views/caseReport/caseReport.container";
import { DoctorContainer } from "./views/doctor/doctor.container";
import { ShramanContainer } from "./views/shramans/shramans.container";
import { MedicineContainer } from "./views/medicine/medicine.container";
import { PageNotFoundContainer } from "./views/404/404.container";
import { HospitalContainer } from './views/hospital/hospital.container';
import { PharmacyContainer } from './views/pharmacy/pharmacy.container';
import { LabContainer } from './views/lab/lab.container';
import { BloodBankContainer } from './views/bloodBank/bloodBank.container';
import { ConsultContainer } from './views/reqstConsult/requstConsult.container';
import { SpecializationContainer } from './views/specialization/specialization.container'; 
import { ProfileContainer } from './views/Profile/Profile.container';
import { PasswordContainer } from './views/updatePass/Password.container';
import { ReportMedicalContainer } from './views/reportMedicalCase/report_medical_case.container';
import { GatchaContainer } from './views/gatcha/gatcha.container';
import { SamudhayContainer } from './views/samudhay/samudhay.container';
import { GuruContainer } from './views/guru/guru.container';



export const RoutePath = () => (
  <Routes>
    <Route path='/login' element={<LoginContainer />} />
    <Route path='/dashboard' element={<ProtectedRoute><DashboardContainer /></ProtectedRoute>} />
    <Route path='/' element={<ProtectedRoute><DashboardContainer /></ProtectedRoute>} />
    <Route path='/caregiver' element={<ProtectedRoute><CaregiverContainer /></ProtectedRoute>} />
    <Route path='/emergency' element={<ProtectedRoute><EmergencyContainer /></ProtectedRoute>} />
    <Route path='/blood-bank' element={<ProtectedRoute><BloodBankContainer /></ProtectedRoute>} />
    <Route path='/vital' element={<ProtectedRoute><VitalContainer /></ProtectedRoute>} />
    <Route path='/hospital' element={<ProtectedRoute><HospitalContainer /></ProtectedRoute>} />
    <Route path='/pharmacy' element={<ProtectedRoute><PharmacyContainer /></ProtectedRoute>} />
    <Route path='/lab' element={<ProtectedRoute><LabContainer /></ProtectedRoute>} />
    <Route path='/medical' element={<ProtectedRoute><MedicalContainer /></ProtectedRoute>} />
    <Route path='/case-report' element={<ProtectedRoute><CaseReportContainer /></ProtectedRoute>} />
    <Route path='/doctor' element={<ProtectedRoute><DoctorContainer /></ProtectedRoute>} />
    <Route path='/shramans' element={<ProtectedRoute><ShramanContainer /></ProtectedRoute>} />
    <Route path='/medicine' element={<ProtectedRoute><MedicineContainer /></ProtectedRoute>} />
    <Route path='/page-not-found' element={<PageNotFoundContainer />} />
    <Route path='/requstConsult' element={<ProtectedRoute><ConsultContainer /></ProtectedRoute>} />
    <Route path='/specialization' element={<ProtectedRoute><SpecializationContainer /></ProtectedRoute>} />
    <Route path='/profile' element={<ProtectedRoute><ProfileContainer /></ProtectedRoute>} />
    <Route path='/change-password' element={<ProtectedRoute><PasswordContainer /></ProtectedRoute>}/>
    <Route path='/report-medical-case' element={<ProtectedRoute><ReportMedicalContainer /></ProtectedRoute>}/>
    <Route path="/gatcha" element={<ProtectedRoute><GatchaContainer /></ProtectedRoute>}/>
    <Route path="/samudhay" element={<ProtectedRoute><SamudhayContainer /></ProtectedRoute>}/>
    <Route path="/guru" element={<ProtectedRoute><GuruContainer /></ProtectedRoute>}/>
  </Routes>
);
