import * as actionType from "./labActionType";

const initialState = {
  isError: false,
  isLoading: false,
  isSaved: false,
  labs: [],
  timezone:{},
  count: 0
};

export const labReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionType.GET_LAB_LIST:
            return {
                ...state,
                isError: false,
                isLoading: true
            };
        case actionType.GET_LAB_LIST_FAILURE:
            return {
                ...state,
                isError: true,
                isLoading: false
            };
        case actionType.GET_LAB_LIST_SUCCESS:
            return {
                ...state,
                isError: false,
                isLoading: false,
                labs: action.payload ? action.payload.data : [],
                count: action.payload ? action.payload.data.count : 0
            };
        case actionType.CREATE_LAB:
            return {
                ...state,
                isError: false,
                isLoading: true,
                isSaved: false
            };
        case actionType.CREATE_LAB_FAILURE:
            return {
                ...state,
                isError: true,
                isLoading: false
            };
        case actionType.CREATE_LAB_SUCCESS:
            return {
                ...state,
                isError: false,
                isLoading: false,
                isSaved: true
            };
        case actionType.UPDATE_LAB:
            return {
                ...state,
                isError: false,
                isLoading: true,
                isSaved: false
            };
        case actionType.UPDATE_LAB_FAILURE:
            return {
                ...state,
                isError: true,
                isLoading: false
            };
        case actionType.UPDATE_LAB_SUCCESS:
            return {
                ...state,
                isError: false,
                isLoading: false,
                isSaved: true
            };
        case actionType.DELETE_LAB:
            return {
                ...state,
                isError: false,
                isLoading: true,
                isSaved: false
            };
        case actionType.DELETE_LAB_FAILURE:
            return {
                ...state,
                isError: true,
                isLoading: false
            };
        case actionType.DELETE_LAB_SUCCESS:
            return {
                ...state,
                isError: false,
                isLoading: false,
                isSaved: true
            };
        case actionType.GET_TIMEZONE:
            return {
                ...state,
                isError: false,
                isLoading: true,
                isSaved: false
            };
        case actionType.GET_TIMEZONE_FAILURE:
            return {
                ...state,
                isError: true,
                isLoading: false
            };
        case actionType.GET_TIMEZONE_SUCCESS:
            return {
                ...state,
                isError: false,
                isLoading: false,
                timezone: action.payload ? action.payload.data : [],
            };
       default:
            return state
            
    }
}
