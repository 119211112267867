import React, { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import Loading from "../../layout/Loading";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import HomeIcon from "@mui/icons-material/Home";
import { Link } from "react-router-dom";
import {
  Button,
  Box,
  TextField,
  FormControl,
  Typography,
  InputLabel,
  OutlinedInput,
  Grid,
  IconButton,
  Avatar,
} from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { confirmAlert } from "react-confirm-alert";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { toast } from "react-toastify";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    background: "teal",
    color: "#fff",
    fontSize: "24px",
  },
  dblink: {
    textDecoration: "none",
  },
  link: {
    display: "flex",
    textDecoration: "none",
    color: "#fff !important",
  },
  icon: {
    marginRight: theme.spacing(0.5),
    width: 20,
    height: 20,
  },
  breadcrum: {
    backgroundColor: theme.palette.primary.main,
    padding: "20px 40px",
    color: "#fff !important",
    marginBottom: "15px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
      alignItems: "flex-start",
      padding: "13px 30px",
    },
  },
  createBtn: {
    background: "#000 !important",

    "&:hover": {
      backgroundColor: "transparent !important",
    },

    [theme.breakpoints.down("md")]: {
      marginTop: "15px",
      paddingLeft: "0px !important",
      paddingBottom: "0px !important",
    },
  },
}));

export function Password(props) {
  const { PasswordUpdate } = props;
  const classes = useStyles();
  const [showPassword, setShowPassword] = useState(false);
  const [Password, setPassword] = useState({
    password: "",
    confirmPass: "",
  });
  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (Password.password === Password.confirmPass) {
      PasswordUpdate(1, { password: Password.confirmPass });
    } else {
      toast("password dose not match");
    }
  };

  return (
    <div className={classes.root}>
      <Loading />
      <Box className={classes.breadcrum}>
        <Breadcrumbs aria-label="breadcrumb">
          <Link to="/" className={classes.link}>
            <HomeIcon className={classes.icon} />
            Home
          </Link>
          <Typography color="textPrimary" className={classes.link}>
            Password
          </Typography>
        </Breadcrumbs>
      </Box>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
       mt={5}
      >
        <Box sx={{ width: { xs: "100%", md: "60%" } }} height="100px">
          <Typography variant="h4" mb={3} textAlign="center">Change Password</Typography>
          <form onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <OutlinedInput
                  id="outlined-adornment-password"
                  type={showPassword ? "text" : "password"}
                  placeholder="Password"
                  fullWidth
                  value={Password.password}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                  onChange={(e) => {
                    setPassword({
                      ...Password,
                      password: e.target.value,
                    });
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  placeholder="Confirm Password"
                  variant="outlined"
                  type="password"
                  value={Password.confirmPass}
                  fullWidth
                  onChange={(event) =>
                    setPassword({
                      ...Password,
                      confirmPass: event.target.value,
                    })
                  }
                />
              </Grid>

              <Grid xs={12}>
                <Button
                  variant="contained"
                  type="submit"
                  sx={{
                    ml: 2,
                    mt: 2,
                    backgroundColor: "#FF8427",
                    "&:hover": {
                      backgroundColor: "#FF8427",
                    },
                  }}
                >
                  Submit
                </Button>
              </Grid>
            </Grid>
          </form>
        </Box>
      </Box>
    </div>
  );
}
