import * as actionType from "./caregiverActionType";

const initialState = {
  isError: false,
  isLoading: false,
  isSaved: false,
  caregivers: [],
  count: 0
};

export const caregiverReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionType.GET_CAREGIVER_LIST:
            return {
                ...state,
                isError: false,
                isLoading: true
            };
        case actionType.GET_CAREGIVER_LIST_FAILURE:
            return {
                ...state,
                isError: true,
                isLoading: false
            };
        case actionType.GET_CAREGIVER_LIST_SUCCESS:
            return {
                ...state,
                isError: false,
                isLoading: false,
                caregivers: action.payload ? action.payload.data : [],
                count: action.payload ? action.payload.data.count : 0
            };
        case actionType.CREATE_CAREGIVER:
            return {
                ...state,
                isError: false,
                isLoading: true,
                isSaved: false
            };
        case actionType.CREATE_CAREGIVER_FAILURE:
            return {
                ...state,
                isError: true,
                isLoading: false
            };
        case actionType.CREATE_CAREGIVER_SUCCESS:
            return {
                ...state,
                isError: false,
                isLoading: false,
                isSaved: true
            };
        case actionType.UPDATE_CAREGIVER:
            return {
                ...state,
                isError: false,
                isLoading: true,
                isSaved: false
            };
        case actionType.UPDATE_CAREGIVER_FAILURE:
            return {
                ...state,
                isError: true,
                isLoading: false
            };
        case actionType.UPDATE_CAREGIVER_SUCCESS:
            return {
                ...state,
                isError: false,
                isLoading: false,
                isSaved: true
            };
        case actionType.DELETE_CAREGIVER:
            return {
                ...state,
                isError: false,
                isLoading: true,
                isSaved: false
            };
        case actionType.DELETE_CAREGIVER_FAILURE:
            return {
                ...state,
                isError: true,
                isLoading: false
            };
        case actionType.DELETE_CAREGIVER_SUCCESS:
            return {
                ...state,
                isError: false,
                isLoading: false,
                isSaved: true
            };
       default:
            return state
            
    }
}
