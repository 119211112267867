import * as actionTypes from "./hospitalActionType";
import axios from 'axios';
import { checkError } from './../error';

const baseUrl = process.env.REACT_APP_API_URL;

export const getHospitals = (data) => {
    const config = { params: data };
      return (dispatch) => {
        dispatch(getHospitalsInit());
        axios.get(`${baseUrl}/hospital/admin`, config)
        .then((response)=>{
            dispatch(getHospitalsSuccess(response.data.data));
        })
        .catch(function (error) {
            checkError(error);
            dispatch(getHospitalsFailure());
        })
    };
};

export const getHospitalsInit = () => ({
    type: actionTypes.GET_HOSPITAL_LIST
});

export const getHospitalsFailure = data => ({
    type: actionTypes.GET_HOSPITAL_LIST_FAILURE
});

export const getHospitalsSuccess = data => ({
    type: actionTypes.GET_HOSPITAL_LIST_SUCCESS,
    payload: { data }
});

export const createHospital = (data) => {
    const config = { headers: { Authorization: `Bearer ${localStorage.getItem("token")}` } };
    data.latitude = data.latitude.toString();
    data.longitude = data.longitude.toString();
    
    return (dispatch) => {
        dispatch(createHospitalInit());
        axios.post(`${baseUrl}/hospital/store`, data, config)
        .then((response)=>{
            dispatch(createHospitalSuccess(response.data.data));
        })
        .catch(function (error) {
            checkError(error);
            dispatch(createHospitalFailure());
        })
    };
};

export const createHospitalInit = () => ({
    type: actionTypes.CREATE_HOSPITAL
});

export const createHospitalFailure = data => ({
    type: actionTypes.CREATE_HOSPITAL_FAILURE
});

export const createHospitalSuccess = data => ({
    type: actionTypes.CREATE_HOSPITAL_SUCCESS,
    payload: { data }
});

export const updateHospital = (data) => {
    const config = { headers: { Authorization: `Bearer ${localStorage.getItem("token")}` } };
    data.latitude = data.latitude.toString();
    data.longitude = data.longitude.toString();
     
    
      return (dispatch) => {
        dispatch(updateHospitalInit());
        axios.put(`${baseUrl}/hospital/${data.id}`, data, config)
        .then((response)=>{
            dispatch(updateHospitalSuccess(response.data.data));
        })
        .catch(function (error) {
            checkError(error);
            dispatch(updateHospitalFailure());
        })
    };
};

export const updateHospitalInit = () => ({
    type: actionTypes.UPDATE_HOSPITAL
});

export const updateHospitalFailure = data => ({
    type: actionTypes.UPDATE_HOSPITAL_FAILURE
});

export const updateHospitalSuccess = data => ({
    type: actionTypes.UPDATE_HOSPITAL_SUCCESS,
    payload: { data }
});

export const deleteHospital = (data) => {
    const config = { headers: { Authorization: `Bearer ${localStorage.getItem("token")}` } };
      return (dispatch) => {
        dispatch(deleteHospitalInit());
        axios.delete(`${baseUrl}/hospital/${data}`, config)
        .then((response)=>{
            dispatch(deleteHospitalSuccess(response.data.data));
        })
        .catch(function (error) {
            checkError(error);
            dispatch(deleteHospitalFailure());
        })
    };
};

export const deleteHospitalInit = () => ({
    type: actionTypes.DELETE_HOSPITAL
});

export const deleteHospitalFailure = data => ({
    type: actionTypes.DELETE_HOSPITAL_FAILURE
});

export const deleteHospitalSuccess = data => ({
    type: actionTypes.DELETE_HOSPITAL_SUCCESS,
    payload: { data }
});


export const getTimezone = () => {
    
    return (dispatch) => {
        dispatch(getTimezoneInit());
        axios.get(`https://api.ipgeolocation.io/ipgeo?apiKey=0a85ab484e4a4f2caf453d75b2e13408`)
            .then((response) => {
                dispatch(getTimezoneSuccess(response.data.time_zone));
            })
            .catch(function (error) {
                checkError(error);
                dispatch(getTimezoneFailure());
            })
    };
};

export const getTimezoneInit = () => ({
    type: actionTypes.GET_TIMEZONE
});

export const getTimezoneFailure = data => ({
    type: actionTypes.GET_TIMEZONE_FAILURE
});

export const getTimezoneSuccess = data => ({
    type: actionTypes.GET_TIMEZONE_SUCCESS,
    payload: { data }
});