import * as actionType from "./pharmacyActionType";

const initialState = {
  isError: false,
  isLoading: false,
  isSaved: false,
  pharmacies: [],
  timezone:{},
  count: 0
};

export const pharmacyReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionType.GET_PHARMACY_LIST:
            return {
                ...state,
                isError: false,
                isLoading: true
            };
        case actionType.GET_PHARMACY_LIST_FAILURE:
            return {
                ...state,
                isError: true,
                isLoading: false
            };
        case actionType.GET_PHARMACY_LIST_SUCCESS:
            return {
                ...state,
                isError: false,
                isLoading: false,
                pharmacies: action.payload ? action.payload.data : [],
                count: action.payload ? action.payload.data.count : 0
            };
        case actionType.CREATE_PHARMACY:
            return {
                ...state,
                isError: false,
                isLoading: true,
                isSaved: false
            };
        case actionType.CREATE_PHARMACY_FAILURE:
            return {
                ...state,
                isError: true,
                isLoading: false
            };
        case actionType.CREATE_PHARMACY_SUCCESS:
            return {
                ...state,
                isError: false,
                isLoading: false,
                isSaved: true
            };
        case actionType.UPDATE_PHARMACY:
            return {
                ...state,
                isError: false,
                isLoading: true,
                isSaved: false
            };
        case actionType.UPDATE_PHARMACY_FAILURE:
            return {
                ...state,
                isError: true,
                isLoading: false
            };
        case actionType.UPDATE_PHARMACY_SUCCESS:
            return {
                ...state,
                isError: false,
                isLoading: false,
                isSaved: true
            };
        case actionType.DELETE_PHARMACY:
            return {
                ...state,
                isError: false,
                isLoading: true,
                isSaved: false
            };
        case actionType.DELETE_PHARMACY_FAILURE:
            return {
                ...state,
                isError: true,
                isLoading: false
            };
        case actionType.DELETE_PHARMACY_SUCCESS:
            return {
                ...state,
                isError: false,
                isLoading: false,
                isSaved: true
            };
        case actionType.GET_TIMEZONE:
            return {
                ...state,
                isError: false,
                isLoading: true,
                isSaved: false
            };
        case actionType.GET_TIMEZONE_FAILURE:
            return {
                ...state,
                isError: true,
                isLoading: false
            };
        case actionType.GET_TIMEZONE_SUCCESS:
            return {
                ...state,
                isError: false,
                isLoading: false,
                timezone: action.payload ? action.payload.data : [],
            };
       default:
            return state
            
    }
}
