import * as actionTypes from "./samudhayActionType";
import axios from 'axios';
import { checkError } from '../error';

const baseUrl = process.env.REACT_APP_API_URL;

export const getSamudhay = (data) => {
    const config = { headers: { Authorization: `Bearer ${localStorage.getItem("token")}` }, params: data };
      return (dispatch) => {
        dispatch(getSamudhayInit());
        axios.get(`${baseUrl}/samudhay/admin`, config)
        .then((response)=>{
            dispatch(getSamudhaySuccess(response.data.data));
        })
        .catch(function (error) {
            checkError(error);
            dispatch(getSamudhayFailure());
        })
    };
};

export const getSamudhayInit = () => ({
    type: actionTypes.GET_SAMUDHAY_LIST
});

export const getSamudhayFailure = data => ({
    type: actionTypes.GET_SAMUDHAY_LIST_FAILURE
});

export const getSamudhaySuccess = data => ({
    type: actionTypes.GET_SAMUDHAY_LIST_SUCCESS,
    payload: { data }
});

export const createSamudhay = (data) => {
    const config = { headers: { Authorization: `Bearer ${localStorage.getItem("token")}` } };
      return (dispatch) => {
        dispatch(createSamudhayInit());
        axios.post(`${baseUrl}/samudhay/store`, data, config)
        .then((response)=>{
            dispatch(createSamudhaySuccess(response.data.data));
        })
        .catch(function (error) {
            checkError(error);
            dispatch(createSamudhayFailure());
        })
    };
};

export const createSamudhayInit = () => ({
    type: actionTypes.CREATE_SAMUDHAY
});

export const createSamudhayFailure = data => ({
    type: actionTypes.CREATE_SAMUDHAY_FAILURE
});

export const createSamudhaySuccess = data => ({
    type: actionTypes.CREATE_SAMUDHAY_SUCCESS,
    payload: { data }
});

export const updateSamudhay = (data) => {
    const config = { headers: { Authorization: `Bearer ${localStorage.getItem("token")}` } };
      return (dispatch) => {
        dispatch(updateSamudhayInit());
        axios.put(`${baseUrl}/samudhay/${data.id}`, data, config)
        .then((response)=>{
            dispatch(updateSamudhaySuccess(response.data.data));
        })
        .catch(function (error) {
            checkError(error);
            dispatch(updateSamudhayFailure());
        })
    };
};

export const updateSamudhayInit = () => ({
    type: actionTypes.UPDATE_SAMUDHAY
});

export const updateSamudhayFailure = data => ({
    type: actionTypes.UPDATE_SAMUDHAY_FAILURE
});

export const updateSamudhaySuccess = data => ({
    type: actionTypes.UPDATE_SAMUDHAY_SUCCESS,
    payload: { data }
});

export const deleteSamudhay = (data) => {
    const config = { headers: { Authorization: `Bearer ${localStorage.getItem("token")}` } };
      return (dispatch) => {
        dispatch(deleteSamudhayInit());
        axios.delete(`${baseUrl}/samudhay/${data}`, config)
        .then((response)=>{
            dispatch(deleteSamudhaySuccess(response.data.data));
        })
        .catch(function (error) {
            checkError(error);
            dispatch(deleteSamudhayFailure());
        })
    };
};

export const deleteSamudhayInit = () => ({
    type: actionTypes.DELETE_SAMUDHAY
});

export const deleteSamudhayFailure = data => ({
    type: actionTypes.DELETE_SAMUDHAY_FAILURE
});

export const deleteSamudhaySuccess = data => ({
    type: actionTypes.DELETE_SAMUDHAY_SUCCESS,
    payload: { data }
});

export const getGatchSamudhay = (GatchaId) => {
    const config = { headers: { Authorization: `Bearer ${localStorage.getItem("token")}` }, params: {} };
    return (dispatch) => {
      dispatch(getGatchSamudhayInit());
      axios.get(`${baseUrl}/samudhay/all-samudhay/${GatchaId}`, config)
      .then((response)=>{
          dispatch(getGatchSamudhaySuccess(response.data.data));
      })
      .catch(function (error) {
          checkError(error);
          dispatch(getGatchSamudhayFailure());
      })
  };
}


export const getGatchSamudhayInit = () => ({
    type: actionTypes.GATCHA_SAMUDHAY
});

export const getGatchSamudhayFailure = data => ({
    type: actionTypes.GATCHA_SAMUDHAY_FAILURE
});

export const getGatchSamudhaySuccess = data => ({
    type: actionTypes.GATCHA_SAMUDHAY_SUCCESS,
    payload: { data }
});


