import React, { useEffect, useState, useRef } from "react";
import { makeStyles } from "@mui/styles";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import { Typography, TextField, Box } from "@mui/material";
import Slide from "@mui/material/Slide";
import Grid from "@mui/material/Grid";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import DialogContent from "@mui/material/DialogContent";
import Input from "@mui/material/Input";
import Divider from "@mui/material/Divider";
import InputAdornment from "@mui/material/InputAdornment";
import ClearIcon from "@mui/icons-material/Clear";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";
import { Autocomplete } from '@mui/material';


const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "relative",
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  paper: {
    marginTop: theme.spacing(3),
    padding: theme.spacing(3),
  },
  formControl: {
    width: "100%",
    spacing: theme.spacing(2),
  },
  inputLabelText: {
    fontSize: "14px !important",
    color: "rgba(0,0,0,.5) !important",
    fontWeight: "600 !important",
    textTransform: "uppercase",
    marginTop: "0px",
    minWidth: "50px",
  },
  mdText1: {
    color: "red",
    fontSize: "12px",
    fontWeight: 400,
  },
  imgSelect: {
    width: "100px",
    marginLeft: "10px",
    // height:"120px"
  },
  imgSection: {
    display: "flex",
    // justifyContent:"space-between",
    alignItems: "center",
    marginBottom: "10px",
  },
  textAreaSection: {
    width: "100%",
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "space-between",
  },
  deleteBtn: {
    backgroundColor: "red !important",
    color: "white !important",
    marginLeft: "10px !important",
    // margin: "10px auto 10px 10px !important",
  },
  addBtn: {
    backgroundColor: "green !important",
    color: "white !important",
    marginLeft: "10px !important",
    // margin: "10px auto 10px 10px !important",
  },
  vitalCustom: {
    marginBottom: "10px !important",
    width: "95%",
    background: "transparent !important",

    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
  },
  customTime: {
    marginBottom: "10px !important",
    width: "95%",
    border: "none !important",
    padding: "0px",

    "& fieldset": { borderColor: "gray !important" },

    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
  },

  vitalInput: {
    width: "97.5%",

    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
  },
  headVital: {
    position: "absolute",
    top: "11%",
    left: "50%",
  },
  vitalsSection: {
    marginTop: "10px",
    marginBottom: "10px",
    display: "flex",
    justifyContent: "space-between",
  },
}));

const Transition = React.forwardRef(function Transition (props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

export default function ConsultDialoge (props) {
  const {
    handleClose,
    open,
    errors,
    selected,
    setSelected,
    validate,
    CareGiver,
    Sharman,
    medicals,
    Doctor,
    vitals,
    createImage,
    imageData,
    vital,
    setVital,
    handleVitalAdd,
    updateVitalBySelect,
    handlevitalDelete,
    updateCustomValue,
    imageArray,
    setImageArray,
    deleteImageArray,
    addImageArray,
  } = props;
  const classes = useStyles();

  const onImageChange = (event, i) => {
    if (
      event &&
      event.target &&
      event.target.files &&
      event.target.files.length > 0
    ) {
      createImage(event.target.files[0], i);
    }
  };



  const handleChange = (e, selectedValues) => {
    if (selectedValues) {
      console.warn('handleChange:- ', selectedValues.value);
      setSelected({
        ...selected,
        shraman_id: selectedValues.value ? selectedValues.value.toString() : 0
      })
    }

  };



  const handleDoctorChange = (e, selectedValues) => {
    if (selectedValues) {
      setSelected({
        ...selected,
        doctor_id: selectedValues.value ? selectedValues.value.toString() : 0
      })
    }

  };
  const handleCaregiverChange = (e, selectedValues) => {
    if (selectedValues) {
      setSelected({
        ...selected,
        caregiver_id: selectedValues.value ? selectedValues.value.toString() : 0
      })
    }

  };
  console.warn("Sharman ::",Sharman);
  const options = Sharman.rows.map(item => ({ value: item.id, label: item.name }));
  const doctorOptions = Doctor.rows.map(item => ({ value: item.id, label: item.name }));
  const caregiverOptions = CareGiver.rows.map(item => ({ value: item.id, label: item.name }));

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        // scroll={scroll}
        TransitionComponent={Transition}
        keepMounted
        aria-labelledby="scroll-dialog-title"
        aria-describedby="alert-dialog-slide-description"
        maxWidth="md"
        fullWidth={true}
      >
        <DialogContent>
          <ClearIcon
            onClick={handleClose}
            style={{ width: "25px", height: "25px", float: "right" }}
          />
          <div
            style={{
              marginTop: "10px",
              marginBottom: "20px",
            }}
          >
            <Typography
              style={{
                fontSize: "30px",
                fontWeight: 400,
                color: "rgba(0,0,0,.6)",
              }}
            >
              Request Consult
            </Typography>
          </div>
          <form
            noValidate
            id="form"
            onSubmit={(event) => {
              event.preventDefault();
              validate(selected);
            }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12} md={6} style={{ marginBottom: "15px" }}>
                <FormControl>
                  <FormLabel id="demo-row-radio-buttons-group-label">
                    Type Of Visit
                  </FormLabel>

                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group" // Specify a common name for the group
                    value={selected.type_of_visit} // Set the selected value
                    onChange={(e) =>
                      setSelected({
                        ...selected,
                        type_of_visit: e.target.value,
                      })
                    }
                  >
                    <FormControlLabel
                      value="Doctor Consultation"
                      control={<Radio />}
                      label="Doctor Consultation"
                    />

                    <FormControlLabel
                      value="lab_test"
                      control={<Radio />}
                      label="Lab Test"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>

              <Grid item xs={12} md={6} style={{ marginBottom: "15px" }}>
                <FormControl fullWidth variant="standard" required>
                  <InputLabel
                    htmlFor="specialization"
                    className={classes.inputLabelText}
                  >
                    Medical
                  </InputLabel>
                  <Input
                    id="medical_case"
                    startAdornment={
                      <InputAdornment position="start"></InputAdornment>
                    }
                    margin="none"
                    type="text"
                    name="medical_case"
                    value={selected.medical_case}
                    onChange={(e) =>
                      setSelected({ ...selected, medical_case: e.target.value })
                    }
                  />
                  {errors && errors.medical_case && (
                    <p className={classes.mdText1}>{errors.medical_case}</p>
                  )}
                </FormControl>
              </Grid>

              <Grid item xs={12} md={6} style={{ marginBottom: "15px" }}>
                <FormControl fullWidth variant="standard">
                  <Autocomplete
                    options={options}
                    onChange={handleChange}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Sharman"

                      />
                    )}
                  />
                  {errors && errors.shraman_id && (
                    <p className={classes.mdText1}>{errors.shraman_id}</p>
                  )}
                </FormControl>
              </Grid>

              <Grid item xs={12} md={6}>
                <FormControl fullWidth variant="standard" required>
                  <Autocomplete
                    options={doctorOptions}
                    onChange={handleDoctorChange}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Doctor"
                        required
                      />
                    )}
                  />

                  {errors && errors.doctor_id && (
                    <p className={classes.mdText1}>{errors.doctor_id}</p>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6} style={{ marginBottom: "15px" }}>
                <FormControl fullWidth variant="standard" required>
                  <Autocomplete
                    options={caregiverOptions}
                    onChange={handleCaregiverChange}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Caregiver"
                        required
                      />
                    )}
                  />
                  {errors && errors.caregiver_id && (
                    <p className={classes.mdText1}>{errors.caregiver_id}</p>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6} style={{ marginBottom: "15px" }}>


                {imageArray &&
                  Array.isArray(imageArray) &&
                  imageArray.map((el, idx) => {
                    return (
                      <div className={classes.imgSection}>
                        <Button
                          variant="contained"
                          className="select_btn"
                          component="label"
                          htmlFor={`att_img${idx}`}
                        >
                          Select Image
                          <input
                            id={`att_img${idx}`}
                            type="file"
                            style={{ display: "none" }}
                            onChange={(e) => onImageChange(e, idx)}
                          />
                        </Button>
                        <div>
                          {el?.image !== "" && (
                            <img
                              className={classes.imgSelect}
                              src={el?.image}
                              alt="not select img"
                            />
                          )}
                        </div>
                        {idx >= 1 ? (
                          <Button
                            autoFocus
                            variant="contained"
                            color="primary"
                            onClick={() => deleteImageArray(idx)}
                            style={{
                              textTransform: "capitalize",
                              backgroundColor: "#e2e4e6",
                              color: "#293038",
                              borderRadius: "20px",
                            }}
                            className={classes.deleteBtn}
                          >
                            Delete
                          </Button>
                        ) : (
                          <Button
                            onClick={() => addImageArray()}
                            autoFocus
                            variant="contained"
                            color="primary"
                            style={{
                              textTransform: "capitalize",
                              backgroundColor: "#e2e4e6",
                              color: "#293038",
                              borderRadius: "20px",
                            }}
                            className={classes.addBtn}
                          >
                            Add
                          </Button>
                        )}
                      </div>
                    );
                  })}
              </Grid>

              <Grid item xs={12} md={12} style={{ marginBottom: "15px" }}>
                <FormControl fullWidth>
                  <InputLabel
                    htmlFor="remark"
                    className={classes.inputLabelText}
                  >
                    Remarks
                  </InputLabel>
                  <Input
                    id="remark"
                    startAdornment={
                      <InputAdornment position="start"></InputAdornment>
                    }
                    margin="none"
                    name="remark"
                    value={selected.remark}
                    onChange={(e) =>
                      setSelected({ ...selected, remark: e.target.value })
                    }
                  />
                  {errors && errors.remark && (
                    <p className={classes.mdText1}>{errors.remark}</p>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={12} md={12} sx={{ position: "relative" }}>
                <Typography variant="h6" sx={{ display: "inline-block" }}>
                  Vitals
                </Typography>
                {vital &&
                  Array.isArray(vital) &&
                  vital.map((el, idx) => {
                    return (
                      <div id={idx} key={idx}>
                        <Grid container md={12} spacing={2}>
                          <Grid item md={2}>
                            <FormControl fullWidth variant="standard" style={{ minWidth: "80px" }}>
                              <InputLabel
                                htmlFor="specialization"
                                className={classes.inputLabelText}
                              >
                                Vital
                              </InputLabel>
                              <Select
                                id="vital"
                                margin="normal"
                                name="vital"
                                onChange={(e) =>
                                  updateVitalBySelect(e.target.value, idx)
                                }
                              >
                                {vitals &&
                                  Array.isArray(vitals) &&
                                  vitals.map((y, i) => {
                                    return (
                                      <MenuItem value={y.id}>{y.name}</MenuItem>
                                    );
                                  })}
                              </Select>
                            </FormControl>
                          </Grid>
                          {Array.isArray(el.custom) &&
                            el.custom.map((z, l) => {
                              return (
                                <Grid item md={2} key={z.id}>
                                  <FormControl
                                    className={classes.vitalCustom}
                                    variant="standard"
                                    required
                                  >
                                    <InputLabel htmlFor="value">
                                      {z.name}
                                    </InputLabel>
                                    <Input
                                      id={`id${z.id}`}
                                      margin="normal"
                                      label={z.name}
                                      endAdornment={
                                        <InputAdornment position="end">
                                          {z.unit}
                                        </InputAdornment>
                                      }
                                      value={z?.value}
                                      onChange={(e) =>
                                        updateCustomValue(
                                          e.target.value,
                                          idx,
                                          l
                                        )
                                      }
                                    />
                                  </FormControl>
                                </Grid>
                              );
                            })}
                          <Grid item md={2}>
                            {idx >= 1 ? (
                              <Button
                                className={classes.deleteBtn}
                                onClick={() => handlevitalDelete(idx)}
                                style={{
                                  textTransform: "capitalize",
                                  backgroundColor: "#e2e4e6",
                                  color: "#293038",
                                  borderRadius: "20px",
                                }}
                              >
                                Delete
                              </Button>
                            ) : (
                              <Button
                                className={classes.addBtn}
                                onClick={handleVitalAdd}
                                style={{
                                  textTransform: "capitalize",
                                  backgroundColor: "#e2e4e6",
                                  color: "#293038",
                                  borderRadius: "20px",
                                }}
                              >
                                Add
                              </Button>
                            )}
                          </Grid>
                        </Grid>
                      </div>
                    );
                  })}
              </Grid>
            </Grid>

            <Divider variant="middle" style={{ margin: "20px auto" }} />
            <Grid item xs={12} style={{ float: "right", margin: "10px auto" }}>
              <Button
                onClick={handleClose}
                autoFocus
                variant="contained"
                color="primary"
                style={{
                  textTransform: "capitalize",
                  minWidth: "120px",
                  marginRight: "5px",
                  backgroundColor: "#e2e4e6",
                  color: "#293038",
                  borderRadius: "20px",
                }}
              >
                Cancel
              </Button>
              <Button
                autoFocus
                variant="contained"
                color="primary"
                form="form"
                type="submit"
                style={{
                  textTransform: "capitalize",
                  minWidth: "120px",
                  backgroundColor: "#FF8427",
                  color: "rgb(255, 255, 255)",
                  borderRadius: "20px",
                }}
              >
                {selected.id ? "Update" : "Add"}
              </Button>
            </Grid>
          </form>
        </DialogContent>
      </Dialog>
    </div>
  );
}
