export const GET_LAB_LIST = "GET_LAB_LIST";
export const GET_LAB_LIST_FAILURE = "GET_LAB_LIST_FAILURE";
export const GET_LAB_LIST_SUCCESS = "GET_LAB_LIST_SUCCESS";

export const CREATE_LAB = "CREATE_LAB";
export const CREATE_LAB_FAILURE = "CREATE_LAB_FAILURE";
export const CREATE_LAB_SUCCESS = "CREATE_LAB_SUCCESS";

export const UPDATE_LAB = "UPDATE_LAB";
export const UPDATE_LAB_FAILURE = "UPDATE_LAB_FAILURE";
export const UPDATE_LAB_SUCCESS = "UPDATE_LAB_SUCCESS";

export const DELETE_LAB = "DELETE_LAB";
export const DELETE_LAB_FAILURE = "DELETE_LAB_FAILURE";
export const DELETE_LAB_SUCCESS = "DELETE_LAB_SUCCESS";

export const GET_TIMEZONE = "GET_TIMEZONE";
export const GET_TIMEZONE_FAILURE = "GET_TIMEZONE_FAILURE";
export const GET_TIMEZONE_SUCCESS = "GET_TIMEZONE_SUCCESS";
