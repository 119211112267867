import { DashboardPage } from './dashboard.component';
import { connect } from 'react-redux';
// import { adminLogin } from '../../api/admin/adminAction';
import { getHospitals } from '../../api/hospital/hospitalAction';
import { getDoctor } from '../../api/doctor/doctorAction';
import { getShramans } from '../../api/shramans/shramansAction';
import { getLabs } from '../../api/lab/labAction';
import { getPharmacies } from '../../api/pharmacy/pharmacyAction';
import { getCaregiver } from '../../api/caregiver/caregiverAction';
import { getReqConsult} from '../../api/requestConsult/requestAction';

const mapDispatchToProps = {
    // adminLogin,
    getHospitals,
    getDoctor,
    getShramans,
    getLabs,
    getPharmacies,
    getCaregiver,
    getReqConsult
};

const mapStateToProps = state => ({
    
    hospitals: state.hospitalPage.hospitals,
    doctors: state.doctorPage.doctors,
    shramans: state.shramansPage.shramans,
    labs: state.labPage.labs,
    pharmacies: state.pharmacyPage.pharmacies,
    caregivers: state.caregiverPage.caregivers,
    isLoggedIn : state.adminPage.isLoggedIn,
    consultData : state.requestConsultPage.consultData
    // isLoading: state.adminPage.isLoading,
    // loggedAdmin: state.adminPage.loggedAdmin,
    // users: state.adminPage.users,
    // munis: state.muniPage.munis,
});

export const DashboardContainer = connect(mapStateToProps, mapDispatchToProps)(DashboardPage);
