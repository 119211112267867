import * as actionTypes from "./bloodbankActionType";
import axios from 'axios';
import { checkError } from './../error';

const baseUrl = process.env.REACT_APP_API_URL;

export const getBloodBanks = (data) => {
    const config = { headers: { Authorization: `Bearer ${localStorage.getItem("token")}` }, params: data };
      return (dispatch) => {
        dispatch(getBloodBanksInit());
        axios.get(`${baseUrl}/blood-bank/admin`, config)
        .then((response)=>{
            dispatch(getBloodBankSuccess(response.data.data));
        })
        .catch(function (error) {
            checkError(error);
            dispatch(getBloodBankFailure());
        })
    };
};

export const getBloodBanksInit = () => ({
    type: actionTypes.GET_BLOODBANKS_LIST
});

export const getBloodBankFailure = data => ({
    type: actionTypes.GET_BLOODBANKS_LIST_FAILURE
});

export const getBloodBankSuccess = data => ({
    type: actionTypes.GET_BLOODBANKS_LIST_SUCCESS,
    payload: { data }
});

export const createBloodBank = (data) => {
    const config = { headers: { Authorization: `Bearer ${localStorage.getItem("token")}` } };
      return (dispatch) => {
        dispatch(createBloodBankInit());
        axios.post(`${baseUrl}/blood-bank/store`, data, config)
        .then((response)=>{
            dispatch(createBloodBankSuccess(response.data.data));
        })
        .catch(function (error) {
            checkError(error);
            dispatch(createBloodBankFailure());
        })
    };
};

export const createBloodBankInit = () => ({
    type: actionTypes.CREATE_BLOODBANKS
});

export const createBloodBankFailure = data => ({
    type: actionTypes.CREATE_BLOODBANKS_FAILURE
});

export const createBloodBankSuccess = data => ({
    type: actionTypes.CREATE_BLOODBANKS_SUCCESS,
    payload: { data }
});

export const updateBloodBank = (data) => {
    const config = { headers: { Authorization: `Bearer ${localStorage.getItem("token")}` } };
    
      return (dispatch) => {
        dispatch(updateEmergencyInit());
        axios.put(`${baseUrl}/blood-bank/${data.id}`, data, config)
        .then((response)=>{
            dispatch(updateEmergencySuccess(response.data.data));
        })
        .catch(function (error) {
            checkError(error);
            dispatch(updateEmergencyFailure());
        })
    };
};

export const updateEmergencyInit = () => ({
    type: actionTypes.UPDATE_BLOODBANKS
});

export const updateEmergencyFailure = data => ({
    type: actionTypes.UPDATE_BLOODBANKS_FAILURE
});

export const updateEmergencySuccess = data => ({
    type: actionTypes.UPDATE_BLOODBANKS_SUCCESS,
    payload: { data }
});

export const deleteBloodBank = (data) => {
    const config = { headers: { Authorization: `Bearer ${localStorage.getItem("token")}` } };
      return (dispatch) => {
        dispatch(deleteBloodBankInit());
        axios.delete(`${baseUrl}/blood-bank/${data}`, config)
        .then((response)=>{
            dispatch(deleteBloodBanksSuccess(response.data.data));
        })
        .catch(function (error) {
            checkError(error);
            dispatch(deleteBloodBankFailure());
        })
    };
};

export const deleteBloodBankInit = () => ({
    type: actionTypes.DELETE_BLOODBANKS
});

export const deleteBloodBankFailure = data => ({
    type: actionTypes.DELETE_BLOODBANKS_FAILURE
});

export const deleteBloodBanksSuccess = data => ({
    type: actionTypes.DELETE_BLOODBANKS_SUCCESS,
    payload: { data }
});


export const getTimezone = () => {
    
    return (dispatch) => {
        dispatch(getTimezoneInit());
        axios.get(`https://api.ipgeolocation.io/ipgeo?apiKey=0a85ab484e4a4f2caf453d75b2e13408`)
            .then((response) => {
                dispatch(getTimezoneSuccess(response.data.time_zone));
            })
            .catch(function (error) {
                checkError(error);
                dispatch(getTimezoneFailure());
            })
    };
};

export const getTimezoneInit = () => ({
    type: actionTypes.GET_TIMEZONE
});

export const getTimezoneFailure = data => ({
    type: actionTypes.GET_TIMEZONE_FAILURE
});

export const getTimezoneSuccess = data => ({
    type: actionTypes.GET_TIMEZONE_SUCCESS,
    payload: { data }
});
