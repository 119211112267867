import { HospitalPage } from './hospital.component';
import { connect } from 'react-redux';
import { getHospitals, createHospital, updateHospital, deleteHospital, getTimezone } from './../../api/hospital/hospitalAction';

const mapDispatchToProps = {
    getHospitals,
    createHospital,
    updateHospital,
    deleteHospital,
    getTimezone
};

const mapStateToProps = state => ({
    error: state.hospitalPage.isError,
    isLoading: state.hospitalPage.isLoading,
    isSaved: state.hospitalPage.isSaved,
    hospitals: state.hospitalPage.hospitals,
    count: state.hospitalPage.count,
    timezone:state.hospitalPage.timezone,
    isLoggedIn : state.adminPage.isLoggedIn
});

export const HospitalContainer = connect(mapStateToProps, mapDispatchToProps)(HospitalPage);