import * as actionType from "./doctorActionType";

const initialState = {
    isError: false,
    isLoading: false,
    isSaved: false,
    doctors: [],
    count: 0,
    imageData: ""
};

export const doctorReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionType.GET_DOCTOR_LIST:
            return {
                ...state,
                isError: false,
                isLoading: true
            };
        case actionType.GET_DOCTOR_LIST_FAILURE:
            return {
                ...state,
                isError: true,
                isLoading: false
            };
        case actionType.GET_DOCTOR_LIST_SUCCESS:
            return {
                ...state,
                isError: false,
                isLoading: false,
                doctors: action.payload ? action.payload.data : [],
                count: action.payload ? action.payload.data.count : 0
            };
        case actionType.CREATE_DOCTOR:
            return {
                ...state,
                isError: false,
                isLoading: true,
                isSaved: false
            };
        case actionType.CREATE_DOCTOR_FAILURE:
            return {
                ...state,
                isError: true,
                isLoading: false
            };
        case actionType.CREATE_DOCTOR_SUCCESS:
            return {
                ...state,
                isError: false,
                isLoading: false,
                isSaved: true
            };
        case actionType.CREATE_IMAGE:
            return {
                ...state,
                isError: false,
                isLoading: true,
                isSaved: false
            };
        case actionType.CREATE_IMAGE_FAILURE:
            return {
                ...state,
                isError: true,
                isLoading: false
            };
        case actionType.CREATE_IMAGE_SUCCESS:
            
            
            return {
                ...state,
                isError: false,
                isLoading: false,
                isSaved: true,
                imageData: action.payload ? action.payload.data : ""
            };
        case actionType.UPDATE_DOCTOR:
            return {
                ...state,
                isError: false,
                isLoading: true,
                isSaved: false
            };
        case actionType.UPDATE_DOCTOR_FAILURE:
            return {
                ...state,
                isError: true,
                isLoading: false
            };
        case actionType.UPDATE_DOCTOR_SUCCESS:
            return {
                ...state,
                isError: false,
                isLoading: false,
                isSaved: true
            };
        case actionType.DELETE_DOCTOR:
            return {
                ...state,
                isError: false,
                isLoading: true,
                isSaved: false
            };
        case actionType.DELETE_DOCTOR_FAILURE:
            return {
                ...state,
                isError: true,
                isLoading: false
            };
        case actionType.DELETE_DOCTOR_SUCCESS:
            return {
                ...state,
                isError: false,
                isLoading: false,
                isSaved: true
            };
        default:
            return state

    }
}
