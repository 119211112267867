import React, { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import DoctorTable from "./component/doctor-table.component";
import DoctorDialoge from "./component/doctor-dialoge";
import Loading from "../../layout/Loading";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import HomeIcon from "@mui/icons-material/Home";
import { Link, json } from "react-router-dom";
import Typography from "@mui/material/Typography";
import { Button, Box } from "@mui/material";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { confirmAlert } from "react-confirm-alert";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    background: "teal",
    color: "#fff",
    fontSize: "24px",
  },
  dblink: {
    textDecoration: "none",
  },
  link: {
    display: "flex",
    textDecoration: "none",
    color: "#fff !important",
  },
  icon: {
    marginRight: theme.spacing(0.5),
    width: 20,
    height: 20,
  },

  breadcrum: {
    backgroundColor: theme.palette.primary.main,
    padding: "20px 40px",
    color: "#fff !important",
    marginBottom: "15px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
      alignItems: "flex-start",
      padding: "13px 30px",
    },
  },
  createBtn: {
    background: "#000 !important",

    "&:hover": {
      backgroundColor: "transparent !important",
    },

    [theme.breakpoints.down("md")]: {
      marginTop: "15px",
      paddingLeft: "0px !important",
      paddingBottom: "0px !important",
    },
  },
}));

export function DoctorPage (props) {
  const {
    getDoctor,
    createDoctor,
    updateDoctor,
    deleteDoctor,
    createImage,
    count,
    doctors,
    imageData,
    specializations,
    getSpecialization,
    isSaved,
    isLoading,
    isLoggedIn,
  } = props;

  // const id = props.match.params.id;
  const classes = useStyles();

  useEffect(() => {
    getSpecialization();
  }, []);

  const emptyLocation = {
    address: "",
    latitude: "",
    longitude: "",
    available_days: [],
    time_in: "",
    time_out: "",
  };

  const emptyDoctor = {
    image: null,
    prefix: "",
    name: "",
    hash: "",
    phone: "",
    password: "",
    dob: "",
    age: "",
    City: "",
    District: "",
    State: "",
    zip: "",
    gender: "Male",
    address: "",
    email: "",
    latitude: "",
    longitude: "",
    barCode: "",
    status: 1,
    UPI: "",
    optin: "0",
    type_of_practise: "",
    specialization: [],
    locations: [],
  };
  const [open, setOpen] = useState(false);
  const [selected, setSelected] = useState(emptyDoctor);
  const [errors, setErrors] = React.useState({});
  const [order, setOrder] = React.useState("desc");
  const [orderBy, setOrderBy] = React.useState("id");
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [filtes, setFiltes] = React.useState("");
  const [available, setAvailable] = useState([]);
  const [location, setLocation] = useState([emptyLocation]);

  // Locations  functions

  const addLocation = () => {
    setLocation(location.concat([emptyLocation]));
  };
  const deleteLocation = (index) => {
    setLocation((location) => location.filter((x, i) => i !== index));
  };
  const updateLocation = (i, v, t) => {
    setLocation(
      location.map((item, index) => (i == index ? { ...item, [t]: v } : item))
    );
  };

  useEffect(() => {
    if (imageData) {
      selected.id
        ? updateDoctor({ ...selected, image: imageData[0].media, specialization: selected.specialization, locations: location })
        : createDoctor({ ...selected, image: imageData[0].media, specialization: selected.specialization, locations: location });
    }
  }, [imageData]);

  useEffect(() => {
    getDoctor({
      offset: page * rowsPerPage,
      limit: rowsPerPage,
      orderBy: orderBy,
      orderType: order,
      search: filtes,
      // specialization: searchAge
    });
  }, []);

  useEffect(() => {
    if (isSaved) {
      setOpen(false);
      setSelected(emptyDoctor);
      setAvailable([]);
      setLocation(emptyLocation);
      // if(imageData) {
      //   createImage();
      // }

      getDoctor({
        offset: page * rowsPerPage,
        limit: rowsPerPage,
        orderBy: orderBy,
        orderType: order,
        search: filtes,
        // specialization: searchAge
      });
    }
  }, [isSaved]);

  useEffect(() => {
    if (filtes.length > 1) {
      getDoctor({
        offset: page * rowsPerPage,
        limit: rowsPerPage,
        orderBy: orderBy,
        orderType: order,
        search: filtes,
        // specialization: searchAge
      });
    } else {
      getDoctor({
        offset: page * rowsPerPage,
        limit: rowsPerPage,
        orderBy: orderBy,
        orderType: order,
      });
    }
  }, [filtes]);

  const validate = (values) => {
    let errorss = {};
    let isError = false;
    const regex = /\S+@\S+\.\S+/;
    if (!values.id) {
      if (!values.password) {
        isError = true;
        errorss.password = "Password is required";
      }
    }
    if (
      !values.name &&
      !values.email &&
      !values.phone &&
      !values.address &&
      !values.latitude &&
      !values.longitude
    ) {
      isError = true;
      errorss.name = "Name is required";
      errorss.email = "Email is required";
      errorss.phone = "Phone is required";
      errorss.address = "Address is required";
      errorss.latitude = "Latitude is required";
      errorss.longitude = "Longitude is required";
    } else if (!values.name) {
      isError = true;
      errorss.name = "Name is required";
    } else if (!values.email) {
      isError = true;
      errorss.email = "Email is required";
    } else if (!values.phone) {
      isError = true;
      errorss.phone = "Phone is required";
    } else if (!values.address) {
      isError = true;
      errorss.address = "Address is required";
    } else if (!values.latitude) {
      isError = true;
      errorss.latitude = "Latitude is required";
    } else if (!values.longitude) {
      isError = true;
      errorss.longitude = "Longitude is required";
    }

    if (!isError) {
      setErrors(null);
      if (values.image) {
        createImage(values.image);
      } else {
        values.id
          ? updateDoctor({
            ...values,
            image: "",
            specialization: values.specialization,
            locations: location,
          })
          : createDoctor({
            ...values,
            image: "",
            specialization: values.specialization,
            locations: location,
          });
      }
    }
    setErrors(errorss);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
    getDoctor({
      offset: page * rowsPerPage,
      limit: rowsPerPage,
      orderBy: property,
      orderType: isAsc ? "desc" : "asc",
    });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    getDoctor({
      offset: newPage * rowsPerPage,
      limit: rowsPerPage,
      orderBy: orderBy,
      orderType: order,
      search: filtes,
      // specialization: searchAge
    });
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    getDoctor({
      offset: 0,
      limit: parseInt(event.target.value, 10),
      orderBy: orderBy,
      orderType: order,
      search: filtes,
    });
  };

  const confirmDelete = (id) => {
    confirmAlert({
      title: "Confirm to delete",
      message: "Are you sure to do this.",
      buttons: [
        {
          label: "Yes",
          onClick: () => deleteDoctor(id),
        },
        {
          label: "No",
        },
      ],
    });
  };

  return (
    <div className={classes.root}>
      <Loading loading={isLoading} />
      <Box className={classes.breadcrum}>
        <Breadcrumbs aria-label="breadcrumb">
          <Link to="/" className={classes.link}>
            <HomeIcon className={classes.icon} />
            Home
          </Link>
          <Typography color="textPrimary" className={classes.link}>
            Doctors
          </Typography>
        </Breadcrumbs>
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            setLocation([emptyLocation]);
            setSelected(emptyDoctor);
            setOpen(true);
          }}
          className={classes.createBtn}
        >
          <AddCircleIcon />
          Create Doctor
        </Button>
      </Box>
      <DoctorTable
        doctors={doctors}
        handleClickOpen={() => {
          setOpen(true);
        }}
        count={count}
        setSelected={setSelected}
        selected={selected}
        order={order}
        orderBy={orderBy}
        rowsPerPage={rowsPerPage}
        page={page}
        setFiltes={setFiltes}
        confirmDelete={confirmDelete}
        handleRequestSort={handleRequestSort}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        isLoggedIn={isLoggedIn}
        setAvailable={setAvailable}
        setLocation={setLocation}
      />
      <DoctorDialoge
        open={open}
        selected={selected}
        setSelected={setSelected}
        validate={validate}
        createImage={createImage}
        imageData={imageData}
        doctors={doctors}
        errors={errors}
        handleClose={() => {
          setSelected(emptyDoctor);
          setAvailable([]);
          setLocation([emptyLocation]);
          setErrors(null);
          setOpen(false);
        }}

        available={available}
        setAvailable={setAvailable}
        locations={location}
        addLocation={addLocation}
        deleteLocation={deleteLocation}
        updateLocation={updateLocation}
        specializations={specializations}
      />
    </div>
  );
}
