import * as actionType from "./guruActionType";

const initialState = {
  isError: false,
  isLoading: false,
  isSaved: false,
  result: [],
  samudhaygururesult: [],
  count: 0
};

export const guruReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionType.GET_GURU_LIST:
            return {
                ...state,
                isError: false,
                isLoading: true
            };
        case actionType.GET_GURU_LIST_FAILURE:
            return {
                ...state,
                isError: true,
                isLoading: false
            };
        case actionType.GET_GURU_LIST_SUCCESS:
            return {
                ...state,
                isError: false,
                isLoading: false,
                result: action.payload ? action.payload.data.result : [],
                count: action.payload ? action.payload.data.count : 0
            };

        case actionType.SAMUDHAY_GURU:
            return {
                ...state,
                isError: false,
                isLoading: true
            };
        case actionType.SAMUDHAY_GURU_FAILURE:
            return {
                ...state,
                isError: true,
                isLoading: false
            };
        case actionType.SAMUDHAY_GURU_SUCCESS:
            return {
                ...state,
                isError: false,
                isLoading: false,
                samudhaygururesult: action.payload ? action.payload.data : [],
            };
        case actionType.CREATE_GURU:
            return {
                ...state,
                isError: false,
                isLoading: true,
                isSaved: false
            };
        case actionType.CREATE_GURU_FAILURE:
            return {
                ...state,
                isError: true,
                isLoading: false
            };
        case actionType.CREATE_GURU_SUCCESS:
            return {
                ...state,
                isError: false,
                isLoading: false,
                isSaved: true
            };
        case actionType.UPDATE_GURU:
            return {
                ...state,
                isError: false,
                isLoading: true,
                isSaved: false
            };
        case actionType.UPDATE_GURU_FAILURE:
            return {
                ...state,
                isError: true,
                isLoading: false
            };
        case actionType.UPDATE_GURU_SUCCESS:
            return {
                ...state,
                isError: false,
                isLoading: false,
                isSaved: true
            };
        case actionType.DELETE_GURU:
            return {
                ...state,
                isError: false,
                isLoading: true,
                isSaved: false
            };
        case actionType.DELETE_GURU_FAILURE:
            return {
                ...state,
                isError: true,
                isLoading: false
            };
        case actionType.DELETE_GURU_SUCCESS:
            return {
                ...state,
                isError: false,
                isLoading: false,
                isSaved: true
            };
       default:
            return state
            
    }
}
