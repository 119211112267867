import * as actionTypes from "./labActionType";
import axios from 'axios';
import { checkError } from './../error';

const baseUrl = process.env.REACT_APP_API_URL;

export const getLabs = (data) => {
    const config = {  params: data };
    return (dispatch) => {
        dispatch(getLabsInit());
        axios.get(`${baseUrl}/lab/admin`, config)
            .then((response) => {
                dispatch(getLabsSuccess(response.data.data));
            })
            .catch(function (error) {
                checkError(error);
                dispatch(getLabsFailure());
            })
    };
};

export const getLabsInit = () => ({
    type: actionTypes.GET_LAB_LIST
});

export const getLabsFailure = data => ({
    type: actionTypes.GET_LAB_LIST_FAILURE
});

export const getLabsSuccess = data => ({
    type: actionTypes.GET_LAB_LIST_SUCCESS,
    payload: { data }
});

export const createLab = (data) => {
    const config = { headers: { Authorization: `Bearer ${localStorage.getItem("token")}` } };
    const params = new FormData();
    params.image = data.image ? params.append("image", data.image) : params.append("image", null);
    params.name = data.name ? params.append("name", data.name) : params.append("name", null);
    params.type = data.type ? params.append("type", data.type) : params.append("type", null);
    params.specialization = data.specialization ? params.append("specialization", data.specialization) : params.append("specialization", null);
    params.email = data.email ? params.append("email", data.email) : params.append("email", null);
    params.latitude = data.latitude ? params.append("latitude", data.latitude) : params.append("latitude", null);
    params.longitude = data.longitude ? params.append("longitude", data.longitude) : params.append("longitude", null);
    params.address = data.address ? params.append("address", data.address) : params.append("address", null);
    params.contact = data.contact ? params.append("contact", data.contact) : params.append("contact", null);
    params.altContact = data.altContact ? params.append("altContact", data.altContact) : params.append("altContact", null);
    params.City = data.City ? params.append("City", data.City) : params.append("City", null);
    params.District = data.District ? params.append("District", data.District) : params.append("District", null) ;
    params.State = data.State ? params.append("State", data.State) : params.append("State", null);
    params.zip = data.zip ? params.append("zip", data.zip) : params.append("zip", null);
    params.available = data.available ? params.append("available",data.available) : params.append("available",null);
    params.status = data.status ? params.append("status", data.status) : params.append("status", null);
    
    
    return (dispatch) => {
        dispatch(createLabInit());
        axios.post(`${baseUrl}/lab/store`, params, config)
            .then((response) => {
                dispatch(createLabSuccess(response.data.data));
            })
            .catch(function (error) {
                checkError(error);
                dispatch(createLabFailure());
            })
    };
};

export const createLabInit = () => ({
    type: actionTypes.CREATE_LAB
});

export const createLabFailure = data => ({
    type: actionTypes.CREATE_LAB_FAILURE
});

export const createLabSuccess = data => ({
    type: actionTypes.CREATE_LAB_SUCCESS,
    payload: { data }
});

export const updateLab = (data) => {
    const config = { headers: { Authorization: `Bearer ${localStorage.getItem("token")}` } };
    const params = new FormData();
    params.image = data.image ? params.append("image", data.image) : params.append("image", null);
    params.name = data.name ? params.append("name", data.name) : params.append("name", null);
    params.type = data.type ? params.append("type", data.type) : params.append("type", null);
    params.specialization = data.specialization ? params.append("specialization", data.specialization) : params.append("specialization", null);
    params.email = data.email ? params.append("email", data.email) : params.append("email", null);
    params.latitude = data.latitude ? params.append("latitude", data.latitude) : params.append("latitude", null);
    params.longitude = data.longitude ? params.append("longitude", data.longitude) : params.append("longitude", null);
    params.address = data.address ? params.append("address", data.address) : params.append("address", null);
    params.contact = data.contact ? params.append("contact", data.contact) : params.append("contact", null);
    params.altContact = data.altContact ? params.append("altContact", data.altContact) : params.append("altContact", null);
    params.City = data.City ? params.append("City", data.City) : params.append("City", null);
    params.District = data.district ? params.append("District", data.District) : params.append("District", null) ;
    params.State = data.State ? params.append("State", data.State) : params.append("State", null);
    params.zip = data.zip ? params.append("zip", data.zip) : params.append("zip", null);
    params.available = data.available ? params.append("available",data.available) : params.append("available",null);
    params.append("status", data.status);
    

    console.warn('params:- ',params);
    return (dispatch) => {
        dispatch(updateLabInit());
        axios.put(`${baseUrl}/lab/${data.id}`, params, config)
            .then((response) => {
                dispatch(updateLabSuccess(response.data.data));
            })
            .catch(function (error) {
                checkError(error);
                dispatch(updateLabFailure());
            })
    };
};

export const updateLabInit = () => ({
    type: actionTypes.UPDATE_LAB
});

export const updateLabFailure = data => ({
    type: actionTypes.UPDATE_LAB_FAILURE
});

export const updateLabSuccess = data => ({
    type: actionTypes.UPDATE_LAB_SUCCESS,
    payload: { data }
});

export const deleteLab = (data) => {
    const config = { headers: { Authorization: `Bearer ${localStorage.getItem("token")}` } };
    return (dispatch) => {
        dispatch(deleteLabInit());
        axios.delete(`${baseUrl}/lab/${data}`, config)
            .then((response) => {
                dispatch(deleteLabSuccess(response.data.data));
            })
            .catch(function (error) {
                checkError(error);
                dispatch(deleteLabFailure());
            })
    };
};

export const deleteLabInit = () => ({
    type: actionTypes.DELETE_LAB
});

export const deleteLabFailure = data => ({
    type: actionTypes.DELETE_LAB_FAILURE
});

export const deleteLabSuccess = data => ({
    type: actionTypes.DELETE_LAB_SUCCESS,
    payload: { data }
});



export const getTimezone = () => {
    
    return (dispatch) => {
        dispatch(getTimezoneInit());
        axios.get(`https://api.ipgeolocation.io/ipgeo?apiKey=0a85ab484e4a4f2caf453d75b2e13408`)
            .then((response) => {
                dispatch(getTimezoneSuccess(response.data.time_zone));
            })
            .catch(function (error) {
                checkError(error);
                dispatch(getTimezoneFailure());
            })
    };
};

export const getTimezoneInit = () => ({
    type: actionTypes.GET_TIMEZONE
});

export const getTimezoneFailure = data => ({
    type: actionTypes.GET_TIMEZONE_FAILURE
});

export const getTimezoneSuccess = data => ({
    type: actionTypes.GET_TIMEZONE_SUCCESS,
    payload: { data }
});
