import * as actionType from "./specializationActionType";

const initialState = {
  isError: false,
  isLoading: false,
  isSaved: false,
  specializations: [],
  count: 0
};

export const specializationReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionType.GET_SPECIALIZATION:
            return {
                ...state,
                isError: false,
                isLoading: true
            };
        case actionType.GET_SPECIALIZATION_FAILURE:
            return {
                ...state,
                isError: true,
                isLoading: false
            };
        case actionType.GET_SPECIALIZATION_SUCCESS:
            return {
                ...state,
                isError: false,
                isLoading: false,
                specializations: action.payload ? action.payload.data.rows : [],
                count: action.payload ? action.payload.data.count : 0
            };
        case actionType.CREATE_SPECIALIZATION:
            return {
                ...state,
                isError: false,
                isLoading: true,
                isSaved: false
            };
        case actionType.CREATE_SPECIALIZATION_FAILURE:
            return {
                ...state,
                isError: true,
                isLoading: false
            };
        case actionType.CREATE_SPECIALIZATION_SUCCESS:
            return {
                ...state,
                isError: false,
                isLoading: false,
                isSaved: true
            };
        case actionType.UPDATE_SPECIALIZATION:
            return {
                ...state,
                isError: false,
                isLoading: true,
                isSaved: false
            };
        case actionType.UPDATE_SPECIALIZATION_FAILURE:
            return {
                ...state,
                isError: true,
                isLoading: false
            };
        case actionType.UPDATE_SPECIALIZATION_SUCCESS:
            return {
                ...state,
                isError: false,
                isLoading: false,
                isSaved: true
            };
        case actionType.DELETE_SPECIALIZATION:
            return {
                ...state,
                isError: false,
                isLoading: true,
                isSaved: false
            };
        case actionType.DELETE_SPECIALIZATION_FAILURE:
            return {
                ...state,
                isError: true,
                isLoading: false
            };
        case actionType.DELETE_SPECIALIZATION_SUCCESS:
            return {
                ...state,
                isError: false,
                isLoading: false,
                isSaved: true
            };
       default:
            return state
            
    }
}
