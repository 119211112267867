import React, { useEffect, useState, useRef } from "react";
import { makeStyles } from "@mui/styles";
import Loading from "../../layout/Loading";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import HomeIcon from "@mui/icons-material/Home";
import { Link } from "react-router-dom";
import {
  Button,
  Box,
  TextField,
  Typography,
  Grid,
  Avatar,
  Input,
  InputAdornment,
} from "@mui/material";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { confirmAlert } from "react-confirm-alert";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    background: "teal",
    color: "#fff",
    fontSize: "24px",
  },
  dblink: {
    textDecoration: "none",
  },
  link: {
    display: "flex",
    textDecoration: "none",
    color: "#fff !important",
  },
  icon: {
    marginRight: theme.spacing(0.5),
    width: 20,
    height: 20,
  },
  breadcrum: {
    backgroundColor: theme.palette.primary.main,
    padding: "20px 40px",
    color: "#fff !important",
    marginBottom: "15px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
      alignItems: "flex-start",
      padding: "13px 30px",
    },
  },
  createBtn: {
    background: "#000 !important",

    "&:hover": {
      backgroundColor: "transparent !important",
    },

    [theme.breakpoints.down("md")]: {
      marginTop: "15px",
      paddingLeft: "0px !important",
      paddingBottom: "0px !important",
    },
  },
}));

export function Profile (props) {
  const { ProfileUpdate } = props;
  const classes = useStyles();
  const [errors, setErrors] = useState({});
  const [profiledata, setProfileData] = useState({
    image: null,
    firstName: "",
    lastName: "",
    email: "",
    phoneNo: "",
  });
  const inputRef = useRef(null);

  const [image, setImage] = useState(null);
  const [file, setFile] = useState(null);

  const handleSubmit = (e) => {
    e.preventDefault();
    ProfileUpdate(1, profiledata);
  };

  const handleImg = () => {
    inputRef.current.click();
  };

  const handleChangeImg = (e) => {
    setFile(URL.createObjectURL(e.target.files[0]));

    // setProfileData({
    //         ...Profile,
    //         image: URL.createObjectURL(e.target.files[0]),
    //       });
  };



  return (
    <div className={classes.root}>
      <Loading />
      <Box className={classes.breadcrum}>
        <Breadcrumbs aria-label="breadcrumb">
          <Link to="/" className={classes.link}>
            <HomeIcon className={classes.icon} />
            Home
          </Link>
          <Typography color="textPrimary" className={classes.link}>
            Profile
          </Typography>
        </Breadcrumbs>
      </Box>
      <Box mt={6}>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12} display="flex" alignItems="end">
              {file ? <img src={file} alt="please select img" width={200} height={150} color="#000000" /> : <Avatar />}

              <Button
                variant="contained"
                className="select_btn"
                onClick={handleImg}
                sx={{ ml: 2 }}
              >
                Select Image
                <input
                  type="file"
                  ref={inputRef}
                  style={{ display: "none" }}
                  onChange={handleChangeImg}
                />
              </Button>
            </Grid>
            <Grid item sm={12} md={6}>
              <TextField
                placeholder="First Name*"
                variant="outlined"
                fullWidth
                value={profiledata.firstName}
                onChange={(event) =>
                  setProfileData({
                    ...profiledata,
                    firstName: event.target.value,
                  })
                }
              />
            </Grid>
            <Grid item sm={12} md={6}>
              <TextField
                placeholder="Last Name*"
                variant="outlined"
                fullWidth
                value={profiledata.lastName}
                onChange={(event) =>
                  setProfileData({
                    ...profiledata,
                    lastName: event.target.value,
                  })
                }
              />
            </Grid>
            <Grid item sm={12} md={6}>
              <TextField
                placeholder="Email*"
                variant="outlined"
                fullWidth
                value={profiledata.email}
                // required
                onChange={(event) =>
                  setProfileData({
                    ...profiledata,
                    email: event.target.value,
                  })
                }
              />
            </Grid>
            <Grid item sm={12} md={6}>
              <TextField
                placeholder="Phone Number*"
                variant="outlined"
                fullWidth
                value={profiledata.phoneNo}
                onChange={(event) =>
                  setProfileData({
                    ...profiledata,
                    phoneNo: event.target.value,
                  })
                }
              />
            </Grid>
            <Grid xs={12}>
              <Button
                variant="contained"
                type="submit"
                sx={{
                  ml: 2,
                  mt: 2,
                  backgroundColor: "#FF8427",
                  "&:hover": {
                    backgroundColor: "#FF8427",
                  },
                }}
              >
                Submit
              </Button>
            </Grid>
          </Grid>
        </form>
      </Box>
    </div>
  );
}
