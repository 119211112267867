export const GET_SPECIALIZATION = "GET_SPECIALIZATION";
export const GET_SPECIALIZATION_FAILURE = "GET_SPECIALIZATION_FAILURE";
export const GET_SPECIALIZATION_SUCCESS = "GET_SPECIALIZATION_SUCCESS";

export const CREATE_SPECIALIZATION = "CREATE_SPECIALIZATION";
export const CREATE_SPECIALIZATION_FAILURE = "CREATE_SPECIALIZATION_FAILURE";
export const CREATE_SPECIALIZATION_SUCCESS = "CREATE_SPECIALIZATION_SUCCESS";

export const UPDATE_SPECIALIZATION = "UPDATE_SPECIALIZATION";
export const UPDATE_SPECIALIZATION_FAILURE = "UPDATE_SPECIALIZATION_FAILURE";
export const UPDATE_SPECIALIZATION_SUCCESS = "UPDATE_SPECIALIZATION_SUCCESS";

export const DELETE_SPECIALIZATION = "DELETE_SPECIALIZATION";
export const DELETE_SPECIALIZATION_FAILURE = "DELETE_SPECIALIZATION_FAILURE";
export const DELETE_SPECIALIZATION_SUCCESS = "DELETE_SPECIALIZATION_SUCCESS";
