export const GET_VITAL_LIST = "GET_VITAL_LIST";
export const GET_VITAL_LIST_FAILURE = "GET_VITAL_LIST_FAILURE";
export const GET_VITAL_LIST_SUCCESS = "GET_VITAL_LIST_SUCCESS";

export const CREATE_VITAL = "CREATE_VITAL";
export const CREATE_VITAL_FAILURE = "CREATE_VITAL_FAILURE";
export const CREATE_VITAL_SUCCESS = "CREATE_VITAL_SUCCESS";

export const UPDATE_VITAL = "UPDATE_VITAL";
export const UPDATE_VITAL_FAILURE = "UPDATE_VITAL_FAILURE";
export const UPDATE_VITAL_SUCCESS = "UPDATE_VITAL_SUCCESS";

export const DELETE_VITAL = "DELETE_VITAL";
export const DELETE_VITAL_FAILURE = "DELETE_VITAL_FAILURE";
export const DELETE_VITAL_SUCCESS = "DELETE_VITAL_SUCCESS";
