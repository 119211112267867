import React, { useEffect, useState, useRef } from "react";

import InputAdornment from "@mui/material/InputAdornment";
import Dialog from "@mui/material/Dialog";
import { Typography, Select, FormControl, Grid, Paper, MenuItem, InputLabel, TextField, Box, Avatar, Input, Button, Autocomplete } from "@mui/material";
import DialogContent from "@mui/material/DialogContent";
import ClearIcon from "@mui/icons-material/Clear";
import { makeStyles } from "@mui/styles";
import { now } from "moment";

const useStyles = makeStyles(() => ({
  centerButtonDiv: {
    display: "flex !important",
    justifyContent: "center !important",
    marginTop: "15px !important"
  },
  centerButton: {
    textTransform: "capitalize !important",
    minWidth: "120px !important",
    backgroundColor: "#FF8427 !important",
    color: "rgb(255, 255, 255) !important",
    borderRadius: "20px !important",

  }

}));

export default function RequestAnother (props) {
  
  const { handleClose, openRequestAnother, selected, setSelected, doctors, caregivers, validate } = props;

  const classes = useStyles();
  const [doctorConsult, setdoctorConsult] = useState([{}]);
  const [caregiverConsult, setcaregiverConsult] = useState([{}]);
  const [assignBy, setAssignBy] = useState('');
  const [assignTime, setassignTime] = useState('');

  const currentTimestamp = Date.now();
  const options = { timeZone: 'Asia/Kolkata' };
  const currentDateTime = new Date(currentTimestamp).toLocaleString(undefined, options);

  const handleOptionChange = (event, newValue) => {
    setSelected({
      ...selected,
      DoctorId: newValue?.value,
      status: 0,
      secondOpinionStatus : 0,
      assignBy: assignBy,
      assignTime: assignTime,
    })
    console.warn('handleOptionChange  selected:- ',selected);
    debugger;
  };

  const careGiverhandleOption = (event, newValue) => {
    setSelected({
      ...selected,
      CareGiverId: newValue?.value,
      assignBy: assignBy,
      assignTime: assignTime,
      status: 0,
      secondOpinionStatus : 0
    })
    console.warn('caregiver selected:- ', selected);
  };


  useEffect(() => {
    if (doctors) {
      let doctorArr = [];
      doctors.rows.map((r) => {
        doctorArr.push({ label: r.name, value: r.id })
      })
      setdoctorConsult(doctorArr)
    }
    setAssignBy('Admin');
    setassignTime(currentDateTime);
  }, [doctors])

  useEffect(() => {
    if (caregivers) {
      let caregiversArr = [];
      caregivers.rows.map((r) => {
        caregiversArr.push({ label: r.name, value: r.id })
      })
      setcaregiverConsult(caregiversArr)
    }
    setAssignBy('Admin');
    setassignTime(currentDateTime);
  }, [caregivers])

  return (
    <div>
      <Dialog
        open={openRequestAnother}
        onClose={handleClose}
        // scroll={scroll}

        keepMounted
        aria-labelledby="scroll-dialog-title"
        aria-describedby="alert-dialog-slide-description"
        maxWidth="md"
        fullWidth={true}
      >
        <DialogContent>
          <ClearIcon
            onClick={handleClose}
            style={{ width: "25px", height: "25px", float: "right" }}
          />
          <div
            style={{
              marginTop: "10px",
              marginBottom: "20px",
            }}
          >
            <Typography
              style={{
                fontSize: "30px",
                fontWeight: 500,
                color: "rgba(0,0,0,.6)",
                marginBottom: "2rem",
              }}
            >
              Request To Another
            </Typography>


            <form
              noValidate
              id="form"
              onSubmit={(event) => {
                event.preventDefault();
                validate(selected)
              }}
            >
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <FormControl fullWidth variant="standard" required>
                    <Autocomplete
                      options={doctorConsult}
                      getOptionLabel={(option) => option.label}
                      onChange={handleOptionChange}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Select doctor"
                          variant="outlined"
                        />
                      )}
                    />
                  </FormControl>
                </Grid>

                <Grid item xs={12} md={6}>
                  <FormControl fullWidth variant="standard" required>
                    <Autocomplete
                      options={caregiverConsult}
                      getOptionLabel={(option) => option.label}
                      onChange={careGiverhandleOption}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Select caregiver"
                          variant="outlined"
                        />
                      )}
                    />
                  </FormControl>
                </Grid>

                <Grid item xs={12} md={12} className={classes.centerButtonDiv}>
                  <Button type="submit" variant="contained" className={classes.centerButton}>
                    Submit
                  </Button>
                </Grid>






              </Grid>

            </form>

          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
}
