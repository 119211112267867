import React, { useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import PharmacyTable from './component/pharmacy-table.component';
import PharmacyDialoge from './component/pharmacy-dialoge';
import Loading from '../../layout/Loading';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import HomeIcon from '@mui/icons-material/Home';
import { Link } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import { Button, Box } from '@mui/material';

import AddCircleIcon from '@mui/icons-material/AddCircle';
import { confirmAlert } from "react-confirm-alert";
import axios from 'axios';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    background: 'teal',
    color: '#fff',
    fontSize: '24px'
  },
  dblink: {
    textDecoration: 'none'
  },
  link: {
    display: 'flex',
    textDecoration: 'none',
    color: '#fff !important'
  },
  icon: {
    marginRight: theme.spacing(0.5),
    width: 20,
    height: 20,
  },

  breadcrum: {
    backgroundColor: theme.palette.primary.main,
    padding: "20px 40px",
    color: "#fff !important",
    marginBottom: "15px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
      alignItems: "flex-start",
      padding: "13px 30px",
    },
  },
  createBtn: {
    background: "#000 !important",

    "&:hover": {
      backgroundColor: "transparent !important",
    },

    [theme.breakpoints.down("md")]: {
      marginTop: "15px",
      paddingLeft: "0px !important",
      paddingBottom: "0px !important",
    },
  },
}));

export function PharmacyPage (props) {
  const {
    getPharmacies,
    pharmacies,
    createPharmacy,
    updatePharmacy,
    deletePharmacy,
    count,
    isLoading,
    isSaved,
    isLoggedIn,
    getTimezone,
    timezone
  } = props;

  const emptyCustom = {
    name: "",
    start: "",
    close: ""
  };

  const emptyPharmacy = {
    name: "",
    specialization: "",
    email: "",
    latitude: "",
    longitude: "",
    address: "",
    contact: "",
    altContact: "",
    City: "",
    District: "",
    State: "",
    zip: "",
    img: null,
    status: 1
  }
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [selected, setSelected] = useState(emptyPharmacy);
  const [errors, setErrors] = React.useState({});
  const [order, setOrder] = React.useState('desc');
  const [orderBy, setOrderBy] = React.useState('id');
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [filtes, setFiltes] = React.useState("");
  const [custom, setCustom] = useState(Array.from({ length: 7 }, () => emptyCustom));
  const [newcustom, setNewCustom] = useState(Array.from({ length: 7 }, () => emptyCustom));
  const [isChecked, setIsChecked] = React.useState(false);
  const [timeZone, setTimeZone] = React.useState({});
  const daysOfWeek = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
  const [newImage, setNewImage] = useState(false);


  /* Day Time */
  const addCustom = () => {
    setCustom(custom.concat([emptyCustom]));
  };
  const deleteCustom = (index) => {
    setCustom((custom) =>
      custom.filter((x, i) => i !== index)
    );
  };
  const updateCustom = async (i, v, t) => {
    setCustom(custom.map((item, index) => i == index ? { ...item, [t]: v, name: 'Monday' } : item))
  }

  useEffect(() => {

    getPharmacies({
      offset: page * rowsPerPage,
      limit: rowsPerPage,
      orderBy: orderBy,
      orderType: order,
      search: filtes
    });
  }, []);

  useEffect(() => {
    if (isSaved) {
      setOpen(false);
      setSelected(emptyPharmacy);
      setCustom(emptyCustom);
      getPharmacies({
        offset: page * rowsPerPage,
        limit: rowsPerPage,
        orderBy: orderBy,
        orderType: order,
        search: filtes
      });
    }
  }, [isSaved]);
  useEffect(() => {
    if (filtes.length > 1) {
      getPharmacies({
        offset: page * rowsPerPage,
        limit: rowsPerPage,
        orderBy: orderBy,
        orderType: order,
        search: filtes
      });
    } else {
      getPharmacies({
        offset: page * rowsPerPage,
        limit: rowsPerPage,
        orderBy: orderBy,
        orderType: order
      });
    }
  }, [filtes]);

  async function convertIntoAvail (y) {
    return await Promise.all(y.map(x => {
      return {
        name: x.name,
        time: `${x.start}-${x.close}`
      }
    }))
  }

  async function convertIntoCustom (z) {
    const s = await Promise.all(z.map(a => {
      let d = a.time.split('-');
      return {
        name: a.name,
        start: d[0],
        close: d[1]
      }
    }));
    setCustom(s);
  }

  const validate = async (values) => {
    const available = await convertIntoAvail(custom);
    let errorss = {};
    let isError = false;
    const regex = /\S+@\S+\.\S+/;



    if (!values.name && !values.latitude && !values.longitude) {
      isError = true;
      errorss.name = "Name is required";
      errorss.latitude = "Latitude is required";
      errorss.longitude = "Longitude is required";
      errorss.day = "day is required";
    } else if (!values.name) {
      isError = true;
      errorss.name = "Name is required";
    } else if (!values.latitude) {
      isError = true;
      errorss.latitude = "Latitude is required";
    } else if (!values.longitude) {
      isError = true;
      errorss.longitude = "Longitude is required";
    }
    if (!isError) {
      setErrors(null);
      values.id ? updatePharmacy({ ...values, available: JSON.stringify(available) }) : createPharmacy({ ...values, available: JSON.stringify(available) });
    }
    setErrors(errorss);
  };

  const confirmDelete = (id) => {
    confirmAlert({
      title: "Confirm to delete",
      message: "Are you sure to do this.",
      buttons: [
        {
          label: "Yes",
          onClick: () => deletePharmacy(id),
        },
        {
          label: "No",
        },
      ],
    });
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
    getPharmacies({
      offset: page * rowsPerPage,
      limit: rowsPerPage,
      orderBy: property,
      orderType: isAsc ? 'desc' : 'asc',
      search: filtes
    });
  };

  const handleChangePage = (event, newPage) => {

    setPage(newPage);
    getPharmacies({
      offset: newPage * rowsPerPage,
      limit: rowsPerPage,
      orderBy: orderBy,
      orderType: order,
      search: filtes
    });
  };

  const handleChangeRowsPerPage = (event) => {

    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    getPharmacies({
      offset: 0,
      limit: parseInt(event.target.value, 10),
      orderBy: orderBy,
      orderType: order,
      search: filtes
    });
  };

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
    console.warn('isChecked:- ', isChecked)
  };
  const fillAllDays = (sourceRowIndex) => {
    const sourceValue = custom[sourceRowIndex];

    const updatedRowData = custom.map((value, index) =>
      index !== sourceRowIndex ? { ...sourceValue, name: daysOfWeek[index] } : value
    );

    if (!isChecked) {
      setCustom(updatedRowData)
    } else {
      const clearedData = updatedRowData.map((item, key) => ({
        name: key > 0 ? '' : item.name,
        start: key > 0 ? '' : item.start,
        close: key > 0 ? '' : item.close,
      }));
      setCustom(clearedData)
      console.warn('else custom:- ', custom);
    }
  }

  useEffect(() => {
    if (custom) {
      getTimezone();
      setTimeZone(timezone)
    }
  }, [custom])

  return (
    <div className={classes.root}>
      <Loading loading={isLoading} />
      <Box className={classes.breadcrum}>
        <Breadcrumbs aria-label="breadcrumb" >
          <Link to="/" className={classes.link}>
            <HomeIcon className={classes.icon} />
            Home
          </Link>
          <Typography color="textPrimary" className={classes.link}>
            Pharmacy
          </Typography>
        </Breadcrumbs>
        <Button
          variant="contained"
          color='primary'
          onClick={() => {
            setOpen(true);
          }}

          className={classes.createBtn}
        >
          <AddCircleIcon />&nbsp;
          Create Pharmacy
        </Button>
      </Box>
      <PharmacyTable
        pharmacies={pharmacies}
        handleClickOpen={() => {
          setOpen(true);
        }}
        count={count}
        setNewImage={setNewImage}
        newImage={newImage}
        setSelected={setSelected}
        selected={selected}
        order={order}
        orderBy={orderBy}
        rowsPerPage={rowsPerPage}
        page={page}
        confirmDelete={confirmDelete}
        handleRequestSort={handleRequestSort}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        setFiltes={setFiltes}
        isLoggedIn={isLoggedIn}
        convertIntoCustom={convertIntoCustom}
      />
      <PharmacyDialoge
        setNewImage={setNewImage}
        newImage={newImage}
        open={open}
        timeZone={timeZone}
        selected={selected}
        setSelected={setSelected}
        validate={validate}
        handleCheckboxChange={handleCheckboxChange}
        isChecked={isChecked}
        fillAllDays={fillAllDays}
        errors={errors}
        handleClose={() => {
          setSelected(emptyPharmacy);
          setCustom([emptyCustom]);
          setErrors(null);
          setOpen(false);
        }}
        custom={custom}
        addCustom={addCustom}
        deleteCustom={deleteCustom}
        updateCustom={updateCustom}
      />
    </div>
  );
}