import * as actionTypes from "./shramansActionType";
import axios from 'axios';
import { checkError } from './../error';

const baseUrl = process.env.REACT_APP_API_URL;

export const getShramans = (data) => {
    const config = { headers: { Authorization: `Bearer ${localStorage.getItem("token")}` }, params: data };
      return (dispatch) => {
        dispatch(getShramansInit());
        axios.get(`${baseUrl}/sharman/admin`, config)
        .then((response)=>{
            dispatch(getShramansSuccess(response.data.data));
        })
        .catch(function (error) {
            checkError(error);
            dispatch(getShramansFailure());
        })
    };
};

export const getShramansInit = () => ({
    type: actionTypes.GET_SHRAMANS_LIST
});

export const getShramansFailure = data => ({
    type: actionTypes.GET_SHRAMANS_LIST_FAILURE
});

export const getShramansSuccess = data => ({
    type: actionTypes.GET_SHRAMANS_LIST_SUCCESS,
    payload: { data }
});

export const createShramans = (data) => {
    const params = new FormData();
    params.image = data.image ? params.append("image", data.image) : params.append("image", null);
    params.prefix = data.prefix ? params.append("prefix", data.prefix) : params.append("prefix", null);
    params.name = data.name ? params.append("name", data.name) : params.append("name", null);
    params.email = data.email ? params.append("email", data.email) : params.append("email", null);
    params.phone = data.phone ? params.append("phone", data.phone) : params.append("phone", null);
    params.dob = data.dob ? params.append("dob", data.dob) : params.append("dob", null);
    params.gender = data.gender ? params.append("gender", data.gender) : params.append("gender", null);
    params.address = data.address ? params.append("address", data.address) : params.append("address", null);
    params.latitude = data.latitude ? params.append("latitude", data.latitude) : params.append("latitude", null);
    params.longitude = data.longitude ? params.append("longitude", data.longitude) : params.append("longitude", null);
    params.age = data.age ? params.append("age", data.age) : params.append("age", null);
    params.CareGiverId = data.CareGiverId ? params.append("CareGiverId", data.CareGiverId) : params.append("CareGiverId", null);
    params.City = data.City ? params.append("City", data.City) : params.append("City", null);
    params.District =  data.District ? params.append("District", data.District) : params.append("District", null);
    params.State = data.State ? params.append("State", data.State) : params.append("State", null);
    params.zip = data.zip ? params.append("zip", data.zip) : params.append("zip", null);
    params.GatchaId = data.GatchaId ? params.append("GatchaId", data.GatchaId) : params.append("GatchaId", null);
    params.SamudhayId = data.SamudhayId ? params.append("SamudhayId", data.SamudhayId) : params.append("SamudhayId", null);
    params.GuruId = data.GuruId ? params.append("GuruId", data.GuruId) : params.append("GuruId", null);

    const config = { headers: { Authorization: `Bearer ${localStorage.getItem("token")}` } };
      return (dispatch) => {
        dispatch(createShramansInit());
        axios.post(`${baseUrl}/sharman/store-by-admin`, params, config)
        .then((response)=>{
            dispatch(createShramansSuccess(response.data.data));
        })
        .catch(function (error) {
            checkError(error);
            dispatch(createShramansFailure());
        })
    };
};

export const createShramansInit = () => ({
    type: actionTypes.CREATE_SHRAMANS
});

export const createShramansFailure = data => ({
    type: actionTypes.CREATE_SHRAMANS_FAILURE
});

export const createShramansSuccess = data => ({
    type: actionTypes.CREATE_SHRAMANS_SUCCESS,
    payload: { data }
});

export const updateShramans = (data) => {
    console.warn('updateShramans city callling....',data);
    const config = { headers: { Authorization: `Bearer ${localStorage.getItem("token")}` } };
    const params = new FormData();
    params.image = data.image ? params.append("image", data.image) : params.append("image", null);
    params.prefix = data.prefix ? params.append("prefix", data.prefix) : params.append("prefix", null);
    params.name = data.name ? params.append("name", data.name) : params.append("name", null);
    params.email = data.email ? params.append("email", data.email) : params.append("email", null);
    params.phone = data.phone ? params.append("phone", data.phone) : params.append("phone", null);
    params.dob = data.dob ? params.append("dob", data.dob) : params.append("dob", null);
    params.gender = data.gender ? params.append("gender", data.gender) : params.append("gender", null);
    params.address = data.address ? params.append("address", data.address) : params.append("address", null);
    params.latitude = data.latitude ? params.append("latitude", data.latitude) : params.append("latitude", null);
    params.longitude = data.longitude ? params.append("longitude", data.longitude) : params.append("longitude", null);
    params.age = data.age ? params.append("age", data.age) : params.append("age", null);
    params.CareGiverId = data.CareGiverId ? params.append("CareGiverId", data.CareGiverId) : params.append("CareGiverId", null);
    params.City = data.City ? params.append("City", data.City) : params.append("City", null);
    params.District =  data.District ? params.append("District", data.District) : params.append("District", null);
    params.State = data.State ? params.append("State", data.State) : params.append("State", null);
    params.zip = data.zip ? params.append("zip", data.zip) : params.append("zip", null);
    params.GatchaId = data.GatchaId ? params.append("GatchaId", data.GatchaId) : params.append("GatchaId", null);
    params.SamudhayId = data.SamudhayId ? params.append("SamudhayId", data.SamudhayId) : params.append("SamudhayId", null);
    params.GuruId = data.GuruId ? params.append("GuruId", data.GuruId) : params.append("GuruId", null);
    console.warn('params:- ',params);
    return (dispatch) => {
        dispatch(updateShramansInit());
        axios.put(`${baseUrl}/sharman/update-by-admin/${data.id}`, params, config)
        .then((response)=>{
            dispatch(updateShramansSuccess(response.data.data));
        })
        .catch(function (error) {
            checkError(error);
            dispatch(updateShramansFailure());
        })
    };
};

export const updateShramansInit = () => ({
    type: actionTypes.UPDATE_SHRAMANS
});

export const updateShramansFailure = data => ({
    type: actionTypes.UPDATE_SHRAMANS_FAILURE
});

export const updateShramansSuccess = data => ({
    type: actionTypes.UPDATE_SHRAMANS_SUCCESS,
    payload: { data }
});

export const deleteShramans = (data) => {
    const config = { headers: { Authorization: `Bearer ${localStorage.getItem("token")}` } };
      return (dispatch) => {
        dispatch(deleteShramansInit());
        axios.delete(`${baseUrl}/sharman/${data}`, config)
        .then((response)=>{
            dispatch(deleteShramansSuccess(response.data.data));
        })
        .catch(function (error) {
            checkError(error);
            dispatch(deleteShramansFailure());
        })
    };
};

export const deleteShramansInit = () => ({
    type: actionTypes.DELETE_SHRAMANS
});

export const deleteShramansFailure = data => ({
    type: actionTypes.DELETE_SHRAMANS_FAILURE
});

export const deleteShramansSuccess = data => ({
    type: actionTypes.DELETE_SHRAMANS_SUCCESS,
    payload: { data }
});
