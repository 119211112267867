import { CaseReportPage } from './caseReport.component';
import { connect } from 'react-redux';
// import { getCaregiver, createCaregiver, editCaregiver, updateCaregiver, deleteCaregiver } from "../../api/caregiver/caregiverAction";

// const mapDispatchToProps = {
//     getCaregiver,
//     createCaregiver,
//     editCaregiver,
//     updateCaregiver,
//     deleteCaregiver
// };

// const mapStateToProps = state => ({
//     error: state.caregiverPage.isError,
//     isLoading: state.caregiverPage.isLoading,
//     isSaving: state.caregiverPage.isSaving,
//     caregivers: state.caregiverPage.caregivers,
//     user: state.caregiverPage.user,
// });

// export const CaregiverContainer = connect(mapStateToProps, mapDispatchToProps)(CaregiverPage);
export const CaseReportContainer = connect(null)(CaseReportPage);