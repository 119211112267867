import * as actionType from "./medicalActionType";

const initialState = {
  isError: false,
  isLoading: false,
  isSaved: false,
  medicals: [],
  count: 0
};

export const medicalReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionType.GET_MEDICAL_LIST:
            return {
                ...state,
                isError: false,
                isLoading: true
            };
        case actionType.GET_MEDICAL_LIST_FAILURE:
            return {
                ...state,
                isError: true,
                isLoading: false
            };
        case actionType.GET_MEDICAL_LIST_SUCCESS:
            return {
                ...state,
                isError: false,
                isLoading: false,
                medicals: action.payload ? action.payload.data.rows : [],
                count: action.payload ? action.payload.data.count : 0
            };
        case actionType.CREATE_MEDICAL:
            return {
                ...state,
                isError: false,
                isLoading: true,
                isSaved: false
            };
        case actionType.CREATE_MEDICAL_FAILURE:
            return {
                ...state,
                isError: true,
                isLoading: false
            };
        case actionType.CREATE_MEDICAL_SUCCESS:
            return {
                ...state,
                isError: false,
                isLoading: false,
                isSaved: true
            };
        case actionType.UPDATE_MEDICAL:
            return {
                ...state,
                isError: false,
                isLoading: true,
                isSaved: false
            };
        case actionType.UPDATE_MEDICAL_FAILURE:
            return {
                ...state,
                isError: true,
                isLoading: false
            };
        case actionType.UPDATE_MEDICAL_SUCCESS:
            return {
                ...state,
                isError: false,
                isLoading: false,
                isSaved: true
            };
        case actionType.DELETE_MEDICAL:
            return {
                ...state,
                isError: false,
                isLoading: true,
                isSaved: false
            };
        case actionType.DELETE_MEDICAL_FAILURE:
            return {
                ...state,
                isError: true,
                isLoading: false
            };
        case actionType.DELETE_MEDICAL_SUCCESS:
            return {
                ...state,
                isError: false,
                isLoading: false,
                isSaved: true
            };
       default:
            return state
            
    }
}
