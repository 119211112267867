export const GET_SAMUDHAY_LIST = "GET_SAMUDHAY_LIST";
export const GET_SAMUDHAY_LIST_FAILURE = "GET_SAMUDHAY_LIST_FAILURE";
export const GET_SAMUDHAY_LIST_SUCCESS = "GET_SAMUDHAY_LIST_SUCCESS";

export const CREATE_SAMUDHAY = "CREATE_SAMUDHAY";
export const CREATE_SAMUDHAY_FAILURE = "CREATE_SAMUDHAY_FAILURE";
export const CREATE_SAMUDHAY_SUCCESS = "CREATE_SAMUDHAY_SUCCESS";

export const UPDATE_SAMUDHAY = "UPDATE_SAMUDHAY";
export const UPDATE_SAMUDHAY_FAILURE = "UPDATE_SAMUDHAY_FAILURE";
export const UPDATE_SAMUDHAY_SUCCESS = "UPDATE_SAMUDHAY_SUCCESS";

export const DELETE_SAMUDHAY = "DELETE_SAMUDHAY";
export const DELETE_SAMUDHAY_FAILURE = "DELETE_SAMUDHAY_FAILURE";
export const DELETE_SAMUDHAY_SUCCESS = "DELETE_SAMUDHAY_SUCCESS";

export const GATCHA_SAMUDHAY = "GATCHA_SAMUDHAY";
export const GATCHA_SAMUDHAY_FAILURE = "GATCHA_SAMUDHAY_FAILURE";
export const GATCHA_SAMUDHAY_SUCCESS = "GATCHA_SAMUDHAY_SUCCESS";




