import * as actionType from "./medicineActionType";

const initialState = {
  isError: false,
  isLoading: false,
  isSaved: false,
  medicines: [],
  count: 0
};

export const medicineReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionType.GET_MEDICINE_LIST:
            return {
                ...state,
                isError: false,
                isLoading: true
            };
        case actionType.GET_MEDICINE_LIST_FAILURE:
            return {
                ...state,
                isError: true,
                isLoading: false
            };
        case actionType.GET_MEDICINE_LIST_SUCCESS:
            return {
                ...state,
                isError: false,
                isLoading: false,
                medicines: action.payload ? action.payload.data.rows : [],
                count: action.payload ? action.payload.data.count : 0
            };
        case actionType.CREATE_MEDICINE:
            return {
                ...state,
                isError: false,
                isLoading: true,
                isSaved: false
            };
        case actionType.CREATE_MEDICINE_FAILURE:
            return {
                ...state,
                isError: true,
                isLoading: false
            };
        case actionType.CREATE_MEDICINE_SUCCESS:
            return {
                ...state,
                isError: false,
                isLoading: false,
                isSaved: true
            };
        case actionType.UPDATE_MEDICINE:
            return {
                ...state,
                isError: false,
                isLoading: true,
                isSaved: false
            };
        case actionType.UPDATE_MEDICINE_FAILURE:
            return {
                ...state,
                isError: true,
                isLoading: false
            };
        case actionType.UPDATE_MEDICINE_SUCCESS:
            return {
                ...state,
                isError: false,
                isLoading: false,
                isSaved: true
            };
        case actionType.DELETE_MEDICINE:
            return {
                ...state,
                isError: false,
                isLoading: true,
                isSaved: false
            };
        case actionType.DELETE_MEDICINE_FAILURE:
            return {
                ...state,
                isError: true,
                isLoading: false
            };
        case actionType.DELETE_MEDICINE_SUCCESS:
            return {
                ...state,
                isError: false,
                isLoading: false,
                isSaved: true
            };
       default:
            return state
            
    }
}
