import * as actionTypes from "./requestActionType";
import axios from "axios";
import { toast } from "react-toastify";
import Moment from "moment";
import { checkError } from './../error';
const baseUrl = process.env.REACT_APP_API_URL;

export const createImage = (data, index) => {
  const config = { headers: { Authorization: `Bearer ${localStorage.getItem("token")}` } };
  const params = new FormData();
  params.append("image", data);
    return (dispatch) => {
      dispatch(createImageInit());
      axios.post(`${baseUrl}/request-consultant-image/store`, params, config)
      .then((response)=>{
          dispatch(createImageSuccess(response.data.data, index));
          
      })
      .catch(function (error) {
        checkError(error);
          dispatch(createImageFailure());
      })
  };
};

export const createImageInit = () => ({
  type: actionTypes.CREATE_IMAGE
});

export const createImageFailure = data => ({
  type: actionTypes.CREATE_IMAGE_FAILURE
});

export const createImageSuccess = (data, i) => ({
  type: actionTypes.CREATE_IMAGE_SUCCESS,
  payload: { data, i }
  
});


export const getReqConsult = (data) => {
  const config = { params: data };
  return (dispatch) => {
    dispatch(getReqConsultInit());
    axios
      .get(`${baseUrl}/request-consultant/admin`, config)
      .then((response) => {
        dispatch(getReqConsultSuccess(response.data.data));
      })
      .catch(function (error) {
        checkError(error);
        dispatch(getReqConsultFailure());
      });
  };
};

export const getReqConsultInit = () => ({
  type: actionTypes.GET_REQ_CONSULT,
});

export const getReqConsultFailure = (data) => ({
  type: actionTypes.GET_REQ_CONSULT_FAILURE,
});

export const getReqConsultSuccess = (data) => ({
  type: actionTypes.GET_REQ_CONSULT_SUCCESS,
  payload: { data },
});

export const createReqConsult = (data) => {
  return (dispatch) => {
    dispatch(createReqConsultInit());
    axios
      .post(`${baseUrl}/request-consultant/admin-store`, data)
      .then((response) => {
        dispatch(createReqConsultSuccess(response.data.data));
        
      })
      .catch(function (error) {
        checkError(error);
        dispatch(createReqConsultFailure());
      });
  };
};

export const createReqConsultInit = () => ({
  type: actionTypes.CREATE_REQ_CONSULT_INIT,
});

export const createReqConsultFailure = (data) => ({
  type: actionTypes.CREATE_REQ_CONSULT_FAILURE,
});

export const createReqConsultSuccess = (data) => ({
  type: actionTypes.CREATE_REQ_CONSULT_SUCCESS,
  payload: { data },
});

export const updateReqConsult = (data) => {
  if (data.DoctorId) data.DoctorId = data.DoctorId;
  if (data.CareGiverId) data.CareGiverId = data.CareGiverId;
  if (data.assignBy != "") data.assignBy = data.assignBy;
  if (data.assignTime != "") data.assignTime = data.assignTime;
  if (data.caseUpdate != "") data.caseUpdate = Moment(data.caseUpdate).format("YYYY-MM-DD");
  const config = {
    headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
  };
  return (dispatch) => {
    dispatch(updateReqConsultInit());
    axios
      .put(`${baseUrl}/request-consultant/admin/${data.id}`, data)
      .then((response) => {
        toast.success("Update Successfully!", {
          position: toast.POSITION.TOP_RIGHT,
        });
        dispatch(updateReqConsultSuccess(response.data.data));
      })
      .catch(function (error) {
        checkError(error);
        dispatch(updateReqConsultFailure());
      });
  };
};

export const updateReqConsultInit = () => ({
  type: actionTypes.UPDATE_REQ_CONSULT,
});

export const updateReqConsultFailure = (data) => ({
  type: actionTypes.UPDATE_REQ_CONSULT_FAILURE,
});

export const updateReqConsultSuccess = (data) => ({
  type: actionTypes.UPDATE_REQ_CONSULT_SUCCESS,
  payload: { data },
});

export const deleteReqConsult = (data) => {
  const config = {
    headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
  };

  return (dispatch) => {
    dispatch(deleteReqConsultInit());
    axios
      .delete(`${baseUrl}/request-consultant/${data}`, config)
      .then((response) => {
        dispatch(deleteReqConsultSuccess(response.data.data));
      })
      .catch(function (error) {
        checkError(error);
        dispatch(deleteReqConsultFailure());
      });
  };
};

export const deleteReqConsultInit = () => ({
  type: actionTypes.DELETE_REQ_CONSULT,
});

export const deleteReqConsultFailure = (data) => ({
  type: actionTypes.DELETE_REQ_CONSULT_FAILURE,
});

export const deleteReqConsultSuccess = (data) => ({
  type: actionTypes.DELETE_REQ_CONSULT_SUCCESS,
  payload: { data },
});



export const createCommentConsult = (data) => {
  return (dispatch) => {
    dispatch(createCommentRequestInit());
    axios
      .post(`${baseUrl}/add-comment/store-admin`, data)
      .then((response) => {
        dispatch(createCommentSuccess(response.data.data));
      })
      .catch(function (error) {
        checkError(error);
        dispatch(createCommentFailure());
      });
  };
};



export const createCommentRequestInit = () => ({
  type: actionTypes.CREATE_ADD_COMMENTCONSULT_INIT,
});

export const createCommentFailure = (data) => ({
  type: actionTypes.CREATE_ADD_COMMENTCONSULT_FAILURE,
});

export const createCommentSuccess = (data) => ({
  type: actionTypes.CREATE_ADD_COMMENTCONSULT_SUCCESS,
  payload: { data },
});
