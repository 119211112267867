import React, { useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import MedicalReportTable from './component/report_medical_case-table.component';
import MedicalReportDialoge from './component/report_medical_case-dialoge';
import Loading from '../../layout/Loading';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import HomeIcon from '@mui/icons-material/Home';
import { Link } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import {Button , Box} from '@mui/material';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { confirmAlert } from "react-confirm-alert";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    background: 'teal',
    color: '#fff',
    fontSize: '24px'
  },
  dblink: {
    textDecoration: 'none'
  },
  link: {
    display: 'flex',
    textDecoration: 'none',
    color: '#fff !important'
  },
  icon: {
    marginRight: theme.spacing(0.5),
    width: 20,
    height: 20,
  },
  breadcrum: {
    backgroundColor: theme.palette.primary.main,
    padding: "20px 40px",
    color: "#fff !important",
    marginBottom: "15px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
      alignItems: "flex-start",
      padding: "13px 30px",
    },
  },
  createBtn: {
    background: "#000 !important",

    "&:hover": {
      backgroundColor: "transparent !important",
    },

    [theme.breakpoints.down("md")]: {
      marginTop: "15px",
      paddingLeft: "0px !important",
      paddingBottom: "0px !important",
    },
  },
}));

export function MedicalReportPage(props) {
  const {
    getMedicalReport,
    createMedicalReport,
    updateMedicalReport,
    deleteMedicalReport,
    createImage,
    reports,
    count,
    isLoading,
    isSaved,
  } = props;

  console.warn('props:- ',props);

  const emptyMedicalReport = {
    name: "",
    location: "",
    additionalInfo: "",
    phoneNumber : "",
    emergency : "",
    attachment: [{}],
  }

  // const id = props.match.params.id;
  const classes = useStyles();

  const [open, setOpen] = useState(false);
  const [selected, setSelected] = useState(emptyMedicalReport);
  const [errors, setErrors] = React.useState({});
  const [order, setOrder] = React.useState('desc');
  const [orderBy, setOrderBy] = React.useState('id');
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [filtes, setFiltes] = React.useState("");

  useEffect(() => {
    getMedicalReport({
      offset: page * rowsPerPage,
      limit: rowsPerPage,
      orderBy: orderBy,
      orderType: order
    });
  }, []);

  useEffect(() => {
    if(isSaved) {
      setOpen(false);
      setSelected(emptyMedicalReport);
      getMedicalReport({
        offset: page * rowsPerPage,
        limit: rowsPerPage,
        orderBy: orderBy,
        orderType: order
      });
    }
  }, [isSaved]);

  useEffect(() => {
    if (filtes.length > 1) {
      getMedicalReport({
        offset: page * rowsPerPage,
        limit: rowsPerPage,
        orderBy: orderBy,
        orderType: order,
        search: filtes
      })
    } else {
      getMedicalReport({
        offset: page * rowsPerPage,
        limit: rowsPerPage,
        orderBy: orderBy,
        orderType: order
      })
    }
  }, [filtes])

  const validate = (values) => {
    let errorss = {};
    let isError = false;
    const regex = /\S+@\S+\.\S+/;

    if (!values.name) {
      isError = true;
      errorss.name = "Name is required";
    } 
    if (!isError) {
      setErrors(null);
      values.id ? updateMedicalReport(values) : createMedicalReport(values);
    }
    setErrors(errorss);
  };

  const confirmDelete = (id) => {
    confirmAlert({
      title: "Confirm to delete",
      message: "Are you sure to do this.",
      buttons: [
        {
          label: "Yes",
          onClick: () => deleteMedicalReport(id),
        },
        {
          label: "No",
        },
      ],
    });
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
    getMedicalReport({
      offset: page * rowsPerPage,
      limit: rowsPerPage,
      orderBy: property,
      orderType: isAsc ? 'desc' : 'asc'
    });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    getMedicalReport({
      offset: newPage * rowsPerPage,
      limit: rowsPerPage,
      orderBy: orderBy,
      orderType: order
    });
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    getMedicalReport({
      offset: 0,
      limit: parseInt(event.target.value, 10),
      orderBy: orderBy,
      orderType: order
    });
  };


  return (
    <div className={classes.root}>
      <Loading loading={isLoading} />
      <Box className={classes.breadcrum}>
      <Breadcrumbs aria-label="breadcrumb" >
        <Link to="/" className={classes.link}>
          <HomeIcon className={classes.icon} />
          Home
        </Link>
        <Typography color="textPrimary" className={classes.link}>
          Medical Case Report
        </Typography>
      </Breadcrumbs>
      {/* <Button variant="contained" color='primary' onClick={()=> setOpen(true)} className={classes.createBtn}>
        <AddCircleIcon />&nbsp;
        Create Report
      </Button> */}
      </Box>
      <MedicalReportTable
        rows={reports}
        handleClickOpen={() => {
          setOpen(true);
        }}
        count={count}
        setSelected={setSelected}
        selected={selected}
        order={order}
        orderBy={orderBy}
        rowsPerPage={rowsPerPage}
        setFiltes={setFiltes}
        page={page}
        confirmDelete={confirmDelete}
        handleRequestSort={handleRequestSort}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
      />
      <MedicalReportDialoge
        open={open}
        selected={selected}
        setSelected={setSelected}
        validate={validate}
        errors={errors}
        handleClose={() => {
          setSelected(emptyMedicalReport);
          setErrors(null);
          setOpen(false);
        }}
      />
    </div>
  );
}