import * as actionTypes from "./medicalActionType";
import axios from 'axios';
import { checkError } from './../error';

const baseUrl = process.env.REACT_APP_API_URL;

export const getMedical = (data) => {
    const config = { headers: { Authorization: `Bearer ${localStorage.getItem("token")}` }, params: data };
      return (dispatch) => {
        dispatch(getMedicalInit());
        axios.get(`${baseUrl}/medical-case/admin`, config)
        .then((response)=>{
            dispatch(getMedicalSuccess(response.data.data));
        })
        .catch(function (error) {
            checkError(error);
            dispatch(getMedicalFailure());
        })
    };
};

export const getMedicalInit = () => ({
    type: actionTypes.GET_MEDICAL_LIST
});

export const getMedicalFailure = data => ({
    type: actionTypes.GET_MEDICAL_LIST_FAILURE
});

export const getMedicalSuccess = data => ({
    type: actionTypes.GET_MEDICAL_LIST_SUCCESS,
    payload: { data }
});

export const createMedical = (data) => {
    const config = { headers: { Authorization: `Bearer ${localStorage.getItem("token")}` } };
      return (dispatch) => {
        dispatch(createMedicalInit());
        axios.post(`${baseUrl}/medical-case/admin/store`, data, config)
        .then((response)=>{
            dispatch(createMedicalSuccess(response.data.data));
        })
        .catch(function (error) {
            checkError(error);
            dispatch(createMedicalFailure());
        })
    };
};

export const createMedicalInit = () => ({
    type: actionTypes.CREATE_MEDICAL
});

export const createMedicalFailure = data => ({
    type: actionTypes.CREATE_MEDICAL_FAILURE
});

export const createMedicalSuccess = data => ({
    type: actionTypes.CREATE_MEDICAL_SUCCESS,
    payload: { data }
});

export const updateMedical = (data) => {
    const config = { headers: { Authorization: `Bearer ${localStorage.getItem("token")}` } };
      return (dispatch) => {
        dispatch(updateMedicalInit());
        axios.put(`${baseUrl}/medical-case/admin/${data.id}`, data, config)
        .then((response)=>{
            dispatch(updateMedicalSuccess(response.data.data));
        })
        .catch(function (error) {
            checkError(error);
            dispatch(updateMedicalFailure());
        })
    };
};

export const updateMedicalInit = () => ({
    type: actionTypes.UPDATE_MEDICAL
});

export const updateMedicalFailure = data => ({
    type: actionTypes.UPDATE_MEDICAL_FAILURE
});

export const updateMedicalSuccess = data => ({
    type: actionTypes.UPDATE_MEDICAL_SUCCESS,
    payload: { data }
});

export const deleteMedical = (data) => {
    const config = { headers: { Authorization: `Bearer ${localStorage.getItem("token")}` } };
      return (dispatch) => {
        dispatch(deleteMedicalInit());
        axios.delete(`${baseUrl}/medical-case/admin/${data}`, config)
        .then((response)=>{
            dispatch(deleteMedicalSuccess(response.data.data));
        })
        .catch(function (error) {
            checkError(error);
            dispatch(deleteMedicalFailure());
        })
    };
};

export const deleteMedicalInit = () => ({
    type: actionTypes.DELETE_MEDICAL
});

export const deleteMedicalFailure = data => ({
    type: actionTypes.DELETE_MEDICAL_FAILURE
});

export const deleteMedicalSuccess = data => ({
    type: actionTypes.DELETE_MEDICAL_SUCCESS,
    payload: { data }
});
