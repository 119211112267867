export const GET_PHARMACY_LIST = "GET_PHARMACY_LIST";
export const GET_PHARMACY_LIST_FAILURE = "GET_PHARMACY_LIST_FAILURE";
export const GET_PHARMACY_LIST_SUCCESS = "GET_PHARMACY_LIST_SUCCESS";

export const CREATE_PHARMACY = "CREATE_PHARMACY";
export const CREATE_PHARMACY_FAILURE = "CREATE_PHARMACY_FAILURE";
export const CREATE_PHARMACY_SUCCESS = "CREATE_PHARMACY_SUCCESS";

export const UPDATE_PHARMACY = "UPDATE_PHARMACY";
export const UPDATE_PHARMACY_FAILURE = "UPDATE_PHARMACY_FAILURE";
export const UPDATE_PHARMACY_SUCCESS = "UPDATE_PHARMACY_SUCCESS";

export const DELETE_PHARMACY = "DELETE_PHARMACY";
export const DELETE_PHARMACY_FAILURE = "DELETE_PHARMACY_FAILURE";
export const DELETE_PHARMACY_SUCCESS = "DELETE_PHARMACY_SUCCESS";

export const GET_TIMEZONE = "GET_TIMEZONE";
export const GET_TIMEZONE_FAILURE = "GET_TIMEZONE_FAILURE";
export const GET_TIMEZONE_SUCCESS = "GET_TIMEZONE_SUCCESS";
