import { MedicalReportPage } from './report_medical_case.component';
import { connect } from 'react-redux';
import { getMedicalReport, createMedicalReport, updateMedicalReport, deleteMedicalReport } from "../../api/medicalReport/medicalReportAction";

const mapDispatchToProps = {
    getMedicalReport,
    createMedicalReport,
    updateMedicalReport,
    deleteMedicalReport
};

const mapStateToProps = state => ({
    error: state.medicalReportPage.isError,
    isLoading: state.medicalReportPage.isLoading,
    isSaved: state.medicalReportPage.isSaved,
    reports: state.medicalReportPage.reports,
    count: state.medicalReportPage.count,
});

export const ReportMedicalContainer = connect(mapStateToProps, mapDispatchToProps)(MedicalReportPage);