import * as actionType from "./shramansActionType";

const initialState = {
    isError: false,
    isLoading: false,
    isSaved: false,
    shramans: [],
    count: 0
};

export const shramansReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionType.GET_SHRAMANS_LIST:
            return {
                ...state,
                isError: false,
                isLoading: true
            };
        case actionType.GET_SHRAMANS_LIST_FAILURE:
            return {
                ...state,
                isError: true,
                isLoading: false
            };
        case actionType.GET_SHRAMANS_LIST_SUCCESS:
            
            return {
                ...state,
                isError: false,
                isLoading: false,
                shramans: action.payload ? action.payload.data : [],
                count: action.payload ? action.payload.data.count : 0
            };
        case actionType.CREATE_SHRAMANS:
            return {
                ...state,
                isError: false,
                isLoading: true,
                isSaved: false
            };
        case actionType.CREATE_SHRAMANS_FAILURE:
            return {
                ...state,
                isError: true,
                isLoading: false
            };
        case actionType.CREATE_SHRAMANS_SUCCESS:
            return {
                ...state,
                isError: false,
                isLoading: false,
                isSaved: true
            };
        case actionType.UPDATE_SHRAMANS:
            return {
                ...state,
                isError: false,
                isLoading: true,
                isSaved: false
            };
        case actionType.UPDATE_SHRAMANS_FAILURE:
            return {
                ...state,
                isError: true,
                isLoading: false
            };
        case actionType.UPDATE_SHRAMANS_SUCCESS:
            return {
                ...state,
                isError: false,
                isLoading: false,
                isSaved: true
            };
        case actionType.DELETE_SHRAMANS:
            return {
                ...state,
                isError: false,
                isLoading: true,
                isSaved: false
            };
        case actionType.DELETE_SHRAMANS_FAILURE:
            return {
                ...state,
                isError: true,
                isLoading: false
            };
        case actionType.DELETE_SHRAMANS_SUCCESS:
            return {
                ...state,
                isError: false,
                isLoading: false,
                isSaved: true
            };
        default:
            return state

    }
}
