import React, { useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import CaregiverTable from './component/caregiver-table.component';
import CaregiverDialoge from './component/caregiver-dialoge';
import CaregiverDetailDialoge from './component/caregiver-detail-dialoge';
import Loading from '../../layout/Loading';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import HomeIcon from '@mui/icons-material/Home';
import { Link } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import { Button, Box } from '@mui/material';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { confirmAlert } from "react-confirm-alert";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    background: 'teal',
    color: '#fff',
    fontSize: '24px'
  },
  dblink: {
    textDecoration: 'none'
  },
  link: {
    display: 'flex',
    textDecoration: 'none',
    color: '#fff !important'
  },
  icon: {
    marginRight: theme.spacing(0.5),
    width: 20,
    height: 20,
  },
  breadcrum: {
    backgroundColor: theme.palette.primary.main,
    padding: "20px 40px",
    color: "#fff !important",
    marginBottom: "15px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
      alignItems: "flex-start",
      padding: "13px 30px",
    },
  },
  createBtn: {
    background: "#000 !important",

    "&:hover": {
      backgroundColor: "transparent !important",
    },

    [theme.breakpoints.down("md")]: {
      marginTop: "15px",
      paddingLeft: "0px !important",
      paddingBottom: "0px !important",
    },
  },
}));

export function CaregiverPage (props) {
  const {
    getCaregiver,
    caregivers,
    createCaregiver,
    updateCaregiver,
    deleteCaregiver,
    isSaved,
    isLoading,
    count
  } = props;


  const emptyCaregiver = {
    image: "",
    prefix: "",
    name: "",
    email: "",
    phone: "",
    password: "",
    City: "",
    District: "",
    State: "",
    zip: "",
    verified: 0,
    verifiedBy: "",
    phoneVerified: 0,
    emailVerified: 0,

    status: ""
  }

  // const id = props.match.params.id;
  const classes = useStyles();

  const [open, setOpen] = useState(false);
  const [show, setShow] = useState(false);
  const [selected, setSelected] = useState(emptyCaregiver);
  const [errors, setErrors] = React.useState({});
  const [order, setOrder] = React.useState('desc');
  const [orderBy, setOrderBy] = React.useState('id');
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [filtes, setFiltes] = React.useState("");
  const [newImage, setNewImage] = useState(false);



  useEffect(() => {

    getCaregiver({
      offset: page * rowsPerPage,
      limit: rowsPerPage,
      orderBy: orderBy,
      orderType: order,
      search: filtes
    });
  }, []);

  useEffect(() => {
    if (isSaved) {

      setOpen(false);
      setSelected(emptyCaregiver);
      getCaregiver({
        offset: page * rowsPerPage,
        limit: rowsPerPage,
        orderBy: orderBy,
        orderType: order,
        search: filtes
      });
    }
  }, [isSaved]);
  useEffect(() => {
    if (filtes.length > 1) {
      getCaregiver({
        offset: page * rowsPerPage,
        limit: rowsPerPage,
        orderBy: orderBy,
        orderType: order,
        search: filtes
      })
    } else {
      getCaregiver({
        offset: page * rowsPerPage,
        limit: rowsPerPage,
        orderBy: orderBy,
        orderType: order
      })
    }
  }, [filtes])

  const verify = (values) => {
    values.verifiedBy = 'admin';
    values.emailVerified = 1;
    values.phoneVerified = 1;
    values.verified = 1;
    updateCaregiver(values);
    setErrors(null);
    setShow(false);
  }
  const validate = (values) => {

    let errorss = {};
    let isError = false;
    const regex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,32}$/;

    if (!values.name && !values.email && !values.phone) {
      isError = true;
      errorss.name = "Name is required";
      errorss.email = "Email is required";
      errorss.phone = "Phone is required";
    } else if (!values.name) {
      isError = true;
      errorss.name = "Name is required";
    } else if (!values.email) {
      isError = true;
      errorss.email = "Email is required";
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
      errorss.email = 'Invalid email address'
    }
    // else if (!regex.test(values.password)) {
    //   errorss.password = 'Password must be one Capital letter, one number and minimum 8 character'
    // } 
    else if (!values.phone) {
      isError = true;
      errorss.phone = "Phone is required";
    }
    if (!isError) {
      setErrors(null);
      values.id ? updateCaregiver(values) : createCaregiver(values);
    }
    setErrors(errorss);
  };

  const confirmDelete = (id) => {
    confirmAlert({
      title: "Confirm to delete",
      message: "Are you sure to do this.",
      buttons: [
        {
          label: "Yes",
          onClick: () => deleteCaregiver(id),
        },
        {
          label: "No",
        },
      ],
    });
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'ASC';
    setOrder(isAsc ? 'DESC' : 'ASC');
    setOrderBy(property);
    getCaregiver({
      offset: page * rowsPerPage,
      limit: rowsPerPage,
      orderBy: property,
      orderType: isAsc ? 'DESC' : 'ASC'
    });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    getCaregiver({
      offset: newPage * rowsPerPage,
      limit: rowsPerPage,
      orderBy: orderBy,
      orderType: order,
      search: filtes
    });
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    getCaregiver({
      offset: 0,
      limit: parseInt(event.target.value, 10),
      orderBy: orderBy,
      orderType: order,
      search: filtes
    });
  };



  return (
    <div className={classes.root}>
      <Loading loading={isLoading} />
      <Box className={classes.breadcrum}>
        <Breadcrumbs aria-label="breadcrumb" >
          <Link to="/" className={classes.link}>
            <HomeIcon className={classes.icon} />
            Home
          </Link>
          <Typography color="textPrimary" className={classes.link}>
            Caregiver
          </Typography>
        </Breadcrumbs>
        <Button variant="contained" color='primary'
          onClick={() => {
            setSelected(emptyCaregiver)
            setOpen(true)
            setShow(false)
          }}
          className={classes.createBtn}>
          <AddCircleIcon />&nbsp;
          Create Caregiver
        </Button>
      </Box>
      <CaregiverTable
        caregivers={caregivers}
        handleClickOpen={() => {
          setOpen(true);
        }}
        handleClickShow={() => {
          setShow(true);
        }}
        setNewImage={setNewImage}
        newImage={newImage}
        count={count}
        setSelected={setSelected}
        selected={selected}
        order={order}
        orderBy={orderBy}
        rowsPerPage={rowsPerPage}
        page={page}
        setFiltes={setFiltes}
        setOpen={setOpen}
        confirmDelete={confirmDelete}
        handleRequestSort={handleRequestSort}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
      />
      {open ? <CaregiverDialoge
        open={open}
        setNewImage={setNewImage}
        newImage={newImage}
        selected={selected}
        setSelected={setSelected}
        validate={validate}
        errors={errors}
        handleClose={() => {
          setSelected(emptyCaregiver);
          setErrors(null);
          setOpen(false);
        }}
      /> : <CaregiverDetailDialoge
        open={open}
        show={show}
        setShow={setShow}
        selected={selected}
        setSelected={setSelected}
        verify={verify}
        handleClose={() => {
          setErrors(null);
          setOpen(false);
        }}

      />}



    </div>
  );
}