import * as actionTypes from "./specializationActionType";
import axios from 'axios';
import { checkError } from './../error';

const baseUrl = process.env.REACT_APP_API_URL;

export const getSpecialization = (data) => {
    const config = {  params: data };
    return (dispatch) => {
        dispatch(getSpecializationInit());
        axios.get(`${baseUrl}/specialization`,config)
            .then((response) => {
                dispatch(getSpecializationSuccess(response.data.data));
            })
            .catch(function (error) {
                checkError(error);
                dispatch(getSpecializationFailure());
            })
    };
};

export const getSpecializationInit = () => ({
    type: actionTypes.GET_SPECIALIZATION
});

export const getSpecializationFailure = data => ({
    type: actionTypes.GET_SPECIALIZATION_FAILURE
});

export const getSpecializationSuccess = data => ({
    type: actionTypes.GET_SPECIALIZATION_SUCCESS,
    payload: { data }
});

export const createSpecialization = (data) => {
    // const params = new FormData();
    // params.append("image", data.image);
    // params.append("name", data.name);
    // params.append("specialization", data.specialization);
    // params.append("email", data.email);
    // params.append("latitude", data.latitude);
    // params.append("longitude", data.longitude);
    // params.append("address", data.address);
    // params.append("contact", data.contact);
    // params.append("altContact", data.altContact);
    // params.append("city", data.city);
    // params.append("district", data.district);
    // params.append("state", data.state);
    // params.append("zip", data.zip);
    return (dispatch) => {
        dispatch(createSpecializationInit());
        axios.post(`${baseUrl}/specialization/store`, data)
            .then((response) => {
                dispatch(createSpecializationSuccess(response.data.data));
            })
            .catch(function (error) {
                checkError(error);
                dispatch(createSpecializationFailure());
            })
    };
};

export const createSpecializationInit = () => ({
    type: actionTypes.CREATE_SPECIALIZATION
});

export const createSpecializationFailure = data => ({
    type: actionTypes.CREATE_SPECIALIZATION_FAILURE
});

export const createSpecializationSuccess = data => ({
    type: actionTypes.CREATE_SPECIALIZATION_SUCCESS,
    payload: { data }
});

export const updateSpecialization = (data) => {
    const config = { headers: { Authorization: `Bearer ${localStorage.getItem("token")}` } };
    // const params = new FormData();
    // params.append("image", data.image);
    // params.append("name", data.name);
    // params.append("specialization", data.specialization);
    // params.append("email", data.email);
    // params.append("latitude", data.latitude);
    // params.append("longitude", data.longitude);
    // params.append("address", data.address);
    // params.append("contact", data.contact);
    // params.append("altContact", data.altContact);
    // params.append("city", data.city);
    // params.append("district", data.district);
    // params.append("state", data.state);
    // params.append("zip", data.zip);
    return (dispatch) => {
        dispatch(updateSpecializationInit());
        axios.put(`${baseUrl}/specialization/${data.id}`, data, config)
            .then((response) => {
                dispatch(updateSpecializationSuccess(response.data.data));
            })
            .catch(function (error) {
                checkError(error);
                dispatch(updateSpecializationFailure());
            })
    };
};

export const updateSpecializationInit = () => ({
    type: actionTypes.UPDATE_SPECIALIZATION
});

export const updateSpecializationFailure = data => ({
    type: actionTypes.UPDATE_SPECIALIZATION_FAILURE
});

export const updateSpecializationSuccess = data => ({
    type: actionTypes.UPDATE_SPECIALIZATION_SUCCESS,
    payload: { data }
});

export const deleteSpecialization = (data) => {
    const config = { headers: { Authorization: `Bearer ${localStorage.getItem("token")}` } };
    return (dispatch) => {
        dispatch(deleteSpecializationInit());
        axios.delete(`${baseUrl}/specialization/${data}`, config)
            .then((response) => {
                dispatch(deleteSpecializationSuccess(response.data.data));
            })
            .catch(function (error) {
                checkError(error);
                dispatch(deleteSpecializationFailure());
            })
    };
};

export const deleteSpecializationInit = () => ({
    type: actionTypes.DELETE_SPECIALIZATION
});

export const deleteSpecializationFailure = data => ({
    type: actionTypes.DELETE_SPECIALIZATION_FAILURE
});

export const deleteSpecializationSuccess = data => ({
    type: actionTypes.DELETE_SPECIALIZATION_SUCCESS,
    payload: { data }
});
