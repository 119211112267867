import React, { useState } from 'react';
import { makeStyles } from '@mui/styles';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import Typography from '@mui/material/Typography';
import Slide from '@mui/material/Slide';
import Grid from '@mui/material/Grid';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import DialogContent from "@mui/material/DialogContent";
import Input from "@mui/material/Input";
import TextField from '@mui/material/TextField';
import Divider from "@mui/material/Divider";
import InputAdornment from "@mui/material/InputAdornment";
import ClearIcon from "@mui/icons-material/Clear";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  textBold: {
    fontWeight: "600",
  },
  list: {
    marginTop: "10px !important",
    marginBottom: "10px !important",
  },
  paper: {
    marginTop: theme.spacing(3),
    padding: theme.spacing(3),
  },
  formControl: {
    width: '100%',
    spacing: theme.spacing(2),
  },
  inputLabelText: {
    fontSize: "14px !important",
    color: "rgba(0,0,0,.5) !important",
    fontWeight: "600 !important",
    textTransform: "uppercase",
    marginTop: "0px"
  },
  mdText1: {
    color: "red",
    fontSize: "12px",
    fontWeight: 400,
  },
}));

const Transition = React.forwardRef(function Transition (props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

export default function MedicalReportDialoge (props) {
  const { handleClose, open, errors, selected, setSelected, validate } = props;
  const classes = useStyles();
  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
        keepMounted
        aria-labelledby="scroll-dialog-title"
        aria-describedby="alert-dialog-slide-description"
        maxWidth="md"
        fullWidth={true}
      >
        <DialogContent>
          <ClearIcon
            onClick={handleClose}
            style={{ width: "25px", height: "25px", float: "right" }}
          />
          <div
            style={{
              marginTop: "10px",
              marginBottom: "20px",
            }}
          >
            <Typography
              style={{
                fontSize: "30px",
                fontWeight: 400,
                color: "rgba(0,0,0,.6)",
              }}
            >
              Medical Report
            </Typography>
          </div>

          {selected.id && selected.attachment[0].image ?

            <Grid container>
              <Grid item md={12} xs={12} className={classes.list}>
                <span className={classes.textBold}>Attatchment :</span>{" "}
              </Grid>
              <Grid item md={12} xs={12} className={classes.list}>
                <img
                  src={selected.attachment[0].image}
                  style={{ maxWidth: "100%", maxHeight: "150px" }}
                />
              </Grid>
            </Grid> :

            ''}

          <Grid container>
            <Grid item md={4} xs={6} className={classes.list}>
              <span className={classes.textBold}>Name :</span>{" "}
              {selected && selected.name
                ? selected.name
                : "N/A"}{" "}
            </Grid>
            <Grid item md={4} xs={6} className={classes.list}>
              <span className={classes.textBold}>Location :</span>{" "}
              {selected && selected.location
                ? selected.location
                : "N/A"}{" "}
            </Grid>
            <Grid item md={4} xs={6} className={classes.list}>
              <span className={classes.textBold}>AdditionalInfo :</span>{" "}
              {selected && selected.additionalInfo
                ? selected.additionalInfo
                : "N/A"}{" "}
            </Grid>
            <Grid item md={4} xs={6} className={classes.list}>
              <span className={classes.textBold}>Phone Number :</span>{" "}
              {selected && selected.phoneNumber
                ? selected.phoneNumber
                : "N/A"}{" "}
            </Grid>
            <Grid item md={4} xs={6} className={classes.list}>
              <span className={classes.textBold}>Emergency :</span>{" "}
              {selected && selected.emergency
                ? selected.emergency
                : "N/A"}{" "}
            </Grid>
          </Grid>









        </DialogContent>
      </Dialog>
    </div>
  );
}