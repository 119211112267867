export const GET_MEDICAL_REPORT_LIST = "GET_MEDICAL_REPORT_LIST";
export const GET_MEDICAL_REPORT_LIST_FAILURE = "GET_MEDICAL_REPORT_LIST_FAILURE";
export const GET_MEDICAL_REPORT_LIST_SUCCESS = "GET_MEDICAL_REPORT_LIST_SUCCESS";

export const CREATE_MEDICAL_REPORT = "CREATE_MEDICAL_REPORT";
export const CREATE_MEDICAL_REPORT_FAILURE = "CREATE_MEDICAL_REPORT_FAILURE";
export const CREATE_MEDICAL_REPORT_SUCCESS = "CREATE_MEDICAL_REPORT_SUCCESS";



export const UPDATE_MEDICAL_REPORT = "UPDATE_MEDICAL_REPORT";
export const UPDATE_MEDICAL_REPORT_FAILURE = "UPDATE_MEDICAL_REPORT_FAILURE";
export const UPDATE_MEDICAL_REPORT_SUCCESS = "UPDATE_MEDICAL_REPORT_SUCCESS";

export const DELETE_MEDICAL_REPORT = "DELETE_MEDICAL_REPORT";
export const DELETE_MEDICAL_REPORT_FAILURE = "DELETE_MEDICAL_REPORT_FAILURE";
export const DELETE_MEDICAL_REPORT_SUCCESS = "DELETE_MEDICAL_REPORT_SUCCESS";


export const CREATE_IMAGE = "CREATE_IMAGE";
export const CREATE_IMAGE_FAILURE = "CREATE_IMAGE_FAILURE";
export const CREATE_IMAGE_SUCCESS = "CREATE_IMAGE_SUCCESS";