import { SamudhayPage } from './samudhay.component';
import { connect } from 'react-redux';
import { getGatcha } from "../../api/gatcha/gatchaAction";
import { getSamudhay, createSamudhay, updateSamudhay, deleteSamudhay } from "../../api/samudhay/samudhayAction";

const mapDispatchToProps = {
    getGatcha,
    getSamudhay,
    createSamudhay,
    updateSamudhay,
    deleteSamudhay
};

const mapStateToProps = state => ({
    error: state.samudhayPage.isError,
    isLoading: state.samudhayPage.isLoading,
    isSaved: state.samudhayPage.isSaved,
    gatcha : state.gatchaPage.result,
    result: state.samudhayPage.result,
    count: state.samudhayPage.count,
    isLoggedIn : state.adminPage.isLoggedIn
});

export const SamudhayContainer = connect(mapStateToProps, mapDispatchToProps)(SamudhayPage);