import React, { useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import ShramanTable from './component/shramans-table.component';
import ShramanDialoge from './component/shramans-dialoge';
import Loading from '../../layout/Loading';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import HomeIcon from '@mui/icons-material/Home';
import { Link } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import { Button, Box } from '@mui/material';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { confirmAlert } from "react-confirm-alert";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    background: 'teal',
    color: '#fff',
    fontSize: '24px'
  },
  dblink: {
    textDecoration: 'none'
  },
  link: {
    display: 'flex',
    textDecoration: 'none',
    color: '#fff !important',
  },
  icon: {
    marginRight: theme.spacing(0.5),
    width: 20,
    height: 20,
  },

  breadcrum: {
    backgroundColor: theme.palette.primary.main,
    padding: "20px 40px",
    color: "#fff !important",
    marginBottom: "15px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
      alignItems: "flex-start",
      padding: "13px 30px",
    },
  },
  createBtn: {
    background: "#000 !important",

    "&:hover": {
      backgroundColor: "transparent !important",
    },

    [theme.breakpoints.down("md")]: {
      marginTop: "15px",
      paddingLeft: "0px !important",
      paddingBottom: "0px !important",
    },
  },
}));

export function ShramanPage (props) {
  const {
    getGatcha,
    getSamudhay,
    getGuru,
    getShramans,
    createShramans,
    createGatcha,
    createSamudhay,
    updateShramans,
    deleteShramans,
    getCaregiver,
    count,
    gatcha,
    samudhay,
    guru,
    shramans,
    caregivers,
    isSaved,
    isLoading,
    isLoggedIn,
    getGatchSamudhay,
    gatchasamudhay,
    samudhayGuru,
    samudhaygururesult,
    createGuru
  } = props;

  // const id = props.match.params.id;
  const classes = useStyles();

  const emptyShramans = {
    image: "",
    prefix: "",
    name: "",
    email: "",
    password: "",
    phone: "",
    dob: "",
    gender: "Male",
    address: "",
    latitude: "",
    longitude: "",
    City: "",
    District: "",
    State: "",
    zip: "",
    age: "",
    CareGiverId: "",
    GatchaId : "",
    SamudhayId : "",
    GuruId : ""
  }

  const emptyGatch = {
    name: "",
    status : 1
  };

  const emptySamudhay = {
    name: "",
    GatchaId : "",
    status : 1
  };

  const emptyGuru = {
    name: "",
    GatchaId : "",
    SamudhayId : "",
    status : 1
  }

  const [open, setOpen] = useState(false);
  const [openGatchaDialogue, setOpenGatchaDialogue] = useState(false);
  const [openSamudhayDialogue, setOpenSamudhayDialogue] = useState(false);
  const [openGuruDialogue, setOpenGuruDialogue] = useState(false);
  const [selected, setSelected] = useState(emptyShramans);
  const [selectedGatch, setSelectedGatcha] = useState(emptyGatch);
  const [selectedSamudhay, setSelectedSamudhay] = useState(emptySamudhay);
  const [selectedGuru, setSelectedGuru] = useState(emptyGuru);

  const [errors, setErrors] = React.useState({});
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('id');
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [filtes, setFiltes] = React.useState("");
  const [newImage, setNewImage] = useState(false);


  useEffect(() => {
    
    getGatcha({
      offset: page * rowsPerPage,
      limit: rowsPerPage,
      orderBy: orderBy,
      orderType: order,
    })
    getSamudhay({
      offset: page * rowsPerPage,
      limit: rowsPerPage,
      orderBy: orderBy,
      orderType: order,
    })
    getGuru({
      offset: page * rowsPerPage,
      limit: rowsPerPage,
      orderBy: orderBy,
      orderType: order,
    })
    
    getShramans({
      offset: page * rowsPerPage,
      limit: rowsPerPage,
      orderBy: orderBy,
      orderType: order,
      search: filtes
    })
    getCaregiver()
  }, [])

  useEffect(() => {
    if (isSaved) {
      setOpen(false);
      setSelected(emptyShramans);
      getShramans({
        offset: page * rowsPerPage,
        limit: rowsPerPage,
        orderBy: orderBy,
        orderType: order,
        search: filtes
      })
    }
  }, [isSaved])

  useEffect(() => {
    if (filtes.length > 1) {
      getShramans({
        offset: page * rowsPerPage,
        limit: rowsPerPage,
        orderBy: orderBy,
        orderType: order,
        search: filtes
      })
    } else {
      getShramans({
        offset: page * rowsPerPage,
        limit: rowsPerPage,
        orderBy: orderBy,
        orderType: order
      })
    }
  }, [filtes])

  const validate = (values) => {
    let errorss = {};
    let isError = false;
    const regex = /\S+@\S+\.\S+/;

    if (!values.name && !values.age && !values.CareGiverId && !values.latitude && !values.longitude && !values.GatchaId && !values.SamudhayId && !values.GuruId) {
      isError = true;
      errorss.name = "Name is required";
      errorss.age = "Age is required";
      errorss.CareGiverId = "CareGiver is required";
      errorss.latitude = "Latitude is required";
      errorss.longitude = "Longitude is required";
      errorss.GatchaId = "Gatcha is requried";
      errorss.SamudhayId = "Samudhay is requried";
      errorss.GuruId = "Guru is requried";
    } else if (!values.name) {
      isError = true;
      errorss.name = "Name is required";
    } else if (!values.CareGiverId) {
      isError = true;
      errorss.CareGiverId = "CareGiver is required";
    } else if (!values.age) {
      isError = true;
      errorss.age = "Age is required";
    } else if (!values.latitude) {
      isError = true;
      errorss.latitude = "Latitude is required";
    } else if (!values.longitude) {
      isError = true;
      errorss.longitude = "Longitude is required";
    }else if (!values.GatchaId && values.GatchaId == "") {
      isError = true;
      errorss.GatchaId = "Gatcha is required";
    }else if (!values.SamudhayId && values.SamudhayId == "") {
      isError = true;
      errorss.SamudhayId = "Samudhay is required";
    }else if (!values.GuruId && values.GuruId == "") {
      isError = true;
      errorss.GuruId = "Guru is required";
    }
    if (!isError) {
      setErrors(null);
      console.warn('values:- ', values);
      values.id ? updateShramans(values) : createShramans(values);
    }
    setErrors(errorss);
  };

  const gatchaValidate = (values) => {
    let errorss = {};
    let isError = false;
    const regex = /\S+@\S+\.\S+/;
    console.warn("gatchaValidate call :: ",values)
    if (!values.name || values.name == "") {
      isError = true;
      errorss.name = "Name is required";
      setOpenGatchaDialogue(true);
    }
    if (!isError) {
      setErrors(null);
      console.warn('values:- ', values);
      createGatcha(values);
      setOpenGatchaDialogue(false);
      getGatcha({
        offset: page * rowsPerPage,
        limit: rowsPerPage,
        orderBy: orderBy,
        orderType: order,
      })
    }
    setErrors(errorss);
  }

  const samudhayValidate = (values) => {
    let errorss = {};
    let isError = false;
    const regex = /\S+@\S+\.\S+/;
    console.warn("samudhayValidate call :: ",values)
    if (!values.GatchaId || values.GatchaId == ""  && !values.name || values.name == "" && !values.status || values.status == "") {
      isError = true;
      errorss.name = "Name is required";
      errorss.GatchaId = "Gatcha is required";
      errorss.status = "Status is required";
      setOpenSamudhayDialogue(true);
    } else if(!values.GatchaId || values.GatchaId == "" ) {
      errorss.GatchaId = "Gatcha is required";
      setOpenSamudhayDialogue(true);
    } else if(!values.name || values.name == "") {
      errorss.name = "Name is required";
      setOpenSamudhayDialogue(true);
    } else if(!values.status || values.status == "" ) {
      errorss.status = "Status is required";
      setOpenSamudhayDialogue(true);
    }
    if (!isError) {
      setErrors(null);
      console.warn('values:- ', values);
      createSamudhay(values);
      setOpenSamudhayDialogue(false);
      getSamudhay()
    }
    setErrors(errorss);
  }

  const guruValidate = (values) => {
    let errorss = {};
    let isError = false;
    const regex = /\S+@\S+\.\S+/;
    console.warn("guruValidate call :: ",values)
    if (!values.GatchaId || values.GatchaId == "" && !values.SamudhayId || values.SamudhayId == "" &&  !values.name || values.name == "" && !values.status || values.status == "") {
      isError = true;
      errorss.name = "Name is required";
      errorss.GatchaId = "Gatcha is required";
      errorss.SamudhayId = "Samudhay is required";
      errorss.status = "Status is required";
      setOpenGuruDialogue(true);
    } else if(!values.GatchaId || values.GatchaId =="") {
      errorss.GatchaId = "Gatcha is required";
      setOpenGuruDialogue(true);
    } else if(!values.SamudhayId || values.SamudhayId == "") {
      errorss.SamudhayId = "Samudhay is required";
      setOpenGuruDialogue(true);
    } else if(!values.name || values.name == "") {
      errorss.name = "Name is required";
      setOpenGuruDialogue(true);
    } else if(!values.status || values.status == "") {
      errorss.status = "Status is required";
      setOpenGuruDialogue(true);
    }
    console.warn(isError);
    if (!isError) {
      setErrors(null);
      console.warn('valuesqqqq:- ', values);
      createGuru(values);
      setOpenGuruDialogue(false);
      getGuru()
    }
    setErrors(errorss);
  }

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
    getShramans({
      offset: page * rowsPerPage,
      limit: rowsPerPage,
      orderBy: property,
      orderType: isAsc ? 'desc' : 'asc'
    })
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    getShramans({
      offset: newPage * rowsPerPage,
      limit: rowsPerPage,
      orderBy: orderBy,
      orderType: order,
      search: filtes
    })
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    getShramans({
      offset: 0,
      limit: parseInt(event.target.value, 10),
      orderBy: orderBy,
      orderType: order,
      search: filtes
    })
  };

  const confirmDelete = (id) => {
    confirmAlert({
      title: "Confirm to delete",
      message: "Are you sure to do this.",
      buttons: [
        {
          label: "Yes",
          onClick: () => deleteShramans(id),
        },
        {
          label: "No",
        },
      ],
    });
  };

  return (
    <div className={classes.root}>
      <Loading loading={isLoading} />
      <Box className={classes.breadcrum}>
        <Breadcrumbs aria-label="breadcrumb" >
          <Link to="/" className={classes.link}>
            <HomeIcon className={classes.icon} />
            Home
          </Link>
          <Typography color="textPrimary" className={classes.link}>
            Shramans
          </Typography>
        </Breadcrumbs>
        <Button variant="contained" color='primary' onClick={() => setOpen(true)} className={classes.createBtn}>
          <AddCircleIcon />
          Create Shramans
        </Button>
      </Box>
      <ShramanTable
        shramans={shramans}
        handleClickOpen={() => {
          setOpen(true);
        }}
        setNewImage={setNewImage}
        newImage={newImage}
        count={count}
        setSelected={setSelected}
        selected={selected}
        order={order}
        orderBy={orderBy}
        rowsPerPage={rowsPerPage}
        page={page}
        setFiltes={setFiltes}
        confirmDelete={confirmDelete}
        handleRequestSort={handleRequestSort}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        isLoggedIn={isLoggedIn}
      />
      <ShramanDialoge
        open={open}
        gatcha={gatcha}
        openGatchaDialogue={openGatchaDialogue}
        setOpenGatchaDialogue={setOpenGatchaDialogue}
        openSamudhayDialogue={openSamudhayDialogue}
        setOpenSamudhayDialogue={setOpenSamudhayDialogue}
        openGuruDialogue={openGuruDialogue}
        setOpenGuruDialogue={setOpenGuruDialogue}
        samudhayGuru={samudhayGuru}
        samudhaygururesult={samudhaygururesult}
        gatchasamudhay={gatchasamudhay}
        samudhay={samudhay}
        guru={guru}
        setNewImage={setNewImage}
        newImage={newImage}
        selected={selected}
        setSelected={setSelected}
        selectedGatch={selectedGatch}
        setSelectedGatcha={setSelectedGatcha}
        selectedSamudhay={selectedSamudhay}
        setSelectedSamudhay={setSelectedSamudhay}
        selectedGuru={selectedGuru}
        setSelectedGuru={setSelectedGuru}
        validate={validate}
        gatchaValidate={gatchaValidate}
        guruValidate={guruValidate}
        samudhayValidate={samudhayValidate}
        errors={errors}
        caregivers={caregivers}
        getGatchSamudhay={getGatchSamudhay}
        handleClose={() => {
          setSelected(emptyShramans);
          setErrors(null);
          setOpen(false);
        }}
      />
    </div>
  );
}