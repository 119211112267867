import * as actionTypes from "./emergrncyActionType";
import axios from 'axios';
import { checkError } from './../error';

const baseUrl = process.env.REACT_APP_API_URL;

export const getEmergency = (data) => {
    const config = { headers: { Authorization: `Bearer ${localStorage.getItem("token")}` }, params: data };
      return (dispatch) => {
        dispatch(getEmergencyInit());
        axios.get(`${baseUrl}/emergency-number`, config)
        .then((response)=>{
            dispatch(getEmergencySuccess(response.data.data));
        })
        .catch(function (error) {
            checkError(error);
            dispatch(getEmergencyFailure());
        })
    };
};

export const getEmergencyInit = () => ({
    type: actionTypes.GET_EMERGENCY_LIST
});

export const getEmergencyFailure = data => ({
    type: actionTypes.GET_EMERGENCY_LIST_FAILURE
});

export const getEmergencySuccess = data => ({
    type: actionTypes.GET_EMERGENCY_LIST_SUCCESS,
    payload: { data }
});

export const createEmergency = (data) => {
    const config = { headers: { Authorization: `Bearer ${localStorage.getItem("token")}` } };
      return (dispatch) => {
        dispatch(createEmergencyInit());
        axios.post(`${baseUrl}/emergency-number/store`, data, config)
        .then((response)=>{
            dispatch(createEmergencySuccess(response.data.data));
        })
        .catch(function (error) {
            checkError(error);
            dispatch(createEmergencyFailure());
        })
    };
};

export const createEmergencyInit = () => ({
    type: actionTypes.CREATE_EMERGENCY
});

export const createEmergencyFailure = data => ({
    type: actionTypes.CREATE_EMERGENCY_FAILURE
});

export const createEmergencySuccess = data => ({
    type: actionTypes.CREATE_EMERGENCY_SUCCESS,
    payload: { data }
});

export const updateEmergency = (data) => {
    const config = { headers: { Authorization: `Bearer ${localStorage.getItem("token")}` } };
      return (dispatch) => {
        dispatch(updateEmergencyInit());
        axios.put(`${baseUrl}/emergency-number/${data.id}`, data, config)
        .then((response)=>{
            dispatch(updateEmergencySuccess(response.data.data));
        })
        .catch(function (error) {
            checkError(error);
            dispatch(updateEmergencyFailure());
        })
    };
};

export const updateEmergencyInit = () => ({
    type: actionTypes.UPDATE_EMERGENCY
});

export const updateEmergencyFailure = data => ({
    type: actionTypes.UPDATE_EMERGENCY_FAILURE
});

export const updateEmergencySuccess = data => ({
    type: actionTypes.UPDATE_EMERGENCY_SUCCESS,
    payload: { data }
});

export const deleteEmergency = (data) => {
    const config = { headers: { Authorization: `Bearer ${localStorage.getItem("token")}` } };
      return (dispatch) => {
        dispatch(deleteEmergencyInit());
        axios.delete(`${baseUrl}/emergency-number/${data}`, config)
        .then((response)=>{
            dispatch(deleteEmergencySuccess(response.data.data));
        })
        .catch(function (error) {
            checkError(error);
            dispatch(deleteEmergencyFailure());
        })
    };
};

export const deleteEmergencyInit = () => ({
    type: actionTypes.DELETE_EMERGENCY
});

export const deleteEmergencyFailure = data => ({
    type: actionTypes.DELETE_EMERGENCY_FAILURE
});

export const deleteEmergencySuccess = data => ({
    type: actionTypes.DELETE_EMERGENCY_SUCCESS,
    payload: { data }
});
