import { toast } from 'react-toastify';

export function checkError(err) {
    if (err?.response?.status === 401) {
        localStorage.removeItem("token");
        localStorage.removeItem("loggedAdmin");
        window.location.reload();
    }
    toast.error(err?.response?.data?.message, {
        position: toast.POSITION.TOP_RIGHT
    });
}