import { GuruPage } from './guru.component';
import { connect } from 'react-redux';
import { getGatcha } from "../../api/gatcha/gatchaAction";
import { getSamudhay } from "../../api/samudhay/samudhayAction";
import { getGuru, createGuru, updateGuru, deleteGuru } from "../../api/guru/guruAction";

const mapDispatchToProps = {
    getGatcha,
    getSamudhay,
    getGuru,
    createGuru,
    updateGuru,
    deleteGuru
};

const mapStateToProps = state => ({
    error: state.guruPage.isError,
    isLoading: state.guruPage.isLoading,
    isSaved: state.guruPage.isSaved,
    gatcha : state.gatchaPage.result,
    samudhay : state.samudhayPage.result,
    result: state.guruPage.result,
    count: state.guruPage.count,
    isLoggedIn : state.adminPage.isLoggedIn
});

export const GuruContainer = connect(mapStateToProps, mapDispatchToProps)(GuruPage);