import * as actionTypes from "./adminActionType";
import axios from 'axios';
import { toast } from 'react-toastify';
import { checkError } from './../error';

const baseUrl = process.env.REACT_APP_API_URL;
export const adminLogin = (data) => {
  return (dispatch) => {
    dispatch(adminLoginInit());
    axios.post(`${baseUrl}/admin/authenticate`, data)
      .then((response) => {
        toast.success("You are logged in!", {
          position: toast.POSITION.TOP_RIGHT
        });
        setLoginSession(response.data.data);
        dispatch(adminLoginSuccess(response.data.data));
      })
      .catch(function (error) {
        checkError(error);
        dispatch(adminLoginFailure());
      })
  };
};


const setLoginSession = (data) => {
  localStorage.setItem('loggedAdmin', JSON.stringify(data));
  localStorage.setItem('token', data.token);
}

export const adminLoginInit = () => ({
  type: actionTypes.ADMIN_LOGIN
});

export const adminLoginFailure = data => ({
  type: actionTypes.ADMIN_LOGIN_FAILURE
});

export const adminLoginSuccess = data => ({
  type: actionTypes.ADMIN_LOGIN_SUCCESS,
  payload: { data }
});


export const PasswordUpdate = (id, data) => {
  const config = {
    headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
  };
  return (dispatch) => {
    dispatch(passwordInit());
    if (data.password != '' && data.confirmPass != '') {
      axios
        .put(`${baseUrl}/admin/${id}`, data, config)
        .then((response) => {
          toast.success("Password Update Successfully", {
            position: toast.POSITION.TOP_RIGHT,
          });
          setLoginSession(response.data.data);
          dispatch(passwordSuccess(response.data.data));
        })
        .catch(function (error) {
          checkError(error);
          dispatch(passwordFailure());
        });
    }

  };
};

// update password

export const passwordInit = () => ({
  type: actionTypes.ADMIN_PASSWORD,
});

export const passwordFailure = (data) => ({
  type: actionTypes.ADMIN_LOGIN_FAILURE,
});

export const passwordSuccess = (data) => ({
  type: actionTypes.ADMIN_PASSWORD_SUCCESS,
  payload: { data },
});

export const ProfileUpdate = (id, data) => {
  const config = {
    headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
  };
  const handleLogout = () => {
    localStorage.removeItem("loggedAdmin");
    localStorage.removeItem("token");
    window.location.reload();
  };

  return (dispatch) => {
    dispatch(profileInit());
    if (data.email && data.firstName && data.lastName && data.phoneNo) {
      axios
        .put(`${baseUrl}/admin/${id}`, data, config)
        .then((response) => {
          toast.success("Profile Update Successfully", {
            position: toast.POSITION.TOP_RIGHT,
          });
          setLoginSession(response.data.data);
          dispatch(profileSuccess(response.data.data));
          setTimeout(() => {
            handleLogout();
          }, 500);
        })
        .catch(function (error) {
          checkError(error);
          dispatch(profileFailure());
        });
    }
  };
};

// update Profile

export const profileInit = () => ({
  type: actionTypes.ADMIN_PROFILE,
});

export const profileFailure = (data) => ({
  type: actionTypes.ADMIN_PROFILE_FAILURE,
});

export const profileSuccess = (data) => ({
  type: actionTypes.ADMIN_PROFILE_SUCCESS,
  payload: { data },
});