import { PharmacyPage } from './pharmacy.component';
import { connect } from 'react-redux';
import { getPharmacies, createPharmacy, updatePharmacy, deletePharmacy, getTimezone } from './../../api/pharmacy/pharmacyAction';

const mapDispatchToProps = {
    getPharmacies,
    createPharmacy,
    updatePharmacy,
    deletePharmacy,
    getTimezone
};

const mapStateToProps = state => ({
    error: state.pharmacyPage.isError,
    isLoading: state.pharmacyPage.isLoading,
    isSaved: state.pharmacyPage.isSaved,
    pharmacies: state.pharmacyPage.pharmacies,
    count: state.pharmacyPage.count,
    timezone:state.pharmacyPage.timezone,
    isLoggedIn : state.adminPage.isLoggedIn
});

export const PharmacyContainer = connect(mapStateToProps, mapDispatchToProps)(PharmacyPage);