import * as actionType from "./adminActionType";

const initialState = {
  isError: false,
  isLoading: false,
  isLoggedIn: localStorage.getItem("loggedAdmin") === null ? false : true,
  loggedAdmin: localStorage.getItem("loggedAdmin")
};

export const adminReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionType.ADMIN_LOGIN:
            return {
                ...state,
                isError: false,
                isLoading: true,
                isLoggedIn: false,
                loggedAdmin: {}
            };
        case actionType.ADMIN_LOGIN_FAILURE:
            return {
                ...state,
                isError: true,
                isLoading: false,
                isLoggedIn: false,
                loggedAdmin: {}
            };
        case actionType.ADMIN_LOGIN_SUCCESS:
            return {
                ...state,
                isError: false,
                isLoading: false,
                isLoggedIn: true,
                loggedAdmin: action.payload ? action.payload.data : {},
            };
       default:
            return state
            
    }
}
