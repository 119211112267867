import { connect } from 'react-redux';
import { ShramanPage } from './shramans.component';
import { getShramans, createShramans, updateShramans, deleteShramans } from '../../api/shramans/shramansAction';
import { getCaregiver } from '../../api/caregiver/caregiverAction';
import { getGatcha,createGatcha } from '../../api/gatcha/gatchaAction';
import { getSamudhay, getGatchSamudhay, createSamudhay } from '../../api/samudhay/samudhayAction';
import { getGuru,samudhayGuru, createGuru } from '../../api/guru/guruAction';
const mapDispatchToProps = {
    getGatcha,
    getSamudhay,
    getGatchSamudhay,
    getGuru,
    getShramans,
    createShramans,
    updateShramans,
    deleteShramans,
    getCaregiver,
    samudhayGuru,
    createGatcha,
    createSamudhay,
    createGuru
}

const mapStateToProps = state => ({
    error: state.shramansPage.isError,
    isLoading: state.shramansPage.isLoading,
    shramans: state.shramansPage.shramans,
    isSaved: state.shramansPage.isSaved,
    gatcha : state.gatchaPage.result,
    samudhay : state.samudhayPage.result,
    gatchasamudhay : state.samudhayPage.gatchresult,
    samudhaygururesult : state.guruPage.samudhaygururesult,
    guru : state.guruPage.result,
    caregivers: state.caregiverPage.caregivers,
    count: state.shramansPage.count,
    isLoggedIn : state.adminPage.isLoggedIn
});

export const ShramanContainer = connect(mapStateToProps, mapDispatchToProps)(ShramanPage);
