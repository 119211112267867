export const GET_MEDICAL_LIST = "GET_MEDICAL_LIST";
export const GET_MEDICAL_LIST_FAILURE = "GET_MEDICAL_LIST_FAILURE";
export const GET_MEDICAL_LIST_SUCCESS = "GET_MEDICAL_LIST_SUCCESS";

export const CREATE_MEDICAL = "CREATE_MEDICAL";
export const CREATE_MEDICAL_FAILURE = "CREATE_MEDICAL_FAILURE";
export const CREATE_MEDICAL_SUCCESS = "CREATE_MEDICAL_SUCCESS";

export const UPDATE_MEDICAL = "UPDATE_MEDICAL";
export const UPDATE_MEDICAL_FAILURE = "UPDATE_MEDICAL_FAILURE";
export const UPDATE_MEDICAL_SUCCESS = "UPDATE_MEDICAL_SUCCESS";

export const DELETE_MEDICAL = "DELETE_MEDICAL";
export const DELETE_MEDICAL_FAILURE = "DELETE_MEDICAL_FAILURE";
export const DELETE_MEDICAL_SUCCESS = "DELETE_MEDICAL_SUCCESS";
