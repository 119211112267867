import React from 'react';
import { makeStyles } from '@mui/styles';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import Typography from '@mui/material/Typography';
import Slide from '@mui/material/Slide';
import Grid from '@mui/material/Grid';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import DialogContent from "@mui/material/DialogContent";
import Input from "@mui/material/Input";
import TextField from '@mui/material/TextField';
import Divider from "@mui/material/Divider";
import InputAdornment from "@mui/material/InputAdornment";
import ClearIcon from "@mui/icons-material/Clear";
import Map from 'react-js-google-maps';

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  paper: {
    marginTop: theme.spacing(3),
    padding: theme.spacing(3),
  },
  formControl: {
    width: '100%',
    spacing: theme.spacing(2),
  },
  inputLabelText: {
    fontSize: "14px !important",
    color: "rgba(0,0,0,.5) !important",
    fontWeight: "600 !important",
    textTransform: "uppercase",
    marginTop: "0px"
  },
  mdText1: {
    color: "red",
    fontSize: "12px",
    fontWeight: 400,
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

// Map start
const setMarker = (map) => {
  const uluru = { lat: -25.363, lng: 131.044 };
  const marker = new window.google.maps.Marker({
    position: uluru,
    map: map
  });
  window.google.maps.event.addListener(marker, 'click', function () {
    const infoWindow = new window.google.maps.InfoWindow({
      content: "<b>Header</b><div>Info Content</div>"
    });
    infoWindow.open(map, marker);
  })
};
// Map end

export default function CaseReportDetailDialoge(props) {
  const { handleClose, scroll, detail, setSelected } = props;
  const classes = useStyles();

  const [value, setValue] = React.useState('2014-08-18T21:11:54');

  const handleChangeD = (newValue) => {
    setValue(newValue);
  };
  const [values, setValues] = React.useState({
    fname: "",
    lname: "",
    age: "",
    dob: "",
    gender: "",
    weight: "",
    height: "",
    bmi: "",
    simpton_detail: "",
    current_location: "",
  });

  const [errors, setErrors] = React.useState({});

  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    });
    setSelected({
      ...values,
      [event.target.name]: event.target.value,
    });
  };

  // const submitHandle = (event) => {
  //   event.preventDefault();
  //   setErrors(validate(values));
  // };
  const validate = (values) => {
    let errorss = {};
    let isError = false;
    const regex = /\S+@\S+\.\S+/;

    if (!values.fname && !values.lname && !values.age && !values.dob && !values.gender && !values.weight && !values.height && !values.bmi && !values.simpton_detail && !values.current_location) {
      isError = true;
      errorss.fname = "First name is required";
      errorss.lname = "Last name is required";
      errorss.age = "Age is required";
      errorss.dob = "Dob is required";
      errorss.gender = "Gender is required";
      errorss.weight = "Weight is required";
      errorss.height = "Height is required";
      errorss.bmi = "Bmi is required";
      errorss.simpton_detail = "Symptom_detail is required";
      errorss.current_location = "Current_location is required";
    } else if (!values.fname) {
      isError = true;
      errorss.fname = "First name is required";
    } else if (!values.lname) {
      isError = true;
      errorss.lname = "Last name is required";
    } else if (!values.age) {
      isError = true;
      errorss.age = "Age is required";
    } else if (!values.dob) {
      isError = true;
      errorss.dob = "Dob is required";
    } else if (!values.gender) {
      isError = true;
      errorss.gender = "Gender is required";
    } else if (!values.weight) {
      isError = true;
      errorss.weight = "Weight is required";
    } else if (!values.height) {
      isError = true;
      errorss.height = "Height is required";
    } else if (!values.bmi) {
      isError = true;
      errorss.bmi = "Bmi is required";
    } else if (!values.simpton_detail) {
      isError = true;
      errorss.simpton_detail = "simpton_detail is required";
    } else if (!values.current_location) {
      isError = true;
      errorss.current_location = "current_location is required";
    }
    if (!isError) {
      setErrors(values);
      // createCaregiver(values);
    }
    return errorss;
  };

  // Map start
  const mapOptions = {
    zoom: 4,
    center: { lat: -25.363, lng: 131.044 }
  }
  // Map end

  return (
    <div>
      <Dialog
        open={detail}
        onClose={handleClose}
        scroll={scroll}
        TransitionComponent={Transition}
        keepMounted
        aria-labelledby="scroll-dialog-title"
        aria-describedby="alert-dialog-slide-description"
        maxWidth="md"
        fullWidth={true}
      >
        <DialogContent>
          <ClearIcon
            onClick={handleClose}
            style={{ width: "25px", height: "25px", float: "right" }}
          />
          <div
            style={{
              marginTop: "10px",
              marginBottom: "20px",
            }}
          >
            <Typography
              style={{
                fontSize: "30px",
                fontWeight: 400,
                color: "rgba(0,0,0,.6)",
              }}
            >
              Case Report
            </Typography>
          </div>
          <form
            noValidate
            // onSubmit={(e) => {
            //   e.preventDefault();
            //   setErrors(validate(values));
            //   createCaregiver(values);
            //   if (!validate(selected)) {
            //     {
            //       selected.id
            //         ? updateCaregiver(selected)
            //         : createCaregiver(selected);
            //     }
            //   }

            // }}
            id="form">
            <Grid container spacing={2}>
              <Grid item xs={12} md={6} style={{ marginBottom: "15px" }}>
                <FormControl fullWidth variant="standard" style={{ margin: "15px 0px" }}>
                  <InputLabel htmlFor="standard-adornment-amount" className={classes.inputLabelText}>
                    Shraman Name
                  </InputLabel>
                </FormControl>
                <FormControl fullWidth variant="standard" style={{ margin: "15px 0px" }}>
                  <InputLabel htmlFor="standard-adornment-amount" className={classes.inputLabelText}>
                    Location
                  </InputLabel>
                </FormControl>
                <FormControl fullWidth variant="standard" style={{ margin: "15px 0px" }}>
                  <InputLabel htmlFor="img" className={classes.inputLabelText}>
                    latitude
                  </InputLabel>
                </FormControl>
                <FormControl fullWidth variant="standard" style={{ margin: "15px 0px" }}>
                  <InputLabel htmlFor="age" className={classes.inputLabelText}>
                    Lonsitude
                  </InputLabel>
                </FormControl>
                <FormControl fullWidth variant="standard" style={{ margin: "15px 0px" }}>
                  <InputLabel htmlFor="gender" className={classes.inputLabelText}>
                    Info
                  </InputLabel>
                </FormControl>
                <FormControl fullWidth variant="standard" style={{ margin: "15px 0px" }}>
                  <InputLabel htmlFor="gender" className={classes.inputLabelText}>
                    Is Emergency
                  </InputLabel>
                </FormControl>
                <FormControl fullWidth variant="standard" style={{ margin: "15px 0px" }}>
                  <InputLabel htmlFor="weight" className={classes.inputLabelText}>
                    Images
                  </InputLabel>
                </FormControl>
                <FormControl fullWidth variant="standard" style={{ margin: "15px 0px" }}>
                  <InputLabel htmlFor="height" className={classes.inputLabelText}>
                    Status
                  </InputLabel>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6} style={{ marginBottom: "15px" }}>
                <Map
                  id="map1"
                  apiKey="AIzaSyDnZHCNVuYH8lZSMZtuHzJ4677eUi6AE8w"
                  mapOptions={mapOptions}
                  style={{ width: 459, height: 357, float: "left" }}
                  onLoad={setMarker}
                />
              </Grid>
            </Grid>
            <Divider variant="middle" style={{ margin: "20px auto" }} />
            <Grid item xs={12} style={{ float: "right", margin: "10px auto" }}>
              {/* <Button
                onClick={handleClose}
                autoFocus
                variant="contained"
                color="primary"
                style={{
                  textTransform: "capitalize",
                  minWidth: "120px",
                  marginRight: "5px",
                  backgroundColor: "#e2e4e6",
                  color: "#293038",
                  borderRadius: "20px",
                }}
              >
                Cancel
              </Button> */}
              {/* <Button
                onClick={handleClose}
                autoFocus
                variant="contained"
                color="primary"
                form="form"
                type="submit"
                style={{
                  textTransform: "capitalize",
                  minWidth: "120px",
                  backgroundColor: "#FF8427",
                  color: "rgb(255, 255, 255)",
                  borderRadius: "20px",
                }}
              >
                Close
              </Button> */}
            </Grid>
          </form>
        </DialogContent>
      </Dialog>
    </div >
  );
}