export const GET_SHRAMANS_LIST = "GET_SHRAMANS_LIST";
export const GET_SHRAMANS_LIST_FAILURE = "GET_SHRAMANS_LIST_FAILURE";
export const GET_SHRAMANS_LIST_SUCCESS = "GET_SHRAMANS_LIST_SUCCESS";

export const CREATE_SHRAMANS = "CREATE_SHRAMANS";
export const CREATE_SHRAMANS_FAILURE = "CREATE_SHRAMANS_FAILURE";
export const CREATE_SHRAMANS_SUCCESS = "CREATE_SHRAMANS_SUCCESS";

export const UPDATE_SHRAMANS = "UPDATE_SHRAMANS";
export const UPDATE_SHRAMANS_FAILURE = "UPDATE_SHRAMANS_FAILURE";
export const UPDATE_SHRAMANS_SUCCESS = "UPDATE_SHRAMANS_SUCCESS";

export const DELETE_SHRAMANS = "DELETE_SHRAMANS";
export const DELETE_SHRAMANS_FAILURE = "DELETE_SHRAMANS_FAILURE";
export const DELETE_SHRAMANS_SUCCESS = "DELETE_SHRAMANS_SUCCESS";
