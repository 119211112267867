export const GET_BLOODBANKS_LIST = "GET_BLOODBANKS_LIST";
export const GET_BLOODBANKS_LIST_FAILURE = "GET_BLOODBANKS_LIST_FAILURE";
export const GET_BLOODBANKS_LIST_SUCCESS = "GET_BLOODBANKS_LIST_SUCCESS";

export const CREATE_BLOODBANKS = "CREATE_BLOODBANKS";
export const CREATE_BLOODBANKS_FAILURE = "CREATE_BLOODBANKS_FAILURE";
export const CREATE_BLOODBANKS_SUCCESS = "CREATE_BLOODBANKS_SUCCESS";

export const UPDATE_BLOODBANKS = "UPDATE_BLOODBANKS";
export const UPDATE_BLOODBANKS_FAILURE = "UPDATE_BLOODBANKS_FAILURE";
export const UPDATE_BLOODBANKS_SUCCESS = "UPDATE_BLOODBANKS_SUCCESS";

export const DELETE_BLOODBANKS = "DELETE_BLOODBANKS";
export const DELETE_BLOODBANKS_FAILURE = "DELETE_BLOODBANKS_FAILURE";
export const DELETE_BLOODBANKS_SUCCESS = "DELETE_BLOODBANKS_SUCCESS";

export const GET_TIMEZONE = "GET_TIMEZONE";
export const GET_TIMEZONE_FAILURE = "GET_TIMEZONE_FAILURE";
export const GET_TIMEZONE_SUCCESS = "GET_TIMEZONE_SUCCESS";
